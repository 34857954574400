.reportStatusCategoryCloseFilterIcon {
  height: 16px;
  width: 16px;
  position: absolute;
  right: -7px;
  top: -7px;
}

.SpecButton__SpecSelected,
.SpecButton__SpecNotSelected {
  display: flex;
  flex-direction: row;
  opacity: 1;
  align-items: center;
}

.SpecButton__SpecSelected {
  border: 2px solid #26abe1;
  border-radius: 4px;
  background-color: #eff2f3 !important;
}

.SpecButton__SpecSelected:hover {
  background-color: #eff2f3 !important;
  border-color: #26abe1 !important;
}

.SpecButton__Spec--disabled {
  opacity: 0.2;
}

.specCategoryIcon {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
}

.reportStatusCategory {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #19305a;
}
