.SampleSubmission__EditImageBtns {
  display: flex;
  gap: 40px;
  align-items: center;
}

.SampleSubmission__EditImageBtns--disabled {
  display: flex;
  gap: 40px;
  align-items: center;
  opacity: 0.7;
  pointer-events: none;
}

.SampleSubmission__RotateButton {
  cursor: pointer;
}