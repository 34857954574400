.customer-info-section-prod-env-checkbox {
 display: none;
}

.signup-invite-customer-info-main {
  display: flex;
  flex-flow: row wrap;
  row-gap: 16px;
}

.signup-invite-customer-info-main-left,
.signup-invite-customer-info-main-right {
  width: 50%;
}

.signup-invite-customer-info-main,
.signup-invite-prod-env-info-container {
  display: flex;
  column-gap: 10%;
  flex-flow: row wrap;
  row-gap: 30px;
}

.signup-invite-customer-info-main {
  column-gap: 10%;
}

.signup-invite-prod-env-info-container {
  height: 70%;
  padding-bottom: 0.5%;
}

.signup-invite-prod-env-info-container-center {
  justify-content: center;
}

.info-input-section-inputs {
  height: calc(100% - 30px);
}

.signUpInviteFormContainer {
  width: 78%;
  height: calc(100vh - 100px);
  box-shadow: -30px -30px 80px rgb(193 200 223 / 5%), 30px 30px 80px rgb(104 127 192 / 10%);
  background: #ffffff;
  margin: 50px auto 50px;
  padding: 3% 5% 3% 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signUpInviteFormContainer__Form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4%;
  row-gap: 16px;
  padding: 0;
  height: 93%;
}

.customer-info-section-prod-env-type-choices {
  display: flex;
  justify-content: space-between;
  column-gap: 8%;
}

.customer-info-section-prod-env-type  {
  width: 46%;
}

.signup-invite-customer-info-main-partial {
  width: 45%;
}

@media (max-width: 940px) {
  .signup-invite-customer-info-main-partial {
    width: 100%;
  }

  .signUpInviteFormContainer-add-hight {
    min-height: calc(100vh + 150px);
  }

  .signUpInviteFormContainer-add-double-height {
    min-height: calc(100vh + 800px);
  }
}