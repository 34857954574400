.BuilderSave__ConfirmButton {
  background: #26abe1;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
  cursor: pointer;
  min-width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 6px;
}

.BuilderSave__ConfirmButton:focus-visible {
  border: 2px solid #4096ff;
}

.BuilderSave__ConfirmButton--disabled {
  background: #b3e0f2;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
  cursor: default;
  min-width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 6px;
  pointer-events: none;
}

.BuilderSave__ConfirmButton:hover {
  box-shadow: 0 6px 12px -6px rgba(24, 39, 75, 0.12),
    0 8px 24px -4px rgba(24, 39, 75, 0.08);
}
