.SampleSubmission__InviteModal .ant-modal-content {
  padding: 0;
  border-radius: 8px;
}

.InviteModal__BodyContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 25px 20px;
  gap: 16px;
}

.InviteModal__Title {
  font-family: "Roboto Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #182534;
  margin-bottom: 15px;
}
