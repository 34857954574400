.SelectLocationForm {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.SelectLocationForm__SearchBar {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.SelectLocationForm__SearchBar .searchInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.SelectLocationForm__SearchBtn {
  width: 52px;
  height: 40px;
  background: #26a1d2;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectLocationForm__Img-whiteMagnifyIcon {
  width: 14px;
  height: 14px;
}

.SelectLocationForm__TableScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.SelectLocationForm__TableScrollbar .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #133e8c !important;
}

.SelectLocationForm__Table {
  width: 100%;
  table-layout: fixed;
}

.SelectLocationForm__Table .ReactVirtualized__Table__headerRow {
  background: #F2F6FA;
}

.SelectLocationForm__Table .ReactVirtualized__Table__headerColumn:not(:first-of-type),
.SelectLocationForm__Table .ReactVirtualized__Table__rowColumn:not(:first-of-type) {
  flex: 1 !important;
}

.SelectLocationForm__Table .ReactVirtualized__Table__headerColumn {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  padding: 20px;
  background: #F2F6FA;
  color: #182534;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.SelectLocationForm__Table .ReactVirtualized__Table__rowColumn {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  color: #182534;
  padding: 20px;
}

.SelectLocationForm__Table .SelectLocationForm__TableCheckboxHeader,
.SelectLocationForm__Table .SelectLocationForm__TableCheckboxCell {
  padding: 20px 12px 20px 10px;
  width: 40px;
}

.SelectLocationForm__Table .SelectLocationForm__TableCheckboxHeader {
  justify-content: center;
}

.SelectLocationForm__Table .SelectLocationForm__TableZone {
  padding-left: 0;
}

.SelectLocationForm__LocationTableCheckbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 0;
  border: 2px solid #6C7E8E !important;
}

.SelectLocationForm__LocationTableCheckbox .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  border-color: #26ABE1 !important;
  border-radius: 0.5px;
}

.ant-select.SelectLocation__ExtraFieldSelect {
  width: 100px;
}

.ant-select.SelectLocation__ExtraFieldSelect>.ant-select-selector {
  border: 1px solid #e1e9f1;
  background: #f6f8fa;
  color: #111a24;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ant-select.SelectLocation__ExtraFieldSelect .ant-select-selection-placeholder {
  color: #6c7e8e;
}

.ant-select.SelectLocation__ExtraFieldSelect:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover>.ant-select-selector,
.ant-select.SelectLocation__ExtraFieldSelect.ant-select-focused:not(.ant-select-customize-input):not(.ant-pagination-size-changer)>.ant-select-selector {
  border-color: #77ccee;
  box-shadow: none;
}

.ant-select.SelectLocation__ExtraFieldSelect .ant-select-arrow .anticon svg {
  color: #6c7e8e;
}

.SelectLocationForm__TableRowCheckboxLoading {
  font-size: 18px;
  margin: auto;
  color: #26a1d2;
}

.SelectLocation__ExtraField__Autocomplete .SelectLocation__ExtraField__AutocompleteLabel {
  display: none;
}

.SelectLocation__ExtraField__Autocomplete .SampleDetailsAutocompleteInput .ant-select-auto-complete .ant-input:hover {
  border: 1px solid #26a1d2 !important;
}

.SelectLocation__ExtraField__Autocomplete .SampleDetailsAutocompleteInput {
  max-width: 120px;
  width: 100%;
}

.SelectLocationForm__Loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 9px 16px 9px 8px;
  min-height: 54.5px;
}

.SelectLocationForm__Table .ReactVirtualized__Grid::-webkit-scrollbar {
  width: 6px;
  /* Width of the vertical scrollbar */
  height: 6px;
  /* Height of the horizontal scrollbar */
}

.SelectLocationForm__Table .ReactVirtualized__Grid::-webkit-scrollbar-thumb {
  background-color: #c4d2df;
  /* Color of the scrollbar thumb */
  border-radius: 3px;
  /* Round the corners of the scrollbar thumb */
  cursor: pointer;
}

.SelectLocationForm__Table .ReactVirtualized__Grid::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the scrollbar track */
}

/* .SelectLocationForm__Table .ReactVirtualized__Grid {
  scrollbar-color: #c4d2df transparent;
} */

.SelectLocationForm__TableContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SelectLocationForm__TableContentTotalTests {
  text-align: center;
}

.SelectLocationForm__TableContentTotalTests .ReactVirtualized__Table__headerTruncatedText {
  width: 100%;
}

.SelectLocationForm__NoResult {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
}