.ReportsTable th {
  vertical-align: middle;
  border: none;
  border-top: 0.5px solid #f9fbfccd;
  word-break: break-word;
  padding: 0.75rem;
  position: sticky;
  top: 0px;
  border-top: 0 !important;
  border-bottom: 0 !important;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

th.ReportsTableHeaderCell {
  text-align: center;
  background-color: #eff2f3;
  color: #375393;
}

th.ReportsTableHeaderCell--linked-field {
  text-align: left;
  background-color: #758ab9;
  color: #fdfdfd;
  z-index: 1;
}

th.ReportsTableHeaderCell:before,
th.ReportsTableHeaderCell--linked-field:before {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  background-color: #eff2f3;
  content: "";
  display: block;
}

th.ReportsTableHeaderCell:after,
th.ReportsTableHeaderCell--linked-field:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eff2f3;
  content: "";
  display: block;
}

.ReportTableHeaderCell__TitleDiv {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.ReportTableHeaderCell__TitleDiv > span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  hyphens: auto;
}

th.ReportsTableHeaderCell--editable:not(.ReportsTableHeaderCell--linked-field)
  .ReportTableHeaderCell__TitleDiv {
  padding: 0 9.5px;
}

th.ReportsTableHeaderCell--editable:hover .ReportTableHeaderCell__TitleDiv {
  padding: 0;
}

th.ReportsTableHeaderCell--editable .ReportTableHeaderCell__TitleDiv img {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}

th.ReportsTableHeaderCell--editing {
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 1;
}

th.ReportsTableHeaderCell--editing input {
  width: 100%;
  color: #375393;
  font-family: Roboto;
  font-size: 14px;
}

th.ReportsTableHeaderCell--editing input:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

th.ReportsTableHeaderCell--editing input:focus {
  border: 2px solid #26abe1;
  border-radius: 3px;
}

.ReportsTableHeaderCell__InfoIcon {
  margin-left: 7px;
  height: 20px;
  padding: 3.3px;
}
