.WeekMonthCheckbox {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
}

.WeekMonthCheckbox__WeekDayInput[type="checkbox"] {
  display: none;
}

.WeekMonthCheckbox__WeekDay {
  width: 28px;
  height: 28px;
  margin: 0;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #EAF0F5;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  color: #6C7E8E;
  cursor: pointer;
  user-select: none;
}

.WeekMonthCheckbox__WeekDayInput[type="checkbox"]:checked+.WeekMonthCheckbox__WeekDay {
  background: #26ABE1;
  color: #FFFFFF;
}