.signup-cancel {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.row.signupWhiteContainer {
  width: calc(100% - 300px);
  height: calc(100vh - 200px);
  box-shadow: -30px -30px 80px rgba(193, 200, 223, 0.05),
    30px 30px 80px rgba(104, 127, 192, 0.1);
  background: #ffffff;
  margin: 100px auto 100px;
  padding: 35px 0 0 35px;
  overflow: hidden;
  min-height: 790px;
  min-width: 610px;
  position: relative;
  flex-wrap: nowrap;
}

.signupWhiteContainer.signupWhiteContainer-invalid-link-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 0 0 35px 0;
}

.signupWhiteContainer.signupWhiteContainer-invalid-link-container
  .no-results-gif-common {
  height: auto;
}

.auth-inner .signupcancel {
  margin-left: 544px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
}

.signupForm {
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 50%;
  min-width: 597px;
}

.signupImage {
  text-align: right;
  position: relative;
  flex-shrink: 2;
  flex-basis: 50%;
  overflow: hidden;
  min-width: 0;
  min-height: 550px;

  display: flex;
}

.signupImageCharacters {
  /* position: absolute; */
  width: 50%;
  min-width: 380px;
  margin: auto;
  z-index: 2;
}

.signupImageBubbles {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  min-width: 597px;
  object-fit: fill;
}

.SignUp__LoginButton {
  padding: 5px 10px;
  height: 34px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  margin-top: 10px;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.SignUp__LoginButton:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}
