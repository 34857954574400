.ProductReport__MainContainer {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 72px;
  padding-right: 52px;
  min-width: 801px;
  display: flex;
  flex-direction: column;
}

.productReportSearchDiv {
  padding-top: 28px;
}

.productReportSpecsBlockDiv {
  padding-bottom: 16px;
  padding-top: 16px;
}

.ProductReport__LoadingContainer {
  position: absolute;
  inset: 0;
  background-color: #fdfdfd76;
  z-index: 2;
}

/* Scrollbar */
.ProductReport__MainContainer .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
  width: 6px !important;
}

.ProductReport__MainContainer
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #19305a !important;
}

.ProductReport__MainContainer
  .specManagerCard
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 4.42px !important;
}
