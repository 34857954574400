.ant-drawer-content-wrapper {
  border-radius: 16px 0 0 16px;
}

.DrawerNav__Container .ant-drawer-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f2f6fa;
  border: 1px solid #eaf0f5;
  padding: 16px;
  box-shadow: 0px 10px 15px -3px #1018281a;
}

.DrawerNav__Container.ant-drawer-content {
  border-radius: 16px 0 0 16px;
}
.userIcon circle {
  fill: #fff;
}
.DrawerNav__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.DrawerNav__HeaderAccountInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.DrawerNav__HeaderAccountInfo svg {
  min-width: 32px;
  min-height: 32px;
}
.DrawerNav__HeaderAccountInfo > svg > path {
  fill: #6c7e8e;
}

.DrawerNav__UserName {
  font-family: "Roboto Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100px;
}

.DrawerNav__HeaderCloseButton {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.DrawerNav__HeaderCloseButton:hover > path {
  fill: #26abe1;
}

.DrawerNav__LogOutButton {
  color: #e63559;
  background: #fff;
}

.DrawerNav__LogOutButton--image {
  margin-right: 5px;
  fill: #e63559;
}

.DrawerNav__LogOutButton--hover {
  fill: #26abe1;
}

.DrawerNav__RoutesContainer,
.DrawerNav__CompaniesContainer {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.DrawerNav__RoutesContainer > span {
  width: 100%;
  text-align: left;
  font-family: "Roboto";
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  border-radius: 16px;
}

.DrawerNav__RoutesContainer > span:hover {
  background: #fff;
  color: #26abe1;
}

.DrawerNav__CompaniesContainer--header {
  border-bottom: 1px solid #eaf0f5;
  padding: 12px;
  color: #6c7e8e;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
}

.plusIcon {
  margin-right: 5px;
}

.DrawerNav__AddCompany {
  background-color: #f8fafc;
}

.DrawerNav__AddCompany__Btn {
  width: 100%;
  margin-top: 10px;
}

.DrawerNav__Version {
  color: #ffffff;
}

.DrawerNav__Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
