.ant-message .AppUpdate__ESV .ant-message-notice-content {
  position: relative;
  padding: 18px 24px;
}

.AppUpdate__ESV .ant-message-custom-content {
  display: flex;
}

.AppUpdate__ESV .anticon.anticon-exclamation-circle {
  display: none;
}

.AppUpdateContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  font-family: "Roboto Medium";
}

.AppUpdate__Text {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.AppUpdate__Title {
  font-size: 14px;
  color: #375393;
}

.AppUpdate__Body {
  font-size: 12px;
  color: #758ab9;
}

.AppUpdate__Buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: space-between;
  width: 100%;
}

.AppUpdate__Buttons > .StyledButton {
  font-size: 12px;
  padding: 5px 15px;
}
