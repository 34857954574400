.env-report-view-header-section {
  display: flex;
  padding: 0px 10px;
  gap: 16px;
  height: 60px;
  flex-grow: 1;
}

.env-report-view-header-block {
  background: #B3BFDB;
  border-radius: 4px;
  width: 20px;
}

.env-report-view-header-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  font-family: 'Roboto Medium';
  font-size: 14px;
  color: #19305A;
}