.TemplateBuilder__LogoContainer {
  width: 186px;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6f8fa;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 14px;
  gap: 7px;
  /* margin-top: 24px; */
  position: relative;
}

.TemplateBuilder__LogoContainer--noImage {
  width: 186px;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6f8fa;
  border-radius: 8px;
  padding: 14px;
  gap: 7px;
  /* margin-top: 24px; */
  position: relative;
  cursor: pointer;
  border: 1px solid #eaf0f5;
}

.TemplateBuilder__LogoContainer--noImage:hover {
  border: 1px solid #26abe1;
}

.TemplateBuilder__ImageContainer {
  width: 186px;
  height: 93px;
  object-fit: contain;
}

.TemplateBuilder__ImageContainer__RemoveIcon {
  position: absolute;
  height: 18px;
  width: 18px;
  top: -6px;
  right: -7px;
  cursor: pointer;
}

.TemplateBuilder__LogoImage {
  width: 24px;
  cursor: pointer;
}

.TemplateBuilder__LogoText {
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  /* color: rgba(0, 0, 0, 0.6); */
  color: #26abe1;
}
