.step-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-circle-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #26ABE1;
}

.step-circle-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 70%;
  background: #EFF2F3;
  color: #26ABE1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
}

.step-circle-inner-text {
  position: absolute;
  margin: -30px 0px 0px 15px;
  color: #26ABE1;
}

.step-circle-title {
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #19305A;
  margin: 11px 0px 0px -20px;
}

.step-circle-title-opt {
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #97a0b1;
  margin: 30px 0px 0px -2px;
}

.step-circle-grey-out {
  opacity: 0.4;
}

.step-circle-check {
  position: absolute;
  margin: 11px 0px 0px -31.5px;
  width: 24px;
  height: 16px;
  fill: white;
}

.step-circle-error-excalmation {
  position: absolute;
  margin: 9px 0px 0px -29.9px;
}