.MapView__TabBar {
  max-width: 100%;
  overflow: hidden;
  height: 41px;
  display: flex;
  padding-left: 6px;
  align-items: flex-end;
}

.MapView__TabBar .ant-tabs {
  flex-grow: 1;
  max-width: calc(100% - 6px);
}

.MapView__TabBar .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}

.MapView__TabBar .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: unset;
}

.MapView__TabBar .ant-tabs .ant-tabs-nav-list {
  align-items: flex-end;
}

.MapView__TabBar .ant-tabs >.ant-tabs-nav .ant-tabs-nav-operations {
  align-items: flex-end;
}

/* Tab styling */
.MapView__TabBar .ant-tabs .ant-tabs-tab {
  height: 32px;
  background-color: #e5e5e5;
  color: #19305a;
  border: none;
  padding: 0px;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab-btn:active,
.MapView__TabBar .ant-tabs .ant-tabs-tab-btn:focus,
.MapView__TabBar .ant-tabs .ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.MapView__TabBar .ant-tabs .ant-tabs-tab-disabled .ant-tabs-tab-btn:focus {
  color: #19305a;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled {
  opacity: 0.7;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  height: 41px;
  background-color: #26abe1;
  color: #f9f9f9;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: unset;
}

.MapView__TabBar .ant-tabs .ant-tabs-nav-add {
  height: 32px;
  background-color: #e5e5e5;
  border: none;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab.ant-tabs-tab-with-remove {
  width: 175px;
  background-color: #e5e5e5;
  border: 1px solid #26abe1;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab.ant-tabs-tab-with-remove .ant-tabs-tab-remove {
  display: none;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab.ant-tabs-tab-with-remove .mapTitleDeleteIcon {
  margin-left: 4px;
}

.MapViewTabTitle {
  height: 100%;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px;
  max-width: 288px;
  overflow: hidden;
  text-overflow: ellipsis;
}


.MapView__TabBar .ant-tabs .ant-tabs-tab .editMapTitleDiv {
  width: 100%;
  height: 100%;
  padding: 4px 4px 4px 8px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.MapView__TabBar .ant-tabs .ant-tabs-tab .editMapTitleDiv input {
  width: 100%;
  background-color: #f9f9f9;
  font-size: 13px;
  border: none;
  border-radius: 3px;
  padding: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;
}

/* Dropdown menu styling */
.MapTabs__Dropdown.ant-tabs-dropdown .ant-tabs-dropdown-menu {
  background-color: #f9f9f9;
}

.MapTabs__Dropdown.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  color: #19305a;
}

.MapTabs__Dropdown.ant-tabs-dropdown .ant-tabs-dropdown-menu-item:hover {
  /* color: #26abe1; */
  background-color: #e5e5e5;
}