#productReportSpecManagerSpecsQualitative{
    width: 360px;
    margin: auto;
}

.productReportSpecManagerSelectButtonNegative{
    color: #375393;
}

.productReportSpecManagerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}