label.BillingAddressSection__Checkbox.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-in-form-item {
  width: auto;
}

.BillingAddressSection__Checkbox.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
  cursor: default;
}

.BillingAddressSection__Checkbox .ant-checkbox .ant-checkbox-inner,
.BillingAddressSection__Checkbox .ant-checkbox-checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.BillingAddressSection__Checkbox .ant-checkbox+span {
  /* margin-top: px; */
  font-family: "Roboto Bold";
  font-size: 16px;
}

.BillingAddressSection__Checkbox .ant-checkbox .ant-checkbox-inner:after {
  inset-inline-start: 27.5%;
}

.BillingAddressSection__Checkbox .ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
}

.BillingAddressText {
  font-family: "Roboto Medium";
  font-size: 16px !important;
  color: #19305a !important;
  margin-top: 10px;
}

.BillingAddressText.ant-input-disabled {
  font-family: 'Roboto';
  font-size: 14px;
}