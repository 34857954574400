.ReportsTable tbody tr {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  color: #19305a;
  height: 74px;
}

.ReportsTable tr.ReportsTableRow--selection-enabled:hover,
.ReportsTable tr.ReportsTableRow--selected {
  background-color: #60e1ff1f;
  cursor: pointer;
}

.ReportsTable
  tr.ReportsTableRow--selection-enabled:hover
  td.ReportsTable__LinkedFieldCell,
.ReportsTable tr.ReportsTableRow--selected td.ReportsTable__LinkedFieldCell {
  background-color: #60e1ff1f;
}

.ReportsTable tr.ReportsTableRow--selection-disabled {
  background-color: rgba(239, 242, 243, 0.25);
  cursor: default;
}

.ReportsTable tr.ReportsTableRow--selection-disabled:hover,
.ReportsTable
  tr.ReportsTableRow--selection-disabled:hover
  td.ReportsTable__LinkedFieldCell {
  background-color: rgba(239, 242, 243, 0.25);
}

.ReportsTable td {
  vertical-align: middle;
  border: none;
  word-break: break-word;
  padding: 0.75rem;
}

.ReportsTable td.ReportsTable__FieldCell {
  text-align: center;
  border-bottom: 2px solid #eff2f3;
}

.ReportsTable td.ReportsTable__EmptyCell {
  padding-right: 0px;
  padding-left: 0px;
}

.ReportsTable td.ReportsTable__LinkedFieldCell {
  background-color: #f9f9f9;
  flex-wrap: wrap;
  border-bottom: 2px solid #eff2f3;
  width: 250px;
  position: relative;
}

.ReportsTable td.ReportsTable__SpecCell {
  text-align: left;
  padding-right: 12px;
  cursor: pointer;
  width: 208px;
  border-bottom: 2px solid #eff2f3;
}

.ReportsTable td.ReportsTable__ActionsCell {
  text-align: center;
  width: 180px;
  border-bottom: 2px solid #eff2f3;
}
