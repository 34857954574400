.productReportEditSpecTableScrollBar {
  width: 100%;
  height: 100%;
  /* min-height: 350px; */
}

#productReportEditSpecTable {
  padding-right: 10px;
}

.specTable tbody tr td.productReportEditSpecTableTest {
  text-align: center;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}

.specTable tbody tr td.productReportEditSpecTableTestType {
  text-align: center;
  padding: 0px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}

.specTable tbody tr td.productReportEditSpecTableSpecs {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  /* height: 48px; */
  min-height: 48px;
}

.productReportEditSpecTableSpecsDefaultValue {
  display: block;
  color: #b3bfdb;
  padding: 10px 15px;
  width: 360px;
  text-align: center;
  line-height: 24px;
}

.productReportEditSpecMRL {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.productReportEditSpecMRL .productReportEditSpecMRLText {
  color: #375393;
}