.AnalyticsDatePickerPopover {
  top: 0px !important;
  z-index: 999;
}

@media only screen and (max-height: 710px) {
  .AnalyticsDatePickerPopover {
    top: -127px !important;
  }
}

.AnalyticsCalendar .Calendar__Head {
  background-color: #f4fbfe;
  color: #26abe1;
}

/* MonthYearSelect styling */
.AnalyticsCalendar .MonthYearSelect .ant-select .ant-select-selector {
  color: #6c7e8e;
}

.AnalyticsCalendar
  .MonthYearSelect
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover:hover
  .ant-select-selector {
  border-color: #26abe1;
}

.AnalyticsCalendar
  .MonthYearSelect
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #26abe1;
  box-shadow: 0 0 0 2px #26ace118;
  outline: 0;
}

/* Days styling */
.AnalyticsCalendar
  .Calendar__Body
  .flatpickr-day:not(.prevMonthDay):not(.nextMonthDay):not(.today) {
  color: #111a24;
}

.AnalyticsCalendar .Calendar__Body .flatpickr-day.today, /* Change today's text color when not selected or end/start of a range */
.AnalyticsCalendar .Calendar__Body .flatpickr-day.today.inRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.today.prevMonthDay.inRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.today.nextMonthDay.inRange {
  border: none;
  color: #26abe1;
}

.AnalyticsCalendar .Calendar__Body .flatpickr-day.nextMonthDay:focus, /* Hover/focus for days from prev/next month shown in current month */
.AnalyticsCalendar .Calendar__Body .flatpickr-day.nextMonthDay:hover,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.prevMonthDay:focus,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.prevMonthDay:hover,
.AnalyticsCalendar .Calendar__Body .flatpickr-day:focus,
.AnalyticsCalendar .Calendar__Body .flatpickr-day:hover {
  outline: 0;
  background-color: #bfe6f9;
  border-color: #bfe6f9;
  color: #111a24;
}

.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange, /* Selected, end/start day styles. Not applied to in-between days of range. */
.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange.today,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange.inRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange.nextMonthDay,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange.prevMonthDay,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange:focus,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.endRange:hover,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected.today,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected.inRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected.nextMonthDay,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected.prevMonthDay,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected:focus,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.selected:hover,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange.today,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange.inRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange.nextMonthDay,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange.prevMonthDay,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange:focus,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.startRange:hover {
  color: #111a24;
  background-color: #bfe6f9;
  border-color: #bfe6f9;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.AnalyticsCalendar .Calendar__Body .flatpickr-day.prevMonthDay.inRange, /* In-between days of range */
.AnalyticsCalendar .Calendar__Body .flatpickr-day.nextMonthDay.inRange,
.AnalyticsCalendar .Calendar__Body .flatpickr-day.inRange {
  background-color: rgba(222, 242, 251, 0.5);
  border-color: rgba(222, 242, 251, 0.5);
  color: #111a24;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

/* Buttons styling */
.AnalyticsCalendar .Calendar__CloseButton path {
  fill: #26abe1;
}

.AnalyticsCalendar .Calendar__ClearLink,
.AnalyticsCalendar .Calendar__ClearLink--inactive {
  color: #26abe1;
}
