.COABuilder__Section.COABuilder__TestReportContainer {
  padding: 37px 75px 28px 75px;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}

.COABuilder__TestReport__SampleID {
  color: #1f2d3b;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 14px;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  width: 430px;
}

.COABuilder__TestReportTable {
  height: auto;
  width: 100%;
  overflow-x: unset;
}

.COABuilder__TestReportTable > tbody {
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: auto;
  width: 100%;
}

.COABuilder__TestReportTable tr {
  display: flex;
  gap: 2px;
  min-height: 36px;
}

.COABuilder__TestReportTable th,
.COABuilder__TestReportTable td {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  flex-basis: 25%;
  max-width: 25%;
}

.COABuilder__TestReportTable__Header > th {
  color: #506375;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 20px;
  font-weight: normal;

  background-color: #c4d2df;
}

.COABuilder__TestReportTable__Row > td {
  color: #505050;
  font-size: 10px;
  font-family: "Roboto";
  line-height: 12px;
  text-align: center;

  background-color: #f2f6fa;
}

.COABuilder__TestReportTable__Row > td > span {
  width: 95px;
}
