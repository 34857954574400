.TestList__SelectedListScrollbar {
  /* height: 320px !important; */
  margin: 20px 0;
}

.TestList__SelectedList {
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0 12px;
}

.TestList__SelectedList .TestList__SelectedListItem {
  box-sizing: border-box;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(38, 171, 225, 1);
  border: 1px solid #26abe1;
  border-radius: 8px;
  background: rgba(242, 246, 250, 1);
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.TestList__SelectedList .TestList__SelectedListItem .TestList__SelectedItemCloseIcon {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TestList__SelectedList .TestList__SelectedListItem.TestList__SelectedListItem--disabled {
  color: #AFBDCA;
  border: 1.5px solid #e1e9f1;
  background-color: #ffffff;
  cursor: default !important;
  font-weight: 400;
}

.TestList__SelectedList .TestList__SelectedListItem.TestList__SelectedListItem--disabled .TestList__SelectedItemCloseIcon {
  display: none;
}

#TestList__ScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

#TestList__ScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}