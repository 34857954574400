.Analytics__ProductCard__Text {
  line-height: 17px;
}

.Analytics__ProductCard__FieldText {
  color: #375393;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 18px;
}
