.docHeaderSection {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 14px 70px 14px 70px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgba(9, 30, 66, 0.05),
    0px 0px 5px rgba(9, 30, 66, 0.08);
  margin-top: -10px;
}

.docHeaderTitle {
  font-family: Roboto;
  font-size: 24px;
  line-height: 32px;
  color: #19305a;
}

.docHeaderStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 24px;
  color: #375393;
  gap: 80px;
  height: 100%;
}

.docMainContainer {
  margin: 16px 24px 0px 24px;
  padding-bottom: 140px;
  height: 100%;
}

/* Table */
.docFileTable {
  width: 100%;
}

table .docFileTableHeadInside > th {
  padding: 8px 25px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #19305a;
  border-bottom: 1px solid #e1e1e1;
}

.docFileTableHead > th {
  padding: 17px 25px;
  background-color: #eff2f3;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 24px;
  color: #19305a;
}

.docFileTableHead th:first-child {
  border-radius: 3px 0 0 0;
}

.docFileTableHead th:last-child {
  border-radius: 0 3px 0 0;
}

.docTableWrapper {
  border: 1px solid #e1e1e1;
  height: 100%;
  position: relative;
  border-radius: 3px 3px 0 0;
}

.docSortByFilter {
  display: flex;
  gap: 22px;
  align-items: center;
}

.sortByFilterOptions {
  border: none;
  background-color: #eff2f3;
  font-family: Roboto Medium;
  font-size: 14px;
  line-height: 20px;
  color: #19305a;
}
.sortByFilterOptions:focus-visible {
  border: none;
  outline: none;
}

.docAddFileButton {
  position: absolute;
  right: 70px;
  bottom: 90px;
  background: #26abe1;
  border-radius: 3px;
  font-family: Roboto Medium;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: #f9f9f9;
  border: none;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.docAddFileButton:focus-visible {
  border: none;
  outline: none;
}

.docAddFileButton:focus {
  border: none;
  outline: none;
}

.docAddFileButton > span > img {
  height: 14px;
}

/* Card View */
.docFileTableHeadCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 17px 46px 14px 25px;
  height: 52px;
  background-color: #eff2f3;
  align-items: center;
}

.docViewButton {
  cursor: pointer;
  width: 54px;
  display: flex;
  height: 100%;
  align-items: center;
}

.docCardFileSize {
  margin-top: auto;
  border-top: 1px solid #f3f4f6;
  padding: 8px 8px 8px 8px;
  font-family: Roboto Medium;
  font-size: 10px;
  line-height: 12px;
  color: #375393;
}

.docCardFileSizeValue {
  font-family: Roboto;
  font-size: 10px;
  line-height: 12px;
  color: #375393;
}

.docFileListViewHeader {
  display: flex;
  flex-direction: row;
}

.docFileCheckboxListView {
  width: 58px;
}

.docMainContainer .ScrollbarsCustom-Content {
  padding: 2px !important;
  padding-top: 0px !important;
}

.docEditIcons {
  display: flex;
  flex-direction: row;
  gap: 29px;
  align-items: center;
}

.docEditIcons > img {
  cursor: pointer;
  height: 18px;
}

.docEditIconClose {
  height: 24px;
  margin-left: 0px;
  cursor: pointer;
  margin-bottom: 1px;
  margin-left: 8px;
}

.docEditIconClose:hover {
  background-color: #d7e1f4;
}

.docFileNameInput {
  border: none;
  border-radius: 3px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  color: #375393;
  width: 100%;
  padding: 10px;
  background-color: #fdfdfd00;
  text-overflow: ellipsis;
  word-break: break-word;
}

.docFileNameInput:focus-visible {
  border: 2px solid #26a1d2;
  outline: none;
  background-color: #fdfdfd;
}

.docSearchIcon {
  height: 16px;
  cursor: pointer;
}

.docSearchInput {
  width: 140px;
  height: 28px;
  background: #fafafa;
  border: 2px solid #26abe1;
  border-radius: 3px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
  color: #19305a;
  padding: 4px 28px 4px 28px;
}

.docSearchInput::placeholder {
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
  color: #758ab9;
  font-style: normal;
}

.docSearchInput:focus-visible {
  border: 2px solid #26abe1;
  outline: none;
}

.docSearchBar {
  position: relative;
}

.docSearchIconActive {
  position: absolute;
  top: 7px;
  left: 9px;
  height: 13.5px;
}

.docSearchIconClear {
  position: absolute;
  top: 7px;
  right: 9px;
  height: 13.5px;
  cursor: pointer;
}

.docConfirmDel {
  text-align: left;
  padding: 28px 40px 18px;
}

.docConfirmDelTitle {
  font-family: Roboto Medium;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305a;
  margin-bottom: 16px;
}

.docConfirmDelTitleMessage {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
}

.docConfirmDelBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.docConfirmDelBtnCancel {
  border: none;
  padding: 8px 12px;
  background: #eff2f3;
  border-radius: 3px;
  color: #19305a;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.docConfirmDelBtnDelete {
  border: none;
  padding: 8px 12px;
  background: #26abe1;
  border-radius: 3px;
  color: #f9f9f9;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.docConfirmDelBtnCancel:hover,
.docConfirmDelBtnDelete:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.cardViewActive {
  background: #26abe1;
  border-radius: 3px;
  padding: 6px;
  width: 24px;
  height: 24px;
  box-shadow: 0px 0.446956px 0.893912px -0.446956px rgba(24, 39, 75, 0.12),
    0px 0.893912px 0.893912px -0.446956px rgba(24, 39, 75, 0.08);
}

.cardViewInactive {
  border-radius: 3px;
  padding: 6px;
  width: 24px;
  height: 24px;
}

.cardListViewBtns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: #fafafa;
  padding: 4px;
  width: 65px;
  border-radius: 3px;
}

table {
  overflow-x: scroll;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .miniCheckmark,
.checkboxContainer:hover input ~ .smallCheckmark,
.checkboxContainer:hover input ~ .checkmark {
  background-color: #d7dae1;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .miniCheckmark,
.checkboxContainer input:checked ~ .smallCheckmark,
.checkboxContainer input:checked ~ .checkmark {
  background-color: #758ab9;
  border: 2px solid #758ab9;
}

.docFileNameInputEdit {
  border: 2px solid #26a1d2;
  outline: none;
}
