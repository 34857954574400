.swabFileExampleContainer{
    background-color: white;
    /* padding: 20px 10px; */
    min-width: 90%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.swabFileExample__Header {
    display: flex;
    font-family: "Roboto Medium";
    font-size: 14px;
    flex-wrap: nowrap;
    align-items: center;
    color: white;
    background-color: #B4B4B4;
    padding: 10px 20px;
    border-radius: 8px 8px 0px 0px;
}
.swabFileExample__content {
    padding: 12px 20px;
    max-height: 250px;
    height: 120px;
  }
  
  .swabFileExample__content .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
    display: unset;
    height: unset !important;
  }
  
  .swabFileExample__content .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
    height: 6px !important;
    background: hsla(0,0%,76.9%,.81) !important;
  }

  .swabFileExample__content .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    width: 6px !important;
    background: hsla(0,0%,76.9%,.81) !important;
  }
  
  .swabFileExample__content th {
    text-align: right;
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: center;
    min-width: 45px;
  }
  
  .swabFileExample__content td {
    text-align: center;
    min-width: 45px;
    height: 20px;
    white-space: nowrap;
  }
.swabFileExample__content .swabFileExample__table {
    text-align: left;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    color: #6b6868 ;
    overflow-x: scroll;
}

.swabFileExample__tableContent {
    margin-top: 5px;
    max-height: 200px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #868282;
    width: max-content;
}

.swabFileExample__tableContent--fade {
  background: linear-gradient(rgba(179, 191, 219, 0.12), rgba(179, 191, 219, 0));
}