.UnderMaintenanceContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #19305a;
  text-align: center;
  padding-bottom: 48px;
}

.UnderMaintenance__SignOutButton {
  padding: 5px 10px;
  height: 34px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  margin-top: 10px;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.UnderMaintenance__SignOutButton:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.UnderMaintenance__ContentContainer {
  width: 50%;
}

.UnderMaintenance__ContentContainer__h3 {
  line-height: 35px;
  font-weight: 700;
}

.UnderMaintenance__Buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
}
