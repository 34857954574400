.ResendInviteButton__RepeatIcon {
  cursor: pointer;
  fill: #26a1d2;
}

.ResendInviteButton__RepeatIcon:hover {
  cursor: pointer;
  fill: #375393;
}

.ResendInviteButton__RepeatIcon--disabled {
  cursor: not-allowed;
  fill: #26a1d2;
  opacity: 0.5;
}

.ResendInviteButton__LoadingIcon {
  font-size: 16px;
}
