.env-upload-map-preview,
.env-upload-map-error {
  width: 100%;
  height: calc(100% - 81px);
  object-fit: contain;
}

.env-upload-map-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  /* mix-blend-mode: soft-light; */
  background-color: rgb(230, 53, 89, 0.1);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.env-upload-map-error-title {
  color: #e63559;
}

.env-upload-map-error-content {
  color: #6c7e8e;
}

.env-upload-map-edit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eff2f3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 12px 12px;
}

.env-upload-map-edit-container.white-bg {
  background: #fdfdfd;
}

.env-upload-map-delete {
  padding-right: 10px;
  width: 38px;
  margin-bottom: 10px;
}

.env-upload-map-delete:hover {
  cursor: pointer;
}

.env-upload-map-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  color: #19305a;
  padding-left: 14px;
  height: 81px;
  justify-content: center;
}

.env-upload-map-img-size {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 2;
  text-align: left;
  margin-left: 2px;
}

.env-upload-map-title-name {
  display: flex;
  flex-direction: row;
}

.env-upload-map-title-edit-icon {
  margin-top: 5px;
  margin-left: 9px;
  cursor: pointer;
}

.env-upload-map-title-edit-icon path {
  fill: #19305a;
}

.env-upload-map-title-edit-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  height: 24px;
  background: rgb(239, 242, 243, 0.45);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #19305a;
  width: 42vw;
}

.env-upload-map-title-edit-input-display {
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: left;
  max-width: 42vw;
  white-space: pre;
  overflow: hidden;
}

.env-upload-map-error-card {
  margin-left: 1px;
  margin-top: 2px;
}

.env-upload-map-error-card-content {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #e63559;
}
