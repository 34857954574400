.FormPageLayout {
  min-width: 400px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.FormPageLayout__Header {
  background-color: white;
  height: 80px;
  min-height: 80px;
  border: 1px solid #eaf0f5;
  z-index: 5;
}

.FormPageLayout__Header__Logos {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-inline: 4.166666666666666%;
}

.FormPageLayout__Header__Logos span {
  font-family: "Roboto Medium";
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #758ab9;
}

.FormPageLayout__Body {
  padding-bottom: 53px;
  padding-top: 60px;
  flex-grow: 1;
  overflow: auto;
  background-image: radial-gradient(circle, #26abe126 30%, transparent);
}

.FormPageLayout__Header__Logos__Right {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.FormPageLayout__Header__Logos__Right__Divider {
  border-left: 1px solid #d7dae1;
}

.FormPageContainer {
  background-color: white;
  border-radius: 12px;
  height: fit-content;
}

.FormPageLayout__Form {
  width: 1143px;
}

@media (max-width: 1200px) {
  .FormPageLayout__Form {
    width: 936px;
  }
}

@media (max-width: 992px) {
  .FormPageLayout__Form {
    width: 736px;
  }
}

@media (max-width: 768px) {
  .FormPageLayout__Form {
    width: 636px;
  }
}
