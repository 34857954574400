.CreateSampleAlert.ant-modal .ant-modal-content{
    border-radius: 3px;
    padding: 74px 47.25px;
    min-height: 244px;
    box-shadow: 0px 4.02px 19.67px -0.89px #18274B24;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .CreateSample{
      text-align: center;
  }
  
  .CreateSampleTitle{
      font-family: "Roboto";
      font-size: 20px;
      font-weight: 700;
      line-height: 23.44px;
      letter-spacing: 0.0015em;
      color: #19305A;
      text-align: center;
      margin-bottom: 30px;
  }
  
  .CreateSampleBtn{
      display: flex;
      justify-content: center;
      gap: 16px;
  }
  
  .CreateSampleBtn button{
      height: 40px;
      width: 170px;
      text-decoration: none;
  }
  .CreateSampleBtn a:hover{
      text-decoration: none;
  }
  
  .CreateSampleBtn button span{
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
  }