.filterStatusCategoryCloseFilterIcon {
  height: 16px;
  width: 16px;
  position: absolute;
  right: -7px;
  top: -7px;
}

.SubmissionFilterButton__SpecSelected,
.SubmissionFilterButton__SpecNotSelected {
  display: flex;
  flex-direction: row;
  opacity: 1;
  align-items: center;
  background-color: #f4fbfe;
  font-size: 14px;
  font-family: "Roboto";
  border-radius: 4px;
  height: 40px;
  color: #6c7e8e;
}

.SubmissionFilterButton__SpecSelected {
  border: 2px solid #26abe1;
  border-radius: 4px;
  background-color: #def2fb !important;
}

.SubmissionFilterButton__SpecNotSelected:hover {
  background-color: #def2fb !important;
}

.SubmissionFilterButton__Spec--disabled {
  opacity: 0.2;
}

.filterCategoryIcon {
  width: 12px;
  height: 13.3px;
  border-radius: 2px;
  margin-right: 10px;
}

.filterStatusCategory {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #6c7e8e;
}
