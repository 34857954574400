.COABuilderCenter {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 34px 0 34px 0;
}

.COABuilderPage {
  width: 612px;
  height: auto;
  min-height: 842px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.29);
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.COABuilder__COATitle {
  font-size: 16px;
  padding: 0 16px 16px 8px;
  text-align: center;
  font-family: "Roboto Medium";
  color: #506375;
}

.COABuilder__Body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
  margin-bottom: 24px;
}

.COABuilder__BodyAndSignature {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}
