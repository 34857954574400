.DeleteInviteModelContainer.ant-modal .ant-modal-content {
  border-radius: 16px;
  padding: 7px 0px 18px;
}

.DeleteInviteModelContainer .DeleteInviteModelHeader {
  height: 25px;
}

.DeleteInviteModel {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.DeleteInviteModelHeader {
  color: #19305a;
  text-align: left;
  font-family: "Roboto Medium";
  font-size: 18px;
  margin-bottom: 20px;
}

.DeleteInviteModelText {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
  text-align: left;
}

.DeleteInviteModelBtns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  height: 32px;
}

.DeleteInviteModelCancelBtn.ant-btn-default {
  padding: 5px 15px;
  width: fit-content;
  border-radius: 3px;
  height: 32px;
}

/* .DeleteInviteModelConfirmBtn {
  padding: 5px 15px;
  width: fit-content;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
} */

/* .DeleteInviteModelCancelBtn:hover,
.DeleteInviteModelConfirmBtn:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
} */
