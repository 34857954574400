.ExportModal__Container__Scrollbar {
  flex: 1;
}

.ExportModal__Container__Scrollbar .ScrollbarsCustom-Content {
  display: flex;
  flex-direction: column;
}

.ExportModal__Container {
  width: 100%;
  background: #f2f6fa;
  padding: 0 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ExportModal__HeaderImage {
  width: 100%;
  margin-top: 8px;
}

.ExportModal__TableContainer {
  padding: 16px;
  background: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ExportModal__Table {
  width: 100%;
}

.ExportModal__Table thead tr th {
  background: #c4d2df;
  padding: 4px 8px;
  font-family: "Roboto bold";
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #182534;
  border: 1px solid #eaf0f5;
}

.ExportModal__Table tbody tr td {
  background: #ffffff;
  padding: 8px;
  border: 1px solid #f2f6fa;
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #182534;
}

.ExportModal__LoadingContainer {
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ExportModal__LoadingContainer .ExportModal__Loading {
  font-size: 18px;
  margin: auto;
  color: #182534;
}

.ExportModal__TableRow {
  word-break: break-all;
}