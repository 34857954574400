.analyticsProductListContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 8px;
    height: 100%;
}

.analyticsProductListHeaderText {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #111A24;
}

.analyticsProductListContainerScrollable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
}

.analyticsProductListContainerScrollable.disabled {
    opacity: 0.7;
    /* pointer-events: none; */
}

.analyticsSelectedListContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 8px;
}

.analyticsSelectedListHeaderText {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #111A24;
}

.analyticsSelectedListItemsContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
}

#analyticsScrollbar{
    height: 100%;
}