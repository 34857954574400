.OnboardingForm__StepsContainer {
  margin: 32px 12px 32px 42px;
}

.ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  padding: 0px;
  top: 8px;
}

.OnboardingForm__Steps.ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
  width: 2px;
  background-color: #c4d2df;
}

.OnboardingForm__Steps.ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
  display: flex;
  gap: 4px;
}

.OnboardingForm__Steps.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #26a1d2;
}

.ant-steps.ant-steps-vertical.OnboardingForm__Steps {
  min-width: 205px;
  position: sticky;
  top: -60px;
  padding-top: 30px;
  height: 320px;
}

.ant-steps.ant-steps-vertical.OnboardingForm__Steps>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

/* Layout styling */
.FormLayout.OnboardingFormLayout .FormLayout__Title {
  top: 280px;
}

@media (min-width: 576px) {
  .FormLayout.OnboardingFormLayout .FormLayout__Title {
    top: 116px;
  }
}

.OnboardingFormLayout>.FormLayout__Body {
  padding: 32px 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 6px -4px #1018281a, 0px 10px 15px -3px #1018281a;
  border: 1px solid #eaf0f5;
}

.OnboardingForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Invalid page styling */
.OnboardingForm__InvalidLink {
  width: 100%;
  margin-bottom: 32px;
}

/* Reset default style for radio buttons */
.OnboardingFormLayout .ant-form input[type="radio"]:checked,
.OnboardingFormLayout .ant-form input[type="radio"]:not(:checked) {
  left: unset;
}

/* Select arrow */
.OnboardingFormLayout .ant-select .ant-select-arrow {
  top: 55%;
}

.OnboardingFormLayout .ant-select .ant-select-clear {
  top: 55%;
}

.OnboardingForm__Loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.ant-steps-item-description {
  margin: auto 0px;
}

#outerCircle {
  fill: none;
  stroke: none;
}

/* Change description color on active and after finished*/
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description,
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: #26a1d2;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

/* Change the SVG color for progress*/
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title svg,
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title svg {
  fill: #26a1d2;
}

/* Change the SVG color for progress*/
.ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon #outerCircle,
.ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon #outerCircle {
  fill: #26a1d2;
  stroke: #26a1d2;
}

.ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon #innerCircle,
.ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon #innerCircle {
  fill: white;
  stroke: #26a1d2;
}

.OnboardingForm__AddCompany__BackBtn{
  position: fixed;
}

.OnboardingForm__AddCompany__BackBtn.ant-btn-default{
  position: sticky;
  top: 90%;
  border: 1px solid ;
  border-color: #26ABE1 !important;
  box-shadow: 0px 1px 2px 0px #1018280D;
  background: #FFFFFF;
  width: 101px;
  height: 36px;
  padding: 8px 14px 8px 14px;
  border-radius: 4px;
}

.OnboardingForm__AddCompany__BackBtn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #A5DDF3;
  background-color: #FFFFFF;
  border-color: #A5DDF3 !important;
}

.OnboardingForm__AddCompany__FormPageLayout{
  height: calc(100vh - 53px) !important;
}