.LandingPage__Div{
  background-image: radial-gradient(circle, #26abe126 30%, transparent);
  /* background-image: url("../../../../assets/images/onboarding/Onboarding_BG.png"); */
  padding: 50px 150px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
}

.LandingPage {
    box-shadow: 0px 18px 28px 0px #091E4226;
    border-radius: 16px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    min-height: 500px;
}

.LandingPage__Image,
.LandingPage__Container {
    flex-basis: 50%;
}

.LandingPage__Image{
    display: flex;
}
.LandingPage__Container {
    padding-left: 72px
}

.LandingPage__Text {
    color: #19305A;
    font-family: "Roboto";
    font-size: 36.21px;
    font-weight: 400;
    line-height: 62.46px;
}

.SuccessLandingPage__Text {
    color: #19305A;
    font-family: "Roboto";
    font-size: 25px;
    font-weight: 400;
}

.LandingPage__Heading {
    width: 415px;
    color: #26A1D2;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 57.94px;
    line-height: 63.37px;
}

.SuccessLandingPage__Heading{
    color: #26A1D2;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 40px;
    /* line-height: 63.37px; */
}

.LandingPage__Description {
    margin-top: 12px;
    color: #B4B4B4;
    font-family: "Roboto";
    font-size: 18.11px;
    font-weight: 500;
    line-height: 28.97px;
}

.LandingPage__Btn {
    margin-top: 30px;
    width: 151.97px;
    height: 50.66px;
    border-radius: 4.75px;
    font-family: "Roboto";
    font-size: 22.16px;
    font-weight: 500;
    line-height: 25.97px;
    color: #F9F9F9;
}

.SuccessLandingPage__Btn{
    margin-top: 30px;
    border-radius: 4.75px;
    font-family: "Roboto";
    font-weight: 500;
    color: #F9F9F9;
    font-size: 18px;
    height: 45px;
}

.LandingPage__RequestText {
    font-family: "Roboto";
    font-size: 22.16px;
    font-weight: 500;
    color: #26A1D2;
    margin-top: 35px;
}

.LandingPage__Image img{
    width: 450px;
    padding: 0px 15px 15px 20px;
    margin: auto;
}

.SuccessLandingPage__BackArrow{
    width: 23px;
    padding-left: 5px;
}