.PrintLabelModal__LoadingIcon {
  margin: auto;
  font-size: 32px;
}

.PrintLabelModal__NoResults {
  flex: 1;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}