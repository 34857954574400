.CustomCalendar.CustomCalendar--is-inline .flatpickr-input {
  display: none;
}

.CustomCalendar .flatpickr-input {
  width: 100px;
  padding: 12px 8px 12px 8px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #C4D2DF;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: center;
  color: #182534;
}

.CustomCalendar--hasError .flatpickr-input {
  border-color: #ff5555;
  background-color: #ff555510;
}

.CustomCalendar .flatpickr-calendar {
  width: 100%;
  box-shadow: none;
}

.CustomCalendar .flatpickr-calendar .flatpickr-innerContainer {
  border-bottom: none !important;
}

.CustomCalendar .flatpickr-calendar .flatpickr-days {
  border: none;
}

.CustomCalendar .flatpickr-calendar .flatpickr-months {
  padding: 8px 0;
}

.CustomCalendar .flatpickr-calendar .flatpickr-months>* {
  position: relative;
}

.CustomCalendar .flatpickr-calendar .flatpickr-day {
  font-size: 12px;
  user-select: none;
}

.CustomCalendar .flatpickr-calendar .flatpickr-day.selected {
  background: #26ABE1;
  border-color: #26ABE1;
}

.CustomCalendar .flatpickr-calendar .flatpickr-day.today {
  border-color: transparent;
  color: #26ABE1;
}

.CustomCalendar .flatpickr-calendar .flatpickr-day.today.selected {
  color: #FFFFFF;
}

.CustomCalendar .flatpickr-calendar .flatpickr-months *,
.CustomCalendar .flatpickr-calendar .flatpickr-weekdays,
.CustomCalendar .flatpickr-calendar .flatpickr-weekdays .flatpickr-weekday {
  background-color: #FFFFFF;
}

.CustomCalendar .flatpickr-calendar .flatpickr-month {
  order: -1;
}

.CustomCalendar .flatpickr-calendar .flatpickr-month .flatpickr-current-month {
  padding-top: 5px;
  width: auto;
  left: 0;
}

.CustomCalendar .flatpickr-calendar .flatpickr-month .flatpickr-current-month select.flatpickr-monthDropdown-months,
.CustomCalendar .flatpickr-calendar .flatpickr-month .flatpickr-current-month .numInputWrapper {
  appearance: none;
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  color: #375393;
}

.CustomCalendar .flatpickr-calendar .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp,
.CustomCalendar .flatpickr-calendar .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown {
  display: none;
}

.CustomCalendar .flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.CustomCalendar .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  color: #375393;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.CustomCalendar .flatpickr-calendar .flatpickr-months .flatpickr-prev-month svg,
.CustomCalendar .flatpickr-calendar .flatpickr-months .flatpickr-next-month svg {
  fill: #375393;
  stroke: #375393;
}

/* Non-inline calendars */

.CustomCalendar__Calendar>.flatpickr-calendar {
  width: 100%;
  max-width: 310px;
  box-shadow: none;
  background-color: #FFFFFF;
  border: 1px solid #00000022;
  border-radius: 0;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-innerContainer {
  border-bottom: none !important;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-days {
  border: none;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months {
  padding: 8px 0;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months>* {
  position: relative;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day {
  font-size: 12px;
  user-select: none;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day.flatpickr-disabled {
  color: rgba(72, 72, 72, 0.3);
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day.nextMonthDay,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day.prevMonthDay {
  color: rgba(72, 72, 72, 0.6);
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day.selected {
  background: #26ABE1;
  border-color: #26ABE1;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day.today {
  border-color: transparent;
  color: #26ABE1;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-day.today.selected {
  color: #FFFFFF;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months *,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-weekdays,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-weekdays .flatpickr-weekday {
  background-color: #FFFFFF;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-month {
  order: -1;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-month .flatpickr-current-month {
  width: auto;
  left: 8px;
  line-height: 34px;
  padding-top: 0;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-month .flatpickr-current-month select.flatpickr-monthDropdown-months,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-month .flatpickr-current-month .numInputWrapper {
  appearance: none;
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  color: #375393;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown {
  display: none;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  color: #375393;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months .flatpickr-prev-month svg,
.CustomCalendar__Calendar>.flatpickr-calendar .flatpickr-months .flatpickr-next-month svg {
  fill: #375393;
  stroke: #375393;
}