.COASave__Container {
  position: relative;
  width: 100%;
  height: 100%;
  width: 432px;
  min-height: 245px;
}

.COASave__Container svg {
  flex-shrink: 0;
}

.COASave__Header {
  padding: 24px 48px 24px 38px;
  display: flex;
  gap: 16px;
  align-items: center;

  background-color: #f6f8fa;
  color: #19305a;
  font-size: 14px;
  font-family: Roboto;
  line-height: 20px;
}

.COASave__Body {
  display: flex;
  padding: 31px 36px 46px 36px;
  width: 100%;
  gap: 16px;
}

.COASaveBody__ReportInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  flex-basis: 50%;
  min-width: 50%;
  max-width: 50%;
}

.COASaveBody__ReportInfoRow {
  display: flex;
  gap: 10px;
  align-items: flex-start;

  color: #6c7e8e;
  font-size: 14px;
  font-family: Roboto;
  line-height: 20px;
  word-wrap: break-word;
}

.COASaveBody__ReportInfoRow > svg {
  margin-top: 2px;
}

.COASaveBody__ReportInfoTag {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  color: #fff;
  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;
  letter-spacing: 0.5px;

  width: fit-content;
}

.COASaveBody__Right {
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.COASave__DeleteButton {
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.COASave__COAName {
  word-wrap: break-word;
  max-width: calc(100% - 28px);
}

.COASave__InputParent {
  width: 100%;
}

.COASave__InputLabel {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  color: #6c7e8e;
  margin-bottom: 5px;
}

.COASave__Input {
  height: 40px;
  background: #fff;
  border: 1px solid #e6eef5;
  border-radius: 8px;
  padding: 10px;
  color: #385387;
  font-size: 14px;
  width: 100%;
}

.COASave__InputButton {
  cursor: pointer;
  flex-shrink: 0;
}

.COASave__InputButton > path {
  fill: #26abe1;
}
