.no-results {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-results__img {
    width: 220px;
}

.no-results__img--fit-object {
    object-fit: contain;
    width: 220px;
    height: 160px;
}

.no-results__message {
    width: 100%;
    text-align: center;
    color: #19305A;
    font-size: 24px;
    margin-top: 20px;
}

