.EnvAnalyticsTable__SubmittedSubmissionsContainer {
  display: flex;
  flex-direction: column;
  flex: 2.4 2.4 0;
  height: 100%;
  position: relative;
}

.EnvAnalyticsTable__GraphContainerPlaceholderText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
  color: #375393;
  background-color: #f2f6fa;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 64px;
  padding: 25px;
}

.EnvAnalyticsTable__SubmissionsTable {
  width: 100%;
}

.EnvAnalyticsTable__SubmissionsTableHeader {
  background: #ffffff;
  position: sticky;
  z-index: 1;
  top: -1px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #f2f6fa;
  border-bottom: 1.5px solid #eaf0f4;
  height: 65px;
}

.EnvAnalyticsTable__SubmissionsTableHeader th {
  font-size: 12px;
  line-height: 16px;
  color: #182534;
  font-family: "Roboto";
  text-align: center;
  letter-spacing: 0.6px;
}

.EnvAnalyticsTable__SubmittedSubmissionsContainer th,
.EnvAnalyticsTable__SubmittedSubmissionsContainer td {
  padding: 19px 10px;
  width: 40px;
}

.EnvAnalyticsTable__SubmissionsTableHeader th:nth-child(1) {
  text-align: left;
  padding-left: 35px;
}

.EnvAnalyticsTableRow__TableData {
  font-family: "Roboto";
  font-size: 12px;
  line-height: 16px;
  color: #19305a;
  text-align: center;
}

.EnvAnalyticsTable__ScrollBar > .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding-top: 72px;
  padding-bottom: 20px;
  padding-right: 20px !important;
  background: transparent !important;
}

.EnvAnalyticsTable__ScrollBar {
  height: calc(100vh - 200px) !important;
  background-color: #fdfdfd;
}

.EnvAnalyticsTable__ScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #c4d2df !important;
  width: 6px !important;
}

.EnvAnalyticsTable__ScrollBar
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

/* Tooltip styling */
.EnvAnalyticsTableRow__CustomTooltipOverlay .ant-tooltip-inner {
  background-color: #FFFF;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.EnvAnalyticsTableRow__CustomTooltipOverlay .ant-tooltip-arrow {
  border-color: white transparent;
  --antd-arrow-background-color: white;
}

.EnvAnalyticsTableRow__CustomTooltip p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  font-family: "Roboto";
}

.EnvAnalyticsTableRow__TooltipTotal {
  color: black;
}

.EnvAnalyticsTableRow__TooltipInSpec {
  color: #1fc04a;
}

.EnvAnalyticsTableRow__TooltipNoSpec {
  color: #8b97a3;
}

.EnvAnalyticsTableRow__TooltipOutOfSpec {
  color: #e11903;
}

.EnvAnalyticsTableRow__ProgressBar {
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.EnvAnalyticsTableRow__ProgressBarRed {
  height: 100%;
  background-color: #e11903;
}

.EnvAnalyticsTableRow__ProgressBarGreen {
  height: 100%;
  background-color: #1fc04a;
}

.EnvAnalyticsTableRow__Row {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e11903;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.EnvAnalyticsTableRow__TableRow {
  border-bottom: 1px solid #eaf0f4;
  height: 52px;
}

.EnvAnalyticsTableRow__TextRow {
  font-family: "Roboto";
  font-size: 12px;
  line-height: 16px;
  color: #19305a;
  text-align: center;
}

.EnvAnalyticsTableRow__Row--NoRatio {
  color: #19305a;
}

.EnvAnalyticsTableRow__SwabID {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 16px;
  color: #19305a;
  width: 100%;
  padding-left: 25px;
}

.EnvAnalyticsTable__NoResults > .no-results-gif-common__msg {
  color: #8b97a3;
}