.analyticsGraphHeaderContainer {
  padding: 0px 50px 0px 30px;
}

.analyticsGraphAmountText {
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #111a24;
}

.analyticsGraphPillMainContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  width: 100%;
  margin-bottom: 21px;
  margin-top: 10px;
}

/* Swiper styling */
.analyticsGraphPillMainContainer .analyticsGraphPillSwiper {
  /* .analyticsGraphPillSwiper { */
  max-width: 100%;
  margin-left: 0;
}

.analyticsGraphPillSwiperSlide {
  flex-shrink: 1 !important;
  margin-right: 8px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled::after,
.swiper-button-next.swiper-button-disabled::after {
  display: none;
}

.analyticsGraphPillMainContainer .swiper-button-prev,
.analyticsGraphPillMainContainer .swiper-button-next {
  /* .swiper-button-prev, .swiper-button-next { */
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  border: 0.88px solid #6c7e8e;
  border-radius: 6px;

  top: 22px;

  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center;
  z-index: 1;
}

.swiper-button-prev {
  margin-left: -10px;
  background-image: url("../../../../../../assets/images/analytics/chevron_left.png");
}

.swiper-button-next {
  margin-right: -10px;
  background-image: url("../../../../../../assets/images/analytics/chevron_right.png");
}

.analyticsGraphPillMainContainer .swiper-button-prev::after {
  /* .swiper-button-prev::after { */
  content: "";
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255, 1)
  );
  width: 120px;
  height: 40px;
  margin-right: -156px;
}

.analyticsGraphPillMainContainer .swiper-button-next::after {
  /* .swiper-button-next::after { */
  content: "";
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255, 1)
  );
  width: 120px;
  height: 40px;
  margin-left: -156px;
}

/* Date styling */
.analyticsGraphDateBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
  position: relative;
}

.analyticsDateContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.analyticsDateIconContainer.DatePickerDisplay {
  padding: 5.83px 7.67px;
  background-color: #eff2f3;
  border-radius: 3.75px;
}

.analyticsDateIconContainer.DatePickerDisplay:hover {
  background-color: #bee6f6;
}

.analyticsDatePickerIcon {
  height: 16.67px;
  width: 16.67px;
}

.analyticsDateText {
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #506375;
}

/* Export button */
.productAnalyticsExportButton {
  position: absolute;
  right: 180px;
  top: 0px;
  padding: 0px 0px 3px 3px;
  width: 35.66px;
  height: 35.66px;
  border-radius: 3.75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productAnalyticsExportButton:hover {
  background: #f6f8fa;
}

/* Bar graph toggle styling */
.analyticsBarGraphToggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.analyticsBarGraphToggleText {
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #506375;
}

.ProductAnalytics__Switch.ant-switch {
  width: 32px;
}
