.ModalPageTitle {
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  color: #1F2D3B;
  position: relative;
  margin-left: calc(10px + 8px);
}

.ModalPageTitle::before {
  content: "";
  width: 10px;
  height: 24px;
  background: #26ABE1;
  border-radius: 4px;
  position: absolute;
  left: calc(-10px - 8px);
  top: 50%;
  transform: translateY(-50%);
}