.Draggable__CloseIcon {
  cursor: pointer;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 6px;
  right: -24px;
}
.Draggable__CloseIcon--largeInput {
  cursor: pointer;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0;
  right: -24px;
}

.Draggable__DragIcon {
  height: 25px;
  width: 25px;
  cursor: grab;
  margin-right: 6px;
}
