.templateBuilderHeader {
  display: flex;
  justify-content: space-between;
  padding: 18px 0px;
  align-items: center;
  padding: 10px 24px;
  border: 1px solid #eaf0f5;
  position: fixed;
  z-index: 1;
  top: 60px;
  width: 100%;
  background-color: #ffffff;
}

.templateBuilderHeaderBackButton {
  background: #ffffff;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  color: #375393;
  cursor: pointer;
  height: 33px;
}

.templateBuilderHeaderBackButton:hover {
  background-color: #506375;
  color: #f9f9f9;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}
.templateBuilderHeaderBackButton > img {
  height: 12.67px;
}
.templateBuilderHeaderBackSpan {
  margin-left: 5px;
}

.templateBuilderHeaderSaveButton {
  background: #26abe1;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
  cursor: pointer;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.templateBuilderHeaderSaveButton:hover {
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
}

.templateBuilderHeaderSaveButtonDisable {
  background: #b3e0f2;
}

.templateBuilderHeaderSaveButtonDisable:hover {
  box-shadow: unset;
}

.templateBuilderHeaderSaveButtonHidden {
  width: 120px;
  height: 40px;
}

/* .editPreviewSwitch {
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  padding: 4px;
} */

/* .editPreviewSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
} */

.templateSwitchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 128px;
  /* background: #FFFFFF; */
  border-radius: 8px;
  box-shadow: 1px 6px 12px 2px rgba(24, 39, 75, 0.12),
    1px 8px 24px 2px rgba(24, 39, 75, 0.08);
  gap: 6px;
}

/* .editPreviewSwitch .tabs {
  width: 145px;
  display: flex;
  position: relative;
  padding: 3px 5px;
  height: 32px;
} */

/* .editPreviewSwitch .tabs * {
  z-index: 2;
} */

/* .editPreviewSwitch input[type="radio"] {
  display: none;
} */

/* .editPreviewSwitch [type="radio"]:checked + label:before,
.editPreviewSwitch [type="radio"]:not(:checked) + label:before {
  display: none;
} */
/* .editPreviewSwitch [type="radio"]:checked + label:after,
.editPreviewSwitch [type="radio"]:not(:checked) + label:after {
  display: none;
} */

/* .editPreviewSwitch .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  width: 60px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
} */

/* .editPreviewSwitch [type="radio"]:checked + label,
.editPreviewSwitch [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  line-height: 27px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-family: "Roboto Medium";
  text-align: center;
} */

/* .editPreviewSwitch input[type="radio"]:checked + label {
  color: white;
} */

/* .editPreviewSwitch input[id="radio-1"]:checked ~ .glider {
  transform: translateX(-4px);
} */
/* .editPreviewSwitch input[id="radio-2"]:checked ~ .glider {
  transform: translateX(105%);
  width: 65px;
} */

/* .editPreviewSwitch .glider {
  position: absolute;
  display: flex;
  height: 27px;
  width: 69px;
  z-index: 1;
  transition: 0.25s ease-out;
  background: #385387;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
} */

.switch {
  border-radius: 4px;
  height: 27px;
  font-family: "Roboto Medium";
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editSwitch {
  width: 52px;
  font-size: 14px;
}

.previewSwitch {
  width: 60px;
  font-size: 12px;
}

.switchActive {
  background-color: #385387;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  color: #ffffff;
}

.switchInactive {
  background-color: #ffffff;
  color: #6c7e8e;
}
