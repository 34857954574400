.env-analytics-report-view-container {
  padding: 26px 20px 26px 16px;
  width: 100%;
  height: calc(100% - 41px);
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  min-width: 850px;
  background-color: #f9f9f9;

  display: grid;
  grid-template-columns: 45% auto;
  grid-template-rows: minmax(200px, auto) 1fr 32px;
  grid-row-gap: 23px;
  grid-column-gap: 16px;
}

.env-analytics-report-view-container-spec-manager {
  padding: 26px 20px 26px 16px;
  width: 100%;
  height: calc(100% - 41px);
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  min-width: 850px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.env-analytics-report-view-section {
  background: #fdfdfd;
  border-radius: 4px;
  padding: 12px;
}

.env-analytics-report-view-section.env-report-view-header {
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.env-analytics-report-view-section-title-container {
  display: flex;
  flex-direction: row;
  gap: 36px;
  background: #fdfdfd;
  border-radius: 12px;
  padding: 24px 26px;
  align-items: center;
}

.env-analytics-report-view-section-title-image {
  width: 122px;
  height: 122px;
  background: #eff2f3;
  border-radius: 4px;
}

.env-analytics-report-view-section-title-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.env-analytics-report-view-section-title-info-header-title {
  font-family: "Roboto Medium";
  font-size: 16px;
  color: #19305a;
  word-break: break-word;
}

.env-analytics-report-view-section-title-info-header-date {
  color: #758ab9;
}

.env-analytics-report-view-section-title-info-header-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  border-radius: 8px;
  width: fit-content;
}

.env-analytics-report-view-section.env-report-view-map {
  grid-column: 1/2;
  grid-row: 2/3;
  overflow: hidden;
}

.env-analytics-report-view-section.env-report-view-report-section {
  grid-column: 2/3;
  grid-row: 1/4;
  display: flex;
  gap: 12px;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
}

.env-report-view-map-button-container {
  display: flex;
  align-items: center;
}

.env-report-view-map-button {
  grid-column: 1/2;
  grid-row: 3/4;

  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 50%;
  border: none;
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.env-report-view-map-button:hover::after {
  content: "Back to Map";
  display: flex;
  background-color: #26abe1;
  border-radius: 0 20px 20px 0;
  position: absolute;
  padding: 1px 10px;
  padding-left: 10px;
  padding-right: 10px;
  left: 26px;
  width: 88px;
  color: #ffffff;
}

.env-report-view-map-button:hover>svg>path {
  fill: #fdfdfd;
}

.env-report-view-map-button:hover {
  background: #26abe1;
}

.env-report-view-map-button-hint {
  background: #26abe1;
  border-radius: 0 20px 20px 0;
  padding: 5px 10px;
  color: #ffffff;
  height: fit-content;
}

.env-report-view-report-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.env-report-view-report-section-edit-specs-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #e3e8f1;
  border-radius: 12px 12px 0 0;
  align-items: flex-start;
}

.env-report-view-edit-spec-button {
  /* background-color: #26abe1; */
  color: #26abe1;
  /* padding: 6px 8px; */
  /* border-radius: 4px; */
  white-space: nowrap;
  cursor: pointer;
}

.env-report-view-edit-spec-button:hover {
  text-decoration: underline;
}

.env-report-view-report-section-results-container {
  padding: 12px;
  background: #f9f9f9;
  border-radius: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.env-report-view-report-section-header-devider {
  border: 2px solid #d7dae1;
  border-radius: 4px;
}

.env-report-view-report-section-header-status-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 60px;
  gap: 10px;
}

.env-report-view-report-section-results-table-container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 0px 17px 14px 17px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.env-report-view-report-section-results-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0px 4px;
  width: 100%;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 14px;
  color: #19305a;
}

.env-report-view-report-section-results-table-header {
  text-align: center;
  position: sticky;
  top: 0px;
  color: #758ab9;
  background-color: #fff;
}

.env-report-view-report-section-results-table-header>tr>th {
  padding: 10px 0 17px 0;
  text-align: center;
}

.env-report-view-report-section-results-table-body {
  text-align: center;
  border-color: #ffffff;
  border: 1px solid white;
}

.env-report-view-report-section-results-table-body>tr {
  background: #eff2f3;
  overflow-wrap: break-word;
  border-radius: 5px;
  height: 1px;
}

.env-report-view-report-section-results-table-body>tr>td:first-child {
  text-align: left;
  border-radius: 5px 0 0 5px;
  padding: 8px 0px 8px 8px;
}

.env-report-view-report-section-results-table-body>tr>td:last-child {
  /* text-align: left; */
  border-radius: 0 5px 5px 0;
  padding: 8px 8px 8px 0px;
}

.env-report-view-report-section-results-table-body>tr>td {
  padding: 8px;
  height: inherit;
}

.env-report-view-report-section-results-table-body>tr>td>div {
  padding: 8px;
}

.env-report-view-report-section-results-table-body>tr>td:first-child>div {
  border-right: 2px solid white;
  border-radius: 2px;
  height: 100%;
}

.env-report-view-report-section-results-table-body>tr>td:last-child>div {
  height: 100%;
}

.env-report-view-report-section-results-table-body>tr>td>div.test-spec {
  border-right: 2px solid white;
  border-radius: 2px;
  height: 100%;
}

.env-report-view-report-section-results-table-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.env-report-view-report-section-results-table-actions-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 24px;
  padding: 0px 8px;
  gap: 8px;
  width: fit-content;
  background-color: #eff2f3;
}

.env-report-view-report-section-results-table-actions-buttons-left {
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.env-report-view-report-section-results-table-actions-buttons-right {
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.env-report-view-report-section-results-table-actions-buttons-right>svg {
  transform: rotate(180deg);
}

.env-report-view-report-section-results-table-actions-buttons-left:hover>svg>path {
  fill: #2db0e4;
}

.env-report-view-report-section-results-table-actions-buttons-right:hover>svg>path {
  fill: #2db0e4;
}

.env-report-view-report-section-results-table-container .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding-top: 40px !important;
}

.env-report-view-report-section-results-table-container .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 3px !important;
  background: rgba(196, 196, 196, 0.81) !important;
}

/* .tableScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  min-height: fit-content !important;
} */

.env-report-view-report-section-dates {
  border-radius: 16px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.env-analytics-report-view-section.env-report-view-report-section.env-report-components {
  height: auto;
  width: auto;
}

.env-analytics-report-view-section.env-report-view-report-section.env-report-view-result-section {
  height: 50%;
  background-color: #ffffff;
}

.env-report-view-report-section-actions {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 16px 14px;
}

.env-analytics-view-report-section-actions-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 6px 17px;
}

.env-analytics-view-report-section-actions-buttons>svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.env-analytics-view-report-section-actions-buttons>svg>path {
  fill: #2db0e4;
}

.env-analytics-view-report-section-actions-buttons>svg:hover>path {
  fill: #b3e0f2;
}

.env-analytics-view-report-section-actions-buttons>svg.disabledAction>path {
  fill: #b3e0f2;
}

.env-analytics-view-report-section-actions-buttons>svg.disabledAction {
  width: 20px;
  height: 20px;
  cursor: auto;
}

.env-analytics-view-report-section-actions-buttons>svg.disabledAction:hover>path {
  fill: #b3e0f2;
}

.environment-report-retest-button-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5%;
  width: 50%;
  align-items: flex-end;
  position: relative;
  display: flex;
  flex-direction: column;
}

.env-analytics-report-view-section.env-report-view-report-section.env-report-components-buttons-section {
  background-color: #ffffff;
  padding: 3% 0%;
  width: 90%;
  margin: auto;
  gap: 10%;
}

.env-analytics-view-report-section-retest-requests {
  align-items: flex-end;
  align-content: flex-end;
  display: inline;
  width: 50%;
  margin-right: 5%;
  right: -10%;
  position: relative;
}

.environment-report-retest-button {
  padding: 2%;
  vertical-align: middle;
  background-color: #26abe1;
  border-radius: 4px;
  border: none;
  width: 70%;
  color: white;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}

.environment-report-retest-complete-button {
  padding: 1%;
  vertical-align: middle;
  background-color: #eff2f3;
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 100%;
  color: #19305a;
}

/* No results styling */
.env-analytics-report-view-container .no-results-gif-common>.no-results-gif-common__msg {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #758ab9;
}

.env-analytics-report-action-buttons {
  display: flex;
  align-items: stretch;
  gap: 24px;
}

.EnvAnalyticsBtnCenterText {
  text-align: center;
}

.ReportResult__ReportActionButton__Dropdown .ant-dropdown-menu,
.ReportResult__ReportActionButton__Dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}

.ReportResult__ReportActionButton__Dropdown .ReportResult__ReportActionButton__DropdownMenuItem {
  padding: 6px 16px;
}

.ReportResult__ReportActionButton__Dropdown .ReportResult__ReportActionButton__DropdownMenuItem>svg {
  width: 14px;
  height: 14px;
  color: #19305A;
}

.ReportResult__ReportActionButton__Dropdown .ReportResult__ReportActionButton__DropdownMenuItem span {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  color: #19305A;
  text-transform: capitalize;
  margin-left: 6px;
}

.loading-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.env-analytics-report-action-button-emptyspace {
  flex: 1;
}