.dataFieldsModal{
  padding: 1px 0px;
  position: relative;
  z-index: 4;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.Disabled{
  opacity: 0.6;
  pointer-events: none;
}

.dataFields_formLabel{
  color: #26ABE1;
  font-weight: 500;
}

.dataFields_listSection{
  padding: 15px 25px;
}

.dataFields_listHeader{
  display:flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.dataFields_searchContainer{
  margin-top: -15px;
}

.dataFields_searchIcon{
  margin-left: auto;
}

.dataFields_listLabel{
  color: #7DCDED;
  font-weight: 500;
}

.dataFields_scrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY{
  background: #EAF0F5 !important;
}

.dataFields_list{
  padding: 20px;
  height: calc(100vh - 580px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
}

.dataFields_list::-webkit-scrollbar {
  width: 4px;
}

.dataFields_list::-webkit-scrollbar-track {
  border-radius: 0px;  
  margin: 20px 0px;
}

.dataFields_list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #EAF0F5;
}

.dataFields_listEmptyMessage{
  margin-top: 100px;
  color: #C4D2DF
}

.dataFields_listItems{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dataFields_group{
  font-weight: 500;
  color: #6C7E8E;
}

.dataFields_listcards{
  display: flex;
  gap: 15px;
  align-items: center;
}

.dataFields_listcard{
  background-color: #F6F8FA;
  border: 2px solid #EAF0F5;
  border-radius: 8px;
  padding: 7px 15px;
  width: 100%;
  align-items: center;
  display: flex;
  color: #375393;
  font-weight: 500;
  gap: 10px;
}

.dataFields_listcard_name {
  word-break: break-all;
}

.dataFields_Loading {
  border: 2px solid #EAF0F5;
  border-radius: 8px;
  width: 100%;
  height: 38.38px;
  align-items: center;
  display: flex;
  color: #375393;
  font-weight: 500;
  gap: 10px;
}

.listcardsMenu{
  margin-left: -100px;
}

.dataFields_menu{
  margin-left: auto;
  cursor: pointer;
  padding: 0px 6px;
}
.dataFields_menu--hidden{
  margin-left: auto;
  padding: 0px 6px;
  opacity: 0;
}

.dataFields_edit{
  background-color: #26ABE1;
  padding: 7px 10px 10px 12px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.dataFields_delete{
  background-color: #E63559;
  padding: 7px 10px 10px 12px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}