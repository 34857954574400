.ReportActionButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: #FFFFFF;
  border: 1px solid #A5DDF3;
  box-shadow: 0px 2px 4px -2px #1018281A, 0px 4px 6px -1px #1018281A;
  padding: 12px 16px;
  cursor: pointer;
}

.ReportActionButton.HoverEnabled:hover {
  background: #26ABE1;
  border: 1px solid #26ABE1;
}

.ReportActionButton .ReportActionButton__Content {
  flex: 1;
  margin-right: 12px;
}

.ReportActionButton .ReportActionButton__Title {
  font-family: "Roboto bold";
  font-size: 16px;
  font-weight: 500;
  color: #26ABE1;
  margin-bottom: 4px;
  word-wrap: normal;
}

.ReportActionButton.HoverEnabled:hover .ReportActionButton__Title {
  color: #ffffff;
}

.ReportActionButton .ReportActionButton__Description {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  color: #6C7E8E;
}

.ReportActionButton.HoverEnabled:hover .ReportActionButton__Description {
  color: #ffffff;
}

.ReportActionButton .ReportActionButton__Icon {
  flex-shrink: 0;
  color: #24AAE1;
}

.ReportActionButton.HoverEnabled:hover .ReportActionButton__Icon {
  color: #ffffff;
}

.ReportActionButton .ReportActionButton__ActionCompleteTitle {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  color: #182534;
  margin: 0;
}

.ReportActionButton__ActionCompleteTitle.truncated-text {
  --no-of-lines: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--no-of-lines);
  line-height: 1.5;
  max-height: calc(1.5em * var(--no-of-lines));
}

.ReportActionButton__LoadingIconContainer,
.ReportActionButton__LoadingIconContainer:hover {
  flex: 1;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReportActionButton__LoadingIconContainer .ReportActionButton__LoadingIcon {
  margin: auto;
  font-size: 16px;
  color: #24AAE1;
}