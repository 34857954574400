.EnvAnalyticsTab__MainContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 26px 36px;
  overflow: visible;
}

.EnvAnalyticsTab__SkeletonContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.EnvAnalyticsTab__SkeletonBody--Stats {
  height: 134px;
  display: flex;
}

.EnvAnalyticsTab__SkeletonBody--Graphs {
  height: calc(100vh - 400px);
  display: flex;
}

.EnvAnalyticsTab__SkeletonBody--Item {
  height: 100% !important;
}

.EnvAnalyticsTab__Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8fafc;
  border-radius: 8px;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
}

.EnvAnalyticsTab__TabDate--Container {
  display: flex;
  align-items: center;
  gap: 8px;
  /* background-color: #ffffff; */
  color: #182534;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.EnvAnalyticsTab__DateDisplayProperties {
  background-color: #ffffff;
  height: 48px;
  border: 1px solid #eaf0f5;
  border-radius: 4px;
}

.EnvAnalyticsTab__DateFilter {
  align-self: flex-start;
}
.EnvAnalyticsTab__Headers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  flex-direction: row;
}


.EnvAnalyticsTab__Charts {
  display: flex;
  width: 100%;
  gap: 8px;
}

.EnvAnalyticsTab__Chart {
  width: calc(50% - 4px);
  height: 650px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1),
    0px 4px 6px -1px rgba(16, 24, 40, 0.1);
}

.EnvAnalyticsTab__TableContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0px 10px;
}

.EnvAnalyticsTab__Tables {
  display: flex;
  flex: 1;
  gap: 8px;
}

.EnvAnalyticsTab__Table {
  width: 50%;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1),
    0px 4px 6px -1px rgba(16, 24, 40, 0.1);
}

.EnvAnalyticsTab__MainContainer--ScrollBar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #c4d2df !important;
  width: 6px !important;
}

.EnvAnalyticsTab__MainContainer--ScrollBar
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.EnvAnalyticsTab__MainContainer--ScrollBar
  > .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding-right: 10px !important;
}

.EnvAnalyticsTab__Loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 43px;
 }

 .EnvAnalyticsTab__Checkbox__Spin {
  color: #26abe1;
  font-size: 48px;
}

@media (max-width: 836px) {
  /* Tables */
  .EnvAnalyticsTab__Table {
    width: 100%;
  }

  /* Headers */
  .EnvAnalyticsTab__Headers {
    flex-direction: column;
    align-items: center;
  }

  .EnvAnalyticsTab__Header {
    width: 100%;
    margin-bottom: 16px;
  }

  /* Charts */
  .EnvAnalyticsTab__Charts {
    flex-direction: column;
  }

  .EnvAnalyticsTab__Chart {
    width: 100%;
  }

  /* Table Container */
  .EnvAnalyticsTab__TableContainer {
    flex-direction: column;
  }

  .EnvAnalyticsTab__Tables {
    flex-direction: column;
  }
}
