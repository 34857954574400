html, body {
    overflow: auto !important;
}

.shareContainer {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    /* justify-content: space-between; */
}

.shareContentErrorContainer {
    width: 100%;
}

.shareHeader {
    display: flex;
    background-image: url("../../assets//images/share/esvBanner.svg");
    height: 120px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.logoImage {
    align-self: center;
    margin-left: 46px;
}

.shareContent {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    gap: 90px;
    margin-bottom: auto;
    padding: 58px 69px;
}

.reportThumbnailMainContainer {
    display: flex;
    flex-direction: column;
    width: 131px;
}

.reportThumbnailContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #6C7E8E;
    border-radius: 16px;
    padding: 16px 25px;
    width: 131px;
}

.reportThumbnailText {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 28px;
    color: #506375;
}

.sampleIdText {
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 16px;
    color: #111A24;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: center;
    margin-top: 11px;
}

.shareFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 142px;
    background-color: #F2F6FA;
    color: #6C7E8E;
    font-size: 10.7123px;
    font-family: 'Roboto';
    line-height: 15px;
}

.shareFooterCopyRight {
    margin-bottom: 12px;
}

.reportDownloadIcon {
    height: 24px;
    cursor: pointer;
    width: fit-content;
    align-self: center;
    margin-top: 12px;
}