.PdfPreview__Loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PdfPreview__LoadingIcon {
  margin: auto;
  font-size: 32px;
}

.PdfPreview__Error.no-results-gif-common {
  gap: 20px;
}

.PdfPreview__Error.no-results-gif-common img {
  width: 200px;
}

.pdf-preview-scroll-bar {
  width: 100%;
}

.pdf-preview-scroll-bar .ScrollbarsCustom-Scroller {
  scrollbar-gutter: stable;
  padding-left: 16px;
}

.pdf-preview-scroll-bar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
  width: 6px !important;
}

.pdf-preview-scroll-bar
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #19305a !important;
}
