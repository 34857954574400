.BuilderPreviewContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 28px 95px 0px 95px;
}

.BuilderPreviewContainer.BuilderPreviewContainer--customizingCoa {
  /* max-width: 676px; */
  padding: 28px 0 0 0;
  margin: auto;
}

.BuilderPreviewContainer__Loading {
  margin: auto;
}

.builderPreviewSection {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #375393;
  padding: 185px 20px 20px 20px;
  border-radius: 8px;
}

.builderPDFViewerContainer {
  margin-top: 10px;
}

.templateAlignButton {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 3px;
}

.templateAlignActive {
  background: #26abe1;
  border-radius: 3px;
  padding: 6px;
  width: 24px;
  height: 24px;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.templateAlignInactive {
  border-radius: 3px;
  padding: 6px;
  width: 24px;
  height: 24px;
}

.builderPDFViewer {
  width: 100%;
  height: calc(100vh - 294px);
  border: none;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  background-color: #ffffff;
}

.builderPreviewSvg .react-pdf__Page__svg {
  margin: 0 auto;
  height: 100% !important;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  margin-top: 20px;
}

.BuilderPreviewContainer .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 0px !important;
}

.builderPreviewSectionFail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 294px);
  background-color: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #375393;
  padding: 20px;
}
