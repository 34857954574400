.TemplatePreview.ant-modal .ant-modal-content{
  padding: 0px;
  border-radius: 4px;
}

.TemplatePreview .templatePreviewHeader{
  border-radius: 4px 4px 0 0;
}

.templatePdfPreview{
  padding: 27px 30px 21px; 
}