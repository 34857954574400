.submission-details-column h2 {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  line-height: 28px;
  /* Neutral/800 */
  color: #1f2d3b;
}

.submission-details-column label {
  font-size: 12px;
  line-height: 16px;
  /* Neutral/600 */
  color: rgba(24, 37, 52, 1);
  font-family: "Roboto Medium";
  display: block;
  margin-bottom: 4px;
}

.submission-details-container {
  display: flex;
  gap: 25px;
}

.submission-details-header {
  margin-bottom: 30px;
}

.submission-details-input-boxes {
  display: flex;
  flex-flow: row wrap;
  gap: 59px;
}

.submission-details-input-boxes input:focus {
  border-color: #97a3ae;
}

.submission-details-input-boxes input.sample-submission-input-error {
  border-color: red;
}

.submission-details-input-boxes input.sample-submission-input-error:focus {
  border-color: red;
}

.submission-address-input-boxes {
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
}

.submission-details-column input {
  font-family: "Roboto medium";
  font-size: 14px;
}

.submission-address-input-boxes input {
  cursor: not-allowed;
  opacity: 0.7;
}

.submission-address-short-input {
  min-width: 292px;
  border-radius: 4px;
  border: 2px solid #c4d2df;
  height: 40px;
  padding: 10px 19px 10px;
}
.submission-address-short-input--OverLimit {
  color: #ff4d4f;
}

/** Styles for the autocomlete resuable component */
.submission-address-po-input.ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  border-radius: 4px;
  border: 2px solid #c4d2df;
  min-width: 292px;
  height: 40px;
  padding: 10px 19px 10px;
}

.submission-address-po-input.ant-select-auto-complete .ant-input:focus {
  border-color: #97a3ae;
}

.submission-address-po-input-popup.ant-select-dropdown {
  min-width: 292px;
}

.submission-address-po-input-dropdown-arrow,
.submission-address-po-input .ant-select-clear {
  inset-inline-end: -10px;
  margin-top: -3px;
}

.submission-address-po-input-max-char {
  bottom: -27px;
  right: -24px;
}

/***/

.submission-address-long-input {
  min-width: 411px;
  border-radius: 4px;
}

.submission-use-as-billing-address {
  display: flex;
  align-items: flex-end;
}

.submission-use-as-billing-address button {
  border: none;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* Neutrals/600 */
  color: #6c7e8e;
}

.SubmissionDetails__InputDiv {
  position: relative;
}
