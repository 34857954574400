.BuilderConfirmSave__Container {
  z-index: 10;
  width: 100vw;
  height: 100%;
  background: linear-gradient(
    rgba(246, 248, 250, 0.527),
    rgba(246, 248, 250, 0.5)
  );
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.BuilderConfirmSave__PopupWrapper {
  width: auto;
  /* max-height: 500px; */
  background: #ffffff;
  right: 0px;
  top: 0px;
  /* margin-top: -1px; */
  margin-right: 24px;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
}

.BuilderConfirmSave__PopupWrapper svg {
  flex-shrink: 0;
}
