
.signup-invite-input-label-remove {
  opacity: 0;
}

.signup-invite-custom-field-input-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
}

.signup-invite-input.signup-invite-display-input {
  align-items: center;
  flex-basis: 100px;
  flex-shrink: 1;
  flex-grow: 1;
}

.signup-invite-display-container {
  justify-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  width: 100%;
  height: 37px;
}

.signup-invite-display-input .customSelectInput,
.signup-invite-display-input .customSelectInput > .MuiSelect-root:focus{
  background-color: #fdfdfd;
}

.signup-invite-display-input .MuiButton-contained.Mui-disabled {
  background-color: #f5f5f5;
  opacity: 1;
}

.signup-invite-display-input .CustomSelect__SelectButton--disabled.MuiButton-contained.Mui-disabled:hover {
  background-color: #f5f5f5;
  opacity: 1;
}

.signup-invite-input-remove > svg {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.signup-invite-input.signup-invite-input-remove {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 12px;
  display: flex;
  align-items: flex-end;
}

/* Close icon styling */
.signup-invite-custom-field-input-row > svg {
  cursor: pointer;
  justify-self: center;
  align-self: center;
}

.signup-invite-custom-field-input-row > svg > path {
  fill: #375393;
}

.signup-invite-custom-field-input-row > svg:focus {
  outline: unset;
}

.signup-invite-custom-field-input-row > svg:focus-visible {
  outline: -webkit-focus-ring-color auto 5px;
}

/* Custom checkbox styling */
.signup-invite-checkbox-container {
  justify-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}

.signup-invite-checkbox-container > input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signup-invite-checkmark {
  position: relative;
  cursor: pointer;
  border-radius: 0.5px;
  width: 18px;
  height: 18px;
  border: 2px solid #D7DAE1;
}

.signup-invite-checkbox-container > input:checked ~ .signup-invite-checkmark {
  border-color: #AFBDCA;
  background-color: #AFBDCA;
}

.signup-invite-checkbox-container > input:focus ~ .signup-invite-checkmark {
  border-color: #97a3ae;
}

.signup-invite-checkbox-container .signup-invite-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: solid #fff;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
  left: 4.5px;
  top: 0.5px;
  width: 5px;
  height: 9px;
}

.signup-invite-checkbox-container > input:checked ~ .signup-invite-checkmark:after {
  display: block;
}

.signup-invite-add-field-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  background: rgba(215, 218, 225, 0.5);
  border-radius: 3px; 
  height: 40px;
  width: 105px;
  border: none;
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #375393;
}

.signup-invite-add-field-button:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}