.LinkReportForm__ProductSpec {
  min-height: 66px;
}

.FieldsForm__Button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
}

.FormButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: unset;
}

.FormButtons_CheckedCase {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  bottom: 0;
}

.FormsContainer_Divider {
  margin-top: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #eaf0f5;
}

.Custom-radio-label {
  color: #6c7e8e !important;
  font-size: 12px !important;
  font-family: "Roboto medium" !important;
  margin-left: 8px;
}

.FieldsForm_Custom_TextLabel {
  margin-bottom: 8px !important;
  font-family: "Roboto medium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #182534;
}

.Ant__RadioBtn .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #6c7e8e;
}

.Ant__RadioBtn .ant-radio-wrapper .ant-radio-inner {
  border: 2px solid #6c7e8e;
}


.FieldsForm__LabelText {
  margin-bottom: 16px !important;
  font-family: "Roboto medium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #182534;
}

.FieldsForm__LabelText_Fields {
  margin-bottom: 16px !important;
  font-family: "Roboto medium";
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #6C7E8E;
  align-items: center;
}

.FieldForm__Loading{
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .FieldForm__Checkbox__Spin {
  color: #26abe1;
  font-size: 16px;
}

.FieldForm_Radio_label{
  color: #758ab9;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 20px;
  padding: 4px 0px 4px 2px;
  margin-right: 18px;
}