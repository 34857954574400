.CompanyList {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.CompanyList__Button {
  white-space: normal;
  text-align: left;
  word-break: break-word;
  padding: 12px;
  font-family: "Roboto";
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}

.CompanyList__Button:hover {
  color: #26abe1;
}

.CompanyList--active {
  color: #26abe1;
  cursor: default;
}

.pumpkinGIF img{
  width: 163px;
}

.pumpkinGIF div{
  font-size: 14px !important;
  margin-bottom: 20px;
}