.uploaded-files-item-main svg {
  min-width: 40px;
}

.uploaded-files-item-main svg.file-status-not-submitted {
  fill: #19305A;
}

.uploaded-files-error-item svg.file-status-not-submitted {
  fill: #E63559;
}

.uploaded-files-item-main svg.file-status-success {
  fill: #1EC04A;
}

.uploaded-files-item-main svg.file-status-partial-success {
  fill: #E63559;
}

.uploaded-files-item-main svg.file-status-failed {
  fill: #E63559;
}

.uploaded-files-item-delete {
  width: 24px;
  height: 24px;
  margin-left: 7px;
}

.uploaded-files-item-delete:hover {
  cursor: pointer;
}

.uploaded-files-item-delete path {
  fill: #19305A;
}