.PinList {
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
}

.PinList__MotionDiv {
  position: relative;
}

.PinList__Scrollbar.ScrollbarsCustom  .ScrollbarsCustom-Content {
  min-height: fit-content !important;
}

.PinList__ToolTip {
  position: fixed;
  z-index: 4;
}

.PinList__ToolTipImg {
  height: 24px;
  width: 170px;
}

.PinList__ToolTipText {
  font-family: "Roboto Medium";
  font-size: 10px;
  color: white;
  margin-left: -156px;
}