.MRLList__Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 24px;
}

.MRLList__CountryList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  gap: 4px;
}

.MRLList__CountryList.MRLList__CountryList__EditMode {
  justify-content: flex-start;
}

.MRLList__CountryTab, .MRLList__DropdownButton {
  font-size: 14px;
  color: rgb(55, 83, 147);
  padding: 2px;
  border-radius: 3px;
  background-color: #eaeaea;
}

.MRLList__DropdownButtonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 24px;
}

.MRLList__DropdownButton {
  padding: 6px;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;

  background-color: #eff2f3 !important;
  padding: 0px 10px;
  border-radius: 3px;

  color: #375393;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 32px;
}

.MRLList__ChevronDownIcon {
  height: 20px;
  color: #375393;
  transition: color 0.3s;
}

.MRLList__DropdownButton .MRLList__ChevronDownIcon {
  height: 16px;
}

.MRLList__CountryList__PlaceholderText {
  display: block;
  flex: 1;
  color: #b3bfdb;
  padding: 10px 15px;
  text-align: center;
}