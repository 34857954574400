.signup-invite-customer-info-main-partial {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.signup-invite-customer-info-type-label,
.customer-info-section-prod-env-type div {
  font-family: 'Roboto medium';
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: #375393;
}

.signup-invite-customer-info-type-label {
  margin-bottom: 4px;
}

.customer-info-section-prod-env-type {
  border: 2px solid #D7DAE1;
  border-radius: 3px;
  border-color: #D7DAE1;
  background-color: #fff;
  color: #375393;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-info-section-prod-env-type:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.customer-info-section-type-active {
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #26ABE1;;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #26ABE1;
  background-color: #F6F8FA;
}

.customer-info-section-prod-env-type:hover {
  border-color: #26ABE1;
  color: #26ABE1;
  background-color:#F6F8FA;
  cursor: pointer;
}

.CustomerInfoSection__ProductLabel--disabled.customer-info-section-prod-env-type,
.CustomerInfoSection__EnvLabel--disabled.customer-info-section-prod-env-type {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.CustomerInfoSection__ProductLabel--disabled.customer-info-section-prod-env-type:hover,
.CustomerInfoSection__EnvLabel--disabled.customer-info-section-prod-env-type:hover {
  box-shadow: none;
  border-color: #D7DAE1;
  color: #375393;
}

.CustomerInfoSection__ProductLabel--disabled.customer-info-section-prod-env-type.customer-info-section-type-active:hover,
.CustomerInfoSection__EnvLabel--disabled.customer-info-section-prod-env-type.customer-info-section-type-active:hover {
  border: 2px solid #26ABE1;;
  color: #26ABE1;
}