.CommonLoadingBtn {
  opacity: 0.7;
  pointer-events: none;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.CommonLoadingBtn > svg {
  fill: #F9F9F9;
}