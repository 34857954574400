.SampleSubmission__CharacterCountText {
  position: absolute;
  right: 0px;
  bottom: -18px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #B3BFDB;
  text-align: right
}

.SampleSubmission__CharacterCountText--OverLimit {
  color: red;
  position: absolute;
  right: 0px;
  bottom: -18px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: right
}