.PesticideSampleAutocomplete__Container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.PesticideSampleAutocomplete__TestName {
  box-sizing: border-box;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #26abe1;
  border: 1.5px solid #26abe1;
  border-radius: 8px;
  background: #f6f8fa;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  word-break: keep-all;
}

.PesticideSampleAutocomplete__Container .ant-input {
  border-radius: 8px !important;
  border: 2px solid #c4d2df;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.PesticideSampleAutocomplete__TestName .PesticideSampleAutocomplete__CloseIcon {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PesticideSampleAutocomplete__TestName .PesticideSampleAutocomplete__CloseIcon:hover {
  cursor: pointer;
}

.PesticideSampleAutocomplete__TestName.PesticideSampleAutocomplete__InvalidTest {
  color: #e63559;
  border: 1.5px solid #e63559;
}

.PesticideSampleAutocomplete__TestName.PesticideSampleAutocomplete__InvalidTest
  svg
  > path {
  fill: #e63559;
}

.PesticideSampleAutocomplete__Container .SampleDetailsAutocompleteInput {
  width: auto !important;
}