.InvitePage {
  height: 100vh;
  position: relative;
}

.row.InvitePageWhiteContainer {
  width: calc(100% - 300px);
  height: calc(100vh - 200px);
  box-shadow: -30px -30px 80px rgba(193, 200, 223, 0.05),
    30px 30px 80px rgba(104, 127, 192, 0.1);
  background: #fff;
  margin: 100px auto;
  padding: 35px 0 0 35px;
  overflow: hidden;
  min-height: 790px;
  min-width: 610px;
  position: relative;
  flex-wrap: nowrap;
}

.InviteSinglePage {
  height: auto;
  overflow-y: visible;
  overflow-x: visible;
  margin-top: 35px;
  background-color: #fff;
  position: relative;
}

.InviteForm > .FormLayout__Body {
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  border: 1px solid #eaf0f5;
}

.InviteCustomerForm {
  min-width: 100%;
  padding: 25px !important;
}

.InvitePageLeft {
  display: flex;
  flex-direction: column;
}

.InviteForm__BtnContainer__RightAlign {
  display: flex;
  flex-direction: row-reverse;
}

.InviteForm__CustomerStatusToggle {
  margin-bottom: 15px;
}

.InviteForm__CustomerStatusToggle label {
  font-size: 16px;
  line-height: 28px;
  min-width: 100px;
  text-align: center;
}

.InviteForm__HeaderContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 26px; /* TODO Once ant-d css is scoped, update the value to 40 */
}

.InviteForm__Header {
  font-family: "Roboto Medium";
  color: #182534;
  font-size: 22px;
}

.InviteForm__HeaderInfo {
  font-family: "Roboto";
  color: #182534;
  font-size: 16px;
}

/* Custom error message */
.InviteForm__CustomErrorsContainer {
  display: flex;
  flex-wrap: nowrap;
}

.InviteForm__CustomErrorsContainer
  > .ant-form-item-explain.ant-form-item-explain-connected
  > .ant-form-item-explain-error:last-of-type {
  margin-bottom: -15px;
}
