.SampleSubmission__ConfirmModal .ant-modal-content {
  padding: 28px 40px;
  min-width: 422px;
}

.SampleSubmission__ConfirmModal_Add .ant-modal-content {
  min-width: 462px !important;
  border-radius: 3px;
  padding: 40px;
}

.SampleSubmission__ConfirmModal .ant-modal-content .ScrollbarsCustom {
  min-height: unset !important;
}

.sampleSubmissionConfirmDel {
  text-align: left;
}

.sampleSubmissionConfirmDelTitle {
  font-family: "Roboto Bold";
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305a;
  margin-bottom: 16px;
}

.sampleSubmissionConfirmDelTitleMessage {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  color: #375393;
}

.sampleSubmissionConfirmDelTitleMessage_Add {
  margin-bottom: 24px;
}

.sampleSubmissionConfirmDelBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.sampleSubmissionModalCancelBtn.ant-btn-default {
  height: 32px;
  padding: 8px 12px;
  width: fit-content;
}

/* .sampleSubmissionModalSubmitBtn {
  height: 32px;
  border: none;
  padding: 8px 12px;
  background: #26abe1;
  border-radius: 3px;
  color: #f9f9f9;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.sampleSubmissionModalSubmitBtn.loading {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.7;
  pointer-events: none;
}

.sampleSubmissionModalSubmitBtn.loading > svg {
  fill: #f9f9f9;
} */

/* .sampleSubmissionModalCancelBtn:hover,
.sampleSubmissionModalSubmitBtn:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
} */
