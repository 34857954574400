.specManagerSearchWhiteMagnifyIcon{
    width: 13.54px;
    height: 13.54px;
    margin: auto;
    position: absolute;
    left: 8.33%;
    right: 7.02%;
    top: 8.33%;
    bottom: 7.02%;
}

#specManagerSearchContainer{
    position: relative;
}

.EnvSpecManagerSearchContainer,
.EnvSpecManagerSearchContainer__MainSection {
  display: flex;
  align-items: center;
}

.ProdSpecManagerSearchBtn {
  width: 53px;
  height: 36px;
  background: #26a1d2;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  position: absolute;
  right: 2px;
  cursor: pointer;
}