.ReportsTableSpecCell__Icon {
  width: 13.33px;
  height: 13.33px;
  margin-bottom: 2px;
}

.ReportsTableSpecCell__Status {
  margin-left: 5px;
  cursor: pointer;
  font-family: Roboto Medium;
}
