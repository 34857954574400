.SelectLocation__ExtraField__AutocompleteContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #fdfdfd;
  padding: 2px;
  position: relative;
}

.SelectLocation__ExtraField__AutocompleteContainer .auto-complete-replacement-input {
  width: 100%;
  flex: 1;
  border: none;
}

.SelectLocation__ExtraField__AutocompleteContainer .auto-complete-replacement-input::placeholder {
  color: rgba(126, 126, 126, 0.184);
}

.SelectLocation__ExtraField__AutocompleteContainer:focus-within {
  border-color: #26ABE1;
}

.SelectLocation__ExtraField__AutocompleteContainer .clear-button {
  appearance: none;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-size: 20px;
  color: #d7dae1;
  cursor: pointer;
  margin-right: 8px;
}

.SelectLocation__ExtraField__AutocompleteContainer .auto-complete-replacement-dropdown {
  width: calc(100% + 4px);
  position: absolute;
  top: calc(100% + 4px);
  left: -2px;
  right: 0;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
  z-index: 90;
  box-shadow: 0 2px 8px -4px #9c9c9c;
  max-height: 100px;
}

#AutocompleteInputScrollbar.ScrollbarsCustom {
  max-height: inherit;
}

#AutocompleteInputScrollbar .ScrollbarsCustom-Wrapper,
#AutocompleteInputScrollbar .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.SelectLocation__ExtraField__AutocompleteContainer .auto-complete-replacement-dropdown .dropdown-item {
  padding-left: 8px;
  padding-right: 8px;
  max-width: 100%;
  word-break: break-all;
  text-wrap: auto;
}

.SelectLocation__ExtraField__AutocompleteContainer .auto-complete-replacement-dropdown .dropdown-item:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.SelectLocation__ExtraField__AutocompleteContainer .auto-complete-replacement-dropdown .dropdown-item:last-of-type {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ReactVirtualized__Grid__innerScrollContainer,
.ReactVirtualized__Table__rowColumn,
.ReactVirtualized__Table__row {
  overflow: visible !important;
}

.auto-complete-replacement-dropdown-arrow {
  margin-inline-end: 7px;
  display: inline-block;
  width: 10px;
  height: 10px;
}

.auto-complete-replacement-dropdown-arrow path {
  stroke: #97a3ae;
  stroke-width: 1.5;
}