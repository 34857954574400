.EditableFields__AddField {
  color: #26abe1;
  border-color: #26abe1;
  border-radius: 8px;
  gap: 8px;
  padding: 8px, 14px, 8px, 14px !;
  font-family: "Roboto medium";
  font-size: 12px;
}

.EditableFields__AddField.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #26abe1;
  border-color: #26abe1;
}

.EditableFields__AddField:hover {
  color: #26abe1 !important;
  border-color: #26abe1 !important;
  background-color: white !important;
  /* box-shadow: 5px 5px 10px #26abe1 !important; */
}

.EditableFields__DynamicItem.ant-form-item .ant-form-item-control-input-content {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 10px;
}

.EditableFields__DynamicItem {
  padding-bottom: 0px;
  margin-bottom: 16px;
  font-family: 'Roboto';
  font-size: 14px !important;
}

.MyForm .ant-form-item {
  padding-bottom: 0px;
  margin-bottom: 14px;
}

.EditableFields__DeleteIcon svg {
  font-size: 16px;
  fill: #6c7e8e;
}

.EditableFields__DeleteIcon:hover svg {
  fill: #6262629e;
}

.EditableFields__DynamicItem .ant-form-item-explain{
  margin: 5px 0px;
}
/* No class for non - editable fields */