.env-upload-files {
  display: flex;
  column-gap: 20px;
  height: 100%;
}

.env-drag-drop-files {
  flex: 0.3 0.3 239px;
}

.env-upload-files-dragging {
  border: 2.72027px dashed #26abe1;
  background-color: #eff2f3;
}

.env-uploaded-files {
  flex: 0.7 0.7 400px;
  box-sizing: border-box;
  background: rgba(179, 191, 219, 0.12);
  border: 2.72027px dashed #eff2f3;
  border-radius: 4px;
  padding: 14px 23px 14px 20px;
  height: 100%;
}

.env-uploaded-files h3 {
  text-align: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #19305a;
  margin-bottom: 16px;
}

.env-uploaded-files-inner {
  flex: 0.7 0.7 400px;
  box-sizing: border-box;
  background: rgba(179, 191, 219, 0.12);
  border: 2.72027px dashed #eff2f3;
  border-radius: 4px;
}

.env-uploaded-files ul {
  padding-left: 0;
}

.uploaded-files-item {
  background: #fdfdfd;
  padding: 12px 10px 12px 10px;
  min-height: 64px;
  display: flex;
  flex-direction: column;
}

.uploaded-files-item-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 7px;
  width: 100%;
  list-style: none;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #19305a;
}

.uploaded-files-item-file-size {
  text-align: left;
  font-family: "Roboto medium";
  font-style: normal;
  font-size: 8px;
  line-height: 13px;
}

li.uploaded-files-item:not(:last-child) {
  margin-bottom: 3px;
}

.env-uploaded-files-no-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b4b4b4;
}

.file-icon-and-name-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.uploaded-files-error-item-right {
  display: flex;
  align-items: center;
  max-width: 56%;
}

.uploaded-files-error-item-right svg path {
  fill: #e63559;
}

.uploaded-files-item-error-text-title-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.uploaded-files-item-error-text-title-body,
.uploaded-files-item-error-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #e63559;
  width: max-content;
}

.uploaded-files-item-warning-text {
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #e1b226;
  width: max-content;
}

.uploaded-files-item-success-text {
  text-align: right;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #1ec04a;
  width: max-content;
}

.uploaded-files-item-text-title {
  font-size: 14px;
}

.uploaded-files-item-swab-errors {
  margin-top: 5px;
  max-height: 200px;
}

.uploaded-files-item-swab-errors table {
  text-align: left;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}

.uploaded-files-item-swab-errors
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  display: unset;
  height: unset !important;
}

.uploaded-files-item-swab-errors
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  height: 6px !important;
  background: hsla(0, 0%, 76.9%, 0.81) !important;
}

.uploaded-files-item-swab-errors th {
  text-align: right;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: center;
  min-width: 45px;
}

.uploaded-files-item-swab-errors td {
  text-align: center;
  min-width: 45px;
  height: 20px;
  white-space: nowrap;
}

.uploaded-files-item-swab-error {
  padding: 3px;
  background: rgba(179, 191, 219, 0.12);
}

.env-uploaded-file-text {
  word-break: break-all;
  text-align: left;
}

.ScrollbarsCustom.env-uploaded-files-scroll-bar {
  height: calc(100% - 36px) !important;
}

.env-uploaded-files-scroll-bar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: hsla(0, 0%, 76.9%, 0.81) !important;
}

.env-uploaded-files-scroll-bar ul {
  margin-bottom: 0;
}

.create-env-modal-next-btn.loading {
  opacity: 0.7;
  pointer-events: none;
  padding-top: 4px;
}

.create-env-modal-next-btn.loading > svg {
  fill: #f9f9f9;
}

.create-env-modal-buttons-optional {
  display: flex;
  justify-content: space-between;
}

.env-main-container th:nth-child(1),
.create-env-modal th:nth-child(1) {
  text-align: left;
}

.env-main-container td:nth-child(1),
.create-env-modal td:nth-child(1) {
  text-align: left;
}

.UploadSwabFiles__btns {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
}

.UploadSwabFiles__btns button {
  height: 32px;
  border-radius: 3px;
  font-family: "Roboto medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  border: none;
}

.UploadSwabFiles__CancelBtn,
.UploadSwabFiles__SubmitBtn {
  padding: 8px 12px;
}

.UploadSwabFiles__btns button:first-child {
  width: 70px;
  background: #eff2f3;
  color: #19305a;
}

.UploadSwabFiles__btns button:last-child {
  width: 70px;
  background: #26abe1;
  color: #f9f9f9;
}

.UploadSwabFiles__BtnsContainer button {
  height: 32px;
  border-radius: 3px;
  font-family: "Roboto medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  border: none;
}

.UploadSwabFiles__SkipBtn {
  padding: 8px 12px;
}
