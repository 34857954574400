/* .templatesBlock {
  position: absolute;
  top: -64px;
  right: 0;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  color: #375393;
  z-index: 1;
}

.templatesHeader > span {
  font-size: 18px;
  margin-right: 20px;
}

.templatesButton {
  position: relative;
  height: 57px;
  width: 54px;
  background-color: #375393;
  color: #f9f9f9;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  border-radius: 8px;
  cursor: pointer;
  border-width: 1px;
  border-color: transparent;
}

.templatesButton.templatesExpanded {
  background-color: #f9f9f9;
  color: #375393;
  border: 1px solid #26abe1;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
}

.templatesButton span {
  display: block;
  margin-top: 13px;
  width: 100%;
  text-align: center;
}

.templatesButton img {
  display: inline-block;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  content: url("../../../../../assets/images/product/chevronDownBlueBg.png");
}

.templatesButton.templatesExpanded img {
  border-radius: 0px 0px 7px 7px;
  content: url("../../../../../assets/images/product/chevronUpBlueBg.png");
}

.templatesBlock .card {
  display: block;
  border-radius: 8px;
  margin-top: 8px;
  height: 600px;
  width: 385px;
  background: #ffffff;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
}

.templatesBlock .cardHeader {
  width: 100%;
  display: flex;
  padding-left: 19px;
  padding-right: 8px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #375393;
  color: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
}

.templatesBlock .cardHeader img {
  content: url("../../../../../assets/images/product/closeIconWhite.png");
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.templatesBlock .cardBody {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 560px;
}

.templatesBlock .cardBody .productCoaSearch {
  margin-top: 20px;
}


.templateCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  color: #f9f9f9;
  background-color: #3874ff;
  border-radius: 2px;
  overflow: hidden;
}

.templateCard.addTemplate {
  background-color: white;
  cursor: pointer;
}

.templateCard.addTemplate:hover {
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
  0px 10px 32px -4px rgba(24, 39, 75, 0.1);
}

.templateCard.displayTemplate img {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 16px;
  cursor: pointer;
}

.templateCard.displayTemplate img:nth-of-type(1) {
  top: 2px;
  right: 5px;
  content: url("../../../../../assets/images/product/dotsIconWhite.png");
}

.templateCard.displayTemplate img:nth-of-type(2) {
  bottom: 2px;
  right: 5px;
  content: url("../../../../../assets/images/product/playIconWhite.png");
}

.templateCard.addTemplate img {
  cursor: pointer;
  display: block;
  width: 24px;
  height: 24px;
  content: url("../../../../../assets/images/product/AddIconBlue.png");
} */

/* ---v2--- */

/* #templatesScrollbar {
  margin-top: 54px;
} */

#templatesScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #b3bfdb !important;
  /* border-radius: 3px !important; */
  /* width: 6px !important; */
}

.templatePreviewContainer {
  margin-top: -12px;
  margin-bottom: -20px;
}

.templatePreviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.templatePreviewHeader {
  background-color: #26abe1;
  height: 36px;
  padding: 0px 8px;
}

.templatePreviewHeader img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.templatesContainer--COA {
  background: #ffffff;
  border-radius: 0 16px 16px 16px;
  height: 100%;
  overflow: hidden;
  min-width: 334px;
  margin-top: 14px;
}

.templatesContainerChild--COA {
  padding-left: 19px;
  border-left: 2px solid #eaf0f5;
}

.templatesContainer {
  background: #ffffff;
  border-radius: 0 16px 16px 16px;
  height: 100%;
  overflow: hidden;
  padding: 19px;
  padding-bottom: 18px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
}

.templatesContainerChild {
  border: 1px solid #eaf0f5;
  border-radius: 16px;
  padding: 16px 18px;
  padding-bottom: 2px;
}

.templatesHeaderContainer {
  margin-bottom: 24px;
}
.templatesHeaderContainer > span {
  font-family: "Roboto Medium";
  color: #506375;
  font-size: 16px;
  margin-bottom: 10px;
}
.templatesHeaderContainer > p {
  font-family: "Roboto Medium";
  color: #afbdca;
  font-size: 14px;
}

.templateBody {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(141px, 1fr));
  gap: 16px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 28px;
  color: #111a24;
}

.headerIndicator {
  width: 12px;
  height: 28px;
  background: #26abe1;
  border-radius: 4px;
}

.headerIndicator--active {
  width: 12px;
  height: 28px;
  background: #26abe1;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px 1px; */
}

.Template__ReportBtn {
  background: #26abe1;
  color: white;
  margin-left: 35px;
  width: 235px;
  height: 49px;
  font-size: 16px;
  font-family: "Roboto Medium";
}

.Template__ReportBtn.ant-btn-default:not(:disabled):hover {
  color: white;
  box-shadow: rgb(233, 232, 232) 2px 3px 7px 2px;
  background-color: #26abe1;
}

.Template__ReportBtn.ant-btn-default:disabled, .Template__ReportBtn.ant-btn-default.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: #FFFFFF;
  background-color: #26ABE1;
  box-shadow: none;
}

.TemplateLoading {
  min-width: 141px;
  height: 90px;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border: 1px solid #eaf0f5;
}