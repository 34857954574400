.TemplateBuilder__Textarea,
.TemplateBuilder__Input {
  width: 100%;
  border-radius: 8px;
}

.TemplateBuilder__Textarea,
.TemplateBuilder__Input,
.TemplateBuilder__Input--withLabel {
  border: 1px solid #e6eef5;
  background: white;

  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;
  color: #1f2d3b;

  padding: 10px;
}

.TemplateBuilder__Textarea {
  height: 94px;
  resize: none;
  overflow: hidden;
}

.TemplateBuilder__InputDiv {
  height: 40px;
  display: flex;
  width: 100%;
}

.TemplateBuilder__Input {
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TemplateBuilder__Input--withLabel {
  border-radius: 0 6px 6px 0;
  border-left: 0px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TemplateBuilder__Textarea::placeholder,
.TemplateBuilder__Input::placeholder {
  color: #afbdca;
  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;
}

.TemplateBuilder__InputLabel {
  height: 100%;
  width: fit-content;
  margin: 0;
  max-width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  background: white;
  border: 1px solid #e6eef5;
  border-radius: 8px 0 0 8px;
  border-right: 0px;

  display: flex;
  align-items: center;
  padding: 0px 4px 0px 12px;

  color: #afbdca;
  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;
}

/* Focus styling */
.TemplateBuilder__Textarea:focus,
.TemplateBuilder__Input:focus {
  outline: unset;
  border: 1px solid #26abe1;
  box-shadow: unset;
}

.TemplateBuilder__Input--withLabel:focus {
  outline: unset;
  border: 1px solid #26abe1;
  border-left: 0px;
  box-shadow: unset;
}

.TemplateBuilder__InputLabel.TemplateBuilder__InputLabel--focus {
  border: 1px solid #26abe1;
  border-right: 0px;
}

/* Disabled styling */
.TemplateBuilder__Input.TemplateBuilder__Input--disabled,
.TemplateBuilder__Input--withLabelTemplateBuilder__Input--disabled {
  background: #f2f6fa;
}

.TemplateBuilder__InputLabel.TemplateBuilder__InputLabel--disabled {
  background: #f2f6fa;
}

/* Error Styling */
.TemplateBuilder__InputError.TemplateBuilder__Textarea,
.TemplateBuilder__InputError.TemplateBuilder__Textarea:focus,
.TemplateBuilder__InputError.TemplateBuilder__Input,
.TemplateBuilder__InputError.TemplateBuilder__Input:focus {
  border: 1px solid #e12626;
}

.TemplateBuilder__InputError.TemplateBuilder__Input--withLabel,
.TemplateBuilder__InputError.TemplateBuilder__Input--withLabel:focus {
  border: 1px solid #e12626;
  border-left: 0;
}

.TemplateBuilder__InputError.TemplateBuilder__InputLabel,
.TemplateBuilder__InputError.TemplateBuilder__InputLabel.TemplateBuilder__InputLabel--focus {
  border: 1px solid #e12626;
  border-right: 0;
}
