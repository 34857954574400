.SubmissionReceiptModal .ant-modal-header {
  margin-bottom: 0px;
  background-color: rgba(233, 254, 229, 1);
  padding: 14px 20px;
}

.SubmissionReceiptModal .ant-modal-title {
  display: flex;
  align-items: center;
}

.SubmissionReceiptModal .ant-modal-close {
  width: 24px;
  height: 24px;
  top: 18px;
  inset-inline-end: 8px;
}

.SubmissionReceiptModal .ant-modal-close:hover {
  background-color: unset;
}

.ReceiptModalActionsBar {
  display: flex;
  gap: 32px;
  align-items: flex-start;
}

.ReceiptModalActionsBar--closable {
  display: flex;
  gap: 32px;
  padding-right: 20px;
  align-items: flex-start;
}

.ReceiptModalActionsBar__Text--with-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 332px;
}

.ReceiptModalActionsBar__Text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.ReceiptModalActionsBar__Title {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 5px;
  color: #000;
  font-family: "Roboto medium";
  font-size: 16px;
  font-weight: normal;
}

.ReceiptModalActionsBar__Text--with-title .ReceiptModalActionsBar__Info {
  display: flex;
  gap: 6px;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 191, 113, 1);
  font-weight: normal;
  font-family: "Roboto medium";
  width: 500px;
}

.ReceiptModalActionsBar__Info {
  display: flex;
  gap: 6px;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 191, 113, 1);
  font-weight: normal;
  font-family: "Roboto medium";
}

.SubmissionReceiptModal__Buttons {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.SubmissionReceiptModal__Buttons .ant-btn-icon {
  margin-bottom: 1px;
}

.SubmissionReceiptModal__Buttons > .StyledButton {
  border-width: 1.5px;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
}

.SubmissionReceiptModal__Buttons > .StyledButton:first-child {
  background-color: rgba(255, 255, 255, 1) !important;
}
