.Disabled{
  opacity: 0.6;
  pointer-events: none;
}

.dataFieldsAdd_formSection{
  border-bottom: 1px solid #EAF0F5;
}

.dataFieldsAdd_newFieldForm{
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  width: 65%;
  border-radius: 8px;
  background-color: #F6F8FA;
  border: 1px solid #E6EEF5;
  margin: 20px auto;
}

.dataFieldsAdd_newFieldForm:focus-within{
  box-shadow: 0px 6px 12px 0px #0000001C;
}

.dataFieldsAdd_formInput{
  font-weight: 500;
}

.dataFieldsAdd_addButton{
  background-color: #26ABE1;
  color: #fff;
  float: right;
  padding: 5px 12px;
  margin: auto;
  align-items: center;
  display: flex;
  gap: 10px;
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 0px;
  cursor: pointer;
}

.dataFieldsAdd_addButtonDisabled{
  background-color: #26ABE1;
  color: #fff;
  float: right;
  padding: 5px 12px;
  margin: auto;
  align-items: center;
  display: flex;
  gap: 10px;
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 0px;
  cursor: auto;
  opacity: 0.7;
  pointer-events: none;
}

.dataFieldsAdd_addButtonIcon{
  width: 18px;
  height: 18px;
}