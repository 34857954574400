.templateSavePopup {
  padding: 50px 16px 16px 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* gap: 27px; */
  width: 432px;
  position: relative;
}

/* .templateSavePopupHeader {
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  font-size: 20px;
  line-height: 28px;
  color: #19305a;
} */

.TemplateSave__DeleteButton {
  position: absolute;
  top: 14px;
  right: 14px;
  height: 18.33px;
  cursor: pointer;
}

.TemplateSaveSuccess {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 36px;
  align-items: center;
  justify-content: center;
  padding: 18px 28px 41px 24px;
}

.TemplateSaveSuccess__TemplateBlock {
  width: 136px;
  height: 120px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid #eaf0f5;
  background: #3874ff;
  box-shadow: 0px 13px 37px 0px rgba(0, 0, 0, 0.21);
}

.TemplateSaveSuccess__TemplateBlock > span {
  color: #f9f9f9;
  font-size: 14px;
  font-family: "Roboto Medium";
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  word-wrap: break-word;
  max-width: 100%;
}

.saveInputSectionTop {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.saveInputSectionBottom {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 3px;
}

.templateBuilderInput {
  height: 40px;
  background: #fff;
  border: 1px solid #e6eef5;
  border-radius: 8px;
  padding: 10px;
  color: #385387;
  font-size: 14px;
}

.saveInputSectionInputTitle {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  color: #6c7e8e;
  margin-bottom: 5px;
}

.saveInputSectionInputTags {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  color: #6c7e8e;
  margin-right: 20px;
}

.saveInputSectionInput {
  width: 350px;
}

.saveInputSectionInput::placeholder {
  font-family: "Roboto";
  font-size: 14px;
  color: rgba(0, 0, 0, 0.38);
}

.saveInputSectionColorTag {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.saveInputSectionTags {
  display: flex;
  gap: 16px;
  padding: 10px;
  width: 300px;
  height: 60px;
  align-items: center;
}

.saveInputSectionColorTag:hover {
  outline: 2px solid #7dcded;
  cursor: pointer;
  transform: scale(1.25);
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.5),
    0px 12px 42px -4px rgba(24, 39, 75, 0.5);
}

.saveInputSectionColorTagActive {
  outline: 2px solid #26abe1;
  transform: scale(1.25);
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.5),
    0px 12px 42px -4px rgba(24, 39, 75, 0.5);
}

.templateSavePopupFooter {
  display: flex;
  justify-content: flex-end;
}

.templateBuilderHeaderSaveButton {
  background: #26abe1;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
  cursor: pointer;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.templateBuilderHeaderSaveButton:hover {
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
}

.templateBuilderHeaderSaveButtonDisable {
  background: #b3e0f2;
  pointer-events: none;
  cursor: default;
}

.templateBuilderHeaderSaveButtonDisable:hover {
  box-shadow: unset;
}

.templateBuilderHeaderSaveButtonHidden {
  width: 120px;
  height: 40px;
}
