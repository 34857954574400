.FailedEmailModalContainer.ant-modal .ant-modal-content {
  border-radius: 16px;
  padding: 7px 0px 18px;
}

.FailedEmailModalContainer .FailedEmailModalHeader {
  height: 25px;
}

.FailedEmailModal {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.FailedEmailModalHeader {
  color: #19305a;
  text-align: left;
  font-family: "Roboto Medium";
  font-size: 18px;
  margin-bottom: 20px;
}

.FailedEmailModalEmailsContainer {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.FailedEmailModalBtns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  height: 32px;
}

.FailedEmailModalCancelBtn {
  padding: 5px 15px;
  width: fit-content;
  background: #eff2f3;
  color: #19305a;
  text-align: center;
  border: 1px solid #eff2f3;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.FailedEmailModalCancelBtn:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.failedEmails{
  word-break: break-all;
}