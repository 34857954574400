.ReportsTable__EmptyCell__Content {
  display: flex;
  width: 100%;
}

.ReportsTable__EmptyCell__Values {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-left: 3px;
}

.ReportsTable__FieldCellContent--show-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ReportsTable__EmptyCell .ReportsTable__FieldCellContent--show-checkbox {
  padding: 0 12px;
}

.ReportsTable__Checkbox--enabled,
.ReportsTable__Checkbox--disabled {
  width: 18px;
  height: 18px;
}

.ReportsTable__Checkbox--enabled .ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
  border: 2px solid #26abe1 !important;
  width: 18px;
  height: 18px;
}

.ReportsTable__Checkbox--disabled .ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
  border: 2px solid #c2c2c2 !important;
  width: 18px;
  height: 18px;
}

.ReportsTable__Checkbox__Spin {
  color: #26abe1;
}
