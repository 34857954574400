.TemplateBuilder__EditPreviewButton {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;

  border: 2px solid #bee6f6;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #26abe1;
  font-size: 14px;
  font-family: "Roboto Medium";
  line-height: 20px;

  padding: 10px 11px;
}

.TemplateBuilder__EditPreviewButton:hover {
  box-shadow: unset;
  width: 116px;

  padding: 10px 13px 10px 17px;
}

button.TemplateBuilder__EditPreviewButton.TemplateBuilder__EditPreviewButton--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.TemplateBuilder__EditIcon {
  width: 15px;
  height: 15px;
}

.TemplateBuilder__EditIcon path {
  fill: #26abe1;
}
