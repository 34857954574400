.productReportSpecManagerDisplayTableNoResultsScrollBar {
  width: 100%;
  height: 100%;
}

.productReportSpecManagerDisplayTableScrollBar {
  width: 100%;
  height: calc(100% - 700px);
  min-height: 384px;
}

.SpecTableRow__Skeleton {
  padding: 0 12px 0 9px;
}

.SpecTableRow__Skeleton
  .ant-skeleton.ant-skeleton-element
  .ant-skeleton-button {
  width: 120px;
}

.SpecTableRow__Skeleton .ant-skeleton .ant-skeleton-title {
  height: 20px;
  margin-bottom: 0;
}

.LinkedFieldTitle {
  font-weight: 400;
  color: #6c7e8e;
  font-size: 12px;
  user-select: none;
}

.LinkedFieldValue {
  color: #375393;
  font-size: 12px;
  font-weight: 600;
}

.LinkedFieldsDelimiterDot {
  background-color: #19305a;
  margin: 0px 5px;
}

#productReportSpecManagerSpecAddedIcon {
  display: inline-block;
  margin-right: 5px;
  width: 13.33px;
  height: 13.33px;
}

#productReportSpecManagerSpecEditButton,
#productReportSpecManagerSpecAddButton {
  background-color: #eff2f3;
}

#productReportSpecManagerPendingIcon {
  width: 13.33px;
  height: 13.33px;
  margin-right: 5px;
  margin-bottom: 2px;
}

#productReportSpecManagerSpecTable {
  padding-right: 10px;
}

button.specStateBtn {
  margin-right: 5px;
  padding: 10px;
  height: 32px;
  background-color: #eff2f3;
  text-transform: none;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 120px;
}

.specEditBtn {
  color: #26abe1;
}

.specAddBtn {
  color: #19305a;
}

.specEditBtn:hover,
.specAddBtn:hover {
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-color: #eff2f3 !important;
}

.specEditBtn:hover {
  color: #26abe1 !important;
}

.specAddBtn:hover {
  color: #000000e0 !important;
}

.specEditBtn,
.specAddBtn {
  border: none;
}

.specEditBtn img.specAdded {
  content: url("../../../../../assets/images/product/checkBlue.png");
}

.specTableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  min-height: 44px;
  width: 800px;
  border-radius: 4px;
  background: white;
}

.specTableRowLeftCol {
  margin: 12px 0px 12px 13px;
  max-width: 80%;
}

.specTableRowRightCol {
  margin-right: 7px;
}

.buttonDisableSection {
  opacity: 0.8;
  pointer-events: none;
}
