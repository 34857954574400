.analyticsGraphPillContainer {
  display: flex;
  /* flex-grow: 1; */
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  height: 100%;
  padding: 10px 12px;
  border-radius: 8px;
  /* min-width: 285px; */
}

.analyticsGraphPillContainer:hover {
  cursor: pointer;
}

.analyticsGraphPillSubContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.analyticsGraphPillContainer .LinkedFieldsSpan {
  flex-flow: row nowrap;
}

.analyticsGraphPillText {
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  color: #506375;
}

.analyticsGraphPillText .Analytics__GraphPill__ValueText {
  font-family: "Roboto";
}

.analyticsGraphPillImage {
  width: 16px;
  height: 16px;
}

.analyticsGraphPillDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;

  align-self: center;
}

/* Tooltip styling */
.Analytics__GraphPill__Tooltip.ant-tooltip .ant-tooltip-inner {
  background: #eff2f3;
}

.Analytics__GraphPill__Tooltip__Text {
  word-break: break-word;
  white-space: pre-wrap;
}

.Analytics__GraphPill__Tooltip__FieldText {
  color: #375393;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 18px;
}

.Analytics__GraphPill__Tooltip__ValueText {
  color: #6c7e8e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
