.StyledSwitch.ant-switch:not(.ant-switch-disabled) {
  background: #6b778c;
}

.StyledSwitch.ant-switch:hover:not(.ant-switch-disabled) {
  background: #a5adba;
}

.StyledSwitch.ant-switch.ant-switch-checked,
.StyledSwitch.ant-switch:hover.ant-switch-checked {
  background-color: #1ec04a;
}

/* .StyledSwitch.ant-switch:focus-visible {
  outline: 2px solid #26abe1 !important;
} */