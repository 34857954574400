.COABuilderHeader {
  padding-right: 19px;
  min-height: 94px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #b3bfdb;
  margin-bottom: 16px;
  position: relative;
  padding-bottom: 9px;

  font-family: "Roboto Medium";
  color: #506375;
  overflow-wrap: break-word;
}

.COABuilderHeader.COABuilderHeader--noImage {
  padding-left: 21px;
  min-height: unset;
}

.COABuilderHeader__LeftContainer {
  max-width: 70%;
}

.COABuilderHeader__NumbersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  max-width: 30%;
  gap: 7px;
  font-size: 11px;
}

.COABuilderHeader__NumberRow {
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: auto;
  justify-content: space-between;
  max-width: 100%;
}
