/* Draft section styling */
.draft-submission-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 300px;
}

.draft-submission-background {
  flex: 1;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  width: 100%;
  height: calc(100% - 53px);
}

.draft-submission-background .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
  width: 6px !important;
}

/* .draft-submission-background .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #19305a !important;
} */

.draft-submissions-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.draft-submissions-ul li {
  background: #def2fb;
  border-radius: 4px;
  padding: 15px 12px;
}

.draft-submissions-ul li:not(:last-child) {
  margin-bottom: 12px;
}

.draft-submissions-ul p span {
  display: block;
}

.sample-submission-title-block {
  background-color: #26abe1;
  border-radius: 4px;
  width: 16px;
  height: 36px;
}

.draft-submission-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.draft-submission-header h2 {
  font-family: "Roboto Bold";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  margin-left: 8px;
}

.Draft_DrawerToggle {
  background: #375393;
  border: none;
  color: white;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  padding: 0 8px;
}

.Draft_DrawerToggle .Draft_DrawerToggleIcon {
  transform: rotate(-90deg);
}

.Draft_DrawerToggle:hover .Draft_DrawerToggleIcon {
  transform: translateX(2px) rotate(-90deg);
  transition: transform .2s ease;
}