.SpecTable__TestType {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.SpecTable__TableContentTest {
  text-align: center;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
}

.SpecTable__TableContentType {
  text-align: center;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  color: #375393;
}

.SpecTable__TableContentTestValue {
  text-align: center;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  color: #375393;
  width: 320px;
}

.SpecTable__Scrollbar {
  width: 100%;
  height: 100%;
  /* min-height: 350px; */
}

.specTable {
  padding-right: 10px;
}