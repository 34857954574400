.SampleSubmissionForm__DisableAddSample {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fdfdfd81;
  z-index: 2;
  border-radius: 16px;
}

.analysis-request-maximized {
  grid-column: 2 / 4 !important;
}