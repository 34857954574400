.datePickerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 8px;

    height: 46px;
}

.dateBlockContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    background-color: #F6F8FA;
    border-radius: 3.5px;
    padding: 10px 6px;

    width: 42px;
    height: 46px;

    cursor: pointer;
}

.dateBlockContainerActive {
    background-color: #26ABE1;

    cursor: pointer;
}

.dateBlockDayContainer {
    font-family: 'Roboto Medium';
    font-size: 16px;
    line-height: 12px;

    color: #6C7E8E;
}

.dateBlockMonthYearContainer {
    font-family: 'Roboto Medium';
    font-size: 8px;
    line-height: 12px;

    color: #6C7E8E;
}

.dateBlockDayContainerActive {
    color: #F9F9F9;
}

.dateBlockMonthYearContainerActive {
    color: #F9F9F9;
}

.disabled {
    pointer-events: none;
    opacity: 0.75;
}

.navigationArrow {
    height: 8px;
}

.nextArrow {
    transform: rotate(180deg);
}

.arrowContainer {
    border-radius: 24px;
    padding: 5px 12px 5px 12px;
}

.disabledArrowContainer {
    border-radius: 24px;
    padding: 5px 12px 5px 12px;
}

.arrowContainer:hover {
    background-color: #F6F8FA;
    cursor: pointer;
}