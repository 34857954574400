.DocumentsListViewScrollbar {
  width: 100% !important;
  height: calc(100vh - 265px) !important;
  min-height: 100px;
}

.DocumentsListViewScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  margin-top: 54px;
}

.docListOptionIcon {
  height: 24px;
}

.DocFileTable__Thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.DocFileTableHeadInside {
  height: 52px;
  background-color: #FDFDFD;
}

table .DocFileTableHeadInside > th {
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #375393;
  border-bottom: 1px solid #e1e1e1;
}

.DocFileTable-CheckMark {
  top: -5px !important;
}

.DocListView__TableNameCol {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.docCheckBoxCol {
  width: 0px;
  padding-left: 25px;
  padding-right: 25px;
}

.DocListView__TableTypeCol, .DocListView__TableSizeCol, .DocListView__TableDateCol {
  padding-left: 10px;
  padding-right: 10px;
}

.docFileTableBody > tr {
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  color: #375393;
  background-color: #fdfdfd;
}

.docFileTableBody > tr:hover {
  background-color: #e7edf8;
  cursor: pointer;
}

.docFileTableBody > tr:not(:last-child) {
  border-bottom: 4px solid white;
}

.docSelectedFile {
  background-color: #e7edf8 !important;
  height: 52px;
}

.DocFileTableBody__tr {
  height: 52px;
}

.DocFileTableBody__td {
  padding-left: 25px;
  padding-right: 25px;
}

.DocFileTableBody__CheckboxInput {
  margin-top: 5px;
}

.DocListView__TableDateCol__Content {
  width: 320px;
  /* height: 24px; */
}

.DocListView__TableDateCol__Content__Text {
  display: inline-block;
  width: 160px;
}


