.AddMemberModal__InputParent {
  margin-left: 20px;
  margin-right: 20px;
  color: #19305a;
  text-align: left;
  margin-top: 1rem;
}

.AddMemberModalInput.AddMemberModalInput--disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #19305add;
}

.AddMemberModalInput.ant-input::placeholder,
.AddMemberModalInput--email .ant-input-group .ant-input::placeholder {
  color: #757575;
}

.AddMemberModalInput,
.AddMemberModalInput--email {
  width: 100%;
  margin-top: 12px;
  height: 40px;
  padding-left: 11px;
  padding-right: 34px;
  border: 2px solid #d7dae1;
  background-color: #fdfdfd;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto";
  font-size: 12px;
  color: #19305a;
}

.AddMemberModalInput--email {
  padding-left: 0px;
  padding-right: 0px;
  border: none;
  background-color: #fdfdfd;
}

.AddMemberModalInput--email .ant-input-group .ant-input {
  border-radius: 3px;
  border: 2px solid #d7dae1;
  height: 40px;
  font-family: "Roboto";
  font-size: 12px;
  color: #19305a;
}

.AddMemberModalInput--email .ant-input-group .ant-input-group-addon {
  font-family: "Roboto";
  font-size: 12px;
  color: #19305a;
  border-left: 0px !important;
}

.AddMemberModalInput--email .ant-input-group .ant-input-group-addon:last-child {
  border-radius: 3px;
  border: 2px solid #d7dae1;
}

.AddMemberModalInput--email .ant-input-group > .ant-input:first-child,
.AddMemberModalInput--email
  .ant-input-group
  .ant-input-group-addon:first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.AddMemberModalInput--email .ant-input-group > .ant-input:last-child,
.AddMemberModalInput--email .ant-input-group .ant-input-group-addon:last-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.AddMemberModalInput.ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: #d7dae1;
  border-inline-end-width: 2px;
  z-index: 0;
}

.AddMemberModalInput:focus,
.AddMemberModalInput .ant-input-group .ant-input:focus {
  border: 2px solid #3ec6ff !important;
  border-color: #3ec6ff !important;
  outline: none;
  box-shadow: none;
}

.AddMemberModalInput.ant-input-affix-wrapper:focus,
.AddMemberModalInput.ant-input-affix-wrapper-focused {
  border-color: #3ec6ff !important;
  box-shadow: none;
}

.AddMemberModalInput.ant-input:hover {
  border-color: #d7dae1;
  border-inline-end-width: 2px;
}

.AddMemberModalSelect.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 408px;
  margin-top: 12px;
  border-radius: 4px;
  border-color: #d7dae1;
  border: 2px solid #d7dae1;
}

.AddMemberModalSelect .ant-select-selection-placeholder {
  color: #757575;
}

.AddMemberModalSelect.ant-select .ant-select-arrow {
  margin-top: 6px;
}

.AddMemberModalSelect.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #3ec6ff;
  box-shadow: none !important;
}

.AddMemberModalSelect
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #d7dae1;
}

.ant-select-focused.AddMemberModalSelect.ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #3ec6ff;
  box-shadow: none;
}

.AddMemberModal__RoleInfo {
  margin-left: 7px;
  height: 20px;
  padding: 3.3px;
  margin-top: -2px;
}
