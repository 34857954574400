.env-report-view-date-slider {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.env-report-view-date-slide {
  width: 110px;
  height: 64px;
}


.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled::after,
.swiper-button-next.swiper-button-disabled::after {
  display: none;
}

.env-report-view-date-slider .swiper-button-prev,
.env-report-view-date-slider .swiper-button-next {
  display: none;
}

.env-report-view-report-section-dates .date-swiper-button-prev,
.env-report-view-report-section-dates .date-swiper-button-next {
  height: 64px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(179, 191, 219, 0.4);
  border-radius: 0px 8px 8px 0px;
  z-index: 1;
  cursor: pointer;
  flex-shrink: 0;
}

.swiper-slide.env-report-view-date-slide{
  width: 110px;
  flex-shrink: 0;
  flex-grow: 0;
}

.env-report-view-report-section-dates .date-swiper-button-prev {
  border-radius: 8px 0px 0px 8px;
  left: 0px;
}

.env-report-view-report-section-dates .date-swiper-button-next:hover,
.env-report-view-report-section-dates .date-swiper-button-prev:hover {
  background: #98cde3;
} 

.env-report-view-report-section-dates .date-swiper-button-disabled,
.env-report-view-report-section-dates .date-swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.env-report-view-date-card {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  background: #FDFDFD;
  border-radius: 8px;
  padding: 7px 10px;
  gap: 8px;

  font-family: 'Roboto Medium';
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #758AB9;

  cursor: pointer;
}

.env-report-view-date-card.selected-date-card {
  background-color: #26A1D2;
  color: white;
}

.env-report-view-date-card:hover {
  background-color: #B3E0F2;
  color: #19305A;
}

.env-report-view-date-card > .test-spec-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 8px;
 }

 .env-report-view-date-card > .test-spec-section > .test-section {
  display: flex;
  gap: 4px;
  font-size: 12px;
  align-items: center;
 }

 .env-report-view-date-card > .test-spec-section > .test-section > .num-tests-text {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  color: #19305A;
 }

 .env-report-view-date-card.selected-date-card > .test-spec-section > .test-section > .num-tests-text {
  color: white;
 }

 .env-report-view-date-card:hover > .test-spec-section > .test-section > .num-tests-text {
  color: #19305A;
 }

 .env-report-view-date-card > .test-spec-section > .vertical-line {
  border: 0.5px solid #EFF2F3;
  height: 16px;
}