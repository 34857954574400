.templateBuilderContent {
  border: 1px solid #eaf0f5;
  min-width: 150px;
  border-radius: 16px;
  width: 100%;
  height: max-content;
  padding: 0 1px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* height: 471px; */
}

.templateBuilderContentHeader {
  padding: 12px;
  border-bottom: 0.5px solid #eaf0f5;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #385387;
}

.templateBuilderContentItems {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.templateBuilderContentItem {
  position: relative;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  /* color: rgba(0, 0, 0, 0.6); */
  color: #506375;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.TemplateBuilder__ContentItem__Background {
  background-color: #def2fb;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.templateBuilderContentItem > span {
  z-index: 2;
}

.templateBuilderContentItem > img {
  width: 15px;
  height: 15px;
  z-index: 2;
}

.templateBuilderContentItem:hover {
  background-color: #e1e5e8;
  /* color: #000000; */
}

.templateBuilderContentItemActive {
  color: #506375;
  font-size: 14px;
}

.templateBuilderContentItemActive:hover {
  color: #506375;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.templateBuilderContentAddItem {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #26abe1;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 12px;
  border-radius: 4px;
  cursor: pointer;
  width: 125px;
}

.templateBuilderContentAddItem:hover {
  background: #f2f6fa;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  width: 125px;
}
.templateBuilderContentAddItem:active {
  background: #506375;
  color: #ffffff;
}

.templateBuilderContentAddItem > img {
  height: 14px;
}

/* Scrollbar styling (needed to make container grow with content until max-height is reached) */
.templateBuilderContentScrollbar {
  padding: 30px 12px;
  flex-grow: 1;
}

.templateBuilderContent .ScrollbarsCustom {
  max-height: max(421px, calc(100vh - 262.5px));
}

.templateBuilderContent .ScrollbarsCustom-Wrapper,
.templateBuilderContent .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.templateBuilderContent
  .ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Content {
  padding-left: 0px !important;
  padding-right: 12px !important;
}

.templateBuilderContentScrollbar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #b3bfdb !important;
}
