.smallFont {
  font-size: 14px;
  line-height: 20px;
}

.mediumFont {
  font-size: 16px;
  line-height: 24px;
}

.normalWeight {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
}

.mediumWeight {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
}

.boldWeight {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
}

.threeLines {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  hyphens: auto;
}

.darkBlue {
  color: #375393;
}

.midnight {
  color: #19305a;
}

.displayProductDetails .MuiChip-root {
  color: #758ab9;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  cursor: unset;
  padding: 4px 0px;
  margin: 8px 8px 0px 0px;
  border: 1px solid #758ab9;
}

.displayProductDetails .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #d7dae1 !important;
  border-radius: 3px !important;
  width: 6px !important;
}

.displayProductDetails .MuiButton-root {
  border-radius: 3px;
  text-transform: none;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

#dropDownSort .drop-down-menu-option.drop-down-menu-option-selected {
  color: #26abe1;
}

#dropDownSort .drop-down-menu-option.drop-down-menu-option-selected img,
#dropDownSort .drop-down-menu-option.drop-down-menu-option-selected:hover img {
  content: url("../../../assets/images/index/selectedBlue.png");
}

.productDocuments__filesContainer--filesBlock {
  position: relative;
}

.productDocuments__filesContainer--filesBlock td,
.productDocuments__filesContainer--filesBlock th {
  text-align: left;
}

.productDocuments__filesContainer--filesBlock thead th {
  background-color: #eff2f3 !important;
}

table tr.docFileTableHeadInside > th {
  color: #375393;
}

.productDocuments {
  background-color: #f9f9f9;
  padding: 23px 19px;
  margin-top: -10px;
}

.productDocuments__HeaderContainer {
  display: flex;
  position: relative;
  padding: 16px 46px 27px;
  background-color: #ffffff;
}

.productDocuments__HeaderContainer--backArrowIcon {
  position: absolute;
  left: 15px;
  top: 22.22px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.productDocuments__HeaderContainer--NameSection {
  width: 35%;
}

.productDocuments__HeaderContainer--NameContainer {
  background-color: #26abe1;
  border-radius: 3px;
  color: #fdfdfd;
  padding: 8px 16px;
  max-height: 84px;
}

.productDocuments__HeaderContainer--productDetailsSection {
  width: 30%;
  padding-left: 36px;
  padding-right: 36px;
}

.productDocuments__HeaderContainer--productDetailsSection-IndexCategory {
  margin-top: 24px;
}

.productDocuments__HeaderContainer--tagsSection {
  display: flex;
  width: 40%;
}

.productDocuments__HeaderContainer--tagsSection-tags {
  height: 100%;
}

.productDocuments__HeaderContainer--tagsSection-scrollbar {
  text-align: left;
  border-radius: 3px;
  height: 100%;
  margin-left: 25px;
}

.ProductDocuments__Tag {
  color: #758ab9;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-color: #758ab9;
  padding: 4px 8px;
  margin-top: 8px;
}

.productDocuments__HeaderContainer--tagsSection-scrollbar
  .ScrollbarsCustom-Content {
  /* display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  margin: 8px 8px 0 0; */
}

.productDocuments__filesContainer {
  position: relative;
  margin-top: 10px;
  background-color: #ffffff;
  min-height: 300px;
}

.productDocuments__filesContainer--filesBlock-listView {
  background-color: #eff2f3;
}

.productDocuments__filesBlock--viewType {
  position: absolute;
  top: 0px;
  right: 40px;
  height: 52px;
  z-index: 1;
}

.productDocuments__filesBlock--viewType-viewBtns {
  margin: auto 0px;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

#productDocuments__filesBlock--viewBtns-cardViewIcon {
  padding: 4.5px 4px;
}

.productDocuments__filesBlock--addFilesButton {
  position: absolute;
  right: 20px;
  bottom: 30px;
  width: 93px;
  height: 32px;
}

.productDocuments__filesBlock--addFilesButton-addIcon {
  margin-right: 5px;
  height: 13.33px;
  width: 13.33px;
}

/* Linked Fields Scrollbar */
.ProductDocuments__LinkedFieldsScrollbar.ScrollbarsCustom {
  max-height: 100px !important;
  height: auto !important;
}

/* Allows for dynamic height up to max height*/
.ProductDocuments__LinkedFieldsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Wrapper,
.ProductDocuments__LinkedFieldsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.ProductDocuments__LinkedFieldsScrollbar .ScrollbarsCustom-Content {
  padding: 0px !important;
}
