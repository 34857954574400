.previewDatePickerMainContainer {
    margin: 0px 20px 8px 20px !important;
}

.previewDatePickerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 8px;
}

.previewDateBlockContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    background-color: #EFF2F3;
    border-radius: 3.5px;
    padding: 10px 6px;

    width: 42px;
    height: 46px;

    cursor: pointer;
}

.previewDateBlockContainerActive {
    background-color: #26ABE1;

    cursor: pointer;
}

.previewDateBlockDayContainer {
    font-family: 'Roboto Medium';
    font-size: 16px;
    line-height: 12px;

    color: #19305A;
}

.previewDateBlockMonthYearContainer {
    font-family: 'Roboto Medium';
    font-size: 8px;
    line-height: 12px;

    color: #19305A;
}

.previewDateBlockDayContainerActive {
    color: #F9F9F9;
}

.previewDateBlockMonthYearContainerActive {
    color: #F9F9F9;
}

.previewDatePickerHorizontalLine {
    height: 1px;
    border: 0.5px solid #EAF0F5;
    margin: 20px -32px 8px -32px;
}