/* ---v2--- */
.coaContainer {
  position: relative;
  height: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 19px;
  padding-bottom: 18px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  min-width: 750px;
}

.coaReportContainer {
  display: flex;
  flex-direction: column;
  gap: 11px;
  height: 100%;
  margin-top: 17px;
  width: 100%;
  margin-right: 17px;
}

.CoaReportAndTemplate {
  display: flex;
  flex-direction: row;
  margin-top: 29px;
}

.CoaReportAndTemplate__Template {
  height: calc(100vh - 407px);
  margin-top: -2px;
}

.coaContainerHeader {
  height: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 19px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.coaPlaceHolderContainer,
.coaPlaceHolderContainer--hasTemplate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #eaf0f5;
  border-radius: 16px;
}

.coaPlaceHolderContainer {
  height: calc(100vh - 307px);
}

.coaPlaceHolderContainer--hasTemplate {
  height: calc(100vh - 307px);
}

.coaPlaceholderText {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6c7e8e;
}

.coaListContainer {
  height: calc(100vh - 307px);
  border-radius: 16px;
  border: 1px solid #eaf0f5;
  padding: 27px 29px;
}

#coaListContainerScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #b3bfdb !important;
}

.coaListItemContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #eaf0f5;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6c7e8e;
  background: #f6f8fa;
}

.coaListItemContainer:hover {
  background: #bee6f6;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
}

.coaListItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.coaListItemHeader {
  padding: 8px 12px 20px 12px;
  font-size: 16px;
  line-height: 24px;
  color: #385387;
  cursor: pointer;
}

.coaListItemHeader__SearchHighlight {
  color: #26abe1;
  background: none;
  padding: 0;
}

.coaListItemContainer:hover .coaListItemHeader {
  color: #1f2d3b;
}

.coaListItemBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  padding: 12px 14px;
  border-radius: 0 0 8px 8px;
}

.coaListItemBodyLeft {
  display: flex;
  align-items: center;
}

.coaListItemBodyRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 80px;
  margin-right: 43px;
  margin-left: 55px;
}

.coaListItemTemplate {
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 4px;
  width: fit-content;
  padding: 2px 4px;
  height: 20px;
  display: flex;
  justify-content: center;
  min-width: 45px;
}

.coaListItemNumber,
.coaListItemDate {
  display: flex;
  align-items: center;
  margin-right: 60px;
}

.coaListItemProductImg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.coaListItemCalendarImg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

div.coaListItemContainer div.coaListItemDeleteImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: url("../../../../../assets/images/product/template/DeleteDisabled.png")
    no-repeat;
  background-size: 18px 18px;
}

div.coaListItemContainer:hover div.coaListItemDeleteImg {
  background: url("../../../../../assets/images/product/template/Delete.png");
  background-size: 18px 18px;
}

.coaListItemDownloadImg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.deleteModal {
  background: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
}

.deleteModalHeader {
  font-family: "Roboto";
  font-size: 22px;
  color: #19305a;
}

.deleteModalBody {
  font-family: "Roboto";
  font-size: 14px;
  color: #375393;
}

.deleteModalSpanContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: flex-start; */
  width: 280px;
  gap: 10px;
}

.deleteModalButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 265px;
}

.deleteModalButton {
  cursor: pointer;
  width: 82px;
  height: 32px;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
}

.deleteModalCancel {
  color: #19305a;
  background-color: #eff2f3;
}

.deleteModalDelete {
  color: #fdfdfd;
  background-color: #26abe1;
}

.CoaReportWrap {
  height: calc(100vh - 253px);
  border-radius: 16px;
  border: 1px solid #eaf0f5;
  padding: 27px 29px;
}

.CoaSubHeader {
  font-family: "Roboto Medium";
  color: #afbdca;
  font-size: 14px;
}

.CoaSubHeader__Second {
  font-family: "Roboto Medium";
  color: #506375;
  font-size: 14px;
  margin-bottom: 12px !important;
}

.dataFieldsModalContainer {
  right: 0;
  margin-right: 19px;
  position: absolute;
  z-index: 3;
  width: 450px;
  height: calc(100vh - 310px);
  overflow-y: hidden;
  box-shadow: 0px 13px 37px 0px #00000036;
  border-radius: 8px 0 8px 8px;
  background: white;
}

.disabledCOAList {
  opacity: 0.5;
  pointer-events: none;
}

@media (min-height: 1200px) {
  .coaListContainer {
    height: calc(100vh - 306px);
  }
  .CoaReportWrap {
    height: calc(100vh - 252px);
  }
}

.coaContainer__EmptyCoaPage {
  display: flex;
  flex-direction: row;
}
