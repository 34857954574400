.templateCardContainer {
  min-width: 141px;
  height: 90px;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border: 1px solid #eaf0f5;
  padding: 8px;
}

.TemplateCard__Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
}

.templateCardContainer:hover {
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}

.templateCardPlayIcon {
  position: absolute;
  bottom: 7px;
  right: 7px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  text-overflow: ellipsis;
}

.templateCardPlayIcon.disabled {
  cursor: initial;
}

/* Dropdown menu styling */
.ant-dropdown.TemplateCard__Menu .ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown.TemplateCard__Menu
  .ant-dropdown-menu
  .ant-dropdown-menu-item:first-of-type {
  padding: 4px 4px 0 4px;
  border-radius: 8px 8px 0px 0px;
}

.ant-dropdown.TemplateCard__Menu
  .ant-dropdown-menu
  .ant-dropdown-menu-item:last-of-type {
  padding: 0px 4px 4px 4px;
  border-radius: 0 0 8px 8px;
}

.ant-dropdown.TemplateCard__Menu
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background: unset;
}

.ant-dropdown.TemplateCard__Menu
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active
  .TemplateCard__MenuItem {
  background: rgba(0, 0, 0, 0.04);
}

.TemplateCard__MenuItem {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 12px;
  border-radius: 4px;
}

.templateCardOptionsIcon {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 20px;
  width: 16px;
  padding: 4px;
  cursor: pointer;
}

.templateCardOptionsIcon path {
  fill: #ffffff;
}
.templateCardOptionsIcon:hover path {
  fill: #19305a;
}

.templateCardEditIcon path {
  fill: #19305a;
}

.templateCardDeleteIcon path {
  fill: #19305a;
}
.templateCardDeleteIcon path {
  fill: #19305a;
}
