.SubmissionFrom__Comment {
  margin-bottom: 32px;
  border: 2px solid rgba(196, 210, 223, 1);
}

.SubmissionFrom__Comment > textarea {
  background: rgba(248, 250, 252, 1) !important;
  border-radius: 8px;
  font-family: "Roboto";
  font-size: 14px;
  color: black;
  padding: 4px 15px 4px 7px;
  resize: none;
}

.SubmissionFrom__Comment > textarea::placeholder {
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: rgba(108, 126, 142, 1);
  /* color: #b3bfdb; */
}

.SubmissionFrom__Comment.ant-input-affix-wrapper:focus-within {
  border-color: rgba(196, 210, 223, 1);
  box-shadow: none;
}

.SubmissionFrom__Comment.ant-input-affix-wrapper:hover {
  border-color: #97a3ae;
}

.SubmissionFrom__Comment .ant-input-data-count {
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: rgba(38, 171, 225, 1);
}

.SubmissionFrom__Comment_Add {
  margin-bottom: 75px;
  border: 1px solid rgba(151, 151, 151, 1);
}

.SubmissionFrom__Comment_Add > textarea {
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 4px;
  font-family: "Roboto";
  font-size: 16px;
  color: black;
  padding: 4px 15px 4px 7px;
  line-height: 24px;
  resize: none;
}

.SubmissionFrom__Comment_Add > textarea::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(95, 99, 104, 1);
  /* color: #b3bfdb; */
}

.SubmissionFrom__Comment_Add.ant-input-affix-wrapper:focus-within {
  border-color: rgba(196, 210, 223, 1);
  box-shadow: none;
}

.SubmissionFrom__Comment_Add.ant-input-affix-wrapper:hover {
  border-color: #97a3ae;
}

.SubmissionFrom__Comment_Add>.ant-input-suffix>.ant-input-data-count {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black!important;
}

.SubmissionFrom__CommentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
}

.SubmissionFrom__CommentButtonTitle{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: self-end;
}

.SubmissionFrom__Comment__Buttons {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
}

.SubmissionForm_Comment--title {
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 83, 147, 1);
  letter-spacing: 0.5px;
}

.SubmissionFrom__Comment__SubmitBtn > path {
  width: 13px;
  height: 13px;
}

.SubmissionFrom__Comment__SubmitBtn.ant-btn-primary:disabled {
  background-color: #26abe1;
  border-color: #26abe1;
  color: white;
}

.SubmissionFrom__Comment__CancelBtn.ant-btn-primary.ant-btn-background-ghost:disabled {
  color: #9dd9f2;
  border-color: #9dd9f2;
  background-color: transparent;
}

.SubmissionFrom__Comment__Button {
  border: unset;
  width: fit-content;
  height: fit-content;
  padding: 0px;
}