.DocCardView {
  width: 100% !important;
  height: calc(100vh - 317px) !important;
  min-height: 100px;
}

.DocCardWrapper {
  padding: 45px 68px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
}