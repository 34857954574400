.COABuilderImageWithText__Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 21px;
  width: 100%;
}

.COABuilderImageWithText__TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: auto;
  max-width: 100%;
  flex-grow: 0;
  overflow-wrap: break-word;
}

.COABuilderImageWithText__Title {
  font-family: "Roboto Medium";
  font-size: 14px;
  color: #506375;
}

.COABuilderImageWithText__Subtitle {
  font-family: "Roboto Medium";
  font-size: 11px;
  color: #506375;
  white-space: pre-line;
}
