.highlighted-sample-card--error {
  background-color: #f9010104;
  border: 1.5px solid #f90101;
}

.highlighted-editing-sample-card {
  background-color: rgba(234, 240, 245, 1);
  border: 1.5px solid rgba(38, 171, 225, 1);
}

.added-sample-delete-icon {
  /* margin-right: 10px; */
  cursor: pointer;
}
.added-sample-delete-icon path:first-child {
  fill: #26abe1;
}

.added-sample-delete-icon path:last-child {
  fill: transparent;
}

.added-sample-delete-icon:hover path {
  fill: #9dd9f2;
  stroke: #9dd9f2;
}

.disable-added-sample-edit-icon:hover {
  cursor: default;
}

.hover-added-sample-edit-icon:hover path {
  fill: #9dd9f2;
}
.added-sample-card-composite-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 22px); 
  margin-left: -12px; 
  margin-right: -10px;
  margin-top: -11px;
  margin-bottom: 8px;
  background-color: #F2F6FA; 
  padding: 8px 12px; 
  box-sizing: border-box; 
  /* border-radius: 8px; */
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px; 
  color: #182534;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}