.analyticsTestPlaceholderText {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    font-family: 'Roboto Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6C7E8E;

    padding: 0px 30px;
}

.analyticsTestListContainerScrollable {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.analyticsTestListMainContainer {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
}

.analyticsTestListHeaderText {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #AFBDCA;
}

.analyticsTestListContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#analyticsScrollbar{
    height: 100%;
}