.MyForm .ant-form-item .ant-form-item-label>label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  min-height: 20px;
  /* color: #375393; */
  font-size: 12px;
  font-family: 'Roboto';
}

.MyForm .ant-form-item .ant-form-item-label>label .MyForm__SectionLabel {
  font-size: 16px;
  margin-bottom: 30px;
  display: flex;
  gap: 12px;
}

.MyForm .ant-form-item .ant-form-item-label>label .MyForm__SectionLabel~.anticon.anticon-question-circle.ant-form-item-tooltip {
  font-size: 16px;
  margin-left: 6px;
  margin-bottom: 30px;
}

.MyForm.ant-form-vertical .ant-form-item-label,
.MyForm.ant-col-24.ant-form-item-label,
.MyForm.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}

/* Input styling */
.MyForm .ant-input:hover {
  border-color: #d7dae1;
}

.MyForm .ant-input:focus,
.ant-input-focused {
  box-shadow: none;
  border-color: #26a1d2;
}

.MyForm .ant-input {
  border-radius: 4px;
  padding: 13px, 8px, 13px, 8px;
  height: 40px;
  border: 1px solid #C4D2DF;
  margin-top: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.MyForm .ant-input::placeholder {
  color: #6C7E8E;
  font-size: 14px;
  font-family: "Roboto";
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 400;
}

.MyForm .ant-input.ant-input-disabled,
.MyForm .ant-input.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.5);
  border: unset;
  background-color: #F2F6FA;
  color: #6C7E8E;
  font-family: 'Roboto';
  font-size: 14px;
}

.MyForm .ant-input-status-error.ant-input-disabled,
.MyForm .ant-input-status-error.ant-input[disabled] {
  border-color: #ff4d4f;
}

.MyForm .ant-form-item {
  padding-bottom: 15px;
}

/* Compact Input styling (input used for user email w/ domain input) */
.MyForm .ant-space-compact {
  width: 100%;
}

.MyForm .ant-input-compact-item:not(.ant-input-compact-last-item) {
  margin-inline-end: -2px;
}

.MyForm .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input.ant-input-compact-item {
  z-index: 2;
}

.MyForm .ant-input-affix-wrapper.ant-input-compact-item {
  border-radius: 3px;
  padding: 4px 11px;
  height: 40px;
  border: 1px solid #d7dae1;
  margin-top: 4px;
}

/* Input with tags styling (using antd select)*/
.MyForm .ant-select .ant-select-selector {
  background-color: #ffff;
  color: #19305a;
  text-transform: none;
  font-family: "Roboto";
  border-radius: 3px;
  min-height: 40px;
  border: 1px solid #d7dae1;
  margin-top: 4px;
}

.MyForm .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #d7dae1;
}

.MyForm .ant-select.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #26abe1;
  box-shadow: none;
}

.MyForm .ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector,
.MyForm .ant-select-status-error.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #ff4d4f;
}

.MyForm .ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #ff4d4f;
}

.MyForm .ant-input-affix-wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: #d7dae1;
  border-radius: 4px;
  height: 40px;
}

.MyForm .ant-input-affix-wrapper>input.ant-input {
  height: 20px;
}

.MyForm .ant-input-affix-wrapper:focus,
.MyForm .ant-input-affix-wrapper-focused {
  border-color: #26abe1;
  box-shadow: none;
}

.MyForm .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.MyForm .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border-color: #ff4d4f;
}

.MyForm .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #26abe1;
  z-index: 1;
}

.MyForm .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
  border-color: #d7dae1;
  z-index: 1;
}

/* Radio Group styling */
.MyForm .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
  color: #375393;
}

.MyForm .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #26abe1;
  border-color: #26abe1;
}

.MyForm .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-disabled) {
  color: #26abe1;
}

.MyForm .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #26abe1;
}

.MyForm .ant-input-affix-wrapper.ant-input-disabled {
  /* color: rgba(0, 0, 0, 0.5); */
  border: unset;
  background-color: #F2F6FA;
  color: #6C7E8E;
  font-family: 'Roboto';
  font-size: 14px;
}