.IndexPageContainer {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.IndexPageBackground {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  padding: 8px 19px 67px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 12px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 4px -18px inset;
}

.IndexPageMainContainer {
  background-color: #fdfdfd;
  filter: drop-shadow(0px 3px 10px rgba(9, 30, 66, 0.05))
    drop-shadow(0px 0px 5px rgba(9, 30, 66, 0.08));
}

.IndexPageMainContainer__SearchContainer {
  display: flex;
  padding: 20px 52px 20px 52px;
  justify-content: space-between;
  max-width: 580px;
}

.IndexGrid .ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.IndexPageMainContainer__ProductCardsContainer {
  height: calc(100vh - 270px);
  width: 100%;
}

.ProductCardsContainer--hasProducts {
  border-top: 2px solid #f9f9f9;
}

.ProductCardsContainer__PaddedContainer {
  height: 100%;
  position: relative;
  padding: 13px 0px 50px 42px;
}

.AddProductCardBtn {
  position: absolute;
  top: 26px;
  left: 12px;
}

.AddProductCardBtn img {
  height: 32px;
  cursor: pointer;
}

.ProductCardsContainer__PaddedContainer__ScrollbarContainer {
  overflow-x: hidden;
}

/* No Products styling*/
.ProductCardsContainer--noProducts {
  height: calc(100vh - 189px);
}

.ProductCardsContainer__NoProductsRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 58px;
}

.ProductCardsContainer__NoProductsRow__NewProductCardDiv {
  max-width: 310px;
  flex: 0 0 100%;
}

/* Tab style */

.customTab {
  background-color: #fdfdfd !important;
}

.nav-tabs .nav-link:hover {
  background-color: white;
  color: #375393;
}

.productTabsContainer {
  width: 100%;
  background-color: rgb(253, 253, 253);
  padding: 20px 78px 0px;
  margin-top: -10px;
  /* filter: drop-shadow(0px 3px 10px rgba(9, 30, 66, 0.05)) drop-shadow(0px 0px 5px rgba(9, 30, 66, 0.08)); */
  /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
}

.productTabsContainer .customTab .nav-item.nav-link.active {
  border-bottom: none !important;
  border-radius: 3px;
  background-color: #fdfdfd !important;
  color: #19305a !important;
  font-family: "Roboto Medium" !important;
}

.productTabsContainer .nav-tabs .nav-link {
  margin-bottom: 20px !important;
  margin-right: 0px !important;
  border: none !important;
  border-bottom: none !important;
  color: #758ab9;
  cursor: pointer;
  height: 36px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.productTabsContainer .nav-tabs .nav-link .indexTab {
  height: 36px;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.productTabsContainer .nav-tabs .nav-link:hover {
  background-color: #fdfdfd;
}

.productTabsContainer .nav-tabs .nav-link:focus {
  isolation: auto;
}

.productTabsContainer .customTab .nav-item.nav-link.active .notEditing {
  background-color: #eff2f3;
  border-radius: 3px;
  position: relative;
  padding-left: 16px;
}

.prodCreateNewIndexText {
  padding-left: 10px;
  padding-right: 10px;
  color: #26abe1;
  border-radius: 3px;
}
.prodCreateNewIndexText:hover {
  background: rgba(38, 171, 225, 0.1);
}

/* Input Fields CSS and animation */

/* Add Index Input Filed */
input:focus-visible {
  outline: none;
}

.custom-field {
  position: relative;
  font-size: 16px;
  --field-padding: 12px;
  margin-bottom: 20px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translate(7px, -6px) scale(1);
  color: #aaa;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}

.custom-field.one input {
  background: none;
  border: 2px solid #b3e0f2;
  transition: border-color 0.3s ease;
  width: 318px;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
  text-align: left;
  color: #b3bfdb;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  /* border-color: #77CCEE; */
  border: 2px solid #d7dae1;
  transition-delay: 0.1s;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input [type="text"] {
  padding-right: 50px;
}

.custom-field.one input.dirty + .placeholder,
.custom-field.one input:not(:placeholder-shown) + .placeholder,
.custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #77ccee;
  background: #fff;
  width: auto;
}

/* Tabs Input field style */
.newIndexInput,
.editInput {
  width: 100%;
  height: 40px;
  padding: 0px 28px 0px 8px;
  color: #19305a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background: #fdfdfd;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
}

.newIndexInput:focus,
.editInput:focus {
  border: 2px solid #26a1d2;
  box-sizing: border-box;
}

.exceedCharsLimit.newIndexInput:focus,
.exceedCharsLimit.editInput:focus {
  border: 2px solid #e63559;
}

.newIndexInput::placeholder {
  color: #758ab9;
  font-family: "Roboto";
  font-style: normal;
}

.custom-field.tag input {
  background: none;
  border: 2px solid #b3e0f2;
  transition: border-color 0.3s ease;
  width: 201px;
}

.custom-field.tag input + .placeholder {
  left: 4px;
  padding: 0 5px;
  text-align: left;
  color: #b3bfdb;
}

.custom-field.tag input.dirty,
.custom-field.tag input:not(:placeholder-shown),
.custom-field.tag input:focus {
  border-color: #77ccee;
  transition-delay: 0.1s;
  padding-right: 50px;
}

.custom-field.tag input.dirty + .placeholder,
.custom-field.tag input:not(:placeholder-shown) + .placeholder,
.custom-field.tag input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #77ccee;
  background: #fff;
  width: auto;
}

/* product-card */

.react-card-front,
.react-card-back {
  z-index: auto !important;
  display: flex;
}

.react-card-front > div:first-of-type,
.react-card-back > div:first-of-type {
  width: 100%;
}

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  color: #fdfdfd;
}

.product-card .product-card-title {
  /* width: 310px; */
  height: 73px;
  margin-left: -1px;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0px;
  background-color: #26abe1;
  padding: 10px 15px;
}

.product-card .product-card-title .productNameHover {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fdfdfd;
}

.product-card .product-card-title .productNameHover:hover {
  text-decoration: none;
  /* color: #375393; */
  cursor: pointer;
}

.product-card .product-card-title .drop-down-menu {
  margin-left: 15px;
  height: 64px;
  width: 110px;
}

.product-card:hover {
  /* box-shadow: ; */
  border: 1px solid rgba(196, 196, 196, 0.6);
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.6);
  border-radius: 4px;
  /* background: rgba(196, 196, 196, 0.6);
  filter: blur(25px);
  visibility: hidden; */
}

.product-card:hover .tagInputFieldHover {
  opacity: 1;
  border: none;
  font-size: 14px;
  height: 25px;
  border-radius: 5px;
}

.product-card:hover .addTagIconHover {
  opacity: 1;
}

/* product tags */

/* .product-card .react-tags-wrapper {
  margin-top: -45px;
} */

p {
  margin-bottom: 0rem !important;
}

.product-card .react-tags-wrapper {
  position: relative;
  margin-top: 14px;
}

.tagInputFieldHide {
  border: none;
  font-size: 14px;
  display: none;
}

.addTagIconHide {
  display: none;
}

.product-card input {
  border: 2px solid #d7dae1;
  border-radius: 3px;
}

.product-card input:focus {
  border: 2px solid #d7dae1;
}

.product-card .tagInputFieldDisplay {
  width: 160px;
  height: 32px;
  background-color: #fdfdfd;
  border-radius: 3px;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
}

.tagInputFieldDisplay::-webkit-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #758ab9;
}

.tagInputFieldDisplay:-moz-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #758ab9;
}

.tagInputFieldDisplay::-moz-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #758ab9;
}

.tagInputFieldDisplay:-ms-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #758ab9;
}

.tagInputFieldHover {
  opacity: 0;
  height: 25px;
  border-radius: 5px;
  background: rgba(39, 170, 226, 0.66);
  border: 2px solid #27aae2;
  color: white;
  padding-left: 5px;
  width: 112px;
  padding-bottom: 2px;
}

.tagInputFieldHover[type="text"] {
  padding-right: 26px;
}

.tagInputFieldHover::placeholder {
  color: white;
  padding-left: 5px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 12px;
}

.tagInputFieldHover:focus {
  outline: none !important;
}

.tagInputFieldCustom {
  display: none;
  height: 25px;
  border-radius: 5px;
  background-color: #efefef;
}

.tagInputFieldCustom:focus {
  outline: none !important;
}

.addTagIcon {
  display: none;
}

.addTagIconHover {
  opacity: 0;
}

.triangleForReminder {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #e63559;
  transform: rotate(45deg);
}

.saveReminderRight .triangleForReminder {
  top: 4px;
  right: -16px;
}

.saveReminderLeft .triangleForReminder {
  top: 30px;
  right: 5px;
}

.addNewCard .saveReminderLeft .triangleForReminder,
.editCard .saveReminderLeft .triangleForReminder {
  top: 40px;
  right: 55px;
}

.saveReminder {
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #f9f9f9;
  position: absolute;
  width: 243px;
  background-color: #e63559;
  padding: 8px 12px;
  border-radius: 3px;
  z-index: 1;
}

.saveReminderRight .saveReminder {
  top: -12px;
  right: -254px;
}

.saveReminderLeft .saveReminder {
  top: 35px;
  right: -10px;
}

.editCard .saveReminderLeft .saveReminder,
.addNewCard .saveReminderLeft .saveReminder {
  top: 45px;
  right: 0px;
}

.product-card .addNewCard .product-card-title,
.product-card .editCard .product-card-title {
  background-color: #19305a;
  height: 40px;
}

.product-card .ScrollbarsCustom-Content {
  padding: 4px !important;
}

.product-card .addNewCard .ScrollbarsCustom-Content,
.product-card .editCard .ScrollbarsCustom-Content {
  padding-left: 8px !important;
}

.product-card .addNewCard label,
.product-card .editCard label {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #375393;
  display: block;
  text-align: left;
  margin-bottom: 2px;
  margin-top: 10px;
}

.product-card .addNewCard input,
.product-card .editCard input {
  height: 28px;
}

.product-card input::placeholder,
.product-card .addNewCard input::placeholder,
.product-card .editCard input::placeholder {
  color: #758ab9;
}

.product-card .addNewCard input,
.product-card .editCard input,
.product-card .addNewCard textarea,
.product-card .editCard textarea {
  width: 100%;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #fdfdfd;
  padding: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  outline: none;
}

.product-card .addNewCard input,
.product-card .editCard input {
  color: #19305a;
}

.product-card .addNewCard textarea,
.product-card .editCard textarea {
  color: #375393;
}

.product-card input:focus,
.product-card .addNewCard input:focus,
.product-card .editCard input:focus,
.product-card .addNewCard textarea:focus,
.product-card .editCard textarea:focus {
  border: 2px solid #26a1d2;
  box-shadow: none;
}

.product-card .addNewCard .charsCount,
.product-card .editCard .charsCount {
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #b3bfdb;
  text-align: right;
  margin-top: -5px;
  margin-bottom: -20px;
}

.product-card .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background-color: #d7dae1 !important;
}

.editCard .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 4px !important;
  background-color: #b3e0f2 !important;
}

.addNewCard .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.addNewCard .trackYVisible,
.editCard .trackYVisible {
  /* border: 2px solid #D7DAE1; */
  box-sizing: border-box;
  border-radius: 3px;
}

.product-card .addNewCard button,
.product-card .editCard button {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.product-card .addNewCard .MuiChip-root,
.product-card .editCard .MuiChip-root {
  color: #2c55a0;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  cursor: unset;
  padding: 4px 8px;
  margin: 8px 8px 0px 0px;
  background-color: #e7edf8;
  border: 1px solid #e7edf8;
}

.product-card .addNewCard .MuiChip-root:hover,
.product-card .editCard .MuiChip-root:hover,
.product-card .addNewCard .MuiChip-root:focus,
.product-card .editCard .MuiChip-root:focus {
  color: #19305c;
  background-color: #e7edf8;
  border: 1px solid #19315c;
  box-sizing: border-box;
  border-radius: 3px;
}

.product-card .addNewCard .MuiChip-labelSmall,
.product-card .editCard .MuiChip-labelSmall {
  padding: 0px;
}

.product-card .addNewCard .MuiChip-deleteIcon,
.product-card .editCard .MuiChip-deleteIcon {
  text-align: center;
  margin: 0px;
  margin-right: -4px;
}

.product-card .addNewCard .MuiChip-deleteIcon img,
.product-card .editCard .MuiChip-deleteIcon img {
  display: inline-block;
  margin-top: 5.33px;
}

.tagDesign {
  padding-left: 62px;
  text-align: left;
}

.tagRemoveIcon {
  content: url("../../assets/images/index/crossCircleIconLightBlue.png");
}

.tagRemoveIcon:hover {
  content: url("../../assets/images/index/crossCircleIconMidnight.png");
}

.product-card .editSaveBtn {
  width: 122px;
  margin-left: 10px;
}
/* 
.ScrollbarsCustom-Content {
  padding-left: 64px !important;
  padding-right: 25px !important;
} */

.rotate-image {
  /* background: red; */
  transform: rotate(-180deg);
  transition: 500ms ease-in-out;
}

.rotate-image-back {
  transform: rotate(0deg);
  transition: 500ms ease-in-out;
}

/* .dropDownSort{
    transition: 4s ease-out;
}

.dropDownSortBack{
    transition: 4s ease-in;
} */

.rotate-image-2 {
  /* background: red; */
  transform: rotate(-180deg);
  transition: 500ms ease-in-out;
}

.rotate-image-back-2 {
  transform: rotate(0deg);
  transition: 500ms ease-in-out;
}

/* Dropdown styling */
.drop-down-menu {
  background: #d7dae1;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.drop-down-menu-option {
  flex: 1;
  background-color: #fdfdfd;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #19305a;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.drop-down-menu-option label {
  margin: 0px;
  cursor: pointer;
}

.drop-down-menu .drop-down-menu-option:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.drop-down-menu .drop-down-menu-option:last-of-type {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.drop-down-menu-option:hover {
  background-color: #eff2f3;
}
