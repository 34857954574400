.InviteModal__EmailInputContainer {
  width: 100%;
  font-size: 14px;
  padding: 5px 15px;
  gap: 8px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #C4D2DF;
  align-items: center
}
.InviteModal__EmailInputContainer:hover,
.InviteModal__EmailInputContainer:focus-within {
  box-shadow: 0px 4px 8px 0px #00000014;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
}

.InviteModal__Input {
  flex: 1;
  height: 100%;
  border: none;
  border-radius: 8px;
  min-width: 10px
}

.InviteModal__Input::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #C4D2DF;
}

.InviteModal__ErrorMessage {
  top: -20px;
  position: absolute;
  color: #e63559;
}

.InviteModal__MessageInput {
  width: 100%;
  height: 96px;
  font-size: 14px;
  padding: 13px 19px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid #C4D2DF;
  color: #182534;
  overflow:auto;
  resize:none;
}
 
.InviteModal__MessageInput:hover {
  box-shadow: none
}
 .InviteModal__MessageInput:focus {
  outline: none;
  border: 1px solid #C4D2DF;
  box-shadow: none;
} 

.InviteModal__MessageInput:hover {
  box-shadow: 0px 4px 8px 0px #00000014;
}

.InviteModal__MessageInput:focus {
  box-shadow: 0px 4px 8px 0px #00000014;
}

.InviteModal__MessageInput::placeholder {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  color: #C4D2DF;
  font-weight: 500;
}

.InviteModal__SendButtonContainer {
  display: flex;
  justify-content: flex-end;
}

/* .InviteModal__SendButton {
  width: 167px;
  height: 40px;

  background: #26abe1;
  color: #ffffff;
  border: none;
  border-radius: 8px;

  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 24px;
} */

.InviteModal__SubmitBtn{
  width: 167px !important;
  height: 40px !important;
  border: none !important;
  margin-bottom: 25px;
}

/* .InviteModal__SendButton.disabledButton {
  background: #c4d2df;
  pointer-events: none;
} */
