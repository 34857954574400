.SignupForm__PasswordDesc {
  color: #6c7e8e;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.SignupForm__ExistingUser {
  color: #26a1d2;
  margin-bottom: 30px;
  display: inline-block;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.SignupForm__Title {
  margin-bottom: 10px;
}

.SignupForm_PasswordItem {
  margin-bottom: -5px !important;
}

.SignUp__MainForm > .ant-form-item {
  padding-bottom: 0px;
  margin-bottom: 20px;
}

.Clear__Button {
  fill: #182534;
  width: 9.5px;
  height: 9.5px;
  top: 7.25px;
  left: 7.25px;
}

.SignUp__MainForm .ant-input-clear-icon {
  margin-bottom: 5px !important;
  color: #182534;
}

.SignUp__MainForm .ant-input-disabled {
  background-color: #f2f6fa;
  color: #6c7e8e;
  border: unset;
}

.SignUp__LabelText {
  font-family: "Roboto medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #182534;
}

/* Suffix styling */
.SignupForm_PasswordItem .ant-input-suffix {
  padding-left: 4px;
}

.SignupForm_PasswordItem .ant-input-suffix > img,
.SignupForm_PasswordItem .ant-input-suffix > span {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.SignupForm_PasswordItem .ant-input-suffix > .ant-input-clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: #182534 !important;
  cursor: pointer;
  transition: all 0.3s;
}

.SignupForm_PasswordConfirm .ant-input-suffix {
  padding-left: 4px;
}

.SignupForm_PasswordConfirm .ant-input-suffix > img,
.SignupForm_PasswordConfirm .ant-input-suffix > span {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.SignupForm_PasswordConfirm .ant-input-suffix > .ant-input-clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: #182534 !important;
  cursor: pointer;
  transition: all 0.3s;
}
