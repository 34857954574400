.COABuilder__Statement {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 22px 35px 0 35px;
}

.COABuilder__StatementInput {
  max-width: 100%;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
  color: #506375;
  font-family: "Roboto Medium";
  font-size: 11px;
}
