.info-input-section-container .ScrollbarsCustom-Content {
  padding-right: 4px !important;
}

.info-input-section-container .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #C4D2DF !important;
}

.info-input-section-container .ScrollbarsCustom{
  height: calc(100% - 53px) !important;
}

.info-input-section-container {
  width: 45%; 
}

@media (max-width: 940px) {
  .info-input-section-container {
    width: 100%;
  }
}

/* .info-input-section-container-env {
  margin-right: -30px;
}

.info-input-section-container-prod {
  margin-left: -30px;
} */

/* Info input section styling */
.signup-invite-title {
  font-family: 'Roboto Bold';
  font-size: 20px;
  line-height: 23px;
  color: #375393;
  margin-bottom: 30px;
  /* margin-left: 30px; */
}

.info-input-section-inputs {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  /* margin-left: 30px; */
  padding-bottom: 5px;
  padding-left: 2px;
}

.info-input-section-container .InfoInputSection__AddFieldBtn--disabled.signup-invite-add-field-button {
  cursor: not-allowed;
  background-color: #f5f5f5;
  opacity: 1;
}

.InfoInputSection__AddFieldBtn--disabled.signup-invite-add-field-button:hover {
  box-shadow: none;
}