.AddTestAlert.ant-modal .ant-modal-content {
  border-radius: 3px;
  padding: 40px;
  max-height: 288px;
  box-shadow: 0px 4.02px 19.67px -0.89px #18274B24;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  gap: 10px;

}

.AddTestTitle {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  letter-spacing: 0.0015em;
  color: #19305A;
  text-align: start;
}
.AddTestText {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(55, 83, 147, 1);
  text-align: left;
  margin-top: 16px;
  margin-bottom: 24px;
}

.AddTestInput input {
  width: 280px;
  height: 48px;
  border-radius: 3px;
  margin-top: 16px;
  font-size: 14px;
  font-family: 'Roboto';
}

.AddTestInput>.ant-input-outlined:hover {
  border-color: #26abe1;
}


.AddTestBtn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}

.AddTestBtn>.ant-btn {
  height: 32px;
  width: 94px;
  text-decoration: none;
  padding: 6px, 12px, 6px, 12px;
}

.AddTestBtn>.ant-btn-default {
  color: #19305A;
}

.AddTestBtn>.ant-btn-primary {
  color: #FDFDFD;
}

.AddTestBtn a:hover {
  text-decoration: none;
}

.AddTestBtn button span {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
}



.AddTestConfirmDel {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.AddTestConfirmDelTitle {
  font-family: Roboto Bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305A;
  margin-bottom: 16px;
}

.AddTestConfirmDelTitleMessage {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
}

.AddTestConfirmDelBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px
}

.AddTestConfirmDelBtnCancel {
  width: 60px;
  height: 32px;
  /* border: none; */
  /* padding: 8px 12px; */
  background: #EFF2F3;
  border-radius: 3px;
  color: #19305A;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.AddTestConfirmDelBtnDelete {
  width: 60px;
  height: 32px;
  border: none;
  /* padding: 8px 12px; */
  background: #26ABE1;
  border-radius: 3px;
  color: #F9F9F9;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.AddTestInput__Error {
  font-weight: 400;
  color: #E63559;
}