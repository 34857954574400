.SubmissionReceiptModal__SamplesInfo {
  padding: 16px 0px 24px 5px;
  border-bottom: 1px solid #0000000a;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.SubmissionReceiptModal__SamplesInfo__Table {
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow-x: hidden;
  margin-top: 16px;
}

.SamplesInfo__Table__Row {
  display: flex;
  gap: 36px;
}

.SubmissionReceiptModal__SamplesInfo__Table th:first-child,
.SubmissionReceiptModal__SamplesInfo__Table td:first-child {
  width: 200px;
}

.SubmissionReceiptModal__SamplesInfo__Table th,
.SubmissionReceiptModal__SamplesInfo__Table td {
  text-align: left;
  font-style: normal;
}

.SubmissionReceiptModal__SamplesInfo__Table th {
  font-size: 12px;
  line-height: 14px;
  color: #afbdca;
  font-family: "Roboto Medium";
  font-weight: 400;
}

.SubmissionReceiptModal__SamplesInfo__Table td {
  padding-top: 13px;
  color: #111a24;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  font-family: "Roboto Medium";
}

.SubmissionReceiptModal__SamplesInfo__QRCode {
  margin-right: 10px;
  margin-top: 4px;
  /* border: 1px solid #111a24; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubmissionReceiptModal__SamplesInfo__QRCode .ant-spin .ant-spin-dot-item {
  background-color: #26abe1;
}
