.COABuilderImage__Container {
  min-width: 150px;
  width: 150px;
  height: 75px;
  border-radius: 8px;
  border: 1px solid #eaf0f5;
  background-color: #f6f8fa;
  margin-top: auto;
  margin-bottom: auto;
}

.COABuilderImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
