.Invite__EmailTagContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;

  border: 1px solid #26ABE1;
  background: #F2F6FA;
  border-radius: 4px;
  padding: 0px 8px;
  min-height: 25px;
}

.EmailTagError {
  background: #fff5f7;
  border: 1px solid #e63559;
}

.EmailTagErrorText{
   color: #e63559 !important;
}

.EmailTagText {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  font-weight: 500;
  color: #26ABE1;
}

.EmailTagRemoveIcon {
  width: 18px;
  height: 18px;
}

.EmailTagRemoveIcon svg path{
  fill: #26ABE1;
}

.EmailTagRemoveIcon path{
  fill: #26ABE1;
}

.EmailTagRemoveIconError path{
  fill: #e63559 !important;
}


.EmailTagRemoveIcon:hover {
  cursor: pointer;
}
