.IndexCategories__productTabsContainer {
  background-color: #fdfdfd;
  padding: 20px 78px 0px;
  margin-top: -10px;
}

.IndexCategories__productTabsContainer--Header {
  font-family: Roboto Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #375393;
  margin-bottom: 5px;
}

.IndexCategories__productTabsContainer--Tabs {
  width: fit-content;
  gap: 10px;
}

.IndexCategories__productTabsContainer--Tabs .nav-link {
  padding: 0px !important;
}

.IndexCategories__productTabsContainer--CreateNewCategory-addIcon {
  height: 16px;
}

.IndexCategories__productTabsContainer--EditCreateNewCategory-indexTab {
  width: 175px;
}

.IndexCategories__productTabsContainer--EditCreateNewCategory-closeIcon,
.IndexCategories__productTabsContainer--editExistingCategory-closeIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 38px;
}

.IndexCategories__productTabsContainer--EditCreateNewCategory-checkIcon,
.IndexCategories__productTabsContainer--editExistingCategory-checkIcon {
  position: absolute;
  right: 4px;
  width: 20px;
  height: 20px;
}

.IndexCategories__productTabsContainer--EditCreateNewCategory-longIndexNameErrorContainer,
.IndexCategories__productTabsContainer--editExistingCategory-longIndexNameErrorContainer {
  position: absolute;
  top: 38px;
  height: 18px;
  width: 300%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #e63559;
  background-color: #ffffff;
}

.IndexCategories__productTabsContainer--EditCreateNewCategory-longIndexNameErrorContainer-exclamationIcon,
.IndexCategories__productTabsContainer--editExistingCategory-longIndexNameErrorContainer-exclamationIcon {
  height: 13.33px;
}

.IndexCategories__productTabsContainer--editExistingCategory-indexTab {
  width: 150px;
}

.IndexCategories__productTabsContainer--notEditingIndexTab {
  cursor: pointer;
}

.IndexCategories__productTabsContainer--notEditingIndexTab-dotIcon {
  cursor: pointer;
  width: 32px;
  height: 12px;
  padding: 0px 15px;
}

.IndexCategories__productTabsContainer--notEditingIndexTab-options {
  position: absolute;
  z-index: 1;
  right: -75px;
  top: 8px;
}

.IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenu {
  height: 64px;
  width: 100px;
}

.IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption {
  padding-left: 3px;
}

.IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption-editIcon {
  margin: auto 10px auto 10px;
}

.IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption-deleteIcon {
  margin: auto 9.33px auto 9.33px;
}
