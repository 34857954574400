.productSearchBtn2__Img-whiteMagnifyIcon {
  width: 13.54px;
  height: 13.54px;
  margin: auto;
  position: absolute;
  left: 8.33%;
  right: 7.02%;
  top: 8.33%;
  bottom: 7.02%;
}

.productReportSearchShowCalender {
  margin-left: -12px;
  margin-top: 7px;
}

#productReportSearchCancelIcon {
  width: 15px;
  height: 15px;
  padding: 1px;
  margin-left: 10px;
}

.productReportSearchDateSelected {
  color: #19305a;
}

#productReportSearchCalenderIcon {
  width: 15px;
  height: 15px;
  padding: 0.83px;
}

.ProductReportSearch__ShowCalenderIconDiv {
  height: 100%;
  background-color: #eff2f3;
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.ProductReportSearch__ShowCalenderIconDiv:hover {
  background-color: #bee6f6;
}

.productReportSearchCalenderContainer {
  position: relative;
  height: 40px;
  text-align: center;
  font-family: Roboto Medium;
  font-weight: 500;
  font-size: 14px;
  color: #19305a;
  cursor: pointer;
}

.productReportSearchDivBetweenSearchBoxandCalender {
  width: 16px;
}

#productReportSearchInputBar {
  position: relative;
}

.ReportSearchContainer {
  display: flex;
  align-items: center;
}

.ReportSearch__SearchBar {
  height: 35px;
  display: flex;
  align-items: center;
}
