.LoadingOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoadingOverlay--greyout {
  background: #fdfdfd76;
}
