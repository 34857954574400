.AddEditTestsForm {
  flex: 1;
  display: flex;
  gap: 16px;
}

.AddEditTestsForm .AddEditTestsForm__SelectionListContainer {
  flex-shrink: 0;
  min-width: 200px;
}

.AddEditTestsForm .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.AddEditTestsForm .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #133e8c !important;
}

.AddEditTestsForm__SelectionList {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  padding-right: 6px;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem {
  padding: 8px;
  border-radius: 4px;
  background: #F8FAFC;
  border: 1px solid #EAF0F5;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem.AddEditTestsForm__SelectionListItem--selected {
  border: 1px solid #26ABE1;
  background: #F2F6FA;
  box-shadow: 0px 1px 2px -1px #1018281A, 0px 1px 3px 0px #1018281A;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem.AddEditTestsForm__SelectionListItem--empty {
  border: 1px solid #f5eaea !important;
  background: #FFF1F0 !important;
  box-shadow: 0px 1px 2px -1px #2810101a, 0px 1px 3px 0px #2810101a !important;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem.AddEditTestsForm__SelectionListItem--empty.AddEditTestsForm__SelectionListItem--selected {
  border: 1px solid #E11903 !important;
  background: rgba(225, 25, 3, 0.1) !important;
  box-shadow: 0px 1px 2px -1px #2810101a, 0px 1px 3px 0px #2810101a !important;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListLine {
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  user-select: none;
  pointer-events: none;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListLine.truncated-text {
  --no-of-lines: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--no-of-lines);
  line-height: 1.5;
  max-height: calc(1.5em * var(--no-of-lines));
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListLine .AddEditTestsForm__SelectionListTitle {
  color: #6C7E8E;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListLine .AddEditTestsForm__SelectionListValue {
  color: #182534;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListItemDelete {
  color: #26ABE1;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 10px;
  line-height: 10px;
  cursor: pointer;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListItemDelete>svg>path:first-child {
  fill: #26ABE1;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListItemDelete>svg>path:last-child {
  fill: transparent;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem .AddEditTestsForm__SelectionListItemDelete:hover>svg>path {
  fill: #26abe1;
  stroke: #26abe1;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem.AddEditTestsForm__SelectionListItem--empty .AddEditTestsForm__SelectionListItemDelete>svg>path:first-child {
  fill: #E11903 !important;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem.AddEditTestsForm__SelectionListItem--empty .AddEditTestsForm__SelectionListItemDelete>svg>path:last-child {
  fill: transparent !important;
}

.AddEditTestsForm__SelectionList .AddEditTestsForm__SelectionListItem.AddEditTestsForm__SelectionListItem--empty .AddEditTestsForm__SelectionListItemDelete:hover>svg>path {
  fill: #e11903bf !important;
  stroke: #E11903 !important;
}

.AddEditTestsForm .AddEditTestsForm__AnalysisRequestContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.AddEditTestsForm__AnalysisRequest {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.AddEditTestsForm__AnalysisRequest>* {
  background: #FFFFFF;
}

.AddEditTestsForm__AnalysisRequest .analysis-request-tests-selected-container {
  height: auto;
  min-height: 40%;
  flex: 1;
}

.AddEditTestsForm__AnalysisRequest .analysis-request-search-box {
  margin-top: 0;
}

.AddEditTestsForm__OpenSuggestionButton {
  margin-left: auto;
}

.AddEditTestsForm__OpenSuggestionButton.ant-btn-default {
  width: auto;
  height: auto;
  padding: 2px 4px;
}

.AddEditTestsForm__OpenSuggestionButton img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.AddEditTestsForm__OpenSuggestionButton span {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #26ABE1;
  margin-left: 6px;
}

.AddEditTestsForm__HideSuggestionsList {
  font-size: 24px;
  background: transparent;
  font-family: "Roboto bold";
  font-weight: 500;
  color: #000000;
  border: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 14px;
  top: -14px;
}