.ListViewCalendar.DatePickerDisplay--row-reverse {
  height: 40px;
}

.ListViewCalendar.DatePickerDisplay--row-reverse svg {
  flex-shrink: 0;
}

.ListViewCalendar--hidden {
  display: none;
}

@media only screen and (max-height: 710px) {
  .EnvCalendarPopover {
    top: 75px !important; /* Dependent on Navbar height */
  }
}
