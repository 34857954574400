.env-report-view-map {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* .env-report-view-map > .report-map-header {
  display: flex;
  padding: 0px 10px;
  margin-top: 10px;
  gap: 16px;
  width: 100%;
  height: 60px;
}

.env-report-view-map .report-map-header-block {
  background: #B3BFDB;
  border-radius: 4px;
  width: 20px;
}

.env-report-view-map .report-map-header-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  font-family: 'Roboto Medium';
  font-size: 14px;
  color: #19305A;
} */

.env-report-view-map > .react-transform-wrapper {
  cursor: default;
  border-radius: 4px;
}

.report-pin-location-container {
  position: absolute;
  justify-content: center;
  flex-wrap: wrap;
  display: block;
  /* transform: translate(-50%, -50%); */
  z-index: 1;
}

.report-pin-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}