.SampleSubmissionConfirmModal__EditsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.SampleSubmissionConfirmModal__EditsContainer .ant-table-content {
  font-family: Roboto;
}

.SampleSubmissionConfirmModal__EditsContainer .ant-table-title {
  font-family: "Roboto Bold";
  font-size: 14px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  color: #375393;
}

.SampleSubmissionConfirmModal__EditsContainer
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td {
  background: unset;
}

.SampleSubmissionConfirmModal__EditsContainer
  .ant-table-wrapper
  .ant-table-content
  th {
  color: #375393;
}

.SampleSubmissionConfirmModal__TestListScrollContainer {
  height: 120px;
  width: 100%;
  /* margin: 10px 0px; */
  padding: 4px;
  border-radius: 3px;
}

.SampleSubmissionConfirmModal__TestList {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  /* justify-content: space-between; */
  gap: 5px;
  width: 100%;
  height: 100%;
}

.SampleSubmissionConfirmModal__TestDiv--added {
  box-sizing: border-box;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1.5px solid #1ec04a;
  color: #1ec04a;
  border-radius: 8px;
  background: #f6f8fa;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.SampleSubmissionConfirmModal__TestDiv--deleted {
  box-sizing: border-box;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1.5px solid #e63559;
  color: #e63559;
  border-radius: 8px;
  background: #f6f8fa;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.SampleSubmissionConfirmModal__Image {
  background-color: #eff2f3;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.SampleSubmissionConfirmModal__Image > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.SampleSubmissionConfirmModal__TitleDiv{
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.SampleSubmissionConfirmModal__TitleDiv span{
  width: 12px;
  height: 28px;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 4px;
  background: #26abe1;
  margin: 0px 10px 0px 0px
}

.SampleSubmissionConfirmModal__Title{
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #1F2D3B  
}

.SampleSubmissionConfirmModal__Divider{
  margin-bottom: 15px;
  border-bottom: 1px solid #D1DCE8;
}

.SampleSubmissionConfirmModal__TestListTitle{
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #6C7E8E;
  padding-bottom: 10px;
}