.SpecManager__Container {
  max-width: 853px;
  margin: 0px 10px -10px -1px;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
}

.SpecManager__Header--title {
  font-size: 16px;
  font-family: "Roboto Medium";
}

.SpecManager__Header--icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.SpecManger__SubHeader {
  display: flex;
  align-items: flex-end;
  padding: 0 16px;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  color: #375393;
  height: 32px;
}

.SpecManger__SubHeader--warning {
  background-color: #e63559;
  color: #fdfdfd;
  font-size: 12px;
  min-height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.SpecManger__Separator {
  margin: 28px 14px 14px 14px;
  border-bottom: 1px solid #e1e1e1;
}

.SpecManger__LinkedTo {
  display: flex;
  align-items: center;
  height: 53px;
  background-color: #eff2f3;
  font-family: "Roboto Medium";
  font-size: 16px;
  color: #375393;
  padding: 18px;
}

.SpecManger__LinkedTo--value {
  color: #19305a;
  margin-left: 5px;
}

.SpecManager__FiltersContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 18px;
}

.SpecManager__Filters {
  display: flex;
  gap: 10px;
  padding-right: 10px;
}

.SpecManager__TableContainer {
  margin: 0px 7px 20px 7px;
  padding: 7px;
  background-color: #fafafa;
  height: 100%;
}

.specManagerButton {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.SpecManager__ConfirmButton {
  height: 32px;
  /* width: 88px; */
  border: none;
  background: #26abe1;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.SpecManager__ConfirmButton:hover:not(:active),
.SpecManager__ConfirmButton:focus-visible {
  background-color: #9dd9f2;
}

.SpecManager__ConfirmButton:hover:active {
  background-color: #7dcded;
}

.SpecManager__ConfirmButton--disabled {
  background-color: #eaf0f5;
  color: #afbdca;
  border-radius: 4px;
  height: 32px;
  width: 88px;
  border: none;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.SpecManager__SecondaryButton {
  height: 31px;
  width: 88px;
  background-color: white;
  border: 1px solid #26abe1;
  color: #26abe1;
  font-size: 14px;
  border-radius: 3px;
  padding: 6px 12px;
}

.SpecManager__SecondaryButton:hover:not(:active),
.SpecManager__SecondaryButton:focus-visible {
  border: 1px solid #9dd9f2;
  color: #9dd9f2;
}

.SpecManager__SecondaryButton:hover:active {
  border: 1px solid #7dcded;
  color: #7dcded;
  background-color: #f4fbfe;
}

.specManagerFilterSquare {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
}

.specManagerFilterText {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #19305a;
}

.specManagerFilterTile {
  display: flex;
  justify-content: center;
  align-items: center;
}

#noResultsScrollbar .ScrollbarsCustom-Content {
  height: 100%;
}

#specManagerDisplayTableScrollBar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

#specManagerDisplayTableScrollBar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}

#specManagerEditSpecTableScrollBar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 4.42px !important;
  background: #b3bfdb !important;
}

#specManagerEditSpecTableScrollBar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 4.42px !important;
  background: #758ab9 !important;
}

#specManagerSpecTableScrollBarProduct .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 4.42px !important;
  background: #b3bfdb !important;
}

#specManagerSpecTableScrollBarProduct
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 4.42px !important;
  background: #758ab9 !important;
}

.specManagerSearchInput {
  width: 310px;
  height: 40px;
  position: static;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 80px;
  /* ^ this is padding for the searchicon button */
  color: #19305a;
  border: 2px solid #d7dae1;
}

.specManagerSearchInput::placeholder {
  color: #758ab9;
}

.specManagerSearchInput:focus,
.specManagerSearchInput:focus-visible {
  box-shadow: none;
  outline: none;
  border-color: #26abe1;
}

.SpecManager__SearchFilters {
  max-width: 100%;
  width: fit-content;
  display: flex;
  padding: 3px 10px;
  border-radius: 8px;
  border: 1px solid #e1e9f1;
  background: #f6f8fa;
  margin-top: 8px;
  position: relative;
}

.SpecManager__SearchFilters__CloseIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  padding: 3px;
  top: -8px;
  right: -6px;
  border-radius: 50%;
  background: white;
  border: 1px solid #edf2f7;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.SpecManager__CardHead {
  height: 54px;
  padding: 15px 16px;
  background-color: #375393;
  color: #fdfdfd;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.ProdSpecManagerDrawerWrapper.ant-drawer {
  display: flex;
  align-items: center;
}

.ProdSpecManagerDrawerWrapper .ant-drawer-content-wrapper {
  height: 618px;
  top: unset;
  bottom: unset;
  right: 20px;
}

.ProdSpecManagerDrawer .ant-drawer-header .ant-drawer-close {
  display: none;
}

.ProdSpecManagerDrawer .ant-drawer-header {
  background-color: #375393;
  color: #fdfdfd;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  height: 56px;
}

.ProdSpecManagerDrawer .ant-drawer-header {
  background-color: #375393;
}

.ProdSpecManagerDrawer .ant-drawer-header .ant-drawer-title {
  color: #fdfdfd;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.ProdSpecManagerDrawer .ant-drawer-body {
  padding: 0px;
  overflow: hidden;
}

.ProdSpecManagerCardBody {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 100%;
  width: 100%;

  /* from .card */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
}
