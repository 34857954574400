.ReportPreviewModal.ant-modal .ant-modal-content{
  border-radius: 0px;
  padding: 0px;
}

.previewDateBlockContainer{
  align-items: center;
}

.ProductReportPreviewModal__Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #375393;
  color: #FDFDFD;
  height: 47px;
  padding: 18px;
  padding-top: 16px;
  margin-top: -14px;
}

.ProductReportPreviewModal__Container__CloseIcon {
  height: 24px;
  cursor: pointer;
}

.ProductReportPreviewModal__ReportContainer .slider-wrapper.axis-horizontal {
  height: 70vh !important;
}

.ProductReportPreviewModal__ReportContainer {
  padding: 24px 0 54px 0;
  margin-bottom: -20px;
  border-radius: 0px;
}

.ProductReportPreviewModal__ReportView {
  background-color: white;
}

.ProductReportPreviewModal__NoResults {
  height: 70vh;
  font-weight: 300;
}

.ProductReportPreviewModal__CategoryList {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #E1E8EF;
  margin-bottom: 8px;
}

.ProductReportPreviewModal__CategoryListItem {
  font-family: "Roboto";
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 4px 8px 4px 8px;
  border-radius: 3px;
  background: #EDF2F7;
  color: #375393;
  cursor: pointer;
}

.ProductReportPreviewModal__CategoryListItem.ProductReportPreviewModal__CategoryListItem__Selected {
  color: white;
  background: #375393;
}
