.TemplateBuilder__TestViewToggle {
  /* z-index: 5; */
  /* font-size: 14px; */
  font-family: "Roboto Medium";
}

.TemplateBuilder__TestViewToggle.ant-switch.ant-switch-checked {
  background-color: #26abe1;
}

.TemplateBuilder__TestViewToggle.ant-switch.ant-switch-checked:hover {
  background-color: #26ace1d5;
}
