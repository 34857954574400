.SubmissionReceiptModal__SenderInfo {
  padding: 24px 5px 48px 5px;
  border-bottom: 1px solid #d9e1ea;
}

.SubmissionReceiptModal__SenderInfo__Title {
  color: #000;
  font-family: "Roboto Medium";
  font-size: 16px;
  padding-bottom: 14px;
}

.SubmissionReceiptModal__SenderInfo__Body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: fit-content(calc(50% - 25px)) 1fr; */
  grid-auto-flow: row;
  grid-auto-rows: minmax(32px, auto);
  row-gap: 20px;
  column-gap: 50px;
  word-break: break-word;
  max-width: 100%;
}

.SenderInfo__Field {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.SenderInfo__Field__Label {
  color: #afbdca;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SenderInfo__Field__Value {
  color: #1f2d3b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
