.signup-single-page-form {
  width: 100%;
}

.signup-single-page-sign-up-button {
  width: 50%;
  margin-top: 30px;
  border-style: none;
  background-color: #27aae1;
  color: #fff;
  border-radius: 3px;
  font-family: "Roboto";
  font-size: 16px;
  padding: 5px 10px;
}

.signup-single-page-sign-up-button.ButtonWithLoading {
  gap: 6px;
}

.signup-single-page-sign-up-button.ButtonWithLoading
  .ButtonWithLoading__LoadingDots {
  margin-top: 2px;
}

.signup-single-page-sign-up-button:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.SignUpSinglePage {
  height: auto;
  overflow-y: visible;
  overflow-x: visible;
  margin-top: 35px;
  background-color: white;
  position: relative;
}

.SignUpSinglePage__LoadingDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 5;
}

.signupTitle {
  font-family: "Roboto Bold";
  font-size: 20px;
  color: #375393;
  margin-bottom: 25px;
}
