.SampleSubmission_UploadImageModal {
  min-width: 800px;
  min-height: 600px;
}

.SampleSubmission_UploadImageModal .ant-modal {
  width: 60% !important;
  height: 75%;
}

.SampleSubmission_UploadImageModal .ant-modal-close {
  width: 20px;
  height: 20px;
  top: 26px;
  right: 22px;
}

.SampleSubmission_UploadImageModal .ant-modal-close:hover {
  background-color: unset;
}

.SampleSubmission_UploadImageModal .ant-modal-close-x {
  line-height: unset;
}

.SampleSubmission_UploadImageModal .ant-modal-header {
  background-color: unset;
  width: 100%;
  border-bottom: 1px solid #D7DAE1;
  padding: 22px;
  margin-bottom: 0px;
}

.SampleSubmission_UploadImageModal .ant-modal-title {
  color: #19305A;
  font-family: "Roboto Bold";
  font-size: 20px;
}

.SampleSubmission_UploadImageModal .ant-modal-content {
  width: 100%;
  height: 100%;
  background: #F9F9F9;
  border-radius: 0px;
  position: relative;
  padding: 0px;
}

.SampleSubmission_UploadImageModal .ant-modal-body {
  width: 100%;
  height: calc(100% - 180px);
}