.DeleteMemberModelContainer.ant-modal .ant-modal-content {
  border-radius: 4px;
  padding: 7px 0px 18px;
}

.DeleteMemberModelContainer .deleteMemberModalHeader {
  height: 25px;
}

.deleteMemberModal {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.deleteMemberModalHeader {
  color: #19305a;
  text-align: left;
  font-family: "Roboto Medium";
  font-size: 18px;
  margin-bottom: 20px;
}

.deleteMemberModalText {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
  text-align: left;
}

.deleteMemberModalBtns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  height: 32px;
}

.deleteMemberCancelBtn {
  padding: 5px 15px;
  width: fit-content;
  background: #eff2f3;
  color: #19305a;
  text-align: center;
  border: 1px solid #eff2f3;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.deleteMemberConfirmBtn {
  padding: 5px 15px;
  width: fit-content;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}
