.CreateNewPin {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-family: "Roboto Medium";
  width: 365px;
  font-size: 14px;
  z-index: 1;
  border-radius: 11px;
  margin-bottom: 10px;
  overflow: hidden;
}

.CreateNewPin__Header {
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: #19305a;
  border-radius: 11px 11px 0px 0px;
  color: #f9f9f9;
  font-weight: bold;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  align-items: center;
}

.CreateNewPin__Body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px;
  padding-top: 10px;
  border-radius: 0px 0px 11px 11px;
}

.CreateNewPin .CreateNewPin__Body button {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 16px;
  width: auto;
  margin-left: 8px;
  border-radius: 3px;
  border: none;
}

.CreateNewPin__Body textarea,
.CreateNewPin__Body input {
  font-size: 14px;
  color: #19305a;
  background-color: #fdfdfd;
  width: 100%;
  border: 2px solid #d7dae1;
  border-radius: 3px;
  padding-left: 8px;
  font-family: "Roboto";
}

.CreateNewPin__Body input {
  height: 32px;
}

.CreateNewPin__Body textarea:focus,
.CreateNewPin__Body input:focus,
.CreateNewPin__Body textarea:hover,
.CreateNewPin__Body input:hover {
  border: 2px solid #26abe1;
}

.CreateNewPin__Body textarea::placeholder,
.CreateNewPin__Body textarea:focus:hover::placeholder,
.CreateNewPin__Body textarea:focus::placeholder,
.CreateNewPin__Body input::placeholder,
.CreateNewPin__Body input:focus:hover::placeholder,
.CreateNewPin__Body input:focus::placeholder {
  font-size: 14px;
  font-family: "Roboto";
  color: #758ab9;
}

.CreateNewPin__Body input.CreateNewPin__Input--error,
.CreateNewPin__Body input.CreateNewPin__Input--error:hover,
.CreateNewPin__Body input.CreateNewPin__Input--error:focus {
  border: 2px solid red;
}

.CreateNewPin__Body textarea:hover::placeholder,
.CreateNewPin__Body input:hover::placeholder {
  color: #19305a;
}

.CreateNewPin__FieldInput {
  position: relative;
  height: 32px;
}

.CreateNewPin__FieldInputInner {
  position: absolute;
  width: 100%;
}

/* Autocomplete styling */
.CreateNewPin__Autocomplete {
  width: 100%;
}

/* Dropdown styling */
.CreateNewPin__Autocomplete__Dropdown.ant-select-dropdown
  .ant-select-item-option {
  font-family: "Roboto";
  color: #506375;
}

/* Dropdown scrollbar styling */
.CreateNewPin__Autocomplete__Dropdown.ant-select-dropdown
  .rc-virtual-list-scrollbar {
  right: 0px !important;
  width: 4px !important;
}

.CreateNewPin__Autocomplete__Dropdown.ant-select-dropdown
  .rc-virtual-list-scrollbar-thumb {
  background-color: #c4d2df !important;
}

.CreateNewPin__DuplicateErrorDiv {
  display: flex;
  gap: 4px;
  width: fit-content;
  align-items: center;
}

.CreateNewPin__DuplicateSwabErrorIcon {
  height: 15px;
  width: 15px;
}

.CreateNewPin__DuplicateSwabErrorText {
  color: #e63559;
  font-family: "Roboto Medium";
  font-size: 12px;
  white-space: nowrap;
}

.CreateNewPin__ChooseQuadrant {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.CreateNewPin__ChooseQuadrantColorBlocks {
  display: flex;
  justify-content: space-between;
}

.CreateNewPin__QuadrantColorBlockTopLeft {
  background-color: #f878ff;
}

.CreateNewPin__QuadrantColorBlockTopRight {
  background-color: #0093a7;
}

.CreateNewPin__QuadrantColorBlockBottomRight {
  background-color: #8a1cff;
}

.CreateNewPin__QuadrantColorBlockBottomLeft {
  background-color: #a7bb2a;
}

.CreateNewPin__QuadrantColorBlock--selected {
  border: 2px solid rgba(25, 48, 90, 0.5);
  box-shadow: 0px 3px 2px rgb(0 0 0 / 25%);
}

.CreateNewPin__QuadrantColorBlock {
  width: 64px;
  height: 24px;
  border-radius: 4px;
}

.CreateNewPin__SwabDescription {
  resize: none;
}

.CreateNewPin__CancelConfirm {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.CreateNewPin__Cancel {
  background-color: #eff2f3;
}

.CreateNewPin__Confirm {
  background-color: #26a1d2;
  color: #f9f9f9;
}
