/* GraphContainerStyle.css */

.EnvAnalyticsGraph__GraphMainContainer {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Space between child elements */
  overflow: visible;
  height: 100%;
  border-radius: 8px;
  padding-bottom: 10px;
}

.EnvAnalyticsGraph__GraphContainerPlaceholderText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
  color: #375393;
  background-color: #f2f6fa;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 64px;
  padding: 25px;
}

.EnvAnalyticsGraph__GraphContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-grow: 1;
  padding: 20px 20px 0 20px;
  position: relative;
  height: 400px;
  width: 100%;
}

.EnvAnalyticsGraph__GraphTooltip--Container {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.EnvAnalyticsGraph__TooltipContainer--Text {
  color: #182534;
}

.EnvAnalyticsGraph__GraphTooltipFooter--Table {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.EnvAnalyticsGraph__GraphTooltipFooter--Total {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: "Roboto Medium";
}

.EnvAnalyticsGraph__GraphTooltipHeader {
  margin-bottom: 5px;
  font-family: "Roboto Medium";
  color: #182534;
}

.EnvAnalyticsGraph__GraphTooltipValue {
  margin-bottom: 5px;
  font-family: "Roboto";
}

.EnvAnalyticsGraph-GraphTooltipTest {
  overflow: hidden;
}

.EnvAnalyticsGraph-GraphTooltipDivider {
  height: 1px;
  background-color: #b1b7bc71;
  margin: 5px 0;
}

.EnvAnalyticsGraph__GraphTooltipFooter {
  display: flex;
  flex-direction: column;
}

.EnvAnalyticsGraph__GraphTooltipBar--Footer {
  display: flex;
  flex-direction: row;
}

svg.recharts-surface {
  overflow: visible;
}

.recharts-brush > rect:first-child {
  stroke: white;
  rx: 8;
}

.recharts-brush-slide {
  fill-opacity: 0.4;
}

.recharts-brush-traveller line {
  stroke: white;
}

.recharts-brush-texts > text {
  font-size: 12px;
  fill: #8b97a3;
  width: 10;
}

.EnvAnalyticsGraph__GraphMainContainer
  > .ScrollbarsCustom
  > .ScrollbarsCustom-TrackY
  > .ScrollbarsCustom-ThumbY {
  background-color: #c4d2df !important;
}

.EnvAnalyticsGraph__GraphMainContainer > .ScrollbarsCustom {
  height: 80px !important;
}
/* .EnvAnalyticsGraph__GraphContainer>.no-results-gif-common>.no-results-gif-common__msg{ */
.EnvAnalyticsGraph__NoResults > .no-results-gif-common__msg {
  text-align: center;
  /* padding: 20px; */
  padding-bottom: 42px;
  color: #8b97a3;
}
.xAxisTickLabel {
  display: inline-block;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}



.EnvAnalyticsGraph__Placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6c7e8e;
  font-size: 16px;
  text-align: center;
  pointer-events: none;
}

