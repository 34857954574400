.NavTabs.ant-tabs {
  line-height: 1.5;
  height: 100%;
}

.NavTabs.ant-tabs.ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}

.NavTabs.ant-tabs:not(.ant-tabs-card) > .ant-tabs-nav {
  margin: 0;
  box-shadow: 0 3px 10px rgba(9, 30, 66, 0.05), 0 0 5px rgba(9, 30, 66, 0.08);
  height: 76px;
  padding: 0 52px 0 72px;
  background-color: #fdfdfd;
}

.NavTabs.ant-tabs:not(.ant-tabs-card) > .ant-tabs-nav .ant-tabs-nav-list {
  align-items: center;
}

.NavTabs.ant-tabs:not(.ant-tabs-card)
  > .ant-tabs-nav
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background-color: #f2f2f2;
  color: #375393;
  border-radius: 4px;
  padding: 8px 12px;
  height: 32px;
}

.NavTabs.ant-tabs:not(.ant-tabs-card)
  > .ant-tabs-nav
  .ant-tabs-tab:hover
  .ant-tabs-tab-btn {
  background-color: #d7dae1;
}

.NavTabs.ant-tabs:not(.ant-tabs-card)
  > .ant-tabs-nav
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  background-color: #375393;
  color: #f9f9f9;
}

.NavTabs.ant-tabs:not(.ant-tabs-card) > .ant-tabs-nav .ant-tabs-tab-icon {
  margin-right: 4px;
  display: flex;
  width: 16px;
  height: 16px;
  margin-top: -1px;
}

.NavTabs.ant-tabs:not(.ant-tabs-card) > .ant-tabs-nav .ant-tabs-tab-icon > svg {
  width: 16px;
  height: 16px;
}

.NavTabs.ant-tabs.ant-tabs-top .ant-tabs-content {
  position: unset;
  height: 100%;
}

.NavTabs.ant-tabs.ant-tabs-top .ant-tabs-tabpane {
  height: 100%;
}
