.EnvSpecManagerSelect.ant-select {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
}

.EnvSpecManagerSelect.ant-select .ant-select-selector {
  background-color: #eff2f3 !important;
  padding: 0px 10px;
  border-radius: 3px;
}

.EnvSpecManagerSelect.ant-select .ant-select-selection-item,
.EnvSpecManagerSelect.ant-select .ant-select-selection-placeholder {
  color: #375393;
  padding-inline-end: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.EnvSpecManagerSelect.ant-select.ant-select-open .ant-select-selector {
  background-color: #375393 !important;
}

.EnvSpecManagerSelect.ant-select.ant-select-open .ant-select-selection-item,
.EnvSpecManagerSelect.ant-select.ant-select-open
  .ant-select-selection-placeholder {
  color: #fdfdfd;
}

.EnvSpecManagerSelect.ant-select .ant-select-selection-placeholder {
  transition: all 0.3s;
}

.EnvSpecManagerSelect.ant-select .ant-select-arrow {
  inset-inline-end: 7px;
}

.EnvSpecManagerSelect__Chevron {
  color: #375393;
  transition: color 0.3s;
}

.EnvSpecManagerSelect.ant-select.ant-select-open
  .EnvSpecManagerSelect__Chevron {
  color: #fdfdfd;
  transform: rotate(180deg);
  margin-bottom: 2px;
}

/* Dropdown styling */
.EnvSpecManagerSelect__Dropdown.ant-select-dropdown {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  background: #fdfdfd;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
}

.EnvSpecManagerSelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option {
  color: #758ab9;
  border-radius: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.EnvSpecManagerSelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option
  .ant-select-item-option-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.EnvSpecManagerSelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-active,
.EnvSpecManagerSelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected,
.EnvSpecManagerSelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected.ant-select-item-option-active {
  font-weight: 400;
  color: #26abe1;
  background-color: #eff2f3;
}
