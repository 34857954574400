.templateBuilderTests {
  border: 1px solid #eaf0f5;
  min-width: 150px;
  border-radius: 16px;
  width: 100%;
  height: max-content;
  padding: 0 1px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* height: 471px; */
}

.templateBuilderTestsHeader {
  padding: 12px;
  border-bottom: 0.5px solid #eaf0f5;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #385387;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.templateBuilderTestsSubHeader {
  font-family: "Roboto Medium";
  color: #afbdca;
  font-size: 14px;
  padding: 12px 12px 0 12px;
}

.templateBuilderTestsDisable {
  pointer-events: none;
  opacity: 0.7;
}

.templateBuilderTestsItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.templateBuilderTestsItem {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  color: #506375;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-word;
  background-color: #f2f6fa;
}
.templateBuilderTestsItem:hover {
  background-color: #c4d2df;
  transform: scale(1.01);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}

.templateBuilderTestsItem--selected {
  background-color: #c4d2df;
}

.templateBuilderTestsItem--selected:hover {
  transform: scale(1.01);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}

/* Scrollbar styling (needed to make container grow with content until max-height is reached) */
.templateBuilderTestsScrollbar {
  padding: 12px 12px 30px 12px;
  flex-grow: 1;
}

.templateBuilderTests .ScrollbarsCustom {
  max-height: max(421px, calc(100vh - 262.5px));
}

.templateBuilderTests .ScrollbarsCustom-Wrapper,
.templateBuilderTests .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.templateBuilderTests
  .ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Content {
  padding-left: 0px !important;
  padding-right: 12px !important;
}

.templateBuilderTestsScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #b3bfdb !important;
}
