.UpdateTestList__Container {
  width: 100%;
  height: 100%;
  min-height: 350px;
  padding-left: 15px;
  padding-right: 5px;
  flex-grow: 1;
  display: flex;
}

.UpdateTestList__TestListContainer {
  flex: 1;
}

.UpdateTestList__SelectedListContainer {
  width: 240px;
}

.UpdateTestList__TestListContainer,
.UpdateTestList__SelectedListContainer {
  margin: 20px 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.UpdateTestList__SelectedListBox {
  flex: 1;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid #E6EEF5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.UpdateTestList__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UpdateTestList__SectionTitle {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #506375;
  margin-bottom: 0;
}

.UpdateTestList__SearchBox {
  position: relative;
  display: flex;
  align-items: center;
}

.UpdateTestList__SearchBox input:focus {
  border-color: #97a3ae;
}

.UpdateTestList__SearchBox img {
  height: 13.54px;
  position: absolute;
  left: 9px;
  top: 13px;
}

.UpdateTestList__SearchBox input {
  background: #ffffff;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  padding-left: 31px;
  padding-right: 30px;
  font-family: "Roboto";
  font-size: 14px;
  color: black;
  line-height: 20px;
  height: 40px;
  border-radius: 8px;
}

.UpdateTestList__SearchBox input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #afbdca;
  opacity: 1;
  /* Firefox */
}

.UpdateTestList__SearchBox input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #afbdca;
}

.UpdateTestList__SearchBox input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #afbdca;
}

.UpdateTestList__ClearButton {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  color: #26ABE1;
  padding: 0;
  background-color: transparent;
  border: none;
}

.UpdateTestList__ButtonPanel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.UpdateTestList__SaveButton {
  gap: 4px;
}