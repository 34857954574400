.StyledButton {
  border-radius: 4px;
  font-family: "Roboto Medium";
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StyledButton.ant-btn:not(.ant-btn-icon-only)
  > .ant-btn-icon.ant-btn-loading-icon,
.StyledButton.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
  margin-inline-end: 4px;
}

/* Primary styling */
.StyledButton.ant-btn-primary,
.StyledButton.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):not(
    :hover
  ):active {
  background-color: #26abe1;
  border-color: #26abe1;
}

.StyledButton.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #9dd9f2;
  border-color: #9dd9f2;
}

.StyledButton.ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover:active {
  background-color: #7dcded;
  border-color: #7dcded;
}

.StyledButton.ant-btn-primary:disabled,
.StyledButton.ant-btn-primary.ant-btn-disabled {
  background-color: #eaf0f5;
  border-color: #eaf0f5;
  color: #afbdca;
}

/* Primary Ghost styling */
.StyledButton.ant-btn-primary.ant-btn-background-ghost > .ant-btn-icon > svg > path {
  fill: #26abe1;
  stroke: #26abe1;
}
.StyledButton.ant-btn-primary.ant-btn-background-ghost,
.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):not(:hover):active {
  color: #26abe1;
  border-color: #26abe1;
  background-color: transparent;
}

.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #9dd9f2;
  border-color: #9dd9f2;
  background-color: transparent;
}

.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):hover > .ant-btn-icon > svg > path,.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):hover > svg > path {
  fill: #9dd9f2;
}

.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):hover:active {
  color: #7dcded;
  border-color: #7dcded;
  background-color: #f4fbfe;
}
.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):hover:active > .ant-btn-icon > svg > path,.StyledButton.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
    .ant-btn-disabled
  ):hover:active > svg > path {
  fill: #7dcded;
}

.StyledButton.ant-btn-primary.ant-btn-background-ghost:disabled,
.StyledButton.ant-btn-primary.ant-btn-background-ghost.ant-btn-disabled {
  background-color: #f6f8fa;
  border-color: #c4d2df;
  color: #c4d2df;
}

/* Link Styling */
.StyledButton.ant-btn-link,
.StyledButton.ant-btn-link:not(:hover):active {
  padding: 0px;
  color: #26abe1;
}

.StyledButton.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #9dd9f2;
}

.StyledButton.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover:active {
  color: #7dcded;
}

/* Disable wave animation */
.StyledButton .ant-wave.wave-motion-appear-active {
  display: none;
}

.StyledButton__LoadingDots {
  fill: #f9f9f9;
  margin-left: 5px;
}

/* Default style */
.StyledButton.ant-btn-default{
  padding: 8px 14px 8px 14px;
  background-color: #f2f6fa;
  box-shadow: 0px 1px 2px 0px #1018280d;
  color: #26abe1;
  border-color:  #f2f6fa !important;
  width: 120px;
  height: 40px;
  font-family: "Roboto medium";
  font-size: 14px;
  font-weight: 500;  
}

.StyledButton.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
  color:#A5DDF3;
  background-color: #f2f6fae3;
}