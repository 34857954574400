.MonthYearSelect {
  position: absolute;
  z-index: 3;
  top: 7px;
  left: 49px;
  width: 232px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.MonthYearSelect .ant-select .ant-select-selector {
  height: 32px;
  outline: 0;
  overflow: hidden;
  color: #375393;
  border: 1px solid #d7dae1;
  box-shadow: none;
  padding-left: 12px;
  border-radius: 4px;
  font-family: Roboto Medium;
  font-size: 12px;
  background: #f9f9f9;
}

.MonthYearSelect
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover:hover
  .ant-select-selector {
  border-color: #375393;
}

.MonthYearSelect
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #375393;
  box-shadow: 0 0 0 2px #3753933a;
  outline: 0;
}

.ant-select.MonthYearSelect__MonthSelect {
  width: 105px;
}

.ant-select.MonthYearSelect__YearSelect {
  width: 78px;
}

/* Dropdown styling */
.MonthYearSelectDropdown.ant-select-dropdown {
  padding: 1px 0px;
  border-radius: 3px;
  background-color: #fdfdfd;
}

.MonthYearSelectDropdown.ant-select-dropdown .ant-select-item {
  color: #19305a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding: 6px 14px;
  border-radius: 0;
}

.MonthYearSelectDropdown.ant-select-dropdown .ant-select-item-option-selected,
.MonthYearSelectDropdown.ant-select-dropdown .ant-select-item-option-active {
  background-color: #eff2f3;
}

.MonthYearSelectDropdown.ant-select-dropdown .ant-select-item-option-selected {
  font-family: "Roboto Bold";
}

/* Dropdown scrollbar styling */
.MonthYearSelectDropdown .rc-virtual-list-scrollbar {
  right: 1px !important;
  width: 3px !important;
}

.MonthYearSelectDropdown .rc-virtual-list-scrollbar-thumb {
  background-color: #b3bfdb !important;
}
