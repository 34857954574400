.envMainContainer {
  height: 100%;
  background-color: #fdfdfd;
}

.tab-content-analytics {
  background-color: #eff2f3;
  padding: 0px;
}

.envshftInput::-webkit-input-placeholder {
  font-family: "Roboto Italic";
  font-size: 18px;
}

.envshftInput:-moz-placeholder {
  font-family: "Roboto Italic";
  font-size: 18px;
}

.envshftInput:-ms-input-placeholder {
  font-family: "Roboto Italic";
  font-size: 18px;
}

/* scroll bar */
.envChartdivContainer::-webkit-scrollbar,
.prodTrackMainContainer::-webkit-scrollbar,
.envTrackMainDiv::-webkit-scrollbar,
.locationTrackList::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
.envChartdivContainer::-webkit-scrollbar-track,
.prodTrackMainContainer::-webkit-scrollbar-track,
.envTrackMainDiv::-webkit-scrollbar-track,
.locationTrackList::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #f0f0f0;
}

/* Handle */
.envChartdivContainer::-webkit-scrollbar-thumb,
.prodTrackMainContainer::-webkit-scrollbar-thumb,
.envTrackMainDiv::-webkit-scrollbar-thumb,
.locationTrackList::-webkit-scrollbar-thumb {
  background: #27aae1;
  border-radius: 2.5px;
}

/* Handle on hover */
.envChartdivContainer::-webkit-scrollbar-thumb:hover,
.prodTrackMainContainer::-webkit-scrollbar-thumb:hover,
.envTrackMainDiv::-webkit-scrollbar-thumb:hover,
.locationTrackList::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.ScrollbarsCustom {
  position: relative;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  display: none;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #27aae1 !important;
  border-radius: 2.5px !important;
  width: 5px;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  width: 5px !important;
  background: #f0f0f0 !important;
  top: 0 !important;
  height: 100% !important;
  /* right: 1px !important; */
}

.ScrollbarsCustom-Content {
  padding: 0px !important;
}

.addMapInputContainer>input {
  display: none;
}

.addMapLabel {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 15px;
}

.envTrackResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.prodTrackLeftContainerScroll {
  /* overflow-y: scroll;
  height: 80vh; */
  width: 200px;
}

.locationTrackList {
  overflow: hidden;
  height: calc(100% - 42px);
  /* width: 64%; */
  width: 350px;
  /* margin-top: 32px;  */
}

.locationTrackList>div {
  border: 0;
}

.envLeftSidebar h3 {
  padding-bottom: 20px;
  margin: 0;
  color: rgb(26, 46, 89);
  font-size: 18px;
  font-family: "Roboto Bold";
}

.envTrackMainContainer {
  width: 100%;
  margin-top: 50px;
  min-height: 80vh;
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: hidden;
}

.envTrackMainDiv {
  width: 950px;
  height: 70vh;
  overflow: scroll;
  padding: 20px;
  float: left;
  border-style: solid;
  border-color: #1a2e59;
  border-width: 4px;
}

.envTrackTopContainer {
  /* width: 1350px;
height: 1000px; */
  /* width: 1300px;
  height: 800px; */
  width: 100%;
  height: calc(100% - 150px);
  margin-top: 20px;
  padding-left: 50px;
  /* float: left; */
}

/* scroll bar */
.envTrackMainContainer::-webkit-scrollbar,
.template-list::-webkit-scrollbar,
.prodTrackMainContainer::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
.envTrackMainContainer::-webkit-scrollbar-track,
.template-list::-webkit-scrollbar-track,
.prodTrackMainContainer::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #f0f0f0;
}

/* Handle */
.envTrackMainContainer::-webkit-scrollbar-thumb,
.template-list::-webkit-scrollbar-thumb,
.prodTrackMainContainer::-webkit-scrollbar-thumb {
  background: #27aae1;
  border-radius: 2.5px;
}

/* Handle on hover */
.envTrackMainContainer::-webkit-scrollbar-thumb:hover,
.template-list::-webkit-scrollbar-thumb:hover,
.prodTrackMainContainer::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.envOperationTable {
  width: 100px !important;
  height: 35px !important;
  margin-top: 20px;
  float: right;
}

.envOperationTd {
  width: 30px !important;
  height: 30px !important;
  padding: 2px 10px !important;
  border-top: none !important;
  cursor: pointer;
}

.envOperationTd>input {
  display: none;
}

.envOperationTd img {
  width: 25px;
  cursor: pointer;
}

.envTrackBottomContainer {
  width: 100%;
  height: 700px;
}

/* common dropdown features for test and prod dropdown */

button[aria-expanded="false"] .trackProdTestArrow {
  position: absolute;
  right: 20px;
  top: 12px;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  /* content: url("../../assets/images/Polygon_white.png"); */
  background: url("../../assets/images/Polygon_white.png");
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

button[aria-expanded="true"] .trackProdTestArrow {
  position: absolute;
  right: 20px;
  top: 12px;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  /* content: url("../../assets/images/Polygon_darkblue.png"); */
  background: url("../../assets/images/Polygon_darkblue.png");
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

/* .charContainer::-webkit-scrollbar {
  width: 5px;
}

.charContainer::-webkit-scrollbar {
  width: 5px;
}

.charContainer::-webkit-scrollbar-thumb {
  background-color: #27aae1;
  border-radius: 10px;
  border: 3px solid #27aae1;
} */

/* char */

.charContainer {
  margin-top: 0px;
  width: 100%;
  overflow: hidden;
  /* height: 70vh; */
}

/* env */
button[aria-expanded="false"] .envZoomArrow {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  content: url("../../assets/images/white-arrow-down.png");
}

button[aria-expanded="true"] .envZoomArrow {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  content: url("../../assets/images/white-arrow-up.png");
}

.envAddMapbtn {
  margin-top: calc(50vh - 280px);
  margin-bottom: 20px;
  border: none;
  background: #26abe1;
  color: white;
  border-radius: 4px;
  width: 184px;
  height: 48px;
  font-size: 14px;
  font-family: "Roboto Medium";
}

.envAddMapbtn:hover {
  background: #19305a;
}

.envAnalyticsAddMapTitleContainer {
  width: 613px;
  height: 60px;
  background: #19305a;
  border-radius: 4px 4px 0px 0px;
  margin-left: -10px;
  line-height: 60px;
  color: white;
  font-family: "Roboto";
  font-size: 16px;
}

.envAnalyticsAddMapBottomContainer {
  width: 613px;
  height: 670px;
  padding-left: 20px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
}

.envAnalyticsAddMapText {
  font-family: "Roboto Medium";
  font-size: 14px;
  text-transform: uppercase;
  color: #19305a;
  text-align: left;
}

.envAnalyticsAddMapInput {
  height: 40px;
  width: 552px;
  margin-top: 10px;
  background: #fdfdfd;
  border: 2px solid #b3e0f2;
  box-sizing: border-box;
  box-shadow: 0px 0.446956px 0.893912px rgba(24, 39, 75, 0.12),
    0px 0.893912px 0.893912px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  padding-left: 10px;
  color: #19304a;
  font-size: 14px;
  font-family: "Roboto";
}

.envAnalyticsAddMapInput.focus,
.envAnalyticsAddMapInput:focus {
  box-shadow: none !important;
  border: 2px solid #1a2f5a !important;
  outline: none;
  border-radius: 4px;
}

.envAnalyticsAddMapContainer {
  height: 350px;
  margin-top: 30px;
  border: 2px dashed #b3e0f2;
  border-radius: 10px;
  text-align: center;
}

.envAnalyticsAddMapDropText {
  font-family: "Roboto";
  font-size: 16px;
  color: #19305a;
}

.envAnalyticsUploadMapInput {
  /* background-color: yellowgreen; */
  position: absolute;
  width: 548px;
  bottom: 109px;
  top: 265px;
  left: 32px;
}

#envAnalyticsUploadMapInput {
  display: inline-block;
}

#envAnalyticsUploadMapInput input[type="file"] {
  display: none;
}

.envAnalyticsUploadMapInputBtn {
  height: 345px;
  width: 546px;
  opacity: 0;
}

.MuiSlider-root {
  color: #19305a !important;
}

.envAnalyticsAddMapCropContainer {
  overflow: hidden;
  height: 404px;
  margin: auto;
  margin-top: -55px;
  width: 70%;
  background: #fdfdfd;
  box-shadow: 0px 1.2552px 4.3932px -0.941401px rgba(24, 39, 75, 0.12),
    0px 2.8242px 13.8072px -0.627601px rgba(24, 39, 75, 0.14);
  border-radius: 2.80833px;
}

.crop-container-mapview {
  width: 100%;
  height: 280px;
  position: relative;
  margin-bottom: 15px;
}

.controls {
  margin: auto;
  width: 55%;
  display: flex;
  align-items: center;
}

.slider {
  padding: 30px 0px;
}

.envAnalyticsAddMapNameText {
  font-family: "Roboto";
  font-size: 12px;
  color: #26abe1;
}

.envAnalyticsAddMapAgainText {
  font-family: "Roboto Bold";
  font-size: 16px;
  color: #19305a;
  text-decoration: underline;
}

.envAnalyticsUploadMapInputAgain {
  /* background-color: yellowgreen; */
  position: absolute;
  width: 548px;
  bottom: 109px;
  top: 265px;
  left: 32px;
}

#envAnalyticsUploadMapInputAgain {
  display: inline-block;
}

#envAnalyticsUploadMapInputAgain input[type="file"] {
  display: none;
}

.envdragDrop {
  position: absolute;
  top: 38%;
  left: 35%;
  width: 100px;
  font-family: "Roboto Medium";
  color: gray;
  font-size: 16px;
}

.envor {
  position: absolute;
  left: 60%;
  top: 38%;
  font-family: "Roboto Black";
  color: gray;
  font-size: 16px;
  font-weight: bold;
}

.envuploadbtn {
  position: absolute;
  right: 15%;
  top: 38%;
  width: 150px;
  height: 40px;
  border: 0px;
  background-color: white;
  border-radius: 30px;
  color: gray;
  font-family: "Roboto Medium";
  font-size: 16px;
}

.envshftInput {
  background-color: #f0f0f0;
  width: 100% !important;
  min-height: 35px;
  height: 100% !important;
  border-width: 0px;
  border-radius: 18px;
  color: #1a2f5a;
  margin-bottom: 10px;
  text-indent: 15px;
  font-family: "Roboto Medium";
  font-size: 22px;
}

.envshftInput:focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(39, 170, 225, 0.5);
}

/* test list and zone */

.env-test-table-responsive {
  display: block;
  width: 80%;
  max-height: 100px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.env-zone-table-responsive {
  display: block;
  width: 60%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.envTestTd {
  text-align: left !important;
  vertical-align: middle !important;
  width: 25%;
  cursor: pointer;
  border-top: none !important;
}

.envZoneTd {
  text-align: left !important;
  vertical-align: middle !important;
  width: 20%;
  cursor: pointer;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #27aae1;
}

/* The container */

.testCheckBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.testCheckBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.testCheckmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #27aae1;
}

/* On mouse-over, add a grey background color */

.testCheckBoxContainer:hover input~.testCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.testCheckBoxContainer input:checked~.testCheckmark {
  background-color: #27aae1;
}

/* Create the checkmark/indicator (hidden when not checked) */

.testCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.testCheckBoxContainer input:checked~.testCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.testCheckBoxContainer .testCheckmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* checkmark for zone section and swab */

.zoneCheckmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #27aae1;
  border-radius: 13px;
}

/* On mouse-over, add a grey background color */

.testCheckBoxContainer:hover input~.zoneCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.testCheckBoxContainer input:checked~.zoneCheckmark {
  background-color: #27aae1;
}

/* Create the checkmark/indicator (hidden when not checked) */

.zoneCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.testCheckBoxContainer input:checked~.zoneCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.testCheckBoxContainer .zoneCheckmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.testDropdownContainerRow {
  margin-left: 250px !important;
}

.testDropdownContainerRow .css-2b097c-container {
  min-width: 350px;
  margin-left: 20px;
}

.envChartdivContainer {
  width: 100%;
  overflow-y: auto;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.envTestDropdownContainerRow {
  margin-left: 0px !important;
  margin-bottom: 20px;
}

.envTestDropdownContainerRow .css-2b097c-container {
  min-width: 350px !important;
  margin-left: 0px !important;
}

.productTrackLeftRow {
  width: 200px;
  padding: 10px 0px !important;
  cursor: pointer;
}

.productTrackLeftRow:hover {
  background-color: #efefef;
}

.productTrackLeftRow:focus {
  background-color: #27aae1;
}

.viewSwablistBtn {
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #27aae1;
  border-radius: 20px;
  border: none;
  color: white;
  font-family: "Roboto Medium";
  font-size: 18px;
}

.templateShowSwabsBtn {
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  margin-top: 20px;
  background-color: #27aae1;
  border-radius: 20px;
  border: none;
  color: white;
  font-family: "Roboto Medium";
  font-size: 18px;
}

.react-calendar {
  width: 80% !important;
  height: fit-content;
}

.label-css {
  font-family: "Roboto Bold";
  font-style: normal;
  font-size: 19px;
  margin-left: 15px;
  line-height: 18px;
  color: #27aae1;
  text-align: left;
  margin-top: 5px;
}

.env-checkbox {
  position: absolute;
  top: 24px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.env-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.arrow-go {
  position: absolute;
  width: 36px;
  height: 36px;
  right: -12px;
  /* left: 10px; */
  /* top: 82px; */
  bottom: 300px;
  bottom: calc((100vh - 340px) / 2 - 35px);
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.arrow-default {
  position: absolute;
  width: 22px;
  height: 200px;
  left: 20%;
  top: 50%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 15px;
  text-align: center;
  z-index: 2;
  transform: translate(0, -50%);
  cursor: pointer;
  visibility: hidden;
}

/* .arrow-default:after{
    position: absolute;    
    top: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    content: "";
    border-radius: 50%;
    right: -15px;
    background: #fff;
    margin-top: -15px;
    border-left: 0;
    border-bottom: 0;
    border-top: 0;
  } */

.arrow-default>svg {
  position: absolute;
  top: 50%;
  z-index: 2;
  right: 0;
  transform: translate(0, -50%);
  right: -10px;
}

.react-datepicker-wrapper {
  margin-top: 10px;
}

.react-datepicker__input-container input {
  width: 120px !important;
  border: 1px solid !important;
  border-radius: 5px !important;
  margin-right: 10px;
  margin-left: 10px;
}

#env_product_container {
  position: relative;
  overflow: hidden;
  /* height: calc(100vh  - 100px); */
  /* height: calc(100vh  - 240px); */
  height: calc(100vh - 150px);
}

#env_product_container .envLeftSidebar {
  position: absolute;
}

#env_product_container .envTrackMainContainer {
  padding-left: 20%;
}

.rstm-tree-item-level0 {
  padding-left: 2px !important;
}

.rstm-tree-item-level1 {
  padding-left: 1rem !important;
}

.rstm-tree-item-level2 {
  padding-left: 2rem !important;
}

.rstm-tree-item-level3 {
  padding-left: 4rem !important;
}

.nodeWrapper[data-key$="$"] {
  display: none;
}

.nodeWrapper[data-key$="$"] .rstm-tree-item-level3,
.nodeWrapper[data-key$="$"]~.nodeWrapper.swabNode .rstm-tree-item-level3 {
  padding-left: 5rem !important;
}

.rstm-tree-item--active {
  color: #1a2e59;
  background-color: rgb(239, 239, 239);
  border-bottom: none;
}

/* swablist -, + btn */
.qty {
  text-align: center;
  margin-bottom: 10px;
}

.qty .count {
  color: #1a2e59;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 22px;
  padding: 0 2px;
  min-width: 35px;
  text-align: center;
}

.qty .plus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  margin-top: 2px;
}

.qty .minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
  margin-top: 2px;
}

.minus:hover {
  background-color: #717fe0 !important;
}

.plus:hover {
  background-color: #717fe0 !important;
}

/*Prevent text selection*/
.qty span {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.qty input {
  /* border: 0; */
  border-color: #27aae1;
  border-radius: 10px;
  width: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.qty input::-webkit-outer-spin-button,
.qty input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.qty input:disabled {
  background-color: white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.rstm-tree-item {
  list-style-type: none;
}

.swab-bttons {
  position: absolute;
  right: 10px;
  top: 15px;
  opacity: 0;
}

.nodeWrapper:hover .swab-bttons {
  opacity: 1;
  z-index: 99999;
}

.swab-bttons.swab-right-arrow {
  opacity: 1;
  z-index: 999;
}

.swab-bttons img {
  margin-left: 10px;
  display: inline-block;
}

/* pin card in slide menu */

.pinCardTitle {
  font-family: "Roboto Bold";
  font-size: 16px;
  color: #19305a;
  text-align: center;
  margin-top: 15px;
}

.pinCardDelete {
  float: right;
  margin-top: 2px;
  cursor: pointer;
}

.pinCardInputTitle {
  color: #1a2f5a;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.pinCardInput {
  width: 100%;
  height: 25px;
  background-color: white;
  border-width: thin;
  border-color: #1a2f5a;
  border-radius: 10px;
  color: #1a2f5a;
  font-size: 14px;
}

.pinCardInputTextarea {
  width: 100%;
  height: 100%;
}

.pinCardInputFreq {
  width: 100%;
  height: 25px;
  background-color: white;
  border-width: thin;
  border-color: #1a2f5a;
  border-radius: 10px;
  color: #1a2f5a;
  font-size: 14px;
  text-align: center;
}

.pinCardFreqUnit {
  text-align: left;
  padding-left: 0px !important;
  line-height: 25px;
  font-size: 14px;
  color: #1a2f5a;
}

button[aria-expanded="false"] .freqArrow {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  content: url("../../assets/images/Polygon_white.png");
}

button[aria-expanded="true"] .freqArrow {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  content: url("../../assets/images/Polygon_darkblue.png");
}

.pinCardSaveBtn {
  width: 80px;
  height: 25px;
  margin-left: 40px;
  background-color: #6fcf97;
  color: white;
  border-radius: 13px;
  border-style: none;
  font-family: "Roboto Bold";
  font-size: 14px;
}

.pinCardSaveBtn:hover {
  background-color: #63ba87;
}

.pinCardSaveBtn:active {
  background-color: #63ba87;
  transform: translateY(2px);
}

.pinCardSaveBtn:focus {
  border: none;
  outline: none;
}

.pinCardDeleteBtn {
  width: 80px;
  height: 25px;
  background-color: #eb5757;
  color: white;
  border-radius: 13px;
  border-style: none;
  font-family: "Roboto Bold";
  font-size: 14px;
  /* letter-spacing: 1px; */
}

.pinCardDeleteBtn:hover {
  background-color: #d34e4e;
}

.pinCardDeleteBtn:active {
  background-color: #d34e4e;
  transform: translateY(2px);
}

.pinCardDeleteBtn:focus {
  border: none;
  outline: none;
}

.envCardDropdownInactive {
  width: 110px;
  /* height: 70px; */
  height: 35px;
  border-radius: 5px;
  background-color: white !important;
  opacity: 0.5;
}

.msgOnCalendarShow {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #eb5757;
  font-size: 12px !important;
}

.msgOnCalendarHide {
  visibility: hidden;
}

.topSeparator {
  /* height: 70px; */
  height: 35px;
  border-left: 1px solid lightgray;
}

.envCardContainer {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none !important;
  background: #efefef !important;
}

.envHomecardContainerInactive {
  width: 110px;
  /* height: 70px; */
  height: 35px;
  background: #efefef !important;
  opacity: 0.5;
  border: none !important;
}

.envHomecardContainer {
  width: 110px;
  height: 35px;
  /* height: 70px; */
  background: #efefef !important;
  border: none !important;
  cursor: pointer;
}

/* .envHomecardContainer:active {
  background-color: lightgray !important;
} */

.envGraphcardContainer {
  width: 110px;
  height: 35px;
  /* height: 70px; */
  /* background: lightgray !important; */
  border: none !important;
  cursor: pointer;
}

/* .envGraphcardContainer:active {
background-color: lightgray !important;
} */

.envTrackToggleContainer {
  width: 145px;
  height: 35px;
  padding-left: 25px;
  padding-top: 2px;
  cursor: pointer;
  background: #efefef !important;
  border: none !important;
}

/* .envTrackToggleContainer:active {
  background-color: lightgray !important;
} */

/* .envCardContainer:active {
  background-color: lightgray !important;
} */

.envGraphcardContainerMap {
  background: #efefef !important;
}

.envCardImg {
  width: 30px;
  height: 30px;
  margin: auto;
}

/* env analytics top tabs */

.envCardTitle {
  height: 100%;
  width: 100%;
  /* margin-left: 45px; */
  font-size: 20px;
}

.envCardTitleUnderline {
  opacity: 0;
  height: 4px;
  background: #26abe1;
  margin-left: 44px;
  width: 50px;
  margin-top: 6px;
  border-radius: 8px;
}

.envTrackToggleTitle {
  padding-top: 1px;
  text-align: left;
  font-family: "Roboto Medium";
  font-size: 12px !important;
}

.imgDownArrow {
  width: 5px;
  height: 5px;
}

.nodeWrapper>li {
  display: block;
}

.nodeWrapper .rstm-tree-item {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.nodeWrapper .rstm-tree-item--active {
  background-color: rgb(239, 239, 239);
  color: #1a2e59;
  font-weight: 700;
  /* box-shadow: 0 0 5px 0 #222;*/
  box-shadow: none;
}

.visible-data-view {
  color: #27aae1;
  text-align: left;
  padding-top: 15px;
}

.date-range-wrapper {
  justify-content: center;
}

.swabNode.nodeWrapper .rstm-tree-item-level1 {
  padding-left: 3rem !important;
}

/* .rstm-tree-item {
  pointer-events: all!important;
}

.rstm-tree-item .rstm-toggle-icon{
  pointer-events: none!important;
} */

.coments-field {
  display: flex;
  padding: 5px;
  align-items: center;
  width: 100%;
  justify-self: space-between;
  overflow: hidden;
}

.coments-field textarea {
  min-width: 100%;
  width: 100%;
}

.coments-field:after {
  content: "";
  opacity: 0;
  width: 0;
}

.coments-field.saving:after {
  content: "Saving...";
  display: block;
  width: 70px;
  color: #27aae1;
  text-align: right;
  opacity: 1;
}

.coments-field * {
  transition: all 0.3s;
}

.coments-field.saving textarea {
  min-width: calc(100% - 100px);
  max-width: calc(100% - 100px);
  width: calc(100% - 100px);
}

.locationTrackList .nodeWrapper:hover>li {
  background-color: rgb(239, 239, 239);
}

.swab-panel>* {
  margin-bottom: 10px;
  text-align: left;
}

.swab-panel label {
  font-size: 15px;
  font-weight: 700;
  color: #1a2f5a;
}

.swab-panel .text-ellipsis,
.swab-panel h6 {
  font-size: 15px;
  color: #1a2f5a;
}

.swab-panel .img-box {
  border: 1px solid #1a2f5a;
  width: 65%;
  margin-left: 65px;
  text-align: center;
  padding: 10px;
}

.swab-panel .img-box>img {
  display: inline-block;
}

.swab-panel .envTrackResult label {
  flex: 1 0 50%;
  max-width: 50%;
}

.swab-panel .envTrackResult>div {
  width: 100%;
  padding: 0 15px;
}

.swab-panel .coments-field {
  padding: 0;
}

#fromDataPicker {
  position: relative;
}

#searchFilterDatePicker {
  position: relative;
}

.locationTrackList .nodeWrapper .rstm-toggle-icon {
  pointer-events: none;
}

g[role="switch"] tspan {
  font-weight: 600;
}

.resetMapZoom {
  float: right;
  height: 30px;
  margin-left: 10px;
  padding: 4px 15px;
  cursor: pointer;
  border: none;
  color: #fff;
  background-color: #19305a;
  border-radius: 15px;
  box-shadow: 0 2px #999;
  text-align: center;
  font-size: 16px;
}

.resetMapZoom:hover {
  background-color: #1d9cd1;
}

.resetMapZoom:active {
  background-color: #1d9cd1;
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}

.resetMapZoom:focus {
  border: none;
  outline: none;
}

.resetMapBtnIcon {
  width: 15px;
  height: 15px;
}

/* EnvTrack Side Bar Navigation Bar Styling */
.envLeftSidebar .MuiTypography-h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #19305a;
  margin-bottom: 36px;
}

.envLeftSidebar .button-add-location .MuiButton-contained {
  background: #fdfdfd;
  border: 2px solid #fdfdfd;
  box-sizing: border-box;
  box-shadow: 0px 0.446956px 0.893912px rgba(24, 39, 75, 0.12),
    0px 0.893912px 0.893912px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
}

.envLeftSidebar .button-add-location .MuiButton-root {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #19305a;
  text-transform: none;
}

.envLeftSidebar .button-add-location .MuiButton-iconSizeMedium>*:first-child {
  font-size: 20px;
  height: 15px;
}

.add-new-location-card .MuiCardContent-root {
  padding: 16px;
  padding-bottom: unset;
}

.add-new-location-card .MuiCardHeader-root {
  display: flex;
  padding: unset;
  align-items: center;
  background: #19305a;
  height: 46px;
}

.add-new-location-card .MuiCardHeader-root .MuiTypography-h5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #fdfdfd;
}

.add-new-location-card .MuiCardHeader-content {
  flex: 1 1 auto;
  text-align: center;
}

.add-new-location-card .MuiCardHeader-action {
  flex: 0 0 auto;
  align-self: flex-start;
  margin-top: 12px;
  margin-right: 8px;
  height: 20px;
  width: 20px;
}

.add-new-location-card .MuiCardHeader-action .MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: unset;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding-bottom: 6px;
  padding-left: 2px;
}

.add-new-location-card .MuiCardContent-root .MuiTypography-subtitle2 {
  /* font-size: 0.875rem; */
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* font-weight: 500; */
  /* line-height: 1.57; */
  /* letter-spacing: 0.00714em; */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #375393;
}

.add-new-location-card .MuiCardContent-root .input-style-content {
  background: url("../../assets/images/locationIcon2x.png") no-repeat;
  background-size: 15px;
  background-position: left 11px top 9px;
  border: 2px solid #b3e0f2;
  box-sizing: border-box;
  box-shadow: 0px 0.311328px 0.622656px rgb(24 39 75 / 12%),
    0px 0.622656px 0.622656px rgb(24 39 75 / 8%);
  border-radius: 2.78621px;
  width: 100%;
  height: 40px;
  padding-left: 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #19305a;
}

.add-new-location-card .MuiCardContent-root .input-style-content[type="text"]:focus {
  outline: none;
  border: 2px solid #375393;
}

.add-new-location-card .card-action-button-1 .MuiCardActions-root {
  display: unset;
  padding: 0px;
  align-items: center;
}

.add-new-location-card .card-action-button-1 .MuiTypography-button {
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: #19305a;
  padding-top: 16px;
}

.add-new-location-card .card-action-button-1 .MuiCardActions-root .MuiButton-root {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  background-color: #19305a !important;
  border-radius: 4px;
  align-items: center;
  color: #fdfdfd !important;
  padding: 5px;
  min-width: 0px;
  box-sizing: border-box;
  letter-spacing: unset;
  text-transform: none;
  margin-left: 20px !important;
  margin-top: 12px;
}

.add-new-location-card .card-action-button-2 .MuiCardActions-root {
  justify-content: flex-end;
  padding-right: 14px;
  margin-top: 29px;
  margin-bottom: 15px;
}

.add-new-location-card .card-action-button-2 .MuiCardActions-root .cancel-action-button-2 {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 8px 0px;
  width: 94px;
  background: #fdfdfd;
  border: 1px solid #26abe1 !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #26abe1;
}

.add-new-location-card .card-action-button-2 .MuiCardActions-root .add-action-button-2 {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 8px 0px;
  width: 94px;
  background-color: #19305a !important;
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #fdfdfd;
}

.add-new-location-card .card-action-button-1 .MuiCardActions-spacing> :not(:first-child) {
  margin-left: 0px;
}

.add-new-location-card .MuiCollapse-wrapperInner .MuiCardContent-root:last-child {
  padding-bottom: 13px;
}

.add-new-location-card .MuiCollapse-wrapperInner .MuiCardContent-root .MuiTypography-body1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #19305a;
  margin-bottom: 8px !important;
}

.add-new-location-card .MuiCollapse-wrapperInner .MuiCardContent-root .input-style-collapse-content {
  border: 2px solid #b3e0f2;
  box-sizing: border-box;
  box-shadow: 0px 0.311328px 0.622656px rgb(24 39 75 / 12%),
    0px 0.622656px 0.622656px rgb(24 39 75 / 8%);
  border-radius: 2.78621px;
  width: 100%;
  height: 40px;
  padding-left: 8px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #19305a;
}

.add-new-location-card .MuiCollapse-wrapperInner .MuiCardContent-root .invalid-input-style-collapse-content {
  border: 2px solid red;
  box-sizing: border-box;
  box-shadow: 0px 0.311328px 0.622656px rgb(24 39 75 / 12%),
    0px 0.622656px 0.622656px rgb(24 39 75 / 8%);
  border-radius: 2.78621px;
  width: 100%;
  height: 40px;
  padding-left: 8px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #19305a;
}

.add-new-location-card .MuiCollapse-wrapperInner .MuiCardContent-root .input-style-collapse-content[type="text"]:focus {
  outline: none;
  border: 2px solid #375393;
}

#transitions-popper {
  z-index: 100;
  transform: translate3d(100px, 431px, 0px) !important;
  width: 296px;
}

#transitions-popper2 {
  display: none;
  z-index: 0;
  transform: translate3d(100px, 431px, 0px) !important;
}

#transitions-popper-maps {
  z-index: 100;
  transform: translate3d(100px, 513px, 0px) !important;
  width: 296px;
}

#transitions-popper2-maps {
  display: none;
  z-index: 0;
  transform: translate3d(100px, 513px, 0px) !important;
}

/* #transitions-popper-threeDots{
  z-index: 100;
  transform: translate3d(264px, 373px, 0px) !important;
} */
#transitions-popper-threeDots2 {
  display: none;
}

/* #transitions-popper-addLocation{
  z-index: 100;
  transform: translate3d(264px, 376px, 0px) !important;
  width: 13%;
  min-width: 324px;
} */
#transitions-popper-addLocation2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 376px, 0px) !important;
}

/* #transitions-popper-editLocation{
  z-index: 100;
  transform: translate3d(264px, 376px, 0px) !important;
  width: 13%;
  min-width: 324px;
} */
#transitions-popper-editLocation2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 376px, 0px) !important;
}

/* #transitions-popper-deleteLocation{
  z-index: 100;
  transform: translate3d(264px, 376px, 0px) !important;
  width: 13%;
  min-width: 296px;
} */
#transitions-popper-deleteLocation2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 376px, 0px) !important;
}

#transitions-popper-maps-threeDots {
  z-index: 100;
  transform: translate3d(264px, 445px, 0px) !important;
}

#transitions-popper-maps-threeDots2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 445px, 0px) !important;
}

#transitions-popper-maps-addMaps {
  z-index: 100;
  transform: translate3d(264px, 445px, 0px) !important;
  width: 13%;
}

#transitions-popper-maps-addMaps2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 445px, 0px) !important;
}

#transitions-popper-maps-editMaps {
  z-index: 100;
  transform: translate3d(264px, 445px, 0px) !important;
  width: 13%;
}

#transitions-popper-maps-editMaps2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 445px, 0px) !important;
}

#transitions-popper-maps-deleteMaps {
  z-index: 100;
  transform: translate3d(264px, 445px, 0px) !important;
  width: 13%;
  min-width: 296px;
}

#transitions-popper-maps-deleteMaps2 {
  display: none;
  z-index: 0;
  transform: translate3d(264px, 445px, 0px) !important;
}

/* #transitions-popper-mapData-threeDots{
  z-index: 100;
  transform: translate3d(308px, 586px, 0px) !important;
  width: 13%;
}
#transitions-popper-mapData-threeDots2{
  display: none;
  z-index: 0;
  transform: translate3d(308px, 586px, 0px) !important;
  
} */

/* SideNavigationButton */

.envLeftSidebar .drop-down-side-navigation .MuiButton-contained {
  background: #fdfdfd;
  border: 2px solid #fdfdfd;
  box-sizing: border-box;
  box-shadow: 0px 0.446956px 0.893912px rgb(24 39 75 / 12%),
    0px 0.893912px 0.893912px rgb(24 39 75 / 8%);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #26abe1;
  text-transform: unset;
}

.envLeftSidebar .drop-down-side-navigation .button-add-maps {
  background: #eff2f3;
  box-shadow: 0px 1.34087px 2.68174px -1.34087px rgba(24, 39, 75, 0.12),
    0px 1.78782px 5.36347px -0.893912px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #19305a;
  border: none;
}

.envLeftSidebar .drop-down-side-navigation .button-add-maps-new {
  background: #eff2f3;
  box-shadow: 0px 1.34087px 2.68174px -1.34087px rgba(24, 39, 75, 0.12),
    0px 1.78782px 5.36347px -0.893912px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #19305a;
  border: none;
  width: 154px;
  height: 42.95px;
  margin-top: 32px;
}

.envLeftSidebar .drop-down-side-navigation .button-add-details2map {
  background-color: #19305a;
  border-radius: 2.34783px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12.3261px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fdfdfd;
  width: 100px;
  box-sizing: border-box;
  box-shadow: none;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 6px;
}

/*pin*/
.envAnalyticsPinText {
  font-family: "Roboto Medium";
  font-size: 14px;
  text-transform: uppercase;
  color: #19305a;
  text-align: left;
}

.envAnalyticsPinInput {
  height: 35px;
  width: 330px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
  background: #fdfdfd;
  border: 2px solid #b3e0f2;
  box-sizing: border-box;
  box-shadow: 0px 0.446956px 0.893912px rgba(24, 39, 75, 0.12),
    0px 0.893912px 0.893912px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  color: #19304a;
  font-size: 14px;
}

.envAnalyticsPinFreqInput {
  height: 35px;
  width: 48px;
  margin: 10px;
  background: #eff2f3;
  border-radius: 4px;
  border: none;
  color: #19304a;
  font-size: 14px;
  text-align: center;
}

.dropdownToggleEnvPin {
  height: 33px;
  width: 326px;
  text-align: left !important;
  font-size: 14px;
  color: #19305a !important;
  background: #fdfdfd !important;
}

button[aria-expanded="false"] .envPinArrow {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  content: url("../../assets/images/lightblue-arrow-down.png");
}

button[aria-expanded="true"] .envPinArrow {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  content: url("../../assets/images/darkblue-arrow-up.png");
}

.dropdownEnvPinFreq {
  width: 135px;
}

.dropdownToggleEnvPinFreq {
  width: 130px;
}

.sectionDetailEmEmpty {
  background-color: #758ab9;
  width: 32px;
  height: 32px;
  color: white;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  font-family: "Roboto Medium";
  margin-bottom: 10px;
  border-radius: 4px;
}

.sectionDetailSelected {
  background-color: #19305a;
  width: 32px;
  height: 32px;
  color: white;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  font-family: "Roboto Medium";
  margin-bottom: 10px;
  border-radius: 4px;
}

.sectionDetailWithContent {
  background-color: #26abe1;
  width: 32px;
  height: 32px;
  color: white;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  font-family: "Roboto Medium";
  margin-bottom: 10px;
  border-radius: 4px;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus>.MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.envCustomThExcluded {
  position: sticky;
  top: 0px;
  background-color: #758ab9;
  border-top: 0 !important;
  border-bottom: 0 !important;
  text-align: left;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #fdfdfd;
}

.envCustomThExcluded a {
  color: #375393;
}

.envCustomThExcluded:before {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  /* height: 1px; */
  /* background: #dee2e6; */
  background-color: #eff2f3;
  content: "";
  display: block;
}

.envCustomThExcluded:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 2px; */
  /* background: #dee2e6; */
  background-color: #eff2f3;
  content: "";
  display: block;
}

.envCustomTh {
  position: sticky;
  top: 0px;
  background-color: #eff2f3;
  border-top: 0 !important;
  border-bottom: 0 !important;
  text-align: center;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #375393;
}

.envCustomTh a {
  color: #375393;
}

.envCustomTh:before {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  /* height: 1px; */
  /* background: #dee2e6; */
  background-color: #eff2f3;
  content: "";
  display: block;
}

.envCustomTh:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 2px; */
  /* background: #dee2e6; */
  background-color: #eff2f3;
  content: "";
  display: block;
}

.envEditingThTitle input:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

.envEditingThTitle input:focus {
  border: 2px solid #26abe1;
  border-radius: 3px;
}

.envCustomDownloadTd {
  /* vertical-align: middle !important; */
  text-align: center;
  /* width: 8%; */
  /* min-width: 140px; */
}

/* Spec Button */
button.envSpecSelected,
button.envSpecNotSelected {
  margin-left: 12px;
}

button.envSpecSelected,
button.envSpecNotSelected,
button.envFilterButton {
  height: 32px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #f9f9f9;
  border: 2px solid transparent;
  text-transform: none;
  /* margin-right: 12px; */
}

button.envSpecSelected {
  background-color: #eff2f3 !important;
  border: 2px solid #26a1d2;
  border-radius: 3px;
}

.envReportStatusCategory {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #19305a;
}

/* Specs Block */
/* The slider */
.envReportSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eff2f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.envReportSlider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  /* bottom: 4px; */
  /* bottom: 1px; */
  top: 2px;
  background-color: #afbdca;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.envReportSlider {
  background-color: #1ec04a;
}

/* input:focus + .envReportSlider {
  box-shadow: 0 0 1px #1EC04A;
} */

input:checked+.envReportSlider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  background-color: white;
}

/* Rounded sliders */
.envReportSlider.round {
  border-radius: 8px;
}

.envReportSlider.round:before {
  border-radius: 50%;
}

.envReportSliderContainer {
  display: flex;
  margin-top: 30px;
  margin-right: 25px;
}

.envRetestSpan {
  color: #19305a;
  font-family: "Roboto Medium";
  font-size: 12px;
  /* margin-right: 5px; */
}

.envRetestSpan2 {
  font-family: Roboto Medium;
  font-size: 10px;
  color: #19305a;
  cursor: pointer;
  /* z-index: 200; */
}

.envRetestSpan2:hover {
  color: #26a1d2;
}

/* The switch - the box around the slider */
.envReportSwitch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}

/* Hide default HTML checkbox */
.envReportSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.envSpecCategoryIcon {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
}

.envSpecCategory {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #375393;
  padding-left: 8px;
  margin-left: 12px;
  padding-bottom: 8px;
}

.EnvSearchContainer {
  display: flex;
  align-items: center;
  height: 40px;
}

.EnvSearch__Bar {
  height: 35px;
  display: flex;
  align-items: center;
  position: relative;
}

.EnvSearch__MagnifyIcon {
  width: 13.54px;
  height: 13.54px;
  margin: auto;
  position: absolute;
  left: 8.33%;
  right: 7.02%;
  top: 8.33%;
  bottom: 7.02%;
}

.ant-tabs-tab .Environment__AnalyticsIcon .invertColor {
  fill: #f2f2f2;
}

.ant-tabs-tab.ant-tabs-tab-active .Environment__AnalyticsIcon .invertColor {
  fill: #375393;
}