.ProductAnalytics__SelectionCard {
  border-radius: 8px;
  border: 1px solid #e1e9f1;
  background: #fdfdfd;
  width: 100%;
  max-height: 104px;
  padding: 6px;
  position: relative;
  cursor: pointer;
}

.ProductAnalytics__SelectionCard:hover {
  background-color: #eaf0f5;
}

/* Selected styling */
.ProductAnalytics__SelectionCard--focused {
  border-radius: 8px;
  border: 2px solid #26a1d2;
  background: #eaf0f5;
  width: 100%;
  max-height: 104px;
  padding: 6px;
  position: relative;
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.ProductAnalytics__SelectionCard__CloseIcon {
  width: 16px;
  height: 16px;
  padding: 3.75px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  flex-shrink: 0;
}

/* Disabled styling*/
.ProductAnalytics__SelectionCard--disabled {
  border-radius: 8px;
  border: 1px solid #f6f8fa;
  background: #fdfdfd;
  width: 100%;
  max-height: 104px;
  padding: 6px;
  pointer-events: none;
}

.ProductAnalytics__SelectionCard--disabled
  span:not(.Analytics__SelectionCard__ValueText--highlighted) {
  color: #c4d2df;
}

/* Content styling*/
.ProductAnalytics__SelectionCard__Content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 90px;
}

.ProductAnalytics__SelectionCard--focused
  .ProductAnalytics__SelectionCard__Content {
  max-height: 88px;
}

.ProductAnalytics__SelectionCard__Content > span {
  word-break: break-all;
}

.Analytics__SelectionCard__ValueText {
  color: #6c7e8e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.Analytics__SelectionCard__ValueText--highlighted {
  color: #26a1d2;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background-color: unset;
  padding: 0;
}

/** Scrollbar styling **/
.ProductAnalytics__SelectionCard__Scrollbar.ScrollbarsCustom {
  max-height: inherit;
}

.ProductAnalytics__SelectionCard__Scrollbar .ScrollbarsCustom-Wrapper,
.ProductAnalytics__SelectionCard__Scrollbar .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

/* Padding added when scrollbar visible */
.analyticsMainContainer
  .ProductAnalytics__SelectionCard__Scrollbar.ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Content {
  padding-left: 0px !important;
  padding-right: 6px !important;
}

.ProductAnalytics__SelectionCard__Scrollbar
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  width: 2px !important;
}

#analyticsScrollbar
  .ProductAnalytics__SelectionCard__Scrollbar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 2px !important;
  background-color: #c4d2df !important;
}
