/* Fix bug where clicking anywhere to the left of the radio button still checked it */
.StyledRadioGroup [type="radio"]:checked,
.StyledRadioGroup [type="radio"]:not(:checked) {
  left: 0;
}

/* Disable wave animation */
.StyledRadioGroup .ant-wave.wave-motion-appear-active {
  display: none;
}

/* Text styling */
.StyledRadioGroup label.ant-radio-wrapper {
  color: #758ab9;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 20px;
  padding: 4px 0px 4px 2px;
}

.StyledRadioGroup label.ant-radio-wrapper.ant-radio-wrapper-checked {
  color: #26abe1;
}

/** Button styling **/

/* Unchecked */
.StyledRadioGroup .ant-radio-wrapper .ant-radio-inner {
  width: 14px;
  height: 14px;
  border: 1.5px solid #758ab9;
  background-color: transparent;
}

.StyledRadioGroup .ant-radio-wrapper:hover .ant-radio-wrapper,
.StyledRadioGroup .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #26abe1;
}

/* Checked */
.StyledRadioGroup .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #26abe1;
  background-color: transparent;
}

.StyledRadioGroup
  .ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: #26abe1;
  transform: scale(0.45);
}

.StyledRadioGroup .ant-radio-wrapper .ant-radio-checked::after {
  border-color: #26abe1;
}
