.sample-submission-background {
  position: fixed;
  width: 100vw;
  height: calc(100% - 50px);
  /* top: 136px; */
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
  font-family: "Roboto medium";
  font-style: normal;
}

.sample-submission-background.logged-out {
  height: calc(100% - 56px);
  top: 56px;
}

.sample-submission-background td {
  word-break: break-word;
}

.submission-details-modal-container .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY,
.SubmissionReceiptModal .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY,
.sample-submission-background .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #c4d2df !important;
}

.sample-submission-background.main {
  padding: 27px 34px 36px 34px;
}