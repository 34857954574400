
.FilterButtons {
  height: 40px;
  display: flex;
  gap: 8px;
}

.FilterButtons__Text {
  font-family: 'Roboto Medium';
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.015em;
  color: #19305A;
}

.FilterButtons__Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  background: #FFFFFF;
  border-radius: 4px;
  flex-grow: 1;
  cursor: pointer;
}

.FilterButtons__Btn:hover .FilterButtons__Text,
.FilterButtons__Btn--selected .FilterButtons__Text{
  color: #26ABE1;
}

.FilterButtons__Btn--selected {
  outline: 2px solid #26A1D2;
  background-color: #EFF2f3;
}

.FilterButtons__GrayOutBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  background: #FFFFFF;
  border-radius: 4px;
  flex-grow: 1;
  opacity: 0.5;
  cursor: default;
}

.FilterButtons__GrayOutBtn--selected {
  outline: 2px solid #26A1D2;
  background-color: #EFF2f3;
}