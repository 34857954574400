.SubmissionsSearch {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  max-width: 100%;
  flex: 1 1 auto;
  justify-content: space-between;
}

.SubmissionsSearch__Filters_SearchDiv_Filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  flex: 1 1 auto;
  justify-content: flex-start;
}

/* Input styling */
.SubmissionsSearch__Input {
  width: 280px;
  flex: 0 1 auto;
}

.SubmissionsSearch__Input .ant-input {
  border: 1px solid #e1e9f1;
  background: #f6f8fa;
}

.SubmissionsSearch__Input input.ant-input {
  color: #111a24;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.SubmissionsSearch__Input .ant-input::placeholder {
  color: #6c7e8e;
  font-family: Roboto;
  font-size: 14px;
}

.SubmissionsSearch__Input.ant-input-search .ant-input:hover,
.SubmissionsSearch__Input.ant-input-search .ant-input:focus {
  border-color: #77ccee;
  box-shadow: unset;
}

.SubmissionsSearch__Input.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #506375;
}

.SubmissionsSearch__Input.ant-input-search .ant-input-search-button {
  border: 1px solid #e1e9f1;
}

.SubmissionsSearch__Input.ant-input-search .ant-input-search-button:hover {
  border: 1px solid #77ccee;
}

.SubmissionsSearch__Input.ant-input-search .ant-input:hover+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.SubmissionsSearch__Input.ant-input-search .ant-input:focus+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-inline-start-color: #77ccee;
}

/* Disable wave animation */
.SubmissionsSearch__Input.ant-input-search .ant-input-search-button .ant-wave.wave-motion-appear-active {
  display: none;
}

/* Date filter styling */
.SubmissionsSearch__DatePicker {
  flex: 1 2 auto;
  min-height: 40px;
  max-width: fit-content;
}

.SubmissionSearch__Filters {
  width: 100%;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #e1e9f1;
  background: #f6f8fa;
  margin-bottom: -12px;
  position: relative;
  overflow: hidden;
}

.SubmissionSearch__Filters_SearchDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  flex-direction: column;
}

.SubmissionSearch__Filters_SearchDiv--show-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-bottom: 12px;
}

.SubmissionSearch__Filters>.StyledRadioGroup label.ant-radio-wrapper {
  color: #6c7e8e;
}

/* Submitted by search */
.ant-select.SubmissionsSearch__SubmittedBySelect {
  width: 142px;
  height: 40px;
}

.ant-select.SubmissionsSearch__SubmittedBySelect>.ant-select-selector {
  border: 1px solid #e1e9f1;
  background: #f6f8fa;
  color: #111a24;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ant-select.SubmissionsSearch__SubmittedBySelect .ant-select-selection-placeholder {
  color: #6c7e8e;
}

.ant-select.SubmissionsSearch__SubmittedBySelect:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover>.ant-select-selector,
.ant-select.SubmissionsSearch__SubmittedBySelect.ant-select-focused:not(.ant-select-customize-input):not(.ant-pagination-size-changer)>.ant-select-selector {
  border-color: #77ccee;
  box-shadow: none;
}

.ant-select.SubmissionsSearch__SubmittedBySelect .ant-select-arrow .anticon svg {
  color: #6c7e8e;
}

.SubmissionSearch__Filters_Filter_Buttons {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: start;
  color: #6c7e8e;
  margin-right: 40px;
}

.SampleSubmission__SearchType .ant-input-group-addon {
  background: #26ABE1;
  border: 1px solid #26ABE1;
}

.SampleSubmission__SearchType .ant-input-group-addon .ant-select-selector {
  width: 110px;
}

.SampleSubmission__SearchType .ant-input-group-addon .ant-select-selector::after {
  color: #ffffff !important;
}

.SampleSubmission__SearchType .ant-input-group-addon .ant-select-selection-item {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-align: left;
}

.SampleSubmission__SearchType .ant-input-outlined {
  border-color: #e1e9f1;
  background: #f6f8fa;
}

.SampleSubmission__SearchType .ant-input-outlined:hover {
  border-color: #77ccee;
}

.SampleSubmission__SearchType .ant-input-outlined:focus-within {
  box-shadow: none;
  border-color: #77ccee;
}

.SampleSubmission__SearchType .ant-input-outlined:focus-within .ant-input::placeholder {
  visibility: hidden;
}

.SubmissionsSearch__InputSelect__DropdownList.ant-select-dropdown {
  padding: 0;
  background-color: #ffffff;
  width: 160px !important;
}

.SubmissionsSearch__InputSelect__DropdownList .ant-select-item-option .ant-select-item-option-content {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.SubmissionsSearch__InputSelect__DropdownList .ant-select-item-option-selected .ant-select-item-option-content {
  color: #26ABE1;
}

.SubmissionsSearch__InputSelect__DropdownList .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: transparent !important;
}

.SubmissionsSearch__InputSelect__DropdownList .ant-select-item-option:not(.ant-select-item-option-disabled):hover {
  background-color: #ffffff;
}

.SubmissionsSearch__InputSelect__DropdownIcon {
  color: white;
}

.SubmissionsSearch__DatePicker {
  border: 1px solid #e1e9f1;
}


.SubmissionsSearch__DatePicker:hover {
  border-color: #77ccee;
  box-shadow: none;
}