.COADeleteModal.ant-modal .ant-modal-content {
  width: 368px;
  height: 200px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 16px;
  /* background-color: #fdfdfd; */
  display: flex;
  justify-content: center;
  padding-top: 36px;
}

.COADeleteModal .deleteModalSpanContainer {
  gap: 4px;
}

.COADeleteModal .deleteModal {
  gap: 25px;
  position: unset;
  box-shadow: unset;
  padding: unset;
  border-radius: unset;
}
