.EnvAnalyticsHeader__Header {
  width: calc(50% - 4px);
  background-color: #ffffff;
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #eaf0f5;
}

.EnvAnalyticsHeader__MainHeading {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  align-self: flex-start;
}

.EnvAnalyticsHeader__Dashboard {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.EnvAnalyticsHeader__Spec {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid;
}

.EnvAnalyticsHeader__Heading {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.EnvAnalyticsHeader__Samples {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}

@media (max-width: 836px) {
  .EnvAnalyticsHeader__Header {
    width: 100%;
  }

  .EnvAnalyticsHeader__Spec {
    width: 100%;
  }
}

@media (max-width: 465px) {
  .EnvAnalyticsHeader__Dashboard {
    flex-direction: column;
    width: 100%;
  }
}
