.MonthFrequencyForm {
  display: flex;
}

.MonthFrequencyForm .Calendar__Grid {
  margin-right: 80px;
  padding: 12px 16px;
  border: 1px solid #EAF0F5;
  border-radius: 12px;
}

.MonthFrequencyForm .Calendar__Grid .FieldTitle {
  display: inline-block;
  margin-bottom: 12px;
}

.MonthFrequencyForm__RecurrenceText {
  margin-right: 24px;
}

.MonthFrequencyForm__MonthGrid {
  gap: 12px !important;
}

.MonthFrequencyForm__MonthGrid .WeekMonthCheckbox__WeekDay {
  border-color: transparent;
  color: #000000;
  font-family: "Roboto";
  font-weight: 400;
}

.MonthFrequencyForm .FieldTitle.ExtraWidth {
  display: inline-block;
  min-width: 70px;
}