.customSelectContainer {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.customSelectContainer > select {
  display: none;
}

.NativeSelect__Option--disabled {
  display: none;
}

.signup-invite-display-input .customSelectInput,
.DropdownFieldInput .customSelectInput {
  width: 100%;
  height: 100%;
}

.customSelectInput.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.customSelectInput.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  overflow: hidden;
  border-radius: 4px;
  border: 2px solid #d7dae1;
}

.customSelectInput.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #d7dae1;
}

.customSelectInput.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  display: none;
}

.ant-select-focused.customSelectInput.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #26ABE1;
  box-shadow: none;
}

.customSelectInput__Menu.ant-select-dropdown {
  width: fit-content !important;
}
