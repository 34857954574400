.docOptionMenu .MuiList-padding {
  padding: 0px;
}

.docOptionIcon {
  height: 13.33px;
  margin-right: 11.33px;
}

.docOptionText {
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  color: #19305A;
}

.DocumentsMenu__OptionContainer {
  display: flex;
  width: 153px;
}