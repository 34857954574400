.ProductReportTableActions {
  gap: 30px;
  display: flex;
  justify-content: center;
}

/** Default styling **/
.ProductReportTableActions__PreviewIcon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  flex-shrink: 0;
}

.ProductReportTableActions__PreviewIcon {
  fill: #26abe1;
}

.ProductReportTableActions__DownloadIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 0;
  fill: #26abe1;
}

.ProductReportTableActions__ShareIcon {
  width: 16px;
  height: 14px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 0;
  fill: #26abe1;
}

/** Hover styling **/
.ProductReportTableActions__PreviewIcon:not(:active):hover {
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 1.5px 2px 0px rgba(9, 30, 66, 0.25);
  background-color: #26abe1;
}

/* Change play icon (just the triangle) color on hover */
.ProductReportTableActions__PreviewIcon:not(:active):hover > path:last-of-type {
  fill: white;
}

.ProductReportTableActions__DownloadIcon:not(:active):hover,
.ProductReportTableActions__ShareIcon:not(:active):hover {
  filter: drop-shadow(0px 1px 1px rgba(9, 30, 66, 0.25));
}

/** Mouse Down styling **/
.ProductReportTableActions__PreviewIcon:active:hover {
  background-color: #19305a;
  fill: #19305a;
}

/* Change play icon (just the triangle) color on mouse down */
.ProductReportTableActions__PreviewIcon:active:hover > path:last-of-type {
  fill: #26abe1;
}

.ProductReportTableActions__DownloadIcon:active:hover {
  fill: #19305a;
}

.ProductReportTableActions__ShareIcon:active:hover {
  fill: #19305a;
}

/** Disabled styling **/
.ProductReportTableActions__PreviewIcon--disabled {
  width: 14px;
  height: 14px;
  cursor: default;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: rgba(215, 218, 225, 1);
}

.ProductReportTableActions__DownloadIcon--disabled {
  margin-top: auto;
  margin-bottom: auto;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.ProductReportTableActions__ShareIcon--disabled {
  margin-top: auto;
  margin-bottom: auto;
  width: 16px;
  height: 14px;
  flex-shrink: 0;
}

.ProductReportTableActions__PreviewIcon--disabled path:first-of-type,
.ProductReportTableActions__DownloadIcon--disabled path,
.ProductReportTableActions__DownloadIcon--disabled rect,
.ProductReportTableActions__ShareIcon--disabled path {
  fill: rgba(215, 218, 225, 1);
}

/* Play icon (just the triangle) white when disabled */
.ProductReportTableActions__PreviewIcon--disabled path:last-of-type {
  fill: white;
}

/* In Process styling */
.ProductReportTableActions__InProcess {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductReportTableActions__InProcess > span {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.ProductReportTableActions__InProcess > img {
  height: 10px;
  margin-bottom: 2px;
  margin-right: 9px;
}
