.SectionInputDraggable {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: default !important;
  border-radius: 6px;
  position: relative;
  margin-bottom: 32px;
}

.SectionInputDraggable--alignStart {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: default !important;
  border-radius: 6px;
  position: relative;
}

.TemplateBuilder__Input.SectionInput__Datafield,
.TemplateBuilder__Input--withLabel.SectionInput__Datafield {
  color: #26abe1;
}
