.site-layout .site-layout-background {
  background: #fff;
}

.AccountMenuDropdown.ant-dropdown:not(.ant-dropdown-hidden) {
  min-width: auto !important;
}

.ant-layout-header {
  height: 0 !important;
  padding: 0 !important;
  color: white !important;
  line-height: 4px !important;
  background: #ffffff !important;
}

.ant-menu {
  background: #ffffff !important;
}

.sideBarMenuWrapper .ant-menu-title-content {
  font-family: "Roboto Medium";
  font-size: 16px;
}

/* padding between each item */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0px 40px 0px 25px !important;
}

/* padding from item title to background image */
.sideBarMenuWrapper .ant-menu-item {
  opacity: 1 !important;
  order: 1 !important;
  padding-left: 25px !important;
}

.sideBarMenuWrapper .ant-men {
  text-align: none !important;
}

.sideBarMenuWrapper
  .ant-menu-horizontal:not(.ant-menu-dark)
  .ant-menu-item:hover::after {
  border-bottom: 3px solid #26abe1 !important;
  border-radius: 3px;
}

.sideBarMenuWrapper
  .ant-menu-horizontal:not(.ant-menu-dark)
  .ant-menu-item-selected::after {
  border-bottom: 3px solid #26abe1 !important;
  border-radius: 3px;
}

.sideBarMenuWrapper
  .ant-menu-horizontal:not(.ant-menu-dark)
  .ant-menu-item::after {
  border-bottom: transparent;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  bottom: 5px !important;
}

.ant-menu-horizontal > .ant-menu-submenu::after {
  right: 36px !important;
  left: 24px !important;
  border-bottom: 3px solid #26abe1 !important;
  border-radius: 3px;
}

.ant-menu-horizontal > .ant-menu-item::after {
  right: 40px !important;
  left: 3px !important;
}

/* default navigation bar item image */
.sideBarMenuWrapper {
  margin: auto 0px;
}
.sideBarMenuWrapper .ant-menu-item[id="1"] {
  background: url("../../assets/images/navbar/sampleSubmissionInactive.svg")
    no-repeat;
  color: #6c7e8e;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="2"] {
  background: url("../../assets/images/navbar/productInactive.svg") no-repeat;
  color: #6c7e8e;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="3"] {
  background: url("../../assets/images/navbar/envInactive.svg") no-repeat;
  color: #6c7e8e;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="4"] {
  background: url("../../assets/images/navbar/indexInactive.svg") no-repeat;
  color: #6c7e8e;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="5"] {
  background: url("../../assets/images/navbar/documentInactive.svg") no-repeat;
  color: #6c7e8e;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="6"] {
  background: url("../../assets/images/navbar/cardlibInactive.png") no-repeat;
  color: #758ab9;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="7"] {
  background: url("../../assets/images/navbar/testreqInactive.png") no-repeat;
  color: #758ab9;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

/* hover navigation bar item image */
.sideBarMenuWrapper .ant-menu-item[id="1"]:hover {
  background: url("../../assets/images/navbar/sampleSubmissionHover.svg")
    no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="2"]:hover {
  background: url("../../assets/images/navbar/productHover.svg") no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="3"]:hover {
  background: url("../../assets/images/navbar/envHover.svg") no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="4"]:hover {
  background: url("../../assets/images/navbar/indexHover.svg") no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="5"]:hover {
  background: url("../../assets/images/navbar/documentHover.svg") no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="6"]:hover {
  background: url("../../assets/images/navbar/cardlibHover.png") no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item[id="7"]:hover {
  background: url("../../assets/images/navbar/testreqHover.png") no-repeat;
  color: #375393 !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

/* active navigation bar item image */
.sideBarMenuWrapper .ant-menu-item-selected[id="1"] {
  background: url("../../assets/images/navbar/sampleSubmissionActive.svg")
    no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item-selected[id="2"] {
  background: url("../../assets/images/navbar/productActive.svg") no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item-selected[id="3"] {
  background: url("../../assets/images/navbar/envActive.svg") no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item-selected[id="4"] {
  background: url("../../assets/images/navbar/indexActive.svg") no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item-selected[id="5"] {
  background: url("../../assets/images/navbar/documentActive.svg") no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item-selected[id="6"] {
  background: url("../../assets/images/navbar/cardlibActive.png") no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.sideBarMenuWrapper .ant-menu-item-selected[id="7"] {
  background: url("../../assets/images/navbar/testreqActive.png") no-repeat;
  color: #19305a !important;
  background-size: 20px 20px;
  background-position: left 0px top 10px;
}

.NavBar__AccountContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: relative;
  cursor: pointer;
  align-items: center;
}

.NavBar__AccountInfo {
  display: flex;
  flex-direction: column;
}

.NavBar__AccountTitle {
  overflow: hidden;
  white-space: nowrap;
  max-width: 291px;
  text-overflow: ellipsis;
  color: #182534;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.NavBar__AccountCompany {
  overflow: hidden;
  white-space: nowrap;
  max-width: 291px;
  text-overflow: ellipsis;
  color: #375393;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.NavBar__Account--hover {
  color: #26abe1;
}

.myAccountUnderline {
  border-bottom: 3px solid #26abe1;
  border-radius: 3px;
  width: 100%;
}

.NavBar__NameAndDropdown {
  display: flex;
  flex-direction: column;
}

.NavBar__AccountIcon {
  width: 32px;
  height: 32px;
}

.NavBar__AccountIcon > path {
  fill: #6c7e8e;
}

.NavBar__AccountIcon--marginBottom {
  margin-bottom: 3px;
}

.NavBar__AccountIcon--marginRight {
  margin-right: 5px;
}

.NavBar__AccountIcon--box-shadow {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 7px rgba(9, 30, 66, 0.31);
}

.NavBar__AccountIcon--box-shadow-radius {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 7px rgba(9, 30, 66, 0.31);
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .sideBarMyaccountIcon {
    display: none;
  }
}

.SideBarSignOutIcon {
  width: 20px;
}

.SideBarBackIcon {
  margin-left: 20px !important;
  width: 30px;
}

.SideBarClientView {
  padding: 10px;
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  background: #fdfdfd;
}

.NavigationBar-Row {
  height: 60px;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid #eaf0f5;
  gap: 10px;
}

.NavigationBar-Col {
  background: white;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaf0f5;
}

.NavigationBar-Col__First {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 24px;
  margin-top: 4px; */
}

.NavigationBar-Col__First__Avatar {
  width: 50px;
  height: 50px;
  cursor: default;
  background-color: unset;
}

.NavBar__ESVLogo {
  width: 100%;
  height: 100%;
  vertical-align: unset;
}

.NavigationBar-Col__Second {
  text-align: center;
  height: 60px;
}

.NavigationBar-Col__Second__Body {
  width: 100%;
  line-height: 40px;
  margin-top: 9px;
}

.NavigationBar-Col__Second__Body__Menu {
  border-bottom: none !important;
  background: #fdfdfd;
}

.NavigationBar-Col__Third {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
}

.accountMenuContainer {
  display: flex;
  justify-content: right;
}

@media (max-width: 991px) {
  .NavBar__DropdownContainer_H1 {
    max-width: 96px;
  }
}

.icon {
  width: 18px;
  height: 18px;
}
