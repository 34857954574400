.headerContainer {
  display: flex;
  justify-content: space-between;
}

.searchContainer {
  display: flex;
  gap: 40px
}
.headerIndicator {
  width: 12px;
  height: 28px;
  background: #26abe1;
  border-radius: 4px;
}

.headerTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 28px;
  color: #111a24;
}

.HeaderTitle__Subtitle {
  color: #1F2D3B;
}

.HeaderTitle__Subtitle__COA {
  color: #AFBDCA;
}

.headerSpan {
  color: #111A24;
  font-family: 'Roboto Medium';
  font-size: 16px;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.TemplateHeader__BackIcon {
  cursor: pointer;
}