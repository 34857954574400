.DataFieldsButton{
  display: flex;
  margin-left: auto;
  width: fit-content;
  gap: 11px;
  background-color: #E6EEF5;
  border-radius: 6px;
  padding: 9px 13px;
  margin-bottom: 14px;
  color: #26ABE1;
  font-weight: 500;
  cursor: pointer;
}

.DataFieldsButton:hover {
  box-shadow: rgb(233, 232, 232) 1px 2px 7px 0px;
}

.DataFieldsButtonActive .DataFieldsIcon{ 
  filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(14%) hue-rotate(213deg) brightness(104%) contrast(104%);
}

.DataFieldsButtonActive{
  display: flex;
  margin-left: auto;
  width: fit-content;
  gap: 11px;
  background-color: #26ABE1;
  border-radius: 8px 8px 0 0;
  padding: 9px 13px 23px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
}