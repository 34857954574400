.reportSliderContainer {
  display: flex;
  margin-top: 30px;
  margin-right: 25px;
  padding-right: 10px;
  gap: 10px;
}

.RetestRequestsSwitch.ant-switch {
  width: 32px;
}

.productReportSpecManagerButtonCycleIcon {
  padding-left: 1px;
  padding-right: 1px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.productReportSpecManagerButtonContainer {
  margin-left: 24px;
  display: flex;
  align-items: flex-end;
}

.productReportSpecManagerButtonContainerEmptyDiv {
  height: 14px;
}

@media (max-width: 800px) {
  .productReportSpecManagerButtonContainer {
    align-items: flex-start;
  }
  .ProductReport__SpecManagerButton {
    margin-top: 22px;
  }
}

.SpecsBlock__SpecButtonBlock {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.SpecsBlockContainer {
  display: flex;
  justify-content: flex-end;
}

.specCategory {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #375393;
  padding-left: 8px;
  margin-left: 12px;
  padding-bottom: 8px;
}

.retestSpan {
  color: #19305a;
  font-family: "Roboto Medium";
  font-size: 12px;
  /* margin-right: 5px; */
}
