.Analytics__Search__OutsideClickContainer {
  width: 100%;
}

.Analytics__Search__InputContainer {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.Analytics__Search__Input {
  border-radius: 8px;
  width: 100%;
  padding-right: 10px;
  font-size: 14px;
}

.Analytics__Search__Input::placeholder {
  color: #6c7e8e;
  font-size: 14px;
}

.Analytics__Search__MagnifyingGlassContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  background-color: #e1e9f1;
  margin-left: -10px;
  border-radius: 0px 8px 8px 0px;
}

.Analytics__Search__MagnifyingGlassContainer:hover {
  cursor: pointer;
}

.Analytics__Search__MagnifyingGlass {
  height: 13.54px;
  width: 13.54px;
}

/* Product search-specific styles */
.Analytics__ProductSearch {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.Analytics__ProductSearch--show-filters {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.Analytics__ProductSearch__FilterIcon {
  display: flex;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
}

.Analytics__ProductSearch__Filters {
  width: 100%;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #e1e9f1;
  background: #f6f8fa;
  margin-bottom: -12px;
  position: relative;
  overflow: hidden;
}

.Analytics__ProductSearch__Filters__CloseIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  padding: 3px;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background: white;
  border: 1px solid #edf2f7;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
