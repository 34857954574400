.SignoutModalAlert.ant-modal .ant-modal-content {
  border-radius: 3px;
  padding: 16px 0px 24px;
}

.modalSubmitBtn {
  margin-top: 25px;
  padding: 10px 27px;
  width: 117px;
  border-radius: 5px;
  background-color: white;
  color: #203360;
  text-align: center;
  border: 1px solid #3ec6ff;
  font-size: 14px;
  margin-right: 20px;
  margin-left: 14px;
}

.modalSubmitBtn:hover {
  background: #3ec6ff;
  color: white;
}

.labelTextSignout {
  float: left;
  color: #203360;
}

.signoutModal {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.signoutModalTitle {
  font-family: Roboto Medium;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305a;
  margin-bottom: 16px;
  text-align: left;
}

.signoutModalTitleMessage {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
}

.signoutModalBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.signoutModalCancelBtn,
.signoutModalConfirmBtn {
  width: 70px;
}

.SignoutModalAlert {
  max-width: 480px !important;
  min-height: 200px !important;
  box-shadow: 0px 1.78782px 6.25738px -1.34087px rgba(24, 39, 75, 0.12),
    0px 4.0226px 19.6661px -0.893912px rgba(24, 39, 75, 0.14) !important;
  border-radius: 3px !important;
  background-color: #fdfdfd !important;
}
