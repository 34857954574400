.company-domain-select {
  display: none;
}

.support-login-company-domain {
  margin-bottom: 24px;
}

.support-login-password-input {
  width: 100%;
  color: #375393;
}

.support-login-login {
  width: 100%;
  text-align: center;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

/* Company Dropdown styling */
.ant-select.SupportLogin__Select {
  width: 100%;
  height: 41px;
}

.ant-select.SupportLogin__Select>.ant-select-selector {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c4d2df;
}

.ant-select.SupportLogin__Select>.ant-select-selector:focus-within {
  border: 1px solid #26abe1 !important;
}

.ant-select.SupportLogin__Select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover>.ant-select-selector,
.ant-select.SupportLogin__Select.ant-select-focused:not(.ant-select-customize-input):not(.ant-pagination-size-changer)>.ant-select-selector {
  border: 1px solid #26abe1 !important;
}

.ant-select.SupportLogin__Select>.ant-select-selector>.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  color: #19305a75;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
}

.ant-select.SupportLogin__Select>.ant-select-selector>.ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #19305a;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
}

.SupportLogin__Select__Popup .ant-select-item {
  font-family: "Roboto";
}

.SupportLogin__Select__Popup .ant-select-item {
  color: #758ab9;
}

.SupportLogin__Select__Popup .ant-select-item.ant-select-item-option {
  color: #375393;
}