.envConfirmDel {
  text-align: left;
  padding: 8px 16px 0px;
}

.envConfirmDelTitle {
  font-family: Roboto Bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305a;
  margin-bottom: 16px;
}

.envConfirmDelTitleMessage {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
}

.envConfirmDelTitleMessage__extra {
  margin-bottom: 24px;
}

.envConfirmDelBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.envModalCancelBtn {
  width: 74.6px;
  height: 32px;
  border: none;
  padding: 8px 12px;
  background: #eff2f3;
  border-radius: 3px;
  color: #19305a;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.envModalCancelBtn.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.envModalSubmitBtn {
  height: 32px;
  border: none;
  padding: 8px 12px;
  background: #26abe1;
  border-radius: 3px;
  color: #f9f9f9;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.envModalSubmitBtn.loading {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.7;
  pointer-events: none;
}

.envModalSubmitBtn.loading>svg {
  fill: #f9f9f9;
}

.envModalCancelBtn:hover,
.envModalSubmitBtn:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}