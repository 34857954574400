.TemplateBuilder__SectionCard:first-of-type {
  margin-top: 21px;
}

.TemplateBuilder__SectionCard:last-of-type {
  margin-bottom: 21px;
}

.TemplateBuilder__SectionCard {
  background: #ffffff;
  border: 1px solid #eaf0f5;
  /* box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08); */
  /* margin-left: 21px;
  margin-right: 21px; */
  border-radius: 8px;
  /* margin-top: 32px;
  margin-left: auto;
  margin-right: auto; */
  /* width: calc(100% - 6px); */
}

/* .sectionCard:nth-of-type(1) {
  margin-top: 52px;
} */

.TemplateBuilder__SectionCard.inEdit {
  border: 4px solid #bee6f6;
  /* box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12); */
}

/* Section Header */

.TemplateBuilder__SectionCard .TemplateBuilder__SectionHeader {
  /* height: 60px; */
  padding: 9px 12px 0;
  align-items: center;
  /* cursor: pointer; */
  border-radius: 16px;
}

.TemplateBuilder__SectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TemplateBuilder__SectionCard .TemplateBuilder__SectionHeader__Left {
  font-family: "Roboto Medium";
  display: flex;
  align-items: center;
  gap: 11px;
}

.TemplateBuilder__SectionCard .TemplateBuilder__SectionHeader__Title {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #6c7e8e;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 16px;
}

/* .sectionCard .TemplateBuilder__SectionHeader__Title div {
  position: absolute;
  left: 23px;
  bottom: -8px;
} */

.sectionCardUnderline {
  border-bottom: 2px solid #9dd9f2;
  width: 100%;
  border-radius: 4px;
}

.TemplateBuilder__SectionHeader__LockIcon {
  width: 14px;
  height: 14px;
}

.TemplateBuilder__SectionHeader__Chevron {
  width: 24px;
  height: 24px;
}

/* End Section Header */

.sectionContentContainer {
  padding: 20px 35px 20px;
  min-height: 233px;
  display: flex;
  gap: 50px;
  position: relative;
  width: 100%;
}

.sectionContentPlaceHolderText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  color: #afbdca;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 193px;
  flex-direction: column;
  margin: 10px 10px 70px 10px;
}

.builderDropInput {
  width: 100%;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #576268;
  background: #f7f7f8;
  border: 1px solid #e1e5e8;
  border-radius: 4px;
}

.builderDropInputSectionContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: flex-start;
  /* gap: 32px; */
  min-height: 233px;
  flex-grow: 1;
  padding: 10px;
  border-radius: 4px;
}

.builderDropInputSectionContainer > div {
  margin-bottom: 32px;
}

.sectionContentInputsOne,
.sectionContentInputsTwo {
  display: flex;
  width: 50%;
  min-height: 233px;
}
