.StatementCard__SignatureContainer {
  display: flex;
  width: 100%;
  height: 93px;
  margin-bottom: 23px;
}

.SignatureContainer__RightSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  margin-left: 38px;
}
