.analyticsGraphMainContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 40px 20px;
  overflow: visible;
  min-width: 500px;
}

.analyticsGraphContainerPlaceholderText {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  color: #6c7e8e;
}

.analyticsLineBarGraphContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  /* padding: 0px 26px 0px 24px; */
  padding: 0px 30px 0px 30px;
  position: relative;
}

/* .composed-graph-prev-button {
    left: 0;
    transform: rotate(180deg);
}

.composed-graph-next-button {
    right: 0;
} */

/* .composed-graph-prev-button, .composed-graph-next-button {
    position: absolute;
    border: none;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.11));
}

.composed-graph-prev-button:disabled img, .composed-graph-next-button:disabled img {
    opacity: 0.3;
}

.composed-graph-prev-button img,
.composed-graph-next-button img {
  width: 8px;
}

.composed-graph-buttons-container {
    display: flex;
    align-items: center;
} */

.analyticsGraphTooltipContainer {
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px;
  max-height: 500px;
  overflow: auto;

  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
}

.analyticsGraphTooltipMultipleValuesContainer {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  min-width: 200px;
}

.analyticsGraphTooltipAverageValueContainer {
  display: flex;
  justify-content: space-between;
  gap: 7px;
}

.analyticsGraphTooltipProductSelectedContainer {
  display: flex;
  justify-content: space-between;
}

.analyticsGraphTooltipText {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
}

.analyticsGraphTooltipScatterContainer {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  gap: 10px;
}

.analyticsGraphTooltipGroupedContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.analyticsGraphSingleGroupContainer {
  display: flex;
  flex-direction: row;
  min-width: 100px;
  justify-content: space-between;
}

svg.recharts-surface {
  overflow: visible;
}

.recharts-brush > rect:first-child {
  stroke: white;
  rx: 8;
}

.recharts-brush-slide {
  fill-opacity: 0.4;
}

.recharts-brush-traveller line {
  stroke: white;
}

.recharts-brush-texts > text {
  font-size: 12px;
  fill: #8b97a3;
  width: 10;
}

.exportDataIcon {
  fill: #748695;
  height: 16.67px;
}

.noSelectedGraphLine,
.noSelectedGraphBar,
.selectedGraphLine,
.selectedGraphDot,
.selectedGraphBar {
  cursor: pointer;
}
