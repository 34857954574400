.COABuilder__Section {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #eaf0f5;
  display: flex;
  /* gap: 10px; */
  position: relative;
  min-height: 65px;
}

.COABuilder__Section__DeleteIcon {
  cursor: pointer;
  position: absolute;
  top: -7px;
  right: -7px;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  z-index: 2;
}

.COABuilder__Section__DeleteIcon path {
  fill: #c4d2df;
}

.COABuilder__Section__DeleteIcon:hover path {
  fill: #506375;
}

.COABuilderSection__PlaceHolderText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 16px;
  color: #afbdca;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
