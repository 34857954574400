.my-profile-edit-name {
  height: 60px;
  margin-bottom: 20px;
}

.my-profile-edit-phone {
  height: 60px;
}

.my-profile-name-input-disabled {
  margin-bottom: 30px;
}

.my-profile-change-password-container {
  height: fit-content;
}

.my-profile-old-password-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-left: 0px;
  width: fit-content;
}

.my-profile-change-password-row:first-child {
  margin-top: 20px;
}

.my-profile-old-password-row img {
  margin-top: 25px;
  margin-left: -25px;
  height: 15px;
  cursor: pointer;
}

.my-profile-new-password-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: fit-content;
  column-gap: 32px;
}

.MyProfile__NewPassword {
  flex: 1 0 auto;
  width: fit-content;
  margin-bottom: 16px;
}

.my-profile-new-password-row img {
  margin-left: -25px;
  height: 15px;
  cursor: pointer;
}

.my-profile-cancel-submit-buttons {
  text-align: right;
}

.my-profile-cancel-submit-buttons.my-profile-loading {
  pointer-events: none;
  opacity: 0.7;
}

.my-profile-submit-button {
  top: 140px;
}

.MyProfile__PasswordReqsDiv {
  margin-top: -12px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: #375393;
}

.myAccountProfileMainContainer .myAccountChangePsw {
  position: absolute;
  top: 40px;
  right: 0px;
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #375393;
  cursor: pointer;
}

.myAccountProfileMainContainer .myAccountEditProfile {
  position: absolute;
  top: 40px;
  right: 0px;
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #375393;
  cursor: pointer;
}

.myAccountProfileMainContainer .myAccountEditProfile:hover,
.myAccountProfileMainContainer .myAccountChangePsw:hover {
  color: #26abe1;
}

/* Profile */
.myAccountProfileMainContainer {
  width: 100%;
  max-width: 800px;
  min-width: 200px;
}

.myAccountProfileMainContainer .myAccountProfileInputContainer {
  position: relative;
  margin-bottom: 30px;
}

.myAccountProfileMainContainer .myaccountDefaultInput {
  width: 100%;
  /* height: 60px; */
  background: white;
  border: none;
  border-radius: 3px;
  line-height: 40px;
  font-family: "Roboto";
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #19305a;
}

.myAccountProfileMainContainer .myaccountDefaultInput:focus {
  outline: none;
}

.myAccountProfileMainContainer .myAccountEditIcon {
  position: absolute;
  top: 35px;
  right: 0px;
  cursor: pointer;
}

.myAccountEditBtn:hover,
.myAccountCancelBtn:hover,
.deleteMemberCancelBtn:hover,
.deleteMemberConfirmBtn:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.myAccountProfileMainContainer .myAccountEditBtn {
  padding: 5px 10px;
  height: 34px;
  margin-left: 20px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.myAccountFaqMainContainer .myAccountEditBtn {
  padding: 5px 10px;
  height: 34px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.myAccountProfileMainContainer .myAccountCancelBtn {
  padding: 5px 10px;
  height: 34px;
  border: 1px solid #eff2f3;
  background-color: #eff2f3;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
  color: #19305a;
}

.myAccountProfileMainContainer .myaccountEditInput {
  margin-top: 12px;
  height: 40px;
  padding-left: 11px;
  padding-right: 34px;
  border: 2px solid #d7dae1;
  background-color: #fdfdfd;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto";
  font-size: 12px;
  color: #19305a;
}

.myAccountProfileMainContainer .myaccountEditInput:focus {
  border: 2px solid #3ec6ff !important;
  border-color: #3ec6ff !important;
  outline: none;
}

.myAccountProfileMainContainer .myaccountEditInputWidth {
  width: 300px;
}

.myAccountProfileMainContainer .myaccountEditInputPassWidth {
  width: 200px;
}
