.SummaryForm {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.SummaryForm__StatusPanel {
  padding: 12px;
  padding-right: 28px;
  gap: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  background: #F2F6FA;
  margin-bottom: 24px;
}

.SummaryForm__StatusPanel .SummaryForm__Name {
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  color: #182534;
}

.SummaryForm__StatusPanel .SummaryForm__NameInput {
  height: 40px;
  margin-bottom: 0;
  position: relative;
}

.SummaryForm__StatusPanel .SummaryForm__NameInput .searchInput {
  padding-right: 40px;
  color: #000000;
}

.SummaryForm__StatusPanel .SummaryForm__NameInput .searchInput.invalid {
  border-color: rgba(220, 53, 53, 0.656);
}

.SummaryForm__StatusPanel .SummaryForm__NameInput .SummaryForm__NameInputCancel {
  font-size: 24px;
  width: 40px;
  height: 40px;
  background: transparent;
  font-family: "Roboto bold";
  font-weight: 500;
  color: #000000;
  line-height: 40px;
  border: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.SummaryForm__StatusPanel .SummaryForm__Frequency {
  margin-left: auto;
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  color: #182534;
}

.SummaryForm__StatusPanel .SummaryForm__Frequency .SummaryForm__Frequency--highlighted {
  font-family: "Roboto bold";
  font-weight: 500;
  line-height: 20px;
  color: #26ABE1;
  background-color: transparent !important;
}

.SummaryForm__StatusPanel .SummaryForm__Frequency .SummaryForm__Frequency--highlighted sup {
  font-size: 12px;
}

.SummaryForm__LocationData {
  flex: 1;
  padding: 12px;
  border-radius: 12px;
  background: #FFFFFF;
  border: 1px solid #EAF0F5;
  display: flex;
  flex-direction: column;
}

.SummaryForm__LocationData .SummaryForm__LocationStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SummaryForm__LocationData .SummaryForm__LocationStatus p {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: #6C7E8E;
}

.SummaryForm__TableScrollbar {
  flex: 1;
  margin-top: 12px;
}

.SummaryForm__TableScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.SummaryForm__TableScrollbar .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #133e8c !important;
}

.SummaryForm__LocationData .SummaryForm__Table {
  width: 100%;
}

.SummaryForm__LocationData .SummaryForm__Table th {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  color: #182534;
  padding: 12px;
  background: #F8FAFC;
  text-align: left;
  min-width: 150px;
}

.SummaryForm__LocationData .SummaryForm__Table th.SummaryForm__TableTestsHeader {
  text-align: center;
}

.SummaryForm__LocationData .SummaryForm__Table tbody tr td {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #182534;
  padding: 12px;
  background: #FFFFFF;
}

.SummaryForm__LocationData .SummaryForm__Table tbody tr td.SummaryForm__TableTestsCell {
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  color: #6C7E8E;
  word-break: break-word;
}

.SummaryForm__Frequency.truncated-text {
  --no-of-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--no-of-lines);
  line-height: 1.5;
  max-height: calc(1.5em * var(--no-of-lines));
}

.SummaryForm__TableRow td {
  word-break: break-all;
}