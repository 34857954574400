.SubmissionReceiptModal .ant-modal-content {
  border-radius: 4px;
  width: 595px;
  /* height: 602px; */
  padding: 0px;
}

.SubmissionReceiptModal__Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SubmissionReceiptModal__Container {
  padding: 36px 27px 14px 36px;
}

.SubmissionReceiptModal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 14px 10px;
  margin: 0;
}

.SubmissionReceiptModal .ant-modal-footer > .StyledButton {
  font-size: 12px;
  line-height: 16px;
}

.SubmissionReceiptModal__Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 18px;
  color: #afbdca;
  font-size: 10px;
  font-family: Roboto;
}

/* Scrollbar */
.SubmissionReceiptModal__Scrollbar.ScrollbarsCustom {
  height: 459px !important;
}

.SubmissionReceiptModal__Scrollbar.ScrollbarsCustom .ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}

.SubmissionReceiptModal__Scrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding: 16px 0px;
  width: 4px !important;
  right: 7px !important;
}
