.FormLayout {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 32px;
}

.FormLayout__Title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: sticky;
  top: 0px;
  background-color: #f7f8fa;
  z-index: 2;
  box-shadow: 5px 0px 0px 0px rgba(247, 248, 250),
    -5px 0px 0px 0px rgba(247, 248, 250);
}

.FormLayout__Title span {
  text-align: center;
  font-family: "Roboto Medium";
  font-size: 26px;
  margin-bottom: 0;
}

.FormLayout__Body {
  background-color: white;
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.FormLayout__Body::-webkit-scrollbar {
  display: none;
}

.FormLayout__Body form {
  width: unset;
  padding: unset;
  min-height: 556px;
}
