.coaPreviewContainer {
  background-color: #f9f9f9;
  padding: 62px 91px 0px 91px;
  position: relative;
  /* overflow: auto; */
}

.builderPreviewSection {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #375393;
  padding: 20px;
  padding-top: 185px;
}

.templateAlignButton {
  position: absolute;
  top: 26px;
  right: 26px;
  cursor: pointer;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  border: 1px solid #e6eef5;
}

.templateAlignButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: #ffffff;
  padding: 4px;
  width: 74px;
  height: 32px;
  border-radius: 8px;
}

.alignActive {
  background-color: #385387;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
  width: 46px;
  height: 24px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.alignInactive {
  background-color: #ffffff;
  border-radius: 4px;
  width: 42px;
  height: 24px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.builderPDFViewer {
  width: 100%;
  height: calc(100vh - 294px);
  border: none;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  background-color: #ffffff;
}

.BuilderPreviewContainer .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 0px !important;
}

#coaPreviewScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #d7dae1 !important;
  /* border-radius: 3px !important;
  width: 6px !important; */
}

.builderPreviewSectionFail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 294px);
  background-color: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #375393;
  padding: 20px;
}
