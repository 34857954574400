.COABuilder__SelectParent {
  flex-grow: 1;
  display: flex;
  gap: 8px;
  word-break: break-word;
  word-wrap: break-word;
}

.COABuilder__SelectParent--isEditing {
  width: fit-content;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;
}

.COABuilder__SelectLabel {
  color: #afbdca;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 20px;
  margin: 0;
  cursor: inherit;
  min-width: 20px;
}

.COABuilder__SelectDiv {
  width: fit-content;
  position: relative;
  display: flex;
  flex-grow: 1;
}

.COABuilder__SelectSpan {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #1f2d3b;
  font-size: 12px;
  font-family: "Roboto Medium";
  height: 100%;
}

.COABuilder__Select {
  display: none;
}

.ant-select.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer).COABuilder__Select--isEditing:hover
  .ant-select-selector {
  border-color: #26abe1;
}

.ant-select.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer).COABuilder__Select--isEditing
  .ant-select-selector {
  border-color: #26abe1;
}

.ant-select.COABuilder__Select--isEditing {
  display: flex;
  flex-grow: 1;
  min-width: 100px;
  width: 100%;
}

.ant-select.COABuilder__Select--isEditing .ant-select-selector {
  display: flex;
  flex-grow: 1;
  color: #6c7e8e;
  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;

  border-radius: 4px;
  border: 1px solid #eaf0f5;
  background: #fdfdfd;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

  min-width: 100px;
  width: 100%;
}

.COABuilder__RefInput {
  display: none;
}

.COABuilder__Select__Dropdown.ant-select-dropdown .ant-select-item-option {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: "Roboto";
  color: #6c7e8e;
}

.COABuilderSelect__EditIconContainer {
  height: 100%;
  padding-top: 4.5px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.COABuilder__SelectParent .COABuilder__DeleteIconContainer {
  margin-top: 2.5px;
}
