.SampleSubmission__ImageSectionHeader {
  font-family: "Roboto Bold";
  font-size: 12px;
  color: #6C7E8E;
}

.SampleSubmission__UploadImageRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.SampleDetails__ImageCheckboxParent {
  display: flex;
}

.sample-submission-checkbox-container.SampleSubmission__UploadImage {
  display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: unset;
    padding: 0px;
}

.sample-submission-checkbox-container.SampleSubmission__UploadImage label {
  font-family: "Roboto medium";
  font-size: 12px;
  line-height: 16px;
  color: #6C7E8E;
  margin-bottom: 0;}


.SampleSubmission__UploadImageInputDiv {
  background-color: #EFF2F3;
  min-width: 100px;
  height: 100px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.SampleSubmission__UploadImageInputDiv.imageWithSrc {
  cursor: default;
}

.SampleSubmission__UploadImageInputDiv svg {
  width: 50%;
  height: 50%;
}

.SampleSubmission__UploadImageInputDiv img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.SampleSubmission__ImageEdit {
  right: -12px;
  bottom: -12px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  background-color: #F9F9F9;
  filter: drop-shadow(0px 2.51685px 4.19475px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 0.838949px rgba(9, 30, 66, 0.31));
}

.SampleSubmission__ImageEdit:hover {
  cursor: pointer;
}

.SampleSubmission__ImageEdit svg {
  width: 16px;
  height: 16px;
}

.SampleSubmission__ImageEdit svg path {
  fill: #19305A;
}