.envAnalyticsListViewBlock .ScrollbarsCustom-Content {
  padding-top: 1px !important;
}

.envAnalyticsListViewBlock .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: rgba(196, 196, 196, 0.81) !important;
}

.AnalyticsListView--nonEmpty {
  height: 100%;
}

.AnalyticsListView__SearchCalendar {
  position: relative;
}

.AnalyticsListView__Search {
  padding: 22px 22px 12px 24px;
  font-size: 12px;
  line-height: 16px;
  background: #E3E8F1;
  border-radius: 4px 4px 0px 0px;
}

.AnalyticsListView__Search--grayOut {
  opacity: 0.5;
}

.AnalyticsListView--emptyList {
  background-color: #F9F9F9;
  height: 100%;
  border-radius: 8px;
  margin-top: 2px;
  position: relative;
}

.AnalyticsListView__AddFileIcon {
  width: 20px;
  height: 20px;
  padding: 3.33px;
  margin-top: -1px;
  margin-left: 5px;
  cursor: pointer;
}

.AnalyticsListView__PinListContainer {
  height: calc(100% - 50px);
}

.AnalyticsListView__DisplayNoResultFound {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
}

.AnalyticsListView__NoResultPumpkin {
  width: 251px;
  height: 251px;
  margin-top: -160px;
}

.AnalyticsListView__NoResultText {
  position: absolute;
  font-size: 16px;
  color: #758AB9;
  font-family: Roboto;
}

.create-new-swab-grayed-out {
  position: absolute;
  background: #F9F9F9;
  top: 0px;
  left: 0px;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.disable-clicking-search {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}

.environment-analytics-list-view-buttons-and-list {
  background-color: #F9F9F9;
  height: calc(100% - 130px);
  border-radius: 0px 0px 16px 16px;
  position: relative;
  padding: 0px 23px 16px 23px;
  overflow: hidden;
}

.environment-analytics-list-view-content-loader {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

/* Create and Upload buttons styling */
.create-and-upload-swab-container {
  display: flex;
  justify-content: space-between;
}

.envAnalyticsUploadButton {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  color: #26ABE1;
  font-family: "Roboto";
  font-size: 14px;
  background: #EFF2F3;
  border-radius: 8px;
  padding: 6px 12px;
}

.createNewSwabBtn,
.uploadSwabFileBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #19305A;
  font-size: 14px;
  width: fit-content;
  height: 50px;
  font-family: "Roboto Medium";
}

.createNewSwabBtn.enable,
.uploadSwabFileBtn.enable {
  cursor: pointer;
}

.createNewSwabBtn > img {
  content: url("../../../../../assets/images/environment/addSwabIconMidnight.svg");
}

.createNewSwabBtn:hover.enable > img {
  content: url("../../../../../assets/images/environment/addSwabIconLightBlue.svg");
}

.createNewSwabBtn:hover.enable,
.uploadSwabFileBtn:hover.enable {
  color: #26ABE1;
}

.createNewSwabBtn.disable > img {
  content: url("../../../../../assets/images/environment/addSwabIconGrey.svg");
}

.createNewSwabBtn.disable,
.uploadSwabFileBtn.disable {
  color: #ccd1d8;
  pointer-events: none;
  cursor: auto;
}

.upload-swab-file-icon-circle {
  box-sizing: border-box;
  border: 1px solid #19305A;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadSwabFileBtn:hover.enable img {
  filter: invert(60%) sepia(26%) saturate(1338%) hue-rotate(154deg) brightness(92%) contrast(91%);
}

.uploadSwabFileBtn:hover.enable .upload-swab-file-icon-circle {
  border-color: #26ABE1;
}

.uploadSwabFileBtn.disable img {
  filter: invert(88%) sepia(15%) saturate(98%) hue-rotate(176deg) brightness(95%) contrast(88%);
}

.uploadSwabFileBtn.disable .upload-swab-file-icon-circle {
  border-color: #ccd1d8;
}