.TooltipComponent.ant-tooltip .ant-tooltip-inner {
  font-size: 12px;
  background-color: #6c7e8e;
  color: #ffffff;
  border-radius: 3px;
  padding: 4px 8px;
  white-space: pre-wrap;
  font-family: "Roboto";
  display: flex;
  align-items: center;
}

.TooltipComponent {
  --antd-arrow-background-color: #6c7e8e;
}
