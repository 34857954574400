.FrequencyForm__Container {
  display: flex;
  align-items: stretch;
}

.FrequencyForm__TabButtonContainer {
  padding: 12px;
  border-right: 1px solid #EAF0F5;
}

.FrequencyForm__TabButtonContainer button {
  width: 100%;
  min-width: 150px;
  height: 32px !important;
  gap: 8px;
  margin-bottom: 12px;
}

.FrequencyForm__Content {
  padding: 12px 24px;
  flex: 1;
}

.FrequencyForm__Content .Field {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 0;
  border-bottom: 1px solid #EAF0F5;
  width: 100%;
}

.FrequencyForm__Content .Field:first-of-type {
  padding-top: 0;
}

.FrequencyForm__Content .Field:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}

.FrequencyForm__Content .Field.No_Border {
  border: none;
}

.FrequencyForm__Content .FieldTitle {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  color: #375393;
}

.FrequencyForm__Content .Recurrences {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #F8FAFC;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #6C7E8E;
}