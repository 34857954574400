.EnvMainContainerFlexLayout {
  display: flex;
  height: 100%;
  padding: 26px 36px;
}

.envAnalyticsMapViewBlock {
  flex: 1 1 200px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #19305a;
  min-width: 850px;
  overflow: hidden;
}

.envAnalyticsListViewBlock {
  margin-right: 25px;
  flex: 0 0 438px;
  width: 438px;
}
