.titleInput,
.detailsInput {
  width: 282px;
  background: #FDFDFD;
  border: 1px solid #EAF0F5;
  font-family: 'Roboto Medium';
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  color: #6C7E8E;
  margin: 0px 15px;
}

.titleInput {
  height: 30px;
  padding: 4px 9px;
}

.detailsInput {
  height: 100px;
  padding: 10px;
  resize: none;
}

.titleInput::placeholder,
.titleInput:disabled,
.detailsInput::placeholder,
.detailsInput:disabled {
  color: #C4D2DF;
}

.DataFieldEditCardContainer {
  display: flex;
  flex-direction: column;
  width: 314px;
}

.dataFieldEditButtonContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dataFieldEditButtonContainer button {
  border: none;
  height: 42px;
  padding: 9px 28.5px;
  border-radius: 8px;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
}

.dataFieldEditButtonContainer .saveButton {
  background-color: #26ABE1;
  color: #FFFFFF;
}

.dataFieldEditButtonContainer .cancelButton,
.dataFieldEditButtonContainer .deleteButton {
  background-color: transparent;
}

.dataFieldEditButtonContainer .cancelButton {
  color: #385387;
}

.dataFieldEditButtonContainer .deleteButton {
  color: #E63559;
}

.createFieldModal {
  height: 567px;
  width: 802px;
}

.createFieldModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 11px 30px 28px;
}

.createFieldHeaderBar {
  background-color: #26ABE1;
  width: 12px;
  height: 28px;
  border-radius: 4px;
  margin-right: 16px;
}

.createFieldModalHeaderInner {
  display: flex;
  align-items: center;
  height: 28px;
}

.createFieldHeaderSpan {
  color: #111A24;
  font-family: 'Roboto Medium';
  font-size: 16px;
}

.createFieldInputsContainer {
  width: 314px;
  height: 228px;
  background-color: #FFFFFF;
  border: 1px solid #EAF0F5;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 16px;
  margin-top: 13px;
}

.addingFieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.createFieldHeaderCloseIcon {
  height: 34px;
  width: 34px;
  cursor: pointer;
}

.createFieldModalBody {
  display: flex;
}

.createFieldModalBodyLeft {
  height: 468px;
  width: 409px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 44px 20px;
}

.createFieldModalBodyRight {
  height: 468px;
  width: 373px;
  border: 1px solid #E6EEF5;
  border-radius: 16px;
  padding: 10px 0px 10px 25px;
}

.bodyRightProductsSpan {
  color: #385387;
  font-family: 'Roboto Medium';
  font-size: 16px;
}

.bodyRightProductsSpan2 {
  color: #AFBDCA;
  font-family: 'Roboto Medium';
  font-size: 11px;
  text-align: left;
  line-height: 30px;
}

.modalSearchContainer {
  margin-right: 25px;
}

.inputTitleSpan {
  color: #375393;
  font-family: 'Roboto Medium';
  font-size: 12px;
}

.spanContainer{
  display: flex;
  margin: 14px 16px 4.5px;
  width: 280px;
}

.addingFieldHeaderSpan {
  color: #385387;
  font-family: 'Roboto Medium';
  font-size: 16px;
  line-height: 24px;
}

.leftSideAddingSpan {
  color: #26ABE1;
  font-family: 'Roboto Medium';
  font-size: 14px;
}

.leftSideAddingDivLarge {
  background-color: #FFFFFF;
  border: 1px solid #EAF0F5;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  width: 171px;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.leftSideAddingDivSmall {
  /* width: 139px; */
  height: 38px;
  border: 1px solid #EAF0F5;
  border-radius: 8px;
  background: #F2F6FA;
  color: #26ABE1;
  font-family: 'Roboto Medium';
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.leftSideTitleDivSmall {
    height: 38px;
    border: 1px solid #EAF0F5;
    border-radius: 8px;
    background: #F2F6FA;
    color: #375393;
    font-family: 'Roboto Medium';
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.leftSideHomeDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leftSideHomeDivHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 6px;
}

.leftSidePlusIcon {
  height: 37px;
  width: 37px;
}

.leftSidePlusIconSmall {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.leftSideHomeDivTitle {
  display: flex;
  align-items: flex-start;
}

.leftSideHomeDivTitle img {
  height: 20px;
  width: 20px;
}

.leftSideHomeDivTitle span {
  color: #385387;
  font-family: 'Roboto Medium';
  font-size: 16px;
  line-height: 24px;
  margin-left: 9px;
}

.addingDivContainer {
  display: flex;
  justify-content: center;
}

.addingDivContainerSmall {
  display: flex;
  flex-wrap: wrap;
  row-gap: 21px;
  column-gap: 23px;
  width: calc(100% - 25px);
}

.leftSideHomeDivBodySpan {
  color: #AFBDCA;
  font-family: 'Roboto Medium';
  font-size: 11px;
  line-height: 16px;
  margin-top: 5px;
  text-align: left;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.productDiv {
  background: #F2F6FA;
  border: 1px solid #EAF0F5;
  height: 43px;
  width: 320px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  border-radius: 8px;
  color: #6C7E8E;
  font-family: 'Roboto Medium';
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  padding-left: 25px;
  align-items: center;
  cursor: pointer;
}

.productDiv:hover {
  transform: scale(1.01);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  background-color: #DEF2FB;
}

#createFieldScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  /* width: 4.42px !important; */
  background: #B3BFDB !important;
}

.editDetailBackIcon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.fieldsForProduct {
  width: 152px;
  height: 110px;
  background: #F6F8FA;
  border: 1px solid #EAF0F5;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pencilIcon {
  height: 12px;
  width: 12px;
}

.rightSideEditPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.rightSideEditPageHeader {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.fieldsForProductContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  /* margin-left: 13px; */
  /* margin-top: 20px; */
  /* justify-content: space-evenly; */
}

.fieldsForProductHeader {
  height: 35px;
  width: 152px;
  padding-left: 14px;
  padding-right: 7px;
  color: #375393;
  font-size: 11px;
  font-family: 'Roboto Medium';
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fieldsForProductBody {
  width: 138px;
  height: 68px;
  background: #FDFDFD;
  border: 1px solid #EAF0F5;
  border-radius: 4px;
  padding: 10px;
}

.fieldsForProductHeaderContent {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fieldsForProductBodyContent {
  font-family: 'Roboto Medium';
  font-size: 11px;
  line-height: 16px;
  color: #6C7E8E;
  overflow: hidden;
  text-align: left;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productTitleSpan {
  color: #26ABE1;
  font-family: 'Roboto Medium';
  font-size: 16px;
}

.createFieldModalBodyRightTop {
  display: flex;
  flex-direction: column;
  position: relative;
}

.createFieldModalBodyRightMid {
  margin-top: 35px;
}

.templateSearchBarContainer, .templateSearchBarContainer--full-width {
  display: flex;
  align-items: center;
  padding-top: 14px;
}

.templateSearchBarInput {
  width: 100%;
  height: 35px;
  background: #FDFDFD;
  border: 1px solid #EAF0F5;
  margin-right: 1px;
  border-radius: 8px;
  color: #506375;
  font-family: 'Roboto';
  font-size: 14px;
  padding-left: 12px;
  padding-right: 55px;
}

.templateSearchBarInput::placeholder {
  color: #AFBDCA;
  font-family: 'Roboto';
  font-size: 14px;
}

.templateSearchBar {
  width: calc(100% - 24px);
  display: flex;
  position: relative;
}

.templateSearchBar--full-width {
  width: 100%;
  display: flex;
  position: relative;
}

.templateSearchClose {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.searchIconSmall {
  height: 33px;
  width: 53px;
  position: absolute;
  cursor: pointer;
  border-radius: 9px;
  top: 1px;
  right: 2px;
}

.rightTopHeader {
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: flex-start;
  padding-right: 20px;
}
