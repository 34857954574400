.scroll-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.scroll-button {
  flex-shrink: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 2rem;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.scroll-content {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  gap: 8px;
}

.scroll-content::-webkit-scrollbar {
  display: none;
}

.rotate-180 {
  transform: rotate(180deg);
}
