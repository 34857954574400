.SampleDetailsInput .SampleDetails__InputDiv {
  width: 100%;
}

.SampleDetailsInput {
  position: relative;
  display: inline-block;
  /* width: 100%; */
  width: calc(100% - 24px);
}

.SampleDetailsInput>.ant-select {
  width: 100%;
}

.SampleDetailsInput .ant-select-clear {
  display: flex;
  align-items: center;
  inset-inline-end: 6px;
  margin-top: -7px;
}

.SampleDetailsInput>input:focus {
  box-shadow: none;
  /* border: 2px solid #506375; */
}

.SampleDetailsInput>input.sample-submission-input-error:hover {
  border: 2px solid red;
}

.SampleDetailsInput>input:hover,
.SampleDetailsInput .ant-select-auto-complete .ant-input:hover {
  border: 2px solid #97a3ae;
}

.SampleDetailsInput .ant-select-auto-complete .ant-input.sample-submission-input-error:hover {
  border: 2px solid red;
}

.SampleDetailsInput .ant-select-auto-complete .ant-input:focus {
  box-shadow: none;
}

.SampleDetailsInput__ClearButton:hover {
  color: #97a3ae;
}

/* Tooltip styling */
.SampleDetailsInput__TooltipTitle {
  font-family: "Roboto";
  color: #506375;
}

.SampleDetailsInput__InvalidChar {
  color: #d9534f;
  font-weight: bold;
}

.SampleDetailsInput__InvalidChar__SpaceHighlight {
  background-color: #f5c6cb;
  padding: 0 2px;
  border-radius: 2px;
}

/* Character count styling */
.MaxCharacterCount {
  position: absolute;
  bottom: -20px;
  right: 6px;
  font-size: 12px;
  color: #506375;
}

.MaxCharacterCount--Exceeded {
  color: #d9534f;
}

.SampleDetailsInput__MaxChar {
  font-size: 10px;
  bottom: -16px;
}