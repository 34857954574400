.COABuilder__InputParent,
.COABuilder__TextAreaParent {
  width: fit-content;
  display: flex;
  gap: 8px;
  word-break: break-word;
  word-wrap: break-word;
  /* align-items: center; */
}

.COABuilder__InputParent--editing {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
}

.COABuilder__TextAreaParent--editing {
  width: fit-content;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-grow: 1;
}

.COABuilder__InputLabel {
  color: #9dd9f2;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 20px;
  margin: 0;
  cursor: inherit;
  min-width: 20px;
}

.COABuilder__InputDiv {
  width: fit-content;
  flex-grow: 1;
  position: relative;
  display: flex;
}

.COABuilder__InputSpan {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #1f2d3b;
  font-size: 12px;
  font-family: "Roboto Medium";
  height: 100%;
  white-space: pre-line;
}

.COABuilder__Input,
.COABuilder__TextArea {
  display: none;
}

.COABuilder__Input--editing {
  display: flex;
  flex-grow: 1;
  color: #6c7e8e;
  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;

  border-radius: 4px;
  border: 1px solid #eaf0f5;
  background: #fdfdfd;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

  padding-right: 29px;
}

.COABuilder__TextArea--editing {
  display: flex;
  flex-grow: 1;
  color: #6c7e8e;
  font-size: 11px;
  font-family: "Roboto Medium";
  line-height: 16px;

  border-radius: 4px;
  border: 1px solid #eaf0f5;
  background: #fdfdfd;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

  height: 94px;
  resize: none;
  padding: 6px;
  padding-right: 29px;
  overflow: hidden;
}

.COABuilder__Input--editing:focus,
.COABuilder__TextArea--editing:focus {
  border-color: #26abe1;
}

.COABuilder__InputParent .COABuilder__EditIcon,
.COABuilder__TextAreaParent .COABuilder__EditIcon {
  margin-top: 4.5px;
}

.COABuilder__InputParent .COABuilder__DeleteIconContainer,
.COABuilder__TextAreaParent .COABuilder__DeleteIconContainer {
  margin-top: 3px;
}

.COABuilder__Input__CheckmarkIcon {
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
  flex-shrink: 0;
}

.COABuilder__TextArea__CheckmarkIcon {
  position: absolute;
  right: 7px;
  top: 6px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  flex-shrink: 0;
}

.COABuilder__DeleteIcon.COABuilder__DeleteIcon--marginTopLarge {
  margin-top: 7px;
}

.COABuilder__DeleteIcon.COABuilder__DeleteIcon--marginTopSmall {
  margin-top: 1px;
}
