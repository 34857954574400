.EventGrid__EventCard {
  width: 100%;
  height: fit-content !important;
  /* Prevent height changes when the card is on drag */
  background: #f8fafc;
  padding: 4px;
  margin-bottom: 4px;
  position: relative;
  user-select: none;
}

.EventGrid__EventCardHeader {
  display: flex;
  align-items: flex-start;
}

.EventGrid__EventCardHeader p {
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  color: #182534;
  flex: 1;
  word-break: break-all;

  /* truncate after 3 lines */
  --no-of-lines: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--no-of-lines);
  line-height: 1.5;
  max-height: calc(1.5em * var(--no-of-lines));
}

.EventGrid__EventCardHeader__Tooltip {
  word-break: break-all;
}

.EventGrid__EventCardHeader button {
  border: none;
  background-color: transparent;
}

.EventGrid__EventCardHeader button>svg>path {
  fill: #182534;
}

.EventGrid__Container .ScrollbarsCustom-Content {
  /* display: block !important; */
}

.Event__Location {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  color: #c4d2df;
}

.Event_LocationsList__Scrollbar.ScrollbarsCustom {
  max-height: inherit;
}

.Event_LocationsList__Scrollbar .ScrollbarsCustom-Wrapper,
.Event_LocationsList__Scrollbar .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.Event_LocationsList {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  max-height: 200px;
  width: 100%;
}

.Event_LocationsList .Event_LocationDetails {
  padding: 4px;
  border-radius: 2px;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  border: 1px solid transparent;
}

.Event_LocationsList .Event_LocationDetails span {
  word-break: break-all;
}

.Event_LocationsList .Event_LocationDetails span.truncated-text {
  --no-of-lines: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--no-of-lines);
  line-height: 1.5;
  max-height: calc(1.5em * var(--no-of-lines));
}

.Event_LocationsList .Event_LocationDetails:last-of-type {
  margin-bottom: 0;
}

.Event_LocationsList .Event_LocationDetails .EventCard__MarkAsSampledButton,
.Event_LocationsList .Event_LocationDetails .EventCard__UnMarkAsSampledButton {
  display: none;
  width: 100%;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  border: none;
  margin-top: 8px;
}

.Event_LocationsList .Event_LocationDetails .EventCard__MarkAsSampledButton {
  background: #ffc143;
  color: #ffffff;
}

.Event_LocationsList .Event_LocationDetails .EventCard__UnMarkAsSampledButton {
  background: #ffffff;
  color: #6c7e8e;
}

.Event_LocationsList .Event_LocationDetails .EventCard__UnMarkAsSampledButton::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #6c7e8e;
  background: #ffffff;
  margin-right: 4px;
}

.Event_LocationsList .Event_LocationDetails:hover .EventCard__MarkAsSampledButton,
.Event_LocationsList .Event_LocationDetails:hover .EventCard__UnMarkAsSampledButton {
  display: block;
}

.Event_LocationDetails.Event_LocationConfirmUpdateStatus {
  background: #fff8eb;
  border: 1px solid #eaf0f5;
}

.Event_LocationDetails.Event_LocationConfirmUpdateStatus p {
  margin: 0;
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  color: #6c7e8e;
}

.Event_LocationDetails.Event_LocationConfirmUpdateStatus .Event_LocationConfirmUpdateStatus__Buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 8px;
}

.Event_LocationDetails.Event_LocationConfirmUpdateStatus .Event_LocationConfirmUpdateStatus__Buttons .Event_LocationConfirmUpdateStatus__Button {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 2px 12px 2px 12px;
  border-radius: 5px;
  height: auto;
}

.Event_LocationDetails.Event_LocationConfirmUpdateStatus .Event_LocationConfirmUpdateStatus__Buttons .Event_LocationConfirmUpdateStatus__Button.Event_LocationConfirmUpdateStatus__Button--ghost,
.Event_LocationDetails.Event_LocationConfirmUpdateStatus .Event_LocationConfirmUpdateStatus__Buttons .Event_LocationConfirmUpdateStatus__Button.Event_LocationConfirmUpdateStatus__Button--ghost:hover {
  background-color: #ffffff;
}

.Drag_Loading {
  position: absolute;
  inset: 0;
  background: rgba(128, 128, 128, 0.292);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
}

.Drag_Loading .Drag_Loading__Loader {
  font-size: 12px;
  margin: auto;
}

.EventGrid__EventCard__SubmitBtn {
  width: 100%;
  margin-top: 5px;
  font-family: 'Roboto';
  color: #26abe1;
}

.EventGrid__EventCard__SubmitBtn.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: #26abe1;
}