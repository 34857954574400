#productReportSpecManagerInput {
  border-radius: 3px;
  background-color: #fdfdfd;
  padding-left: 5px;
  padding-right: 5px;
}

input.SpecManagerCustomInput::placeholder {
  color: #b3bfdb;
}

input.SpecManagerCustomInput {
  border: 2px solid #d7dae1;
}

input.SpecManagerCustomInput:focus {
  border: 2px solid #26abe1;
  outline: none;
}

input.SpecManagerCustomInput.rangeValueMissing {
  border: 2px solid #e63559;
}

input.SpecManagerCustomInput.rangeValueMissing:focus {
  border: 2px solid #e63559;
}

input.SpecManagerCustomInput.specManagerWarning {
  border: 2px solid #eed202;
}

input.SpecManagerCustomInput.specManagerWarning:focus {
  border: 2px solid #eed202;
}
