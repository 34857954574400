.SampleSubmission__ImageUpload__Slider {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 200px;
}

.SampleSubmission__ImageUpload__Slider .ant-slider {
  width: 100%;
}

.SampleSubmission__ImageUpload__Slider .ant-slider-rail,
.SampleSubmission__ImageUpload__Slider .ant-slider:hover .ant-slider-rail {
  background-color: #B3E0F2;
}

.SampleSubmission__ImageUpload__Slider .ant-slider-track {
  background-color: #26ABE1;
}

.SampleSubmission__ImageUpload__Slider .ant-slider:hover .ant-slider-track {
  background-color: #26ABE1;
}

.SampleSubmission__ImageUpload__Slider .ant-slider-handle::after {
  background-color: #26ABE1;
  box-shadow: 0 0 0 2px #26ABE1;
}

.SampleSubmission__ImageUpload__Slider .ant-slider-handle:hover::after,
.SampleSubmission__ImageUpload__Slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #26ABE1;
}

.SampleSubmission__SliderButtons {
  cursor: pointer;
}