.analyticsMainContainer {
  display: grid;
  grid-template-columns: 260px 3fr 260px;
  padding: 20px 16px;
}

.analyticsColumnContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: calc(100vh - 175px);
  padding: 33px 16px;
  gap: 20px;
}

#analyticsScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 4px !important;
  background-color: #c4d2df !important;
}

.analyticsMainContainer
  .ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Content {
  padding: 0px 1px 0px 0px !important;
}

.analyticsHorizontalLine {
  width: 100%;
  border: #eaf0f5 0.5px solid;
  margin: 4px 0px 4px 0px;
}
