.NoResultFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
}

.NoResultFoundContainer__Img {
  width: 251px;
  height: 251px;
}

.NoResultFoundContainer__Text {
  margin-top: -100px;
  font-size: 16px;
  color: #758AB9;
  font-family: "Roboto";
}