/* Date filter styling */
.DatePickerDisplay {
  position: relative;
  height: 100%;
  background-color: #eff2f3;
  border-radius: 3px;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #19305a;
  cursor: pointer;
  text-align: center;
  font-family: Roboto Medium;
  font-weight: 500;
  font-size: 14px;
  gap: 10px;
}

.DatePickerDisplay--row-reverse {
  height: 100%;
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 0px 12px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: #19305a;
  cursor: pointer;
  text-align: center;
  font-family: Roboto Medium;
  font-weight: 500;
  font-size: 14px;
  gap: 10px;
}

.DatePickerDisplay>span,
.DatePickerDisplay--row-reverse>span {
  max-height: 100%;
  overflow: hidden;
}

.DatePickerDisplay:hover,
.DatePickerDisplay--row-reverse:hover {
  background-color: #def2fb;
}

.DatePickerDisplay__CalenderIcon {
  width: 15px;
  height: 15px;
  padding: 0.83px;
}

.DatePickerDisplay__CancelIcon {
  width: 15px;
  height: 15px;
  padding: 1px;
}

/* Tooltip styling */
.DatePickerDisplay__Tooltip {
  font-family: "Roboto";
  font-size: 12px;
}

.DatePickerDisplay__Tooltip .ant-tooltip-inner,
.DatePickerDisplay__Tooltip .ant-tooltip-arrow::before {
  color: #505050;
  background-color: #e0e4eb;
}