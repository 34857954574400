.AddressSection__ShortInput {
  flex-grow: 1;
  flex-basis: 45%;
  min-width: 150px;
  width: 150px;
  padding: 0px !important;
  margin-bottom: -20px !important;
}

.AddressSection__FlexRow {
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  padding: 0px !important;
}

.AddressSection__FlexRow>.ant-form-item {
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.AddressSection__FieldText {
  font-family: "Roboto Medium";
  font-size: medium;
  /* letter-spacing: 0.5px; */
  padding-bottom: 4px;
  line-height: 16px;
}

.AddressSection__FieldRow .ant-input {
  font-family: 'Roboto';
  font-size: 14px;
}

.BillingAddressSection__Loading{
 display: flex;
 align-items: center;
 margin-bottom: 14px;
 min-height: 32px;
}

.BillingAddressSection__Address_label{
  font-family: "Roboto Bold";
  font-size: 16px;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  color: #19305a;
}