.MuiButton-root.ResultActions__AddSpecsBtn {
  height: 32px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  border: 2px solid transparent;
  text-transform: none;
  background-color: rgb(38, 171, 225);
  min-width: 107px;
}

.MuiButton-root.ResultActions__AddSpecsBtn:hover {
  background-color: rgb(38, 171, 225);
  filter: drop-shadow(0px 1px 1px rgba(9, 30, 66, 0.25)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
}