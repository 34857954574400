.SubmissionDetailsModal .ant-modal {
  width: 60% !important;
}

.SubmissionDetailsModal .ant-modal-content {
  border-radius: 4px;
  /* padding: 24px; */
  padding: 0;
  min-width: 680px;
}

.SubmissionDetailsModal .ant-modal-header {
  margin-bottom: 0px;
}

.SubmissionDetailsModal .ant-modal-title {
  font-size: 16px;
  line-height: 32px;
  color: #111a24;
  font-weight: unset;
  font-family: "Roboto medium";
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.SubmissionDetailsModal .ant-modal-title::before {
  content: "";
  width: 12px;
  height: 27px;
  background: #26abe1;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
}

.SubmissionDetailsModal .ant-modal-close:hover {
  background-color: unset;
}

.SubmissionDetailsModal > .ant-modal .ant-modal-close-x > svg > path {
  fill: rgba(108, 126, 142, 1);
}

.submission-details-modal-container td {
  word-break: break-word;
}

/* .submission-details-modal-header {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.submission-details-modal-header button {
  border: none;
  background-color: white;
  align-self: flex-start;
  padding: 0;
}

.submission-details-modal-header-color-block {
  width: 12px;
  height: 27px;
  background: #26ABE1;
  border-radius: 4px;
  display: inline-block;
}

.submission-details-modal-header h2 {
  font-size: 16px;
  line-height: 32px;
  color: #111A24; 
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  font-family: "Roboto medium";
} */

.SubmissionDetailsModal__SubmissionTableContainer {
  width: 98%;
  padding: 24px;
  padding-bottom: 10px;
  margin-bottom: 1rem;
}

.submission-details-modal-submission-table {
  width: 100%;
}

.submission-details-modal-submission-table thead tr {
  background: #F8FAFC;
}

.submission-details-modal-submission-table thead tr th {
  padding-bottom: 19px;
}

.submission-details-modal-submission-table thead tr th:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.submission-details-modal-submission-table thead tr th:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.submission-details-modal-sample-cards {
  margin-left: 18px;
  margin-right: 18px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(355px, 1fr));
  gap: 24px;
}

.submission-details-modal-scrollbar-container {
  height: 400px;
}

.submitted-submissions-status-td div img {
  margin-right: 8px;
  margin-bottom: 2px;
  width: 15px;
  height: 15px;
}

.submitted-submissions-status-icons {
  margin-right: 8px;
  margin-bottom: 2px;
  width: 12px;
  height: 13.3px;
}

.submission-details-modal-submission-table th {
  font-family: "Roboto medium";
  font-style: normal;
}

.submission-details-modal-submission-table td {
  font-family: "Roboto medium";
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
}

.SubmissionDetailsModal__Footer {
  width: 100%;
  /* display: flex;
  justify-content: space-between; */
  /* margin-top: 20px; */
  /* padding-left: 10px; */
  padding: 24px;
  padding-top: 1rem;
  /* padding: 1rem 10px 10px 10px; */
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
  border-top: 1px solid #e5e5e5;
}


.SubmissionDetailsModal__Footer_Comment,
.SubmissionDetailsModal__Footer_Comment--edit {
  font-family: "Roboto";
  font-size: 12px;
  line-height: 16px;
  color: #111a24;
  word-break: break-all;
  padding-right: 12px;
}

.SubmissionDetailsModal__Footer_Comment>span,
.SubmissionDetailsModal__Footer_Comment--edit>span {
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #6c7e8e;
}

.SubmissionDetailsModal__Footer_Comment--edit:hover {
  cursor: pointer;
  color: #26abe1;
}

.SubmissionDetailsModal__Footer_Comment--edit:hover path {
  fill: #26abe1;
}

.SubmissionDetailsModal__Footer_Comment__EditIcon {
  margin-left: 5px;
}

.SubmissionDetailsModal__AddSampleButton {
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  height: 36px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: unset;
  border-radius: 4px;
  cursor: pointer;
  color: #375393;
  background-color: #eff2f3;
  font-family: "Roboto Medium";
  font-size: 14px;
  margin: 10px 10px 10px 0px;
}

.SubmissionDetailsModal__AddSampleButton:hover {
  background: #f6f8fa;
}

.SubmissionDetailsModal__AddSampleButton--disabled button:disabled {
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  height: 36px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: unset;
  border-radius: 4px;
  cursor: pointer;
  color: #375393;
  background-color: #eff2f3;
  font-family: "Roboto Medium";
  font-size: 14px;
  margin: 10px 10px 10px 0px;
  opacity: 0.5;
  cursor: not-allowed;
}

.SubmissionDetailsModal__ReceiptButton.StyledButton {
  font-size: 12px;
  line-height: 16px;
}

.SubmissionDetailsModal__ReceiptButton__QRIcon {
  margin-bottom: 1px;
}

.SampleSubmission__ActionsMenu__Tooltip {
  font-family: "Roboto";
  font-size: 12px;
}

.SampleSubmission__ActionsMenu__Tooltip .ant-tooltip-inner,
.SampleSubmission__ActionsMenu__Tooltip .ant-tooltip-arrow::before {
  color: #505050;
  background-color: #e0e4eb;
}

.SubmissionDetailsModal__Body {
  height: 36vh;
  min-height: 360px;
}

.SubmissionDetailsModal__Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.SubmissionDetailsModal__FooterButtonPanel {
  display: flex;
  align-items: center;
  flex: 1;
}

.SubmissionDetailsModal__FooterCommentButton {
  flex: 1;
}