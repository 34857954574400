.sample-card {
  box-sizing: border-box;
  background: #ffffff;
  /* border: 1px solid #bee6f6; */
  border-radius: 10px;
  /* width: 276px; */
  height: 224px;
  padding: 11px 10px 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 4px 6px -1px #1018281A;
  margin: 4px;
  border: 1.5px solid #EAF0F5;
}

.sample-card-modal {
  border: 1px solid rgba(165, 221, 243, 1);
  height: 243px;
  /* width: 355px; */
  box-shadow: none;
  background-color: rgba(255, 255, 255, 1);
}

.sample-card-details-modal:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(165, 221, 243, 1);
}

.sample-card table {
  text-align: left;
}

.sample-card-added {
  width: 100%;
}

.sample-card-details th {
  vertical-align: top;
  white-space: nowrap;
}

.sample-card-details-sampleid-container {
  cursor: pointer;
}

.sample-card-details-sampleid-container-disabled {
  cursor: unset;
}

.sample-card-details td {
  width: 99%;
}

.sample-card-details th,
.sample-card-details td {
  padding-top: 0;
  padding-bottom: 6px;
}

.sample-card-details tr:last-child th,
.sample-card-details tr:last-child td {
  padding-bottom: 0px;
}

.submitted-submissions-sample-card {
  height: 40px;
}

.sample-card h3 {
  display: inline;
  font-size: 12px;
  line-height: 20px;
  color: #6c7e8e;
}

.sample-card table td,
.sample-test-ul {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6c7e8e;
  margin-bottom: 0;
  text-align: left;
}

.sample-test-ul li:not(:last-child) {
  margin-bottom: 6px;
}

.sample-card table th {
  font-family: "Roboto medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* Neutral/600 */
  color: #6c7e8e;
  padding-right: 10px;
}

.sample-card tbody tr:first-child th,
.sample-card tbody tr:first-child td,
.sample-card-sample-id span {
  color: #26abe1;
}

.sample-card-status-test-view-cointainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 24px;
}

.sample-card-status {
  font-family: "Roboto medium";
}

.sample-card-status img {
  width: 15.33px;
  height: 15.33px;
  margin-right: 8px;
  margin-bottom: 2px;
}

.added-sample-delete-number,
.sample-card-status-test-view-cointainer {
  margin-top: 5px;
}

.sample-card-test-view-choice {
  display: flex;
  align-items: center;
  column-gap: 4px;
  align-self: flex-end;
  font-family: "Roboto medium";
  font-style: normal;
  color: #111a24;
}

.sample-card-test-view-choice button {
  border: none;
  width: 32px;
  height: 16px;
  border-radius: 8px;
  position: relative;
}

.to-open-test-view {
  /* Neutral/500 */
  background: #afbdca;
}

.to-close-test-view {
  background: #00875a;
}

.sample-card-white-dot {
  position: absolute;
  width: 12px;
  height: 12px;
  /* Neutral (Light) / N0 */
  background: #ffffff;
  border-radius: 50%;
  top: 2px;
}

.to-open-test-view .sample-card-white-dot {
  left: 2px;
}

.to-close-test-view .sample-card-white-dot {
  right: 2px;
}

.sample-test-ul {
  list-style: none;
  padding: 0;
}

.sample-test-ul-container {
  display: flex;
  justify-content: flex-start;
}

.added-sample-edit-icon {
  margin-right: 8px;
  cursor: pointer;
}

.added-sample-edit-icon path {
  fill: #26abe1;
}

.added-sample-delete-number {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.added-sample-delete-number span {
  display: block;
}

.added-sample-delete-number img {
  cursor: pointer;
}

.added-sample-edit-delete-icons {
  display: flex;
  align-items: center;
}

.added-sample-tests-number {
  min-width: 96px;
  background: rgba(242, 246, 250, 1);
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 4px;
  font-family: "Roboto Medium";
  color: rgba(38, 171, 225, 1);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.highlighted-added-sample-card {
  background: rgba(255, 255, 255, 1);
}

.added-sample-tests-number:hover {
  background: #dfe1e6;
}

.SampleSubmission__SampleCard__ActionsMenu {
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 1;
}

.SampleSubmission__SampleCardImageDiv {
  margin-top: 6px;
  background-color: #eff2f3;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.SampleSubmission__SampleCardImage {
  object-fit: contain;
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
}

.SampleSubmission__SampleCardLoading {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fdfdfd81;
  z-index: 2;
}

.sample-card-composite-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 28px);
  margin-left: -12px;
  margin-right: -10px;
  margin-top: -11px;
  margin-bottom: 8px;
  background-color: #f0f0f0;
  padding: 10px 16px;
  box-sizing: border-box;
  /* border-radius: 8px; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #182534;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}