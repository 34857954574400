.AuthFailedPage {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AuthFailedPage__Header {
  color: #19305a;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 8px;
}

.AuthFailedPage__SubHeader {
  color: #19305a;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.AuthFailedPage__SignOutButton {
  padding: 5px 10px;
  height: 34px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  margin-top: 10px;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.AuthFailedPage__SignOutButton:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}
