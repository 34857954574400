.ReportModal.ant-modal .ant-modal-content {
  border-radius: 0px;
  padding: 0px;
  min-height: 70vh !important;
  display: flex;
  flex-direction: column;
}

.ReportModal.ant-modal .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ReportModal__Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #375393;
  color: #FDFDFD;
  height: 47px;
  padding: 18px;
  padding-top: 16px;
  margin-top: -14px;
}

.ReportModal__Container__CloseIcon {
  height: 24px;
  cursor: pointer;
}

.ReportModal__Content .slider-wrapper.axis-horizontal {
  height: 70vh !important;
}

.ReportModal__Content {
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ReportModal__Content .ReportModal__ButtonPanel,
.ReportModal__Content .ReportModal__ButtonPanel .ReportModal__Buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.ReportModal__Content .ReportModal__ButtonPanel {
  background: #EAF0F5;
  padding: 8px 16px;
}

.ReportModal__Content .ReportModal__ButtonPanel.ReportModalNoCalendarView {
  justify-content: flex-end;
}

.ReportModal__PrintButton {
  background: #FFFFFF !important;
}

.ReportModal__DownloadButton,
.ReportModal__PrintButton {
  gap: 8px;
}

.ReportModal__DownloadButton>svg,
.ReportModal__PrintButton>svg {
  width: 12px;
  height: 12px;
}

.ReportModal__DownloadButton>svg>path {
  fill: #FFFFFF;
}

.ReportModal__DatePicker {
  flex: 1 2 auto;
  min-height: 40px;
  max-width: fit-content;
  background-color: #FFFFFF;
}

.ReportModal__Content .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

.ReportModal__Content .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}