.analyticsTitleBlockContainer {
    display: flex;
    flex-direction: row;
}

.analyticsTitleBlockRectangle {
    width: 12px;
    height: 28px;

    background: #26ABE1;
    border-radius: 4px;

    margin-right: 8px;
}

.analyticsTitleBlockHeaderText {
    font-family: "Roboto Medium";
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    color: #111A24;
}

.analyticsTitleBlockSubTextContainer {
    margin-left: 20px;
}

.analyticsTitleBlockSubText {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6C7E8E;
}