/* Tabs */
.ProductTabs--overflow-auto.NavTabs .ant-tabs-content {
  overflow-x: auto;
}

.prodMainContainer {
  width: 100%;
  height: 100%;
}

/* search */
.searchForm {
  padding: 0;
}

.searchFormAnalytics {
  margin-top: 34px;
  margin-left: 10px;
  width: 325px;
  float: left;
}

.searchBar {
  height: 35px;
}

.searchInput {
  width: 310px;
  height: 40px;
  position: static;
  left: 0px;
  top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 80px;
  color: #19305a;
  border: 2px solid #d7dae1;
}

.reportMainContainer input.searchInput {
  padding-left: 8px;
}

.speManagerAddSpecTr {
  display: flex;
}

.searchInput::placeholder,
.searchInput::-webkit-input-placeholder {
  color: #758ab9;
}

.searchInput:focus,
.searchInput:focus-visible {
  box-shadow: none;
  outline: none;
  border-color: #26abe1;
}

.productSearchBtn2 {
  width: 53px;
  height: 36px;
  background: #26a1d2;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
  right: 2px;
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

.specTable {
  width: 100%;
  /* font-family: "Roboto Medium"; */
  font-weight: 500;
  border-collapse: separate;
  /* border-spacing: 0px 8px; */
  border-spacing: 4px 4px;
}

.specTable thead {
  padding: 20px 98px;
  color: #19305a;
}

.specTable tbody {
  font-size: 14px;
  color: #19305a;
}

.specTable thead tr th {
  border: 1px solid #d7dae1;
  height: 48px;
  border-radius: 3px;
  text-align: center;
}

.specTable tbody tr {
  border: 1px solid #d7dae1;
  height: 48px;
  border-radius: 3px;
}

.specManagerEditSpecTh1 {
  height: 48px;
  width: 200px;
}

.specTable thead tr th {
  background-color: #d7dae1;
  font-family: "Roboto Medium";
  font-size: 12px;
}

.specTable tbody tr td {
  background-color: #fdfdfd;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #758ab9;
  border-radius: 100%;
  background: #fdfdfd;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #26abe1;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.normalWeight {
  font-family: "Roboto";
  font-weight: 400;
}

textarea:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 5px #719ece;
}

/* Product Analytics*/
.ant-menu-title-content {
  margin-top: 0px !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26abe1 !important;
  border-color: #26abe1 !important;
}

.ant-checkbox-inner {
  border: 1px solid #26abe1 !important;
}

.register-link .ant-checkbox-checked .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  background-color: #26abe1 !important;
  border: 1px solid #b3e0f2 !important;
}

.ant-row {
  background-color: #ffffff !important;
}

.ant-layout.site-layout.sm-margin-0 {
  /* background-color: #E5E5E5 !important; */
  height: calc(100vh);
}

.ant-checkbox-wrapper {
  width: 100%;
  font-family: "Roboto Bold";
  font-size: 14px !important;
  color: #19305a !important;
}

.searchHighlight {
  color: #26abe1;
  background: none;
  padding: 0;
}

.specManagerConfirmDel {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.specManagerConfirmDelTitle {
  font-family: Roboto Bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305a;
  margin-bottom: 16px;
}

.specManagerConfirmDelTitleMessage {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
}

.specManagerConfirmDelBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.specManagerConfirmDelBtnCancel {
  width: 60px;
  height: 32px;
  border: none;
  /* padding: 8px 12px; */
  background: #eff2f3;
  border-radius: 3px;
  color: #19305a;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.specManagerConfirmDelBtnDelete {
  width: 60px;
  height: 32px;
  border: none;
  /* padding: 8px 12px; */
  background: #26abe1;
  border-radius: 3px;
  color: #f9f9f9;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.specManagerConfirmDelBtnCancel:hover,
.specManagerConfirmDelBtnDelete:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.ActiveloadingAnimation {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06) 25%,
    rgba(0, 0, 0, 0.15) 37%,
    rgba(0, 0, 0, 0.06) 63%
  );
  background-size: 400% 100%;
  animation-name: active-loading-animation;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes active-loading-animation {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

/* Dot used to delimit linked fields (USED IN SPEC MANAGER AND ANALYTICS) */
.LinkedFieldsSpan {
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  word-wrap: break-word;
  word-break: break-word;
}

.LinkedFieldsDelimiterDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #26abe1;
  align-self: center;
  flex-shrink: 0;
}
