.COALoadingPage__Container {
  height: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 19px;
  padding-bottom: 18px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
  0px 8px 24px -4px rgba(24, 39, 75, 0.08);
}

.COALoadingPage__PlaceHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #EAF0F5;
  border-radius: 16px;
  height: calc(100vh - 253px);
}

@media (min-height: 1200px) {
  .COALoadingPage__PlaceHolder {
    height: calc(100vh - 252px);
  }
}

.COALoadingPage__Content {
  height: 100%;
  padding: 27px 29px;
  width: 100%;
  overflow: hidden;
}

.COALoadingPage__Skeleton {
  padding: 8px 12px 20px 12px;
  font-size: 16px;
  line-height: 24px;
}

.COALoadingPage__Header {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

.COALoadingPage__Search.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  width: 328.99px;
}

.COALoadingPage__CreateBtn.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  width: 171.89px;
}

.COALoading {
  display: flex;
  flex-direction: column;
  border: 1px solid #EAF0F5;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6C7E8E;
  height: 98.19px;
  margin-top: 32px;
}