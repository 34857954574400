.FloatingButton {
  position: relative;
  z-index: 3;
  box-shadow: 0px 0px 0.63px 0px #091e424f, 0px 5.04px 7.57px 0px #091e4226;
  border-radius: 50%;
  border: 1.89px solid #24aae1;
  background-color: #f0fdff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: default;
}

.FloatingButton--hover {
  background-color: #24aae1;
}
.FloatingButton--hover > svg > path {
  stroke: #fff;
}

.FloatingButton__Options {
  position: absolute;
  top: -130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  z-index: 2;
  cursor: default;
  right: 0;
}

.FloatingButton__Option {
  display: flex;
  gap: 10px;
  background-color: white;
  border: 1px solid #afc3e9;
  box-shadow: 0px 8px 10px -6px #1018281a, 0px 20px 25px -5px #1018281a;
  padding: 18.5px;
  border-radius: 50%;
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-family: "Roboto Medium";
  color: #26abe1;
  line-height: 18px;
}

.FloatingButton__Option--hover {
  border-radius: 200px;
}
.FloatingButton__Option--hover > span {
  color: #26abe1;
}

.FloatingButton__Option--hover > svg > path {
  fill: #26abe1;
}

.FloatingButton__Option_Create--hover {
  border-radius: 200px;
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-family: "Roboto Medium";
  color: #26abe1;
  line-height: 18px;
  box-shadow: 0px 8px 10px -6px #1018281a, 0px 20px 25px -5px #1018281a;
  /* padding: 18.5px; */
  display: flex;
  gap: 10px;
  border: 1.89px solid #26abe1;
}
