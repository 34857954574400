.SetFrequencyForm__ToggleContainer {
  padding: 12px 0;
}

.SetFrequencyForm__TypeToggle {
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  gap: 16px;
  border-radius: 4px;
  background: #F2F6FA;
}

.SetFrequencyForm__TypeToggle .SetFrequencyForm__ToggleButton {
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #26ABE1;
  background: transparent;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: none;
  text-transform: capitalize;
  letter-spacing: .1px;
}

.SetFrequencyForm__TypeToggle .SetFrequencyForm__ToggleButton.SetFrequencyForm__ToggleButton--selected {
  color: #FFFFFF;
  background: #26ABE1;
  box-shadow: 0px 1px 2px -1px #1018281A;
}

.SetFrequencyForm__OneTime {
  padding: 16px 0;
}

.SetFrequencyForm__OneTime .FieldTitle {
  display: block;
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #182534;
}