.CreateSamplingEventModal.ant-modal .ant-modal-content {
  border-radius: 12px;
  box-shadow: 0px 4.02px 19.67px -0.89px #18274B24;
  padding: 0;
  display: flex;
}

.CreateSamplingEventModal.ant-modal .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
}

.CreateSamplingEventModal .CreateSamplingEventModal__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
  border-bottom: 1px solid #EAF0F5;
}

.CreateSamplingEventModal .CreateSamplingEventModal__Header .CreateSamplingEventModal__HeaderTitle {
  font-family: "Roboto bold";
  font-size: 16px;
  font-weight: 500;
  color: #375393;
  margin: 0;
  padding: 0;
}

.CreateSamplingEventModal .CreateSamplingEventModal__Header .CreateSamplingEventModal__CloseButton {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.CreateSamplingEventModal .CreateSamplingEventModal__Content {
  flex: 1;
  min-height: calc(90vh - 152px);
  max-height: calc(90vh - 152px);
  /* min-height: 300px; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* background: #F8FAFC; */
  overflow: hidden;
}

.CreateSamplingEventModal .CreateSamplingEventModal__Footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 20px;
  border-top: 1px solid #EAF0F5;
}

.CreateSamplingEventModal .CreateSamplingEventModal__Footer button {
  height: 32px !important;
  min-width: 120px;
}

.CreateSamplingEventModal__ModalTabs {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 24px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 24px;
}

.CreateSamplingEventModal__ModalTabs .CreateSamplingEventModal__ModalTabItem {
  flex: 1;
}

.CreateSamplingEventModal__ModalTabs .CreateSamplingEventModal__ModalTabLine {
  width: 100%;
  height: 4px;
  background: #C4D2DF;
  margin-bottom: 8px;
}

.CreateSamplingEventModal__ModalTabs .CreateSamplingEventModal__ModalTabTitle {
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  color: #C4D2DF;
  text-align: left;
}

.CreateSamplingEventModal__ModalTabItem--selected {
  cursor: pointer;
}

.CreateSamplingEventModal__ModalTabItem.CreateSamplingEventModal__ModalTabItem--selected .CreateSamplingEventModal__ModalTabLine {
  background: #26ABE1;
}

.CreateSamplingEventModal__ModalTabItem.CreateSamplingEventModal__ModalTabItem--selected .CreateSamplingEventModal__ModalTabTitle {
  color: #26ABE1;
}

.CreateSamplingEventModal__ModalFooter__NextButton {
  gap: 10px;
}

.CreateSamplingEventModal__ContentScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.CreateSamplingEventModal__ContentScrollbar .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #133e8c !important;
}