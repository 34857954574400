.TemplateBuilder__COAPlaceholder {
  width: 100%;
  height: 181px;
  border: 1px solid #e1e9f1;
  border-left: 0;
  border-right: 0;
  padding-top: 19px;
  padding-bottom: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.TemplateBuilder__COAPlaceholder__Title {
  color: #6c7e8e;
  font-size: 22px;
  font-family: "Roboto";
  line-height: 28px;
}

.TemplateBuilder__COAPlaceholder__Subtitle {
  color: #c4d2df;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 18px;
}
