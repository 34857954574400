.StyledCheckbox__Checkbox .ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
  border: 2px solid #C4D2DF !important;
}

.StyledCheckbox__Checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #758AB9 !important;
  border-color: #758AB9 !important;
}

.StyledCheckbox__Checkbox span:not(.ant-checkbox) {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  color: #1F2D3B;
}

.StyledCheckbox__Checkbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #758ab9b0 !important;
  border-color: #758ab985 !important;
}

.StyledCheckbox__Checkbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: white;
}

.StyledCheckbox__Checkbox .ant-checkbox-wrapper-disabled span:not(.ant-checkbox) {
  color: #1F2D3Bb0;
}