.AntDDocumentAddModal.ant-modal {
  width: 90%;
  height: 95%;
  position: relative;
  background-color: white;
  padding: 0px;
  border-radius: 3px;
}

.AntDDocumentAddModal.ant-modal .ant-modal-content {
  padding: 0px;
}

.DocumentAddModal {
  position: absolute;
  top: 0px;
  width: 100%;
  border-radius: 0px;
}

.DocumentAddModal__Header {
  height: 48px;
  position: relative;
  background-color: #19305a;
}

.DocumentAddModal__Header__HeaderText {
  font-family: "Roboto";
  color: #f9f9f9;
  text-align: center;
  font-size: 20px;
  line-height: 48px;
}

.DocumentAddModal__Header > img {
  position: absolute;
  top: 14px;
  right: 26px;
  cursor: pointer;
}

.DocumentAddModal__TopBar {
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.DocumentAddModal__SearchSortContainer {
  height: 40px;
  margin: auto 24px;
  display: flex;
  align-items: center;
}

.DocumentAddModal__SearchSortContainer__Search {
  display: inline-block;
  margin-right: 40px;
}

.DocumentAddModal__SearchSortContainer__Search > input {
  width: 300px;
  height: 40px;
  background: #fdfdfd;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
}

.DocumentAddModal__SearchSortContainer__Search > input:focus {
  border: 2px solid #26a1d2;
  box-shadow: none;
  outline: none;
}

.DocumentAddModal__SearchSortContainer__Search > input::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #758ab9 !important;
}

.DocumentAddModal__SearchSortContainer__Search > input[type="text"] {
  padding-left: 31px;
  padding-right: 20px;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  color: #1a2e5c;
}

.DocumentAddModal__SearchSortContainer__Search > img {
  height: 13.54px;
  position: relative;
  left: 24px;
}

.DocumentAddModal__AddFilesContainer {
  display: flex;
  align-items: center;
  height: 40px;
  margin: auto 24px;
  color: #375393;
  font-size: 14px;
  font-family: "Roboto";
  gap: 20px;
}

.DocumentAddModal__AddFilesContainer__ClearButton {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
}

.DocumentAddModal__AddFilesContainer__NumFilesSelectedText {
  font-family: "Roboto Medium";
}

.DocumentAddModal__AddFilesContainer__AddFileButton {
  width: 64px;
  height: 32px;
}

.DocumentAddModal__TableContainer {
  position: relative;
}

.DocumentAddModal__TableContainer td,
.DocumentAddModal__TableContainer th {
  text-align: left;
}

.DocumentAddModal__TableContainer__ListViewContainer {
  background-color: #eff2f3;
}

.DocumentAddModal__TableContainer__ListViewContainer
  tr.DocFileTableHeadInside
  > th {
  background-color: #eff2f3;
}

.DocumentAddModal__TableContainer__ViewToggleContainer {
  position: absolute;
  top: 10px;
  right: 32px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  background-color: #fafafa;
  padding: 4px;
  width: 65px;
  border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.ViewToggleContainer__ViewButton {
  background: #26abe1;
  border-radius: 3px;
  padding: 6px;
  width: 24px;
  height: 24px;
  box-shadow: 0px 0.446956px 0.893912px -0.446956px rgba(24, 39, 75, 0.12),
    0px 0.893912px 0.893912px -0.446956px rgba(24, 39, 75, 0.08);
}

.ViewToggleContainer__ViewButton--inactive {
  background: unset;
  box-shadow: unset;
}

.ViewToggleContainer__ViewButton:first-child {
  padding: 4.5px 4px;
}
