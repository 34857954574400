.templateBuilderComponents {
  border: 1px solid #eaf0f5;
  border-radius: 16px;
  width: 100%;
  padding: 0 5px;
  background-color: #ffffff;
  position: relative;
}

.templateBuilderComponentsHeader {
  padding: 13px 8px 11px 14px;
  border-bottom: 1px solid #eaf0f5;
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #385387;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

.templateBuilderComponentsSubHeader {
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.38);
  padding: 16px 18px;
}

.templateBuilderComponentsTitle {
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
}

.templateBuilderComponentsTitle > img {
  height: 13.33px;
}

.templateBuilderComponentsTitleSmall {
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}

.templateBuilderComponentsTextbox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 19px 18px;
}

.templateBuilderTemplateInput {
  border: 2px solid #eaf0f5;
  background-color: #eaf0f5;
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #385387;
  border-radius: 8px;
  padding: 6px 12px;
  cursor: -webkit-grab;
  cursor: grab;
  user-select: none;
}

.templateBuilderTemplateInput:hover {
  transform: scale(1.01);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}

.templateBuilderTemplateInput.smallTextBox {
  height: 36px;
}

.templateBuilderTemplateInput.largeTextBox {
  height: 87px;
}

.templateBuilderTemplateDataField {
  border: 2px solid #f2f6fa;
  padding: 6px 12px;
  background-color: #f2f6fa;
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #385387;
  border-radius: 8px;
  cursor: -webkit-grab;
  cursor: grab;
  user-select: none;
  min-width: fit-content;
  max-height: min-content;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  word-break: break-word;
}

.templateBuilderTemplateDataField:hover {
  transform: scale(1.01);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}

.templateBuilderCustomDataField {
  border: 2px solid #9dd9f2;
  padding: 6px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: #f4fbfe;
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #375393;
  border-radius: 8px;
  cursor: -webkit-grab;
  cursor: grab;
  user-select: none;
  min-width: fit-content;
  max-height: min-content;
  justify-content: center;
  word-wrap: break-word;
  word-break: break-word;
}

.templateBuilderTemplateDataField:focus-visible,
.templateBuilderCustomDataField:focus-visible,
.templateBuilderTemplateInput:focus-visible {
  outline: unset;
  border-color: #26abe1;
}

.templateBuilderDataFieldFont {
  font-family: "Roboto Medium";
  color: #385387;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.templateBuilderDataFieldHeader {
  color: #26abe1;
  font-size: 16px;
  padding: 0 0 10px;
}

.templateBuilderDataFieldHeader__HelpingWord {
  color: #c4d2df;
  font-size: 14px;
}

.templateBuilderDataFieldHeader__Div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
}

.templateBuilderIsDragging {
  opacity: 0;
}

.templateBuilderComponentsCreateButton {
  cursor: pointer;
  border: 1px solid #eaf0f5;
  border-radius: 8px;
  color: #26abe1;
  font-family: "Roboto Medium";
  font-size: 12px;
  height: 32px;
  width: 140px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-left: 16px;
}

.templateBuilderComponentsCreateButton:hover {
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}

.templateBuilderComponentsDataFields {
  display: flex;
  flex-direction: column;
  min-height: 110px;
  flex-grow: 1;
}

.templateBuilderComponentsDataFields .templateSearchBarContainer {
  padding-left: 5px;
  padding-right: 5px;
}

.templateBuilderComponentsDataFields .templateSearchBarContainer--full-width {
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.templateBuilderComponentsDataFieldsSpan {
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #385387;
}

.templateBuilderProfileIcon {
  width: 14px;
  height: 15.3px;
  margin-left: 10px;
  margin-right: 10px;
}

.templateBuilderComponentsSortIcon {
  width: 14px;
  height: 17px;
}

.templateBuilderComponentsDataFieldsHeader {
  display: flex;
  justify-content: space-between;
  margin: 25px 12px 34px 14px;
}

.templateBuilderComponentsDataFieldsHeader.compact {
  margin: 12px 12px 20px 14px;
}

.templateBuilderComponentsDataFieldsHeader.marginless {
  margin: 0px;
  margin-top: 13px;
  min-width: fit-content;
}

.sortingDiv {
  height: 188px;
  border: 1px solid #eaf0f5;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 11px 6px 20px;
  width: 100%;
}

.closeIcon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.sortSpan {
  color: #26abe1;
  font-family: "Roboto Medium";
  font-size: 11px;
}

.sortSpan.greyTextColor {
  color: #6c7e8e;
}

.sortIconContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sortIconContainerSecondary {
  display: flex;
}

.sortingDivHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 11px;
  margin-right: 7px;
}

.radioButtonContainer {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.radioButtonContainer .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #26abe1;
  background-color: #26abe1;
}

.radioButtonContainer .ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  font-size: 12px;
  font-family: "Roboto Medium";
  color: #375393;
}

.radioButtonContainer .ant-radio-wrapper:hover .ant-radio-inner,
.radioButtonContainer .ant-radio:hover .ant-radio-inner,
.radioButtonContainer .ant-radio-input:focus + .ant-radio-inner {
  border-color: #26abe1;
}

.applyButton {
  width: 70px;
  height: 23px;
  background-color: #26abe1;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Roboto Medium";
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.applyButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.searchIcon {
  cursor: pointer;
  width: 36px;
  height: 38px;
}

.allDataFields {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  max-width: 100%;
  justify-items: stretch;
  align-items: center;
  /* display: flex;
  flex-flow: row wrap; */
  row-gap: 20px;
  column-gap: 10px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: white !important;
}

#builderComponentsScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #b3bfdb !important;
  height: 100%;
}

.builderSearchBar {
  width: 286px;
  height: 35px;
  background: #fdfdfd;
  border: 1px solid #eaf0f5;
  border-radius: 8px;
  color: #506375;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.builderSearchBarContainer {
  width: 315px;
  height: 40px;
  display: flex;
  position: relative;
  margin-left: -5px;
}

.builderSearchIconSmall {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 0px;
  margin-right: 39px;
  margin-top: 8px;
}

.customFieldImg {
  margin-bottom: 1px;
  pointer-events: none;
  height: 14px;
  width: 14px;
}

.TemplateBuilder__SearchAndSort {
  display: flex;
  flex-direction: row;
  padding-bottom: 22px;
}
