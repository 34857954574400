.InviteModal__HeaderContainer {
  background-color: #EAF0F5;
  margin-top: -12px;
  height: 54px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 0px 24px;

  border-radius: 8px 8px 0 0;
}

.InviteModal__HeaderText {
  font-family: "Roboto Medium";
  font-size: 16px;
  line-height: 24px;
  color: #182534;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
}

.InviteModal__CloseIcon {
  height: 17px;
  width: 17px;
  margin-top: 5px;
  cursor: pointer;
}

/* .InviteModal__CloseIcon:hover {
  background-color: #26ace1ab;
} */

/* Tooltip styling */
.InviteModal__Tooltip {
  font-family: "Roboto";
  font-size: 12px;
}

.InviteModal__Tooltip .ant-tooltip-inner,
.InviteModal__Tooltip .ant-tooltip-arrow::before {
  color: #505050;
  background-color: #e0e4eb;
}
