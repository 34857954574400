.SelectLocationForm__LocationTableCheckbox__Container {
  position: relative;
}

.SelectLocationForm__LocationTableCheckbox {
  appearance: none;
  display: none;
}

.SelectLocationForm__LocationTableCheckbox+label {
  cursor: pointer;
}

.SelectLocationForm__LocationTableCheckbox+label::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 0;
  border: 2px solid #6C7E8E !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #ffffff;
}

.SelectLocationForm__LocationTableCheckbox:checked+label::before {
  content: "✓";
  background: #26ABE1 !important;
  border-color: #26ABE1 !important;
  border-radius: 0.5px;
}