.ButtonWithLoading {
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.ButtonWithLoading.ButtonWithLoading--loading {
  opacity: 0.7;
  cursor: not-allowed;
}
