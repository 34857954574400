.signup-single-page-eye-icon {
  margin-left: -30px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.signup-single-page-password {
  position: relative;
}

.signup-password-input {
  margin-bottom: 0;
  padding-right: 36px;
}

.signup-password-require {
  font-family: "Roboto";
  font-style: normal;
  font-size: 11px;
  line-height: 20px;
  color: #375393;
  margin-top: 3px;
  margin-bottom: 15px;
}
