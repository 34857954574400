.TeamMemberLayout {
  background-color: white !important;
}

.TeamMemberLayout__Slide {
  margin-bottom: 10px;
}

/* .TeamMemberLayout__Slide_Button {
  height: 50px;
}

.TeamMemberLayout__Slide_Button__NewMember {
  margin-right: 34px !important;
  margin-top: 0px !important;
  height: 32px !important;
  float: right !important;
} */

.TeamMemberLayout__ScrollBar .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding-top: 42px;
  right: 0;
}

.TeamMemberLayout__ScrollBar {
  height: calc(100vh - 200px) !important;
  background-color: #fdfdfd;
  /* margin-left: -10px; */
}

.TeamMemberLayout__ScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
  width: 6px !important;
}

.TeamMemberLayout__ScrollBar
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #19305a !important;
}

.myAccountMemberMainContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.newMemberBtnTableDiv {
  position: relative;
}

table.TeamMemberTable {
  border-collapse: collapse;
  overflow-x: scroll;
  width: 100%;
  color: #212529;
  margin-bottom: 2px;
}

.TeamMember__TableHeaderCell {
  position: sticky;
  top: -1px;
  background-color: #eff2f3;
  border: 0 !important;
  text-transform: uppercase;
  text-align: left;
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #375393;
  z-index: 1;
  padding: 12px;
}

.TeamMember__TableHeaderCell.TeamMember__TableHeaderCell--center-align {
  text-align: center;
}

/* .member-tbody-customTr {
  border-top: none !important;
} */

.myAccountMemberMainContainer thead {
  /* margin-top: 20px; */
  background: #eff2f3;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.13),
    0px 0px 1px rgba(9, 30, 66, 0.23);
}

.myAccountMemberMainContainer tbody {
  margin-top: 20px;
  background: #fdfdfd;
}

.myAccountMemberMainContainer .ScrollbarsCustom {
  height: calc(100vh - 236px) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.myAccountMemberMainContainer .ScrollbarsCustom-Content {
  min-height: unset !important;
}

.TeamMemberTable td.TeamMember__TableCell {
  padding-top: 20px !important;
  text-align: left;
  vertical-align: middle !important;
  width: 30%;
  min-width: 120px;
  font-family: "Roboto";
  font-size: 14px;
  border-top: none !important;
  color: #19305a;
  padding: 12px;
}

.TeamMemberTable td.TeamMember__TableCell.TeamMember__TableCell--center-align {
  text-align: center;
}

.TeamMemberTable
  td.TeamMember__TableCell.TeamMember__TableCell--center-align
  > .TeamMember__TableCellContent {
  justify-content: center;
}

.TeamMember__TableCellContent {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.TeamMemberLayout_Button_TrashIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.TeamMemberLayout_Button_TrashIcon path:last-of-type,
.TeamMemberLayout_Button_TrashIcon--disabled path:last-of-type {
  fill: transparent;
}

.TeamMemberLayout_Button_TrashIcon:hover path {
  fill: #26a1d2;
}

.TeamMemberLayout_Button_TrashIcon--disabled {
  width: 20px;
  height: 20px;
  cursor: not-allowed;
  opacity: 0.5;
}

.newMemberBtn {
  position: relative;
  left: calc(100% - 125px);
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px 15px;
  width: 125px;
  height: 36px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 12px;
}

.newMemberBtn:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.specificationInfo {
  margin-left: 7px;
  height: 20px;
  padding: 3.3px;
}

/* Role column and select styling */
.TeamMember__Role.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  min-width: 95px;
  z-index: 0;
  font-family: "Roboto";
  font-size: 14px;
  color: #19305a;
  margin-left: -12px;
}

.TeamMember__Role.ant-select-single.ant-select-disabled .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
}

.TeamMember__Role.ant-select .ant-select-selector {
  padding: 0 0 0 12px;
}

.TeamMember__Role.ant-select:not(.ant-select-disabled):hover
  .ant-select-selector,
.TeamMember__Role.ant-select:not(.ant-select-disabled).ant-select-focused
  .ant-select-selector {
  background: #def2fb !important;
}

.TeamMember__Role__DropdownMenu.ant-select-dropdown .ant-select-item {
  padding: 5px 10px;
}

.TeamMember__Role__DropdownMenu.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #def2fb;
}

/* Tooltip styling */
.TeamMember__Tooltip {
  font-family: "Roboto";
  font-size: 12px;
}

.TeamMember__Tooltip .ant-tooltip-inner,
.TeamMember__Tooltip .ant-tooltip-arrow::before {
  color: #505050;
  background-color: #e0e4eb;
}
