.SampleTestResultTable__ScrollContainer {
  height: 325px !important;
  background: #eff2f3;
  padding: 7px;
  padding-right: 0px;
  border-radius: 8px;
}

.SampleTestResultTable {
  width: 100%;
  font-family: "Roboto Medium";
  font-weight: 500;
  border-collapse: separate;
  border-spacing: 4px 4px;
  background: #eff2f3;
  padding-right: 7px;
  table-layout: fixed;
}

.SampleTestResultTable thead {
  color: #19305a;
}

.SampleTestResultTable tbody {
  max-height: 250px;
  overflow: auto;
}

.SampleTestResultTable tbody {
  font-size: 14px;
  color: #19305a;
}

.SampleTestResultTable tbody tr td,
.SampleTestResultTable thead tr th {
  /* border: 1px solid #D7DAE1; */
  border-radius: 3px;
  text-align: center;
}

.SampleTestResultTable thead tr th {
  background-color: #d7dae1;
  font-weight: 500;
  font-size: 12px;
  height: 48px;
}

.SampleTestResultTable tbody tr td {
  background-color: #fdfdfd;
}

.sampleTestResultTableTestColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  z-index: 1;
  min-height: 48px;
}

.sampleTestResultTableCheckBoxContainer {
  display: flex;
  width: 18px;
}

.sampleTestResultTableTestText {
  flex: 1;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.sampleTestResultTableHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}

tr.tempClass:hover > td {
  background-color: #e9f9ff;
}

.SampleTestResultTable__ScrollContainer
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding: 5px 0px;
}

.SampleTestResultTable__ScrollContainer
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
  width: 6px !important;
}

.SampleTestResultTable__ScrollContainer
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #19305a !important;
}

.SampleTestResultTable__TestInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.SampleTestResultTable__PesticideDetailTitle {
  font-size: 14px;
  font-weight: 500;
  color: #19305A;
}

.SampleTestResultTable__TotalTestContainer {
  color: #758AB9;
  /* margin-right: 2px;
  margin-bottom: 4px; */
  font-family: 'Roboto Medium';
  font-weight: 500;
  font-size: 12px;
}

.SampleTestResultTable__TotalTestTitle {
  color: #758AB9;
  margin-right: 5px;
  font-family: 'Roboto Medium';
  font-weight: 500;
  font-size: 12px;
}

.SampleTestResultTable__TotalTestValue {
  color: #19305A;
}

.SampleTestResultTable__PesticideResult {
  text-decoration: underline;
  cursor: pointer;
}

.TruncateText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}