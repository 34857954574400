.DetailedPesticideReport__ResultTable {
  width: 100%;
  font-family: "Roboto Medium";
  font-weight: 500;
  border-collapse: separate;
  border-spacing: 4px 4px;
  background: #eff2f3;
  padding-right: 7px;
  table-layout: fixed;
}

.DetailedPesticideReport__ResultTable thead {
  color: #19305a;
}

.DetailedPesticideReport__ResultTable tbody {
  max-height: 250px;
  overflow: auto;
}

.DetailedPesticideReport__ResultTable tbody {
  font-size: 14px;
  color: #19305a;
}

.DetailedPesticideReport__ResultTable tbody tr td,
.DetailedPesticideReport__ResultTable thead tr th {
  /* border: 1px solid #D7DAE1; */
  border-radius: 3px;
  text-align: center;
}

.DetailedPesticideReport__ResultTable thead tr th {
  background-color: #d7dae1;
  font-weight: 500;
  font-size: 12px;
  height: 48px;
}

.DetailedPesticideReport__ResultTable tbody tr td {
  background-color: #fdfdfd;
}

.DetailedPesticideReport__ResultTableHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}

.DetailedPesticideReport__ResultTableColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  z-index: 1;
  min-height: 48px;
}

.DetailedPesticideReport__ResultTableText {
  flex: 1;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.DetailedPesticideReport__ResultTableHead {
  background-color: #26A1D2 !important;
  color: white !important;
}

.DetailedPesticideReport__ReportHeader {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.DetailedPesticideReport__BackButton, .DetailedPesticideReport__AddButton {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.DetailedPesticideReport__BackButton {
  margin-right: 24px;
}

.DetailedPesticideReport__BackButton img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.DetailedPesticideReport__AddButton img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.DetailedPesticideReport__CountriesTabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 1;
  border-left: 2px solid #AFBDCA;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 24px;
  height: 32px;
  list-style: none;
  gap: 8px;
  /* overflow-x: auto;
  overflow-y: hidden; */
}

.DetailedPesticideReport__CountriesTabItem {
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px 4px;
  color: black;
  position: relative;
  border-bottom: 4px solid transparent;
  font-weight: 400;
  cursor: pointer;
}

.DetailedPesticideReport__CountriesTabItem.DetailedPesticideReport__CountriesTabItem__Selected {
  border-bottom-color: #26ABE1;
  font-weight: 500;
}

.DetailedPesticideReport__Loading {
  height: 300px;
  background: #eff2f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .DetailedPesticideReport__OtherTests {
  text-decoration: underline;
  cursor: pointer;
} */

.TruncateText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.DetailedPesticideReport__SpecNoResult {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  padding-top: 35px;
  padding-bottom: 35px;
}

.DetailedPesticideReport__SpecNoResultWatermelonImg {
  width: 220px !important;
  height: 160px !important;
}

.DetailedPesticideReport__SpecNoResultErrorMessage {
  font-size: 16px;
  color: #758ab9;
  font-family: Roboto;
}