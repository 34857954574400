.RequestRetestModal.ant-modal .ant-modal-content {
  padding: 10px 0px 18px;
  border-radius: 3px;
}

.requestRetestModalContainer {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  padding: 20px 40px 10px 40px;
}

.requestRetestHeaderText {
  font-family: "Roboto Bold";
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  color: #19305a;
  margin-bottom: 10px;
}

.requestRetestMainInfoText {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #375393;
  margin-top: 16px;
  margin-bottom: 0px;
}

.requestRetestModalContainer .ScrollbarsCustom {
  min-height: unset !important;
}

.requestRetestTestListScrollContainer {
  height: 120px;
  width: 100%;
  /* margin: 10px 0px; */
  padding: 4px;
  border-radius: 3px;
}
.requestRetestTestList {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #375393;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  /* justify-content: space-between; */
  gap: 5px;
  width: 100%;
  height: 100%;
}

.requestRetestTestDiv {
  box-sizing: border-box;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1.5px solid #375393;
  border-radius: 8px;
  background: #f6f8fa;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.requestRetestSubInfoText {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #375393;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.requestRetestModalButtonDiv {
  display: flex;
  align-self: flex-end;

  margin-top: 28px;
}

.requestRetestModal__CancelBtn {
  background-color: #eff2f3 !important;
  color: #19305a !important;
  border-color: #eff2f3 !important;
  width: 64px;
  margin-right: 8px;
}

.requestRetestModal__CancelBtn:hover {
  background-color: #26abe1;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.requestRetestModal__SubmitBtn {
  min-width: 64px;
}
