.env-empty-page {
  margin-top: 43px;
  height: calc(100% - 43px);
  background: #FFFFFF;
}

.env-empty-white-page {
  height: calc(100% - -96px);
  background: #FFFFFF;
  width: 120%;
  margin: -15px 0px 0px -73px;
}

.env-upload-body {
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 51px;
  
  color: #375393;

  position: absolute;
  width: 413px;
  height: 108px;
  left: calc(50% - 391px/2);
  top: calc(50% - 108px/2);
}

.env-upload-body.top-label {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  height: 124px;
}

.env-upload-body:hover path {
  fill: #26ABE1;
}

.env-upload-body:hover {
  color: #26ABE1;
}