.sample-details-column .input-checkbox-list-container {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  width: 100%;
}

.input-checkbox-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  gap: 14px;
  margin-top: 16px;
}

.input-checkbox-row:first-of-type {
  margin-top: 0;
}

.input-checkbox-row:last-of-type {
  margin-bottom: 16px;
}

.input-checkbox-row.composite-sample-input:has(.hide-label) {
  margin-top: 8px;
}

.SampleDetails__InputDiv,
.SampleDetailsAutocompleteInput {
  width: calc(100% - 24px);
}

.SampleDetails__InputDiv {
  display: inline-block;
  position: relative;
}

.sample-field-minus-icon {
  margin-left: 6px;
  cursor: pointer;
  border-radius: 100%;
}

.sample-field-minus-icon:hover {
  content: url("../../../../../assets/images/sample-submission/FieldsMinusIconHover.png");
}

.sample-field-minus-icon:focus-visible {
  outline: 2px solid #97a3ae;
}

.header-input-checkbox-list-container {
  margin-top: -15px;
  flex-grow: 1;
}

.use-in-all-samples-subtitle {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #6c7e8e;
  font-size: 12px;
  text-align: center;
  gap: 9px;
  top: 8px;
  width: 100%;
}

/* Pills horizontal scrollbar styling */
.new-submission-pages-card.sample-details
  > .ScrollbarsCustom.trackXVisible
  .ScrollbarsCustom-TrackX {
  display: block;
  left: 0px !important;
  top: 35px !important;
  height: 5px !important;
  width: 100% !important;
}

.new-submission-pages-card.sample-details
  > .ScrollbarsCustom.trackXVisible
  .ScrollbarsCustom-TrackY {
  display: none;
}

.new-submission-pages-card.sample-details
  > .ScrollbarsCustom.trackXVisible
  .ScrollbarsCustom-TrackX
  .ScrollbarsCustom-ThumbX {
  position: relative;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* Field selection pills styling */

.sample-field-pills-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  overflow: visible;
  width: fit-content;
}

.sample-field-pill {
  padding: 6px 8px;
  height: 28px;
  width: fit-content;
  flex-shrink: 0;
  background: rgba(242, 246, 250, 1);
  border-radius: 4px;
  color: rgba(108, 126, 142, 1);
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.sample-field-pill:hover,
.sample-field-pill:hover > svg > path {
  color: rgba(38, 171, 225, 1);
  stroke: rgba(38, 171, 225, 1);
  background: #f6f8fa;
}

.sample-field-pill > svg > path {
  stroke: rgba(108, 126, 142, 1);
}

.sample-field-pill > svg {
  margin-left: 8px;
}

/* Input-checkbox-list (vertical) scrollbar styling */

.header-input-checkbox-list-container
  > .ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-TrackY {
  right: -10px !important;
}

.header-input-checkbox-list-container
  > .ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Scroller {
  margin-right: -25px !important;
}

/* Custom checkbox styling */
.sample-submission-checkbox-container {
  position: relative;
  flex-grow: 0.4;
  flex-shrink: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  min-width: 107px;
}

.sample-submission-checkbox-container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sample-submission-checkmark {
  position: relative;
  cursor: pointer;
  border-radius: 0.5px;
  width: 18px;
  height: 18px;
  border: 2px solid #dfe1e6;
}

.sample-submission-checkmark--hidden {
  position: relative;
  pointer-events: none;
  opacity: 0;
  border-radius: 0.5px;
  width: 18px;
  height: 18px;
  border: 2px solid #dfe1e6;
}

.sample-submission-checkbox-container
  input:checked
  ~ .sample-submission-checkmark {
  border-color: #afbdca;
  background-color: #afbdca;
}

.sample-submission-checkbox-container
  input:focus
  ~ .sample-submission-checkmark {
  border-color: #97a3ae;
}

.sample-submission-checkbox-container .sample-submission-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: solid #fff;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
  left: 4.5px;
  top: 0.5px;
  width: 5px;
  height: 9px;
}

.sample-submission-checkbox-container
  input:checked
  ~ .sample-submission-checkmark:after {
  display: block;
}

.no-fields-added-text {
  font-family: "Roboto";
  color: #6c7e8e;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  justify-self: center;
}

.sample-details-input {
  flex-grow: 0.94;
  flex-basis: 0;
}

.sample-details-input input {
  height: 32px;
  width: 100%;
  background: rgba(250, 251, 252, 1);
  border: 2px solid rgba(196, 210, 223, 1);
  border-radius: 4px;
  font-family: "Roboto Medium";
  font-size: 14px;
  color: rgba(24, 37, 52, 1);
  padding: 4px 15px 4px 7px;
}

.sample-details-input input:focus {
  border: 2px solid #97a3ae;
}

/* Error icon tooltip styling */
.sample-details-input-title > svg > path {
  fill: red;
}

/* on tooltip hover, change the color of the inner svg */
.sample-details-input-title > svg:hover > path {
  fill: rgb(166, 2, 2);
}

.sample-submission-checkmark-opacity {
  opacity: 0;
}

.SampleDetails__GrayDivider {
  border: 0.5px solid #d1dce8;
  width: 90%;
  height: 0px;
}

.SampleDetails__GrayDivider--ImageSection {
  margin-top: 10px;
  margin-bottom: 14px;
  border-top: 1px solid #d1dce8;
  width: 100%;
  height: 0px;
}

.sample-submission-composite-toggle-container {
  border-bottom: 1px solid #eaf0f5;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  white-space: nowrap;
  height: 49px;
}

.sample-submission-composite-container {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "Roboto Medium";
  font-size: 16px;
}

.sample-submission-composite-sample-container {
  display: flex;
  gap: 16px;
  align-items: center;
  font-family: "Roboto Medium";
  font-size: 14px;
}

.sample-submission-composite-edit {
  cursor: pointer;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background-color: #f2f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sample-submission-composite-edit:hover {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.sample-submission-composite-edit-icon path {
  fill: #26abe1;
}

.sample-submission-composite-edit:hover
  .sample-submission-composite-edit-icon
  path {
  fill: #9dd9f2;
}

.CompositeSwitch.ant-switch.ant-switch-checked,
.CompositeSwitch.ant-switch:hover.ant-switch-checked {
  background-color: #26abe1;
}
