.TemplateBuilderContainer--twoColumn > .TemplateBuilder__CenterContainer {
  min-width: 720px;
}

.TemplateBuilder__CenterContainer {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #eaf0f5;
  border-radius: 16px;
  background-color: #ffffff;
  min-width: 836px;
}

.templateBuilderCenter {
  height: 100%;
}

.templateBuilderCenter .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: transparent !important;
}

.templateBuilderCenter .ScrollbarsCustom-Wrapper {
  right: 0px !important;
}

.templateBuilderCenterContent {
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: unset;
  padding: 0 21px 0 21px;
}

.builderSectionHide {
  display: none;
}
