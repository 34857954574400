.CompanyInfoForm__TagSelect .ant-select-selector {
  padding-top: 4px;
  padding-bottom: 4px;
}

.CompanyInfoForm__TagSelect
  .ant-select-selector
  .ant-select-selection-overflow {
  row-gap: 4px;
  width: 0px; /** Set width = 1px or 0px here to prevent long input text causes the component grows beyond the parent component */
  overflow: hidden; /** Set overflow hidden here to prevent long input text causes the component grows beyond the parent component */
}

.CompanyInfoForm__Tag > span {
  white-space: normal;
  word-break: break-all;
}

.CompanyInfoForm__Tag.ant-tag {
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto";
  font-weight: 400;
  display: flex;
  align-items: center;
  white-space: normal;
  word-break: break-all;
}

.CompanyInfoForm__Tag.ant-tag > .ant-tag-close-icon svg {
  height: 6.33px;
  width: 6.33px;
  align-items: center;
  margin-inline-start: 10px;
  margin-inline-end: 5px;
}

.CompanyInfoForm__Tag_Admin.ant-tag {
  background-color: #eaf0f5 !important;
  padding: 2px 8px;
  border: unset;
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto";
}

.OnboardingFormTitle {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-bottom: 35px;
  color: #182534;
}

.OnboardingFormSubTitle {
  display: flex;
  margin-top: 25px;
}

.OnboardingFormSubTitle p {
  font-family: "Roboto medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #375393;
  margin: 13px 0px 10px 0px;
}

.OnboardingFormSubTitle span {
  width: 12px;
  height: 28px;
  gap: 0px;
  border-radius: 4px;
  background: #26abe1;
  margin: 10px 10px 10px 0px;
}

/* .CompanyContactForm__ContactInfoSection > .ant-form-item {
  padding: 0px;
  margin-bottom: 20px;
} */

.CompanyContactForm__ContactInfoSection_FieldsText {
  font-family: "Roboto Medium";
  font-size: 12px;
  letter-spacing: 0.5px;
  padding-bottom: 4px;
  line-height: 16px;
  font-weight: 500;
  color: #182534;
  text-wrap: nowrap;
}

.CompanyContactForm__ContactInfoSection_FieldsText > .ant-input-disabled {
  background-color: #f2f6fa;
  color: #6c7e8e;
}

.CompanyInfoForm__TagSelect .ant-select-selector {
  min-height: 80px !important;
  padding: 13px 8px;
  margin-top: 0px !important;
}

#company_info_form_customer_name {
  margin-top: 0px;
}

.CompanyInfoForm__TagSelect .ant-select-selection-overflow {
  align-self: flex-start;
}

.Clear__Button {
  fill: #182534;
  width: 9.5px;
  height: 9.5px;
  top: 7.25px;
  left: 7.25px;
}

.CompanyInfoForm__TagSelect .ant-tag-default {
  border: 2px solid #eaf0f5;
  background-color: #f8fafc;
  color: #182534;
}
