.ReportsTableWrapper {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  flex-grow: 1;
}

table.ReportsTable {
  width: 100%;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: -10px;
  table-layout: fixed;
}

.ReportsTable--no-results {
  position: sticky;
  top: 0px;
}

.ReportsTable__NoResults {
  margin-top: 10px;
  flex-grow: 1;
  min-height: 400px;
}

/* Scrollbar */
.ReportsTableScrollbar {
  position: "";
  width: 100%;
  height: 100%;
}

.ProductReport__MainContainer .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY,
.ReportsTableScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
  width: 6px !important;
}

.ProductReport__MainContainer
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY,
.ReportsTableScrollbar
  .ScrollbarsCustom-TrackY:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #19305a !important;
}

.ReportsTableScrollbar .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  display: block;
}

.ProductReport__MainContainer .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX,
.ReportsTableScrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  background-color: #b3bfdb !important;
  height: 6px !important;
}

.ProductReport__MainContainer
  .ScrollbarsCustom-TrackX:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX,
.ReportsTableScrollbar
  .ScrollbarsCustom-TrackX:hover
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  background-color: #19305a !important;
}

.ReportsTableScrollbar .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  /* background: #EFF2F3 !important; */
  padding-top: 45px;
  right: 0px;
}

.ReportsTableScrollbar .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  height: 6.5px !important;
  width: calc(100% - 6.5px) !important;
  background: inherit !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 2;
}

.ReportsTableScrollbar--no-results .ScrollbarsCustom-Content {
  display: flex;
  flex-direction: column;
  position: relative;
}
