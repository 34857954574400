.signup-invite-invite-btn {
  align-self: flex-end;
  width: 150px;
  flex-flow: row-reverse;
  gap: 10px;
}

.SignupInvite__InviteButton__Arrow {
  fill: white;
  transform: rotate(180deg);
}
