.EnvAnalyticsGraph__TestPills--Container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
}

.EnvAnalyticsGraph__TestPill {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  width: 110px;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

/* .EnvAnalyticsGraph__TestPill.selected {
    opacity: 0.8;
  }
   */
.EnvAnalyticsGraph__TestPill .analyticsGraphPillImage {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  flex-shrink: 0; /* Prevent image from shrinking */
  display: flex;
  align-items: center;
  justify-content: center;
}

.EnvAnalyticsGraph__TestPill svg.analyticsGraphPillImage {
  width: 16px;
  height: 16px;
}

.EnvAnalyticsGraph__TestPill span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #182534;
}

.EnvAnalyticsGraph__ShowMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px; /* Same width as test pills */
  height: 32px; /* Same height as test pills */
  padding: 5px 10px;
  border: none;
  background-color: #afbdca;
  color: #fff;
  border-radius: 15px; /* Match pill border-radius */
  cursor: pointer;
  flex-shrink: 0; /* Prevent button from shrinking */
  white-space: nowrap; /* Prevent text from wrapping */
}

.EnvAnalyticsGraph__ShowMoreButton:hover {
  background-color: #8fa3b3;
}

/* Tooltip styling */
.EnvAnalyticsGraph__GraphPill__Tooltip.ant-tooltip .ant-tooltip-inner {
  background: #eff2f3;
}

.EnvAnalyticsGraph__GraphPill__Tooltip__Text {
  word-break: break-word;
  white-space: pre-wrap;
}

.EnvAnalyticsGraph__GraphPill__Tooltip__FieldText {
  color: #375393;
  font-family: "Roboto Medium";
  font-size: 12px;
  line-height: 18px;
}

.EnvAnalyticsGraph__GraphPill__Tooltip__ValueText {
  color: #6c7e8e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.EnvAnalyticsGraph__GraphPill__Tooltip__Text {
  word-break: break-word;
  white-space: pre-wrap;
}

.EnvAnalyticsGraph__GraphPill__Tooltip__ValueText {
  color: #182534;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
