.pin-detail-block-status-color-tag.in-spec-color-tag {
  background-color: #1EC04A;
}

.pin-detail-block-status-color-tag.out-of-spec-color-tag {
  background-color: #E63559;
}

.pin-detail-block-status-color-tag.transparent-color-tag {
  background-color: transparent;
}

.pinInfoBlock {
  font-size: 12px;
  font-family: "Roboto Medium";
  padding: 10px 7px 8px 9px;
  margin: 1px 15px 10px 1px;
  color: #19305A;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 3px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
}

.PinDetailBlock__Body {
  background-color: #FFFFFF;
  border: 0.5px solid #FFFFFF;
}

.PinDetailBlock__Body--selected {
  background: #E9F1F3;
  border: 0.5px solid #E9F1F3;
  box-shadow: none;
}

.pinInfoBlock .pinBasicInfo {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.pinInfoBlock .pinBasicInfo span {
  word-break: break-all;
}

.pinInfoBlock.darkBackground {
  background-color: #EFF2F3;
  border: 0.5px solid #EFF2F3;
}

.pinInfoBlock.highlightBackground {
  background-color: #C3E5F3;
  border: 0.5px solid #C3E5F3;
}

.pinInfoBlock.confirmDeleteBlock {
  background-color: #EDEDED;
  border: 0.5px solid #EDEDED;
  padding: 7px 10px 7px 10px;
}

.pinInfoBlock input {
  cursor: pointer;
}

.pinInfoBlock.checkboxVisible {
  border: 0.5px solid #26ABE1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  border: 0.5px solid #FFFFFF;
}

.pinInfoBlock > label {
  display: none;
  position: absolute;
  left: 2px;
  top: 13px;
}

.pinInfoBlock.checkboxVisible label,
.pinInfoBlock:hover label {
  display: block;
}

.pinInfoBlock .label {
  color: #375393; 
}

.pinInfoBlock .date {
  font-size: 10px;
  color: #375393;
  margin-top: 4px;
  line-height: 18px;
}

.pinInfoBlock button {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 16px;
  width: auto;
  margin-left: 8px;
  border-radius: 3px;
  border: none;
}

.pinInfoBlock button {
  padding: 4px 12px;
  border-color: transparent;
}

.PinDetailBlock__Content {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
}

.pinInfoBlock {
  cursor: pointer;
}

.pinDetailBlock .pinInfoBlock .label {
  color: #758AB9;
}

.pinDetailBlock,
.selecting-quadrants {
  position: relative;
}

.PinDetailBlock__QuadrantsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 11px;
  line-height: 13.33px;
  padding-left: 12px;
  padding-right: 3px;
}

.PinDetailBlock__Quadrants {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 7px 14px 0px 12px;
}

.PinDetailBlock__Quadrant {
  width: 64px;
  height: 32px;
  border-radius: 8px;
}

.PinDetailBlock__PinnedIcon,
.PinDetailBlock__UnpinnedIcon {
  background: #FAFAFA;
  filter: drop-shadow(0px 1px 1px rgba(9, 30, 66, 0.25)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.PinDetailBlock__UnpinnedIcon {
  background-color: #f9f9f9;
}

.PinDetailBlock__PinnedIcon--cursor-auto {
  cursor: auto;
}

.PinDetailBlock__PinnedIcon--grayOut,
.PinDetailBlock__UnpinnedIcon--grayOut {
  opacity: 0.3;
}

.PinDetailBlock__QuadrantTopLeft,
.PinDetailBlock__PinnedIcon--quadrantTopLeft {
  background-color: #f878ff;
}

.PinDetailBlock__QuadrantTopRight,
.PinDetailBlock__PinnedIcon--quadrantTopRight {
  background-color: #0093a7;
}

.PinDetailBlock__QuadrantBottomRight,
.PinDetailBlock__PinnedIcon--quadrantBottomRight {
  background-color: #8a1cff;
}

.PinDetailBlock__QuadrantBottomLeft,
.PinDetailBlock__PinnedIcon--quadrantBottomLeft {
  background-color: #a7bb2a;
}

.PinDetailBlock__Quadrant--selected {
  border: 2px solid rgba(25, 48, 90, 0.5);
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25);
}

.PinDetailBlock__QuadrantsSelectBody {
  padding: 10px 7px 11px 9px;
  border: 0.5px solid #fff;
}

.pinDetailBlock-heading,
.selecting-quadrants-heading {
  color: #19305A;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.pinDetailBlock-heading {
  width: fit-content;
}

.PinDetailBlock__DeleteIcon {
  width: 13.33px;
  height: 13.33px;
  cursor: pointer;
}

.pin-detail-block-status-color-tag {
  width: 8px;
  position: absolute;
  z-index: 1;
  border-radius: 4px 0 0 4px;
  height: 100%;
}

.PinDetailBlock__PinnedIconImg,
.PinDetailBlock__UnpinnedIconImg {
  width: 16px;
  height: 14.17px;
}

.PinDetailBlock__UnpinnedIcon.PinDetailBlock__UnpinnedIcon--cursor-auto {
  cursor: auto;
}

.PinDetailBlock__UnpinnedIcon--hoveringColor-blue,
.pinDetailBlock .hovering-pin-icon,
.pinInfoBlock .hovering-pin-icon,
.pinInfoBlock:hover .pinDetailBlock-heading {
  color: #26ABE1;
}

.quadrant-grayed-out-filter {
  opacity: 0.5;
}

.PinDetailBlock__InfoFields {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  margin-top: 6px;
}

.pinDetailBlock .swab-description {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.0025em;
}

.pinDetailBlock .pin-detail-block-icon-and-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 80px;
}

.pinDetailBlock .pinInfoBlock .pin-detail-block-date {
  color: #B3BFDB;
  margin-top: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  letter-spacing: 0.015em;
}

.pinDetailBlock .pin-detail-block-grayed-out {
  position: absolute;
  background: #FFFFFF;
  top: 0px;
  opacity: 0.7;
  width: 100%;
  height: calc(100% + 10px);
  margin-left: -11px;
  margin-top: -1px;
  border-radius: 4px;
  z-index: 2;
}

.PinDetailBlock__SearchHighlight {
  color: #26ABE1;
  background: none;
  padding: 0;
}