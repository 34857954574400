.sample-submission-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 23px 15px 36px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 5px 0px rgba(9, 30, 66, 0.08),
    0px 3px 10px 0px rgba(9, 30, 66, 0.05);
  overflow: hidden;
}

.sample-submission-main-container-flex {
  display: flex;
  gap: 20px;
}

.sample-submission-create-new-in-center {
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submissions-list {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  padding-top: 15px;
  flex: 1 1;
  max-width: 100%;
  /* overflow: hidden; */
}

.submissions-list .submitted-submission-row:hover {
  cursor: pointer;
}

.sample-submission-list-fixed-width {
  width: 400px;
}

.sample-submission-list-zero-width {
  width: 0;
}

.sample-submission-main-container h2 {
  font-size: 16px;
  line-height: 32px;
}

/* Loading skeletons and no results styling */
.submitted-submissions-table-skeleton-td {
  vertical-align: middle;
}

.submitted-submissions-table-skeleton,
.submitted-submissions-table-skeleton--draft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 9px 16px 9px 8px;
  min-height: 54.5px;
}

.submitted-submissions-table-skeleton {
  margin-top: 4px;
}

.submitted-submissions-table-skeleton--draft {
  min-height: 85px;
}

.submitted-submissions-no-results {
  margin-top: 10px;
  flex-grow: 1;
  min-height: 400px;
}

.submitted-submissions-container .submitted-submissions-no-results>img {
  height: 290px;
  object-fit: contain;
  width: 314px;
}

.submitted-submissions-no-results>.no-results-gif-common__msg {
  color: #6c7e8e;
  font-family: "Roboto";
}

.submitted-submissions-no-results--draft {
  font-size: 12;
  font-family: "Roboto";
  color: #6c7e8e;
}

.MainPage_DraftToggle {
  background: #375393;
  border: none;
  color: white;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  position: absolute;
  right: 0;
  top: 40px;
}

.MainPage_DraftToggle span {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.MainPage_DraftToggle .MainPage_DraftToggleIcon {
  transform: rotate(90deg);
  margin-right: 8px;
}

.MainPage_DraftToggle:hover .MainPage_DraftToggleIcon {
  transform: translateX(-2px) rotate(90deg);
  transition: transform .2s ease;
}