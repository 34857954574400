.SubmissionsHeader {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  row-gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
  padding-right: 10px;
  max-width: 100%;
}

.Submissions_FloatingButton {
  position: absolute;
  bottom: -12px;
  right: 0px;
  cursor: pointer;
}

.submitted-submissions-container {
  display: flex;
  flex-direction: column;
  flex: 2.4 2.4 0;
  height: 100%;
  position: relative;
}

/* Scrollbar styling */
/* .submitted-submissions-container .ScrollbarsCustom.trackYVisible {
  height: calc(100% - 0.5rem - 54px) !important;
} */

.submitted-submissions-container .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  padding-top: 60px;
}

.sample-submission-main-container .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
}

/*** Table styling ***/
.submitted-submissions-container th,
.submission-details-modal-submission-table th {
  font-size: 14px;
  line-height: 16px;
  color: #6c7e8e;
  font-weight: 500;
}

.submitted-submissions-container td,
.submission-details-modal-submission-table td {
  padding: 19px 10px 19px 10px;
  text-align: left;
}

.submitted-submissions-container th:first-child,
.submitted-submission-row td:first-child,
.submission-details-modal-submission-table th:first-child {
  padding-left: 33px;
}

.submission-details-modal-submission-table {
  table-layout: fixed;
}

.submission-details-modal-submission-table .submitted-submission-row td:first-child,
.submission-details-modal-submission-table th:first-child {
  padding-left: 10px;
}

.submission-details-modal-submission-table thead tr th {
  word-wrap: normal;
}

table tr .submitted-submissions-table-samples-number-th,
table tr .submitted-submissions-table-samples-number-td {
  text-align: center;
}

.submitted-submissions-container tr td.submitted-submissions-table-actions-td,
td.submitted-submissions-table-actions-td {
  cursor: default;
  text-align: center;
}

.submitted-submissions-container tr td,
.submission-details-modal-submission-table tr td {
  /* border-bottom: 1px solid #e5e5e5; */
  color: #111a24;
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
}

.submitted-submissions-submitted-on-td div {
  min-width: 69px;
}

.submitted-submissions-submitted-by-td div {
  min-width: 69px;
}

.submitted-submissions-product-spec-td div {
  min-width: 114px;
  word-break: initial;
}

.submitted-submissions-product-spec-color,
.submitted-submissions-product-spec-text {
  vertical-align: middle;
}

.submitted-submissions-product-spec-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 8px;
}

.submitted-submissions-product-spec-color-added {
  background: #7dcded;
}

.submitted-submissions-product-spec-color-incomplete {
  background: #c4d2df;
}

/* Submissions Table-specific styling */
.sample-submission-main-container th {
  padding: 0px 10px 10px 10px;
  text-align: left;
}

.submitted-submissions-table-container {
  padding-right: 10px;
}

.submitted-submissions-table-container table {
  border-collapse: separate;
  /* Don't collapse */
  border-spacing: 0px;
  overflow: visible;
}

.submitted-submissions-container .SubmissionsTable__Header th {
  background: #F4FBFE;
  position: sticky;
  z-index: 1;
  top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #f2f6fa;
  border-bottom: 1px solid #f2f6fa;
}

.submitted-submissions-container .SubmissionsTable__Header th:first-child {
  border-left: 1px solid #f2f6fa;
  border-radius: 8px 0px 0px 8px;
}

.submitted-submissions-container .SubmissionsTable__Header th:last-child {
  border-right: 1px solid #f2f6fa;
  border-radius: 0px 8px 8px 0px;
}

/* Submission Details Modal Table-specific styling */
.submission-details-modal-submission-table th {
  padding: 19px 10px 0 10px;
  text-align: left;
}

.submitted-submissions-container table {
  width: 100%;
}

.submitted-submissions-container tbody tr td:first-child img {
  margin-right: 8px;
  margin-bottom: 2px;
}

/* .submitted-submissions-container th {
  position: sticky;
  z-index: 1;
  top: 0px;
  background-color: white;
} */