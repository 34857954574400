/* Input Field Style */

.add-new-item .custom-field {
  position: relative;
  font-size: 16px;
  --field-padding: 12px;
  margin-bottom: 12px;
  width: 100%;
}

.add-new-item .custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 2px;
}

.add-new-item .custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 16px;
  line-height: 100%;
  transform: translate(7px, -6px) scale(1);
  color: #aaa;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}

.add-new-item .custom-field input.dirty + .placeholder,
.add-new-item .custom-field input:focus + .placeholder,
.add-new-item .custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}

.add-new-item .custom-field.one input {
  background: none;
  border: 2px solid #b3e0f2;
  transition: border-color 0.3s ease;
  /* width: 218px; */
  width: 100%;
  height: 32px;
}
.add-new-item .custom-field.one input[type="text"] {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #375393;
  padding-left: 12px;
  padding-right: 25px;
}

.add-new-item .custom-field.one input + .placeholder {
  left: 4px;
  padding: 0 5px;
  text-align: left;
  color: #b3bfdb;
  font-size: 12px;
}

.add-new-item .custom-field.one input.dirty,
.add-new-item .custom-field.one input:not(:placeholder-shown),
.add-new-item .custom-field.one input:focus {
  border-color: #77ccee;
  transition-delay: 0.1s;
}

.add-new-item .custom-field.one input.dirty + .placeholder,
.add-new-item .custom-field.one input:not(:placeholder-shown) + .placeholder,
.add-new-item .custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #77ccee;
  background: #fff;
  width: auto;
}

/* Tabs Input field style */

.add-new-item .custom-field.tag input {
  background: none;
  border: 2px solid #b3e0f2;
  transition: border-color 0.3s ease;
  width: 160px;
  height: 32px;
}

.add-new-item .custom-field.tag input[type="text"] {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #375393;
  padding-left: 12px;
  padding-right: 25px;
  width: 100%;
}

.add-new-item .custom-field.tag input + .placeholder {
  left: 4px;
  padding: 0 5px;
  text-align: left;
  color: #b3bfdb;
  font-size: 12px;
}

.add-new-item .custom-field.tag input.dirty,
.add-new-item .custom-field.tag input:not(:placeholder-shown),
.add-new-item .custom-field.tag input:focus {
  border-color: #77ccee;
  transition-delay: 0.1s;
  /* padding-right: 50px; */
}

.add-new-item .custom-field.tag input.dirty + .placeholder,
.add-new-item .custom-field.tag input:not(:placeholder-shown) + .placeholder,
.add-new-item .custom-field.tag input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #77ccee;
  background: #fff;
  width: auto;
}

.tagDesign {
  padding-left: 62px;
  text-align: left;
}

/* .ScrollbarsCustom-Content {
  padding-left: 64px !important;
  padding-right: 25px !important;
} */

/* Button CSS */
.add-new-item .cancelBtn {
  background-color: white;
  text-transform: none;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  /* width: 72px; */
  width: 52px;
  height: 27px;
  margin-bottom: 18px;
  /* margin-left: 20px; */
  margin-left: 0px;
  border: 1px solid rgb(119, 204, 238);
  color: rgb(119, 204, 238);
  box-shadow: none;
  transform: translate(11px, -8px);
  border-radius: 5px;
}

.add-new-item .cancelBtn:hover {
  background-color: #3ec6ff;
  color: white;
}

.product-card-title {
  margin-bottom: 8px;
}

.newProductCard__Title {
  font-family: Roboto Medium;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.product-card .addNewCard .ProductNewCard__ItemName label {
  margin-top: 0px;
}

.product-card .addNewCard .newProductCard__Body--itemName-textArea {
  resize: none;
}

.NewProductCard__Body__CharsCount {
  margin-top: 0px;
}

.product-card
  .addNewCard
  .charsCount.NewProductCard__Body__CharsCount--is-last {
  margin-top: 0px;
  margin-bottom: 0px;
}

.newProductCard__Body--itemNumber {
  position: relative;
}

.product-card .addNewCard .newProductCard__Body--itemNumber-Input {
  color: #19305a;
  padding-right: 28px;
}

.product-card .addNewCard .newProductCard__Body--addEditTags-Input {
  max-width: 205px;
}

.newProductCard__Body--addEditTags-addTagIcon {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.newProductCard__Body--Buttons {
  height: 32px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.newProductCard__Body--Scrollbar {
  text-align: left;
  border: none;
  border-radius: 3px;
  height: 81px;
  margin-top: 8px;
  background-color: #ffffff;
}

/** Inputs Scrollbar styling **/
.product-card .addNewCard .ProductNewCard__InputsScrollbar.ScrollbarsCustom {
  max-height: 205px !important;
  margin-top: 10px;
}

/* Allows for dynamic height up to max height*/
.product-card
  .addNewCard
  .ProductNewCard__InputsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Wrapper,
.product-card
  .addNewCard
  .ProductNewCard__InputsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

/* Make space for scrollbar only when it should show */
.product-card
  .addNewCard
  .ProductNewCard__InputsScrollbar.ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Scroller {
  margin-right: -15px !important;
}

.product-card
  .addNewCard
  .ProductNewCard__InputsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  right: -12px !important;
}

.product-card
  .addNewCard
  .ProductNewCard__InputsScrollbar
  .ScrollbarsCustom-Content {
  padding: 0px !important;
}

.newProductCard__TabsLabelInput {
  display: flex;
  align-items: center;
}

.ProductNewCard__CardBody {
  padding: 0px 15px;
  background-color: #ffffff;
}
