.createTemplateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #eaf0f5;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  min-width: 141px;
  height: 90px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #26abe1;
  cursor: pointer;
}

.createTemplateContainer:hover {
  background: #26abe1;
  color: #ffffff;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.createTemplateContainer img {
  width: 24px;
  height: 24px;
}
