/* Tabs */
.SampleSubmission__NavTabs.NavTabs.ant-tabs:not(.ant-tabs-card)
  > .ant-tabs-nav {
  z-index: 1;
  background-color: white;
}

.SampleSubmission__ParentContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 60px;
  left: 0px;
  display: flex;
  flex-direction: column;
  background-color: #f7f8fa;
}

.SampleSubmission__ParentContainer.logged-out {
  height: 100vh;
  top: 0px;
}

.SampleSubmission__Login {
  position: fixed;
  top: 22px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  background: #26abe1;
  border-radius: 4px;
  color: white;
  font-family: "Roboto Medium";
  font-size: 16px;
  padding: 4px 12px;
}

.SampleSubmission__Login:hover {
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  color: white;
  text-decoration: unset;
}

.SampleSubmission__InvalidLInk {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.SampleSubmissionForm__Nav {
  position: fixed;
  border-color: #eaf0f5;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  background-color: #ffffff;
  width: 100vw;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 34px;
  gap: 20px;
}

.SampleSubmissionForm__Nav_title {
  font-size: 16px;
  font-family: "Roboto Medium";
  line-height: 24px;
  color: rgba(55, 83, 147, 1);
  word-break: break-all;
}

.SampleSubmissionForm__Nav_title > span {
  margin-left: 16px;
  align-items: center;
}

.sample-submission-form-back-button-nav {
  cursor: pointer;
  transform: rotate(-180deg);
}

.sample-submission-form-back-button-nav > path {
  fill: rgba(55, 83, 147, 1);
}

.sample-submission-form-back-button-nav:focus {
  outline: unset;
}

.sample-submission-form-back-button-nav:focus-visible {
  outline: -webkit-focus-ring-color auto 5px;
}

.sample-submission-form-close-button {
  cursor: pointer;
}

.sample-submission-form-close-button > path {
  fill: rgba(55, 83, 147, 1);
}

.sample-submission-form-close-button:focus {
  outline: unset;
}

.sample-submission-form-close-button:focus-visible {
  outline: -webkit-focus-ring-color auto 5px;
}
