.AddMemberModalContainer.ant-modal .ant-modal-content {
  border-radius: 4px;
  padding: 8px 0px 20px;
}

.AddMemberModalContainer .addMemberModalHeader {
  height: 29px;
}

.addMemberModalHeader {
  font-family: "Roboto Medium";
  font-size: 20px;
  padding-left: 20px;
  padding-top: 5px;
  margin-bottom: 25px;
  cursor: pointer;
  color: #19305a;
  text-align: left;
}

.addMemberModalHeader > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.AddMemberModalContainer .addMemberModalSection {
  height: 66px;
}
.AddMemberModalContainer .myAccountFormTitle {
  height: 14px;
}

.AddMemberModal--disabled input {
  pointer-events: none;
}

.AddMemberModal__Buttons {
  margin-left: 59%;
  margin-top: 25px;
}

.AddMemberModal__Buttons--loading {
  margin-left: 50%;
  margin-top: 25px;
}

.AddMemberModal__CancelBtn,
.addMemberSendInviteBtn {
  padding: 5px 12px;
  width: fit-content;
  height: 32px;
  background-color: #26a1d2;
  border: 1px solid #26a1d2;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto Medium";
  font-size: 12px;
}

.AddMemberModal__CancelBtn {
  border: 1px solid #eff2f3;
  background-color: #eff2f3;
  color: #19305a;
}

.AddMemberModal__CancelBtn {
  margin-right: 15px;
}

.AddMemberModal__Buttons button:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.AddMemberModal__CancelBtn--disabled {
  pointer-events: none;
}

.addMemberSendInviteBtn--loading {
  opacity: 0.7;
  pointer-events: none;
}

.addMemberSendInviteBtn-loading-dots {
  margin-left: 8px;
  display: inline-block;
}
