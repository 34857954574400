.TemplateBuilder__Parent {
  height: calc(100vh - 122px);
  position: relative;
  margin-top: -16px;
}

.TemplateBuilderContainer {
  display: grid;
  grid-template-columns: 1fr 3fr 1.5fr;
  gap: 24px;
  height: 100%;
  background-color: #f6f8fa;
  padding: 15px 25px;
  position: relative;
  overflow-x: auto;
}

.TemplateBuilderContainer--twoColumn {
  display: grid;
  grid-template-columns: 3.2fr 1fr;
  gap: 24px;
  height: 100%;
  background-color: #f6f8fa;
  padding: 15px 25px;
  position: relative;
  overflow-x: auto;
}

@media screen and (min-height: 935px) {
  .TemplateBuilderContainer--twoColumn {
    overflow-y: hidden;
  }
}

.TemplateBuilderContainer > div {
  max-height: 100%;
}

.TemplateBuilderContainer--twoColumn > div {
  max-height: 100%;
  min-height: 778px;
}

.templateImageBlock {
  position: relative;
}

.rectangleBlock {
  opacity: 0;
  padding-bottom: 50%;
}

.selectIconContainer {
  display: flex;
  font-family: "Roboto Medium";
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  justify-content: flex-end;
  color: #c4d2df;
  gap: 10px;
  margin-right: 20px;
}

.templateBuilderDisableSection {
  opacity: 0.3;
  pointer-events: none;
}

.TestReportContainer {
  padding: 30px 20px 40px;
}

.TestReportContainer__Body {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.testReportContent {
  border: 1px solid #d1dce8;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  padding: 24px 99px;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  /* margin-bottom: 36px; */
}

.testReportContentText {
  font-family: "Roboto Medium";
  font-size: 11px;
  color: #6c7e8e;
  margin-bottom: 20px;
}

.dummyTable .dummyTableHeader div {
  background-color: #c4d2df;
  color: #506375;
  font-size: 11px;
  line-height: 32px;
  font-family: "Roboto Medium";
}

.dummyTable .dummyTableBodyRow div {
  background-color: #f2f6fa;
}

.dummyTable .dummyTableBodyRow,
.dummyTable .dummyTableHeader {
  display: flex;
}

.dummyTable .dummyTableBodyRow div,
.dummyTable .dummyTableHeader div {
  text-align: center;
  /* width: 117px; */
  width: 159px; /* when Test Method is removed */
  height: 32px;
  margin-right: 6px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.builderComponents {
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-width: 270px;
}

.builderComponentTextField {
  height: 228px;
}

.builderComponentDataField {
  min-height: 200px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cardHeaderCollapsed:hover {
  background-color: #def2fb;
}
