.CountriesCheckboxList__Container {
    background-color: white;
    min-width: 280px;
    padding: 8px;
}

.CountriesCheckboxList__Scrollbar {
    height: 140px !important;
}

.CountriesCheckboxList__List {
    display: grid;
    grid-template-columns: 50% 50%;
}

.CountriesCheckboxList__Checkbox {
    margin-bottom: 16px !important;
}

.CountriesCheckboxList__Divider {
    margin-top: 8px;
    margin-bottom: 16px;
}

.CountriesCheckboxList__Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}