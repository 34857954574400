.ZebraPrintModal.ant-modal .ant-modal-content {
  border-radius: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 400px;
}

.ZebraPrintModal.ant-modal .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ZebraPrintModal__Input__Label {
  font-family: "Roboto Medium";
  line-height: 16px;
  display: block;
  text-align: left;
  letter-spacing: 0.5px;

  font-size: 12px !important;
  color: #182534 !important;
  margin-bottom: 2px;
}

.ZebraPrintModal__Input__Label--Bold {
  font-family: "Roboto Medium";
  font-size: 16px;
}

.ZebraPrintModal__Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #375393;
  color: #fdfdfd;
  height: 47px;
  padding: 18px;
  padding-top: 16px;
  margin-top: -14px;
}

.ZebraPrintModal__Container__CloseIcon {
  height: 24px;
  cursor: pointer;
}

.ZebraPrintModal__Content {
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ZebraPrintModal__Content .ZebraPrintModal__ButtonPanel {
  padding: 12px 16px;
}

.ZebraPrintModal__Content .ZebraPrintModal__ContentContainer {
  padding: 0 16px 12px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ZebraPrintModal__Content
  .ZebraPrintModal__ContentContainer
  .ZebraPrintModal__InputPanels {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.ZebraPrintModal__InputContainer {
  margin-bottom: 10px;
}

.ZebraPrintModal__Content
  .ZebraPrintModal__ContentContainer
  .ZebraPrintModal__InputPanels
  .ZebraPrintModal__InputContainer {
  flex: 1;
}

.ZebraPrintModal__Content
  .ZebraPrintModal__ContentContainer
  .ZebraPrintModal__InputPanels
  .ZebraPrintModal__InputContainer.Inline {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ZebraPrintModal__InputContainer.Inline .ZebraPrintModal__Input__Label {
  margin-bottom: 0;
  margin-right: 16px;
}

.ZebraPrintModal__Content .ZebraPrintModal__ButtonPanel,
.ZebraPrintModal__Content
  .ZebraPrintModal__ButtonPanel
  .ZebraPrintModal__Buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.ZebraPrintModal__Header__Title {
  font-family: "Roboto Medium";
  font-size: 16px;
  color: #26abe1;
}

.ZebraPrintModal__Button {
  gap: 8px;
}

.ZebraPrintModal__Button > svg {
  width: 12px;
  height: 12px;
}

.ZebraPrintModal__Button > svg > path {
  fill: #ffffff;
}

.ZebraPrintModal__CancelAllPrintsButton {
  margin-right: auto;
}

/* Help Text */
.ZebraPrintModal__HelpText {
  color: #26abe1;
  cursor: pointer;
}

.ZebraPrintModal__HelpText:hover {
  text-decoration: underline;
}

/* Zebra Print Settings */

.ZebraPrintModal__CopiesInput.ant-input-affix-wrapper,
.ZebraPrintModal__CopiesInput.ant-input-number {
  gap: 4px;
  height: 40px;
  width: 100%;
  border-color: #c4d2df;
  border-radius: 4px;
  /* padding: 6px 12px; */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.ZebraPrintModal__CopiesInput.ant-input-affix-wrapper:hover,
.ZebraPrintModal__CopiesInput.ant-input-affix-wrapper:focus,
.ZebraPrintModal__CopiesInput.ant-input-number:focus {
  border: 1px solid #26abe1 !important;
}

.ZebraPrintModal__CopiesInput .ant-input-number-input-wrap,
.ZebraPrintModal__CopiesInput .ant-input-number-input {
  height: 100%;
}

/* Dropdown */
.ant-select.ZebraPrintModal__Select {
  width: 100%;
  height: 41px;
}

.ant-select.ZebraPrintModal__Select > .ant-select-selector {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c4d2df;
}

.ant-select.ZebraPrintModal__Select > .ant-select-selector:focus-within {
  border: 1px solid #26abe1 !important;
}

.ant-select.ZebraPrintModal__Select:not(.ant-select-status-error):not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  > .ant-select-selector,
.ant-select.ZebraPrintModal__Select.ant-select-focused:not(
    .ant-select-status-error
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  > .ant-select-selector {
  border: 1px solid #26abe1 !important;
}

.ant-select.ZebraPrintModal__Select
  > .ant-select-selector
  > .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  color: #19305a75;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
}

.ant-select.ZebraPrintModal__Select
  > .ant-select-selector
  > .ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #19305a;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
}

.ZebraPrintModal__Select__Popup .ant-select-item {
  font-family: "Roboto";
}

.ZebraPrintModal__Select__Popup .ant-select-item {
  color: #758ab9;
}

.ZebraPrintModal__Select__Popup .ant-select-item.ant-select-item-option {
  color: #375393;
}

.ZebraPrintModal__LabelImageContainer {
  padding: 16px;
  border-radius: 4px;
  border: 2px dashed #e0e0e0;
  width: 100%;
  margin: 16px 0;
}

.ZebraPrintModal__LabelImageContainer .ZebraPrintModal__LabelImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Zebra Print Edit */
.ZebraPrintModal__EditTableScrollbar {
  flex: 1;
}

.ZebraPrintModal__EditTableScrollbar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

.ZebraPrintModal__EditTableScrollbar
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}

.ZebraPrintModal__EditTable {
  width: 100%;
  table-layout: fixed;
  flex: 1;
}

.ZebraPrintModal__EditTable th,
.ZebraPrintModal__EditTable td {
  border-bottom: 1px solid #dddddd7e;
  font-family: "Roboto";
  font-weight: normal;
  padding: 12px 0;
}

.ZebraPrintModal__EditTable th {
  text-align: left;
  font-size: 14px;
  font-family: "Roboto Medium";
  color: #444444;
  position: sticky;
  z-index: 1;
  top: 0px;
  background: #ffffff;
  box-shadow: 0 4px 8px #eeeeee71;
}

.Center__align {
  text-align: center !important;
}

.ZebraPrintEdit__ActionButton {
  cursor: pointer;
}

.ZebraPrintEdit__ActionButton:first-of-type {
  margin-right: 8px;
}

.ZebraPrintEdit__ActionButton svg > * {
  fill: #afbdca;
}

.ZebraPrintEdit__ActionButton:hover svg > * {
  fill: #8b8f93;
}

.ZebraPrintModal__AddPrinterIcon > * {
  stroke: #ffffff;
}

.ZebraPrintModal__DownloadIcon {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.ZebraPrintModal__DownloadText {
  color: #26abe1;
}

.ZebraPrintModal__CancelButton {
  margin-right: auto;
  padding: 4px 8px;
}

.ZebraPrintModal__BackText {
  color: #26abe1;
}

.ZebraPrintModal__BackIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.ZebraPrintEdit__AddInput {
  width: 90%;
}

.Flex__Center {
  display: flex;
  align-items: center;
  justify-content: center;
}
