.sample-submission-background.sample-submission-background__Form {
  overflow: auto;
  position: absolute;
  top: 56px;
  height: calc(100% - 116px);
}

.SampleSubmission__ParentContainer.logged-out
  > .sample-submission-background.sample-submission-background__Form.sample-submission-background-address {
  height: 100%;
}

.sample-submission-background.sample-submission-background__Form.sample-submission-background-address {
  top: 0px;
  height: calc(100% - 60px);
}

.SampleSubmissionForm__Loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fdfdfd81;
  z-index: 2;
}

.new-submission-pages-container {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 26px 34px;
  width: 100%;
  height: 100%;
  min-width: 1100px;
  min-height: 589px;
  display: grid;
  grid-column-gap: 18px;
  grid-template-columns: 32% auto minmax(300px, 24%);
  grid-template-rows: auto 0px;
  place-content: stretch;
}

.new-submission-pages-container.address-page-container {
  grid-template-columns: auto;
  grid-template-rows: auto 64px;
  height: 100%;
}

.new-submission-pages-col {
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex-wrap: nowrap;
}

.new-submission-pages-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0px 0px 5px 0px rgba(9, 30, 66, 0.08),
    0px 3px 10px 0px rgba(9, 30, 66, 0.05);
  border-radius: 8px;
  padding: 25px 18px;
  /* gap: 20px; */
  background-color: white;
  position: relative;
}

/* Submission details column */

.submission-details-column {
  grid-row: 1 / 2;
}

.new-submission-pages-card.submission-details {
  position: relative;
  flex-grow: 7;
  padding: 40px 83px 40px 40px;
  gap: 30px;
}

.new-submission-pages-card.address {
  flex-grow: 18;
  padding: 40px 30px 40px 83px;
  gap: 30px;
}

.new-submission-pages-card.address.logged-out {
  padding-left: 40px;
}

/* Submission/sample card list column */
.submission-column > .new-submission-pages-card {
  gap: 16px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.submission-column {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  margin-right: 6px;
}

.num-samples-added-time-div {
  display: flex;
  justify-content: space-between;
  height: 16px;
  font-family: "Roboto";
  font-size: 12px;
  color: #6c7e8e;
}

.num-samples-added-time-div > .num-samples-added-span {
  font-family: "Roboto Medium";
}

.num-samples-added-time-div.zero-samples {
  align-self: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.added-samples-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 98%;
}

/* Sample details column */

.sample-details-column {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  position: relative;
}

.new-submission-pages-card.sample-details {
  flex-grow: unset;
  padding: 25px;
  gap: 20px;
  height: 100%;
}

.new-submission-pages-title-container.sample-details {
  margin-bottom: 10px;
}

.new-submission-pages-card.sample-fields {
  flex-grow: 2;
}

/* .sample-details-column .input-checkbox-list-container { */
.sample-details-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

/* SampleID/analysis request column */
.analysis-request-column {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  z-index: 1;
}

.analysis-request-column-edit {
  grid-column: 2 / 4;
  grid-row: 1 / 2;
  z-index: 1;
}

.new-submission-pages-card-sample-id {
  height: 66px;
  flex-grow: unset;
  border-radius: 8px;
  padding: 14.5px 20px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  box-shadow: 0px 0px 5px 0px rgba(9, 30, 66, 0.08),
    0px 3px 10px 0px rgba(9, 30, 66, 0.05);
}
.new-submission-pages-card-sample-id-edit {
  width: 100%;
  flex-grow: unset !important;
}

.new-submission-pages-card.analysis-request {
  flex-grow: 1;
  padding: 22px;
}

.new-submission-pages-title-composite-container {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

/* Title block styling */
.new-submission-pages-title-container {
  display: flex;
  flex-direction: row;
  font-family: "Roboto Medium";
  font-size: 20px;
  gap: 12.67px;
  align-items: center;
}

.sample-submission-title-block {
  background-color: #26abe1;
  border-radius: 4px;
  width: 16px;
  height: 36px;
}

.new-submission-pages-title-container.withBlock {
  gap: 16px;
}

.new-submission-pages-title-container.submission {
  margin-bottom: 28px;
}

/* Buttons styling */

.sample-submission-form-back-button {
  cursor: pointer;
  transform: rotate(-180deg);
}

.sample-submission-form-back-button > path {
  fill: rgba(0, 0, 0, 0.54);
}

.sample-submission-form-back-button:focus {
  outline: unset;
}

.sample-submission-form-back-button:focus-visible {
  outline: -webkit-focus-ring-color auto 5px;
}

.sample-submission-form-back-button.SubmissionDetailsAddressForms-back {
  margin-top: 10px;
}

.sample-submission-form-back-button.sample-submission-form-back-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.new-submission-pages-button-div {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  justify-self: end;
  align-self: end;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.samplesubmission-exit-button {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  justify-self: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.exitDraftButton {
  height: 36px;
  width: 100px;
  cursor: pointer;
  border: 2px solid #26abe1;
  border-radius: 4px;
  cursor: pointer;
  color: #26abe1;
  background-color: white;
}

.exitDraftButton:hover {
  background-color: #f6f8fa;
}

.new-submission-pages-button {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  justify-self: end;
  align-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 40px;
  width: 190px;
  border: 1px solid #26abe1;
  border-radius: 4px;
  cursor: pointer;
  color: #26abe1;
  background-color: rgba(255, 255, 255, 1);
}

.new-submission-pages-button:focus-visible {
  border: 2px solid #025fcc;
}

.new-submission-pages-button.continue {
  grid-column: auto;
}

.new-submission-pages-button.continue.disable {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.new-submission-pages-button > svg > path {
  fill: #26abe1;
}

.sample-submission-submit-button {
  align-self: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #26abe1;
  border: 2px solid #26abe1;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  height: 36px;
  width: 210px;
  cursor: pointer;
}

/* .sample-submission-submit-button:not(
    .sample-submission-submit-button-disabled
  ):hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
} */

.sample-submission-submit-button:focus-visible {
  border: 2px solid #025fcc;
}

.sample-submission-submit-button > svg > path {
  fill: white;
}

.sample-submission-submit-button.sample-submission-submit-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.new-submission-pages-button.add-sample {
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
}
/* Svg path stroke to update the hover color for styled button*/
.new-submission-pages-button.add-sample:not(.disabled):hover > .ant-btn-icon > svg > path {
  stroke: #9dd9f2;
}

.new-submission-pages-button.add-sample.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: unset;
}

.new-submission-pages-button.add-sample > svg > path {
  stroke: #26abe1;
}

.new-submission-pages-button.update-sample {
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
  color: rgba(246, 248, 250, 1);
  background: rgba(38, 171, 225, 1);
}

.new-submission-pages-button.update-sample:not(.disabled):hover {
  background: #26abe1;
  color: rgba(246, 248, 250, 1);
}

.new-submission-pages-button.update-sample.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: unset;
}

.new-submission-pages-button.update-sample > svg > path {
  stroke: rgba(246, 248, 250, 1);
}

/* Form input styling */
.sample-details-input-title {
  margin-bottom: 4px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.sample-details-input-title > label {
  font-family: "Roboto Bold";
  font-size: 12px;
  color: #6c7e8e;
  margin-bottom: 0px;
}

.submission-name-input-title {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.submission-name-input-title > label {
  font-size: 12px !important;
  color: #182534 !important;
  margin-bottom: 0px;
}

.submission-name-input-title > svg > path {
  fill: red;
}

/* on tooltip hover, change the color of the inner svg */
.submission-name-input-title > svg:hover > path {
  fill: rgb(166, 2, 2);
}

.sample-details-input-title.sample-id {
  margin-bottom: 0px;
  margin-right: 5.33px;
}

.sample-details-input input.sample-submission-input-error {
  border-color: red;
}

.sample-details-input input.sample-submission-input-error:focus {
  border-color: red;
}

/* Invalid char tooltip styling */
.SampleSubmission__InvalidCharsTooltipTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
}

.SampleSubmission__InvalidCharsTooltipTitle > .SampleSubmission__InvalidChar {
  white-space: nowrap;
}

.SampleSubmission__InvalidChar {
  display: flex;
  align-items: center;
}

.SampleSubmission__InvalidChar__SpaceHighlight {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: "Roboto Bold";
  font-size: 8px;
  /* color: transparent; */
  height: 9px;
  width: 9px;
}

/* Misc */
.new-submission-pages-container .grayDivider {
  border: 0.5px solid #d1dce8;
  width: 100%;
  height: 0px;
}
