.InviteModal__SharedListContainer {
  /* border-bottom: 1px solid #e6eef5; */
  padding-bottom: 16px;
}

.InviteModal__SharedList {
  display: flex;
  flex-direction: column;
  height: 200px;
}

.InviteModal__NoResults {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
}

#InviteModal_Scrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 4px !important;
  background-color: #c4d2df !important;
}

#InviteModal_Scrollbar .ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}

.InviteModal_ListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.InviteModal__EmailContainer {
  background: #f2f6fa;
  padding: 6px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.InviteModal__Email {
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  font-family: "Roboto";
  word-break: break-all;
}

.InviteModal__Email span{
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #182534
}

.InviteModal__PersonIcon{
  height: 24px;
  width: 24px;
}

.InviteModal__DeleteIcon {
  display: flex;
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
}

.InviteModal__DeleteIcon path:last-child {
  fill: transparent;
}

.InviteModal__DeleteIcon path {
  fill: #afbdca;
}

.InviteModal__DeleteIcon:hover {
  cursor: pointer;
}

.InviteModal__DeleteIcon:hover path {
  fill: #26abe1;
}

.InviteModal__NoResults__GIF > img {
  height: 100px;
  width: 100px;
}

.InviteModal__NoResults__GIF > .no-results-gif-common__msg {
  color: #6c7e8e;
  font-family: "Roboto";
  font-size: 14px;
}
