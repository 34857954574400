.BuilderSaveSuccess {
  display: flex;
  align-items: center;
  gap: 13px;

  color: #00bf71;
  font-size: 16px;
  font-family: "Roboto Medium";
  line-height: 24px;
}

.BuilderSave__SuccessIcon__Container {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 4px;
}

.BuilderSave__SuccessIcon {
  width: 48px;
  height: 48px;
}
