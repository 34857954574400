.EnvSearch--grayout {
  opacity: 0.2;
}

.envAnalyticsListViewBlock .EnvSearch {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.envAnalyticsListViewBlock .search-calendar-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.envAnalyticsListViewBlock .search-calendar-container > div:first-of-type {
  display: flex;
  flex-grow: 1;
}

.envAnalyticsListViewBlock .search-container {
  position: relative;
  border: 2px solid #d7dae1;
  border-radius: 4px;
  height: 40px;
  flex-grow: 1;
}

.envAnalyticsListViewBlock .search-container > img {
  cursor: pointer;
  position: absolute;
  right: 65px;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}

.envAnalyticsListViewBlock .search-container.searching-border {
  border: 2px solid #26abe1;
}

.envAnalyticsListViewBlock .searchInput {
  font-family: "Roboto";
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #19305a;
  border-radius: 4px;
  border: none;
  padding-left: 14px;
  padding-right: 70px;
  background-color: #fdfdfd;
  box-sizing: border-box;
}

.envAnalyticsListViewBlock .searchButton-container {
  width: 53px;
  height: 36px;
  background: #eff2f3;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.envAnalyticsListViewBlock img.searchButton {
  width: 13.54px;
  height: 13.54px;
  cursor: pointer;
}

.searchOption {
  width: 100%;
  height: 40px;
  background-color: #fdfdfd;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.searchOption button {
  margin-right: 10px;
  font-family: "roboto medium";
  font-size: 12px;
  padding: 2px 10px;
  background-color: transparent;
  border-color: transparent;
  color: #26a1d2;
  border-radius: 3px;
}

.searchOption button:hover,
.searchOption button.selected {
  background-color: #b3e0f2;
  color: #19305a;
}

.searchResult {
  max-height: 155px;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: #f9f9f9;
  border-radius: 3px;
  top: 73px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.searchResult .searchResultItemContainer {
  font-family: "roboto medium";
  font-size: 12px;
  background-color: #fdfdfd;
  padding: 16px 7px;
  color: #19305a;
  margin-top: 1px;
  position: relative;
  border-radius: 3px;
}

.searchResult .searchResultItemContainer:hover {
  background-color: #b3e0f2;
}

.searchResult .searchResultItemContainer span.selected {
  color: #26a1d2;
}

.searchResult .searchResultItemContainer label {
  font-family: "roboto";
  font-size: 6px;
  color: #758ab9;
  position: absolute;
  bottom: 5px;
  right: 5px;
  margin-bottom: 3px;
}
