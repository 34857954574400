.editModal{
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 270px;
  z-index: 9;
  box-shadow: 0px 13px 37px 0px #00000036;
  background: white;
  border-radius: 4px;
  padding: 20px 24px;
  position: absolute;
  left: 90px;
  top:35%;
}

.editModalInputContainer{
  width: 100%;
}

.editModal_formInput{
  width: 100%;
  font-weight:500;
}

.editModal_cancelButton{
  float: left;
  color: #E63559;
  border: none;
  font-weight:500;
}

.editModal_saveButton{
  float: right;
  background-color: #26ABE1;
  color: white;
  padding: 6px 40px;
  border: none;
  border-radius: 4px;
}