.CustomDateFrequencyForm {
  display: flex;
  align-items: stretch;
}

.CustomDateFrequencyForm__Scrollbar {
  height: 275px !important;
}

.CustomDateFrequencyForm__Scrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.CustomDateFrequencyForm__Scrollbar .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #133e8c !important;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__CalendarField {
  flex-shrink: 0;
  padding-right: 60px;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__CalendarField .FieldTitle {
  margin-bottom: 12px;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__SelectedDates {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  border-left: 1px solid #EAF0F5;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__SelectedDates .CustomDateFrequencyForm__SelectedDatesList {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
  padding: 0;
  width: 100%;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__SelectedDates .CustomDateFrequencyForm__SelectedDatesList.CustomDateFrequencyForm__SelectedDatesList--non-empty {
  justify-content: flex-start;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__SelectedDates .CustomDateFrequencyForm__SelectedDatesList .CustomDateFrequencyForm__ListItem {
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  color: #182534;
  /* height: 24px; */
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background: #F8FAFC;
  border: 1px solid #EAF0F5;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__SelectedDates .CustomDateFrequencyForm__SelectedDatesList .CustomDateFrequencyForm__ListItem.Invalid {
  background: #FFF1F0;
  border: 1px solid #E11903;
}

.CustomDateFrequencyForm__Cancel {
  margin-left: auto;
  margin-left: 12px;
  color: #182534;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  cursor: pointer;
  z-index: 1;
}

.CustomDateFrequencyForm .CustomDateFrequencyForm__SelectedDates .CustomDateFrequencyForm__SelectedDatesList .CustomDateFrequencyForm__EmptyListItem {
  font-family: "Roboto bold";
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #375393;
}