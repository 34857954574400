.SubmissionReceiptModal__SamplesList {
  width: 100%;
  padding: 24px 5px 48px 5px;
  color: #1f2d3b;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.SubmissionReceiptModal__SamplesList__Title {
  color: #000;
  font-family: "Roboto Medium";
  font-size: 16px;
  padding-bottom: 6px;
}

.SubmissionReceiptModal__Sample {
  padding: 8px;
  border: 1px dashed #C4D2DF;
}

.SubmissionReceiptModal__Sample--Counter {
  color: #111a24;
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto Medium";
  margin-right: 4px;
}

.SubmissionReceiptModal__SampleText {
  font-family: "Roboto";
  font-size: 10px;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 8px;
  word-break: break-word;
}

.SubmissionReceiptModal__SampleText:first-of-type {
  margin-top: 0;
}

.SubmissionReceiptModal__SampleText .SubmissionReceiptModal__SampleText--Title {
  color: #afbdca;
  word-break: break-word;
}

.SubmissionReceiptModal__SampleText .SubmissionReceiptModal__SampleText--Data {
  color: #000000;
}