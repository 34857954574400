.OnboardingWelcomePage {
  display: flex;
  overflow: hidden;
  font-family: "Roboto";
}

.OnboardingWelcomePage__Container {
  padding-left: 72px;
  padding-top: 80px;
}

.OnboardingWelcomePage__Title {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #182534;
  font-family: "Roboto medium";
}

.OnboardingWelcomePage__SubTitle {
  color: #6c7e8e;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.OnboardingWelcomePage__SubTitle span {
  font-family: "Roboto medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.OnboardingWelcomePage__StepContainerOne {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 45px;
  width: 526px;
  height: 153px;
  margin: 40px 0px 15px 0px;
}

.OnboardingWelcomePage__StepContainerTwo {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0px;
  width: 526px;
  height: 153px;
  margin: 15px 0px 40px 0px;
}

.OnboardingWelcomePage__StepContainerTwo div,
.OnboardingWelcomePage__StepContainerOne div {
  background: #f2f6fa;
  border-radius: 16px;
  height: 149px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}

.OnboardingWelcomePage__Customer__Svg {
  width: 267px;
}

.OnboardingWelcomePage__Contact__Svg {
  width: 241px;
}

.OnboardingWelcomePage__ESV__Svg {
  grid-column: span 2 / span 2;
  width: 330px;
}

.OnboardingWelcomePage__ESV__Svg svg {
  width: 34px;
  height: 27px;
}

.OnboardingWelcomePage__AccountCreation__Svg {
  width: 178px;
}

.OnboardingWelcomePage__StepContainer__Title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #26abe1;
  font-family: "Roboto medium";

}

.OnboardingWelcomePage__StepContainer__SubTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #6c7e8e;
  letter-spacing: 0.4px;
}

.OnboardingWelcomePage__Image {
  margin-bottom: 8px;
}

.OnboardingWelcomePage__Welcome__Image {
  padding: 32px 0px 24px 72px;
  position: relative;
}

.OnboardingWelcomePage__Welcome__Image img{
  position: relative;
  bottom: 15px;
}

.onBoardingBtn.Ant__SubmitBtn {
  width: 216px;
  font-family: "Roboto Medium";
  padding: 10px, 16px, 10px, 16px;
  gap: 8px;
  letter-spacing: 0.11px;
}

.ESVColor1 {
  color: #19305a;
}

.ESVColor2 {
  color: #26abe1;
}