.quadrantImage {
  height: 100%;
  object-fit: fill;
}

.quadrantImage + .quadrantImageSelected {
  position: relative;
  z-index: 1;
}

.quadrantImage,
.quadrantImage + .quadrantImageSelected {
  width: 100%;
}

.quadrantImage:hover,
.quadrantImage:hover + .quadrantImageSelected {
  width: 200% !important;
}

.quadrantImage:hover {
  object-fit: cover;
}

.quadrantContainer:nth-of-type(1) .quadrantImage:hover {
  object-position: left top;
}

.quadrantContainer:nth-of-type(2) .quadrantImage:hover {
  object-position: right top;
}

.quadrantContainer:nth-of-type(3) .quadrantImage:hover {
  object-position: right bottom;
}

.quadrantContainer:nth-of-type(4) .quadrantImage:hover {
  object-position: left bottom;
}

.quadrantImageSelected {
  margin-top: -17px;
  height: 17px;
  opacity: 0.3;
}

.quadrantContainer:nth-of-type(1) .quadrantImageSelected {
  background-color: #f878ff;
  border-radius: 0px 0px 3px 3px;
}

.quadrantContainer:nth-of-type(2) .quadrantImageSelected {
  background-color: #0093A7;
  border-radius: 0px 0px 3px 3px;
}

.quadrantContainer:nth-of-type(3) .quadrantImageSelected {
  background-color: #8A1CFF;
  border-radius: 0px 0px 3px 3px;
}

.quadrantContainer:nth-of-type(4) .quadrantImageSelected {
  background-color: #A7BB2A;
  border-radius: 0px 0px 3px 3px;
}

.envMainContainer .envAnalyticsMapViewBlock .tab-content {
  padding: 0px;
}

.react-transform-wrapper {
  /* set max-height and max-width to display the whole image when panning */
  max-height: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}

.react-transform-element {
  width: 100% !important;
  height: 100% !important;
}

.mapViewContainer-container {
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  width: 100%; 
  height: calc(100% - 41px);
}

#mapViewContainer {
  position: relative;
  display: grid;
  grid-template-columns: 42px auto 42px;
  grid-template-rows: 42px auto 42px;
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  border-radius: 8px;
}

.envMapBlurb {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: center;
  align-self: center;
  display: flex;
  align-items: center;
  text-align: center;
  height: 26px;
  padding: 0px 8px;
  background-color: #19305A;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  line-height: 32px;
  /* transform: translateX(-50%); */
}

.fullscreenButton {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  justify-self: center;

  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  z-index: 2;
}

#borderedMapContainer {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  width: 100%;
  background-color: #E5E5E5;
  border-radius: 8px;
  overflow:hidden;
}

.react-transform-wrapper {
  cursor: grab;
} 

.tools {
  position: absolute;
  top: 42px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 44px;
  background-color: #F9F9F9;
  border-radius: 6px;
  padding: 2px;
  row-gap: 2px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.tools button {
  width: 20px;
  border: none;
  padding: 0px;
  background: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.tools button.disableZoom {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.tools button > div {
  background-color: #EFF2F3;
  border-radius: 5px;
}

.tools button img {
  width: 100%;
}


.envAnalyticsZoomDisplay {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  justify-self: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #D7DAE1;
  border-radius: 8px;
  padding: 8px;
  gap: 5px;
}

.envZoomDisplayIcon {
  width: 13.54px;
  height: 13.54px;
}

.env-remove-swab-from-map-div {
  cursor: pointer;
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  justify-self: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #26A1D2;
  color: white;
  border-radius: 8px;
  padding: 8px;
  gap: 6px;
}

.env-remove-swab-from-map-div > img {
  width: 13.54px;
  height: 13.54px;
}

.env-remove-swab-from-map-div:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.addMapTitleMessage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}