.productReportSpecManagerFilterButton,
.productReportSpecManagerFilterButton--active {
    margin-right: 5px;
    padding: 10px;
    height: 32px;
    text-transform: none;
    font-family: Roboto Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #19305A;
}

.productReportSpecManagerFilterButtonCancelIcon{
    height: 16px;
    width: 16px;
    position: absolute;
    right: -7px;
    top: -7px
}

.specManagerFilterButtonIcon{
    height: 15px;
    width: 15px;
    margin-right: 7px;
}

.productReportSpecManagerFilterButton--active {
  box-shadow: 0 0 0 2px #26ABE1;
  border: 2px solid #26ABE1;
}

.productReportSpecManagerFilterButton--active:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border: 2px solid #26ABE1 !important;
}