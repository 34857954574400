.EventGrid__Container .ScrollbarsCustom-Content {
  display: flex;
}

.EventGrid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  position: relative;
}

.EventGrid .EventGridColumn {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #EAF0F5;
  padding-right: 4px;
  padding-left: 4px;
}

.EventGrid .EventGridColumn:last-of-type {
  border-right: none;
}


.EventGrid__ColumnHeader {
  text-align: center;
  padding: 20px;
  color: #375393;
}

.EventGridColumn--selected .EventGrid__ColumnHeader {
  color: #26ABE1;
}

.EventGrid__EventDay {
  font-family: "Roboto bold";
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px !important;
}

.EventGrid__EventDate {
  font-family: "Roboto bold";
  font-size: 16px;
  font-weight: 500;
  color: inherit;
}

.EventGrid__EventColumn {
  flex: 1;
}

#EventGrid__Scrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

#EventGrid__Scrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}

.EventGrid__Loader {
  position: absolute;
  inset: 0;
  background: #70707017;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventGrid__Loader .EventGrid__LoaderIcon {
  font-size: 32px;
  color: #26ABE1;
}