.COABuilder__Section.COABuilder__SampleReportContainer {
  padding: 15px;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}

.COABuilder__SampleReportTable {
  height: auto;
  width: 100%;
  overflow-x: unset;
}

.COABuilder__SampleReportTable > tbody {
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: auto;
  width: 100%;
}

.COABuilder__SampleReportTable tr {
  display: flex;
  gap: 2px;
  min-height: 36px;
}

.COABuilder__SampleReportTable th,
.COABuilder__SampleReportTable td {
  padding: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  text-align: center;
}

.COABuilder__SampleReportTable__Header > th {
  color: #506375;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 20px;
  font-weight: normal;

  background-color: #c4d2df;
}

.COABuilder__SampleReportTable__Row > td {
  color: #505050;
  font-size: 10px;
  font-family: "Roboto";
  line-height: 12px;
  text-align: center;

  background-color: #f2f6fa;
}

.COABuilder__SampleReportTable .COABuilder__SampleReportTable__SampleID {
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}
