.no-results-gif-common {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-results-gif-common > img {
  object-fit: contain;
  width: 314px;
}

.no-results-gif-common >  .no-results-gif-common__msg {
  width: 100%;
  text-align: center;
  color: #19305A;
  font-size: 24px;
}