.SortBySelect.ant-select {
  border-radius: 3px;
  width: 136px;
  height: 40px;
}

.SortBySelect.ant-select.ant-select-disabled {
  opacity: 0.7;
}

.SortBySelect.ant-select .ant-select-selector {
  background-color: #eff2f3 !important;
  padding: 0px 10px;
  border-radius: 4px;
}

.SortBySelect.ant-select:not(.ant-select-disabled) .ant-select-selector:hover {
  background-color: #d5d5d5 !important;
}

.SortBySelect.ant-select .ant-select-selection-item,
.SortBySelect.ant-select .ant-select-selection-placeholder {
  color: #19305a;
  font-family: "Roboto";
  font-weight: normal;
  padding-inline-end: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.SortBySelect.ant-select .ant-select-selection-placeholder {
  transition: all 0.3s;
}

.SortBySelect.ant-select .ant-select-arrow {
  inset-inline-end: 7px;
}

.SortBySelect__Chevron {
  color: #19305a;
  transition: color 0.3s;
}

.SortBySelect.ant-select.ant-select-open .SortBySelect__Chevron {
  transform: rotate(180deg);
  margin-bottom: 2px;
}

/* Dropdown styling */
.SortBySelect__Dropdown.ant-select-dropdown {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  background: #fdfdfd;
  padding: 0;
}

.SortBySelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #758ab9;
  border-radius: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.SortBySelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option
  .ant-select-item-option-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.SortBySelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-active,
.SortBySelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected,
.SortBySelect__Dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected.ant-select-item-option-active {
  font-weight: 400;
  color: #26abe1;
  background-color: #eff2f3;
}
