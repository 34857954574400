/* Input styling */
.signup-invite-input {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 4px;
  min-width: 0px;
}

.signup-invite-input label {
  color: #375393;
  font-family: "Roboto Medium";
  font-size: 12px;
  margin-bottom: 0px;
}

.signup-invite-input-label {
  display: flex;
  gap: 6px;
}

.signup-invite-input input {
  color: #18305a;
  font-family: "Roboto";
  font-size: 14px;
  width: 100%;
}

.signup-invite-input input:focus {
  border-color: #26abe1;
}

.FieldInputParentDiv {
  position: relative;
  display: flex;
  align-items: center;
}

.FieldInputParentDiv--InnerErrorPlacement > input {
  padding-right: 18px;
}

.FieldInputParentDiv__ErrorIcon {
  position: absolute;
  right: 5px;
}

.signup-invite-input input.signup-invite-input-error,
.signup-invite-input input.signup-invite-input-invalid {
  border: 2px solid red;
  outline: none;
}

.signup-invite-input .toolTipWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-invite-input > svg > path {
  fill: red;
}

/* on tooltip hover, change the color of the inner svg */
.signup-invite-input > svg:hover > path {
  fill: rgb(166, 2, 2);
}

.signup-invite-invalid-chars-tooltip-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
}

.signup-invite-invalid-chars-tooltip-title > .invalid-char {
  white-space: nowrap;
}

.signup-invite-input-read-only {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #19305add;
  pointer-events: none;
}
