/* checkbox */
.checkboxContainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  border-radius: 2px;
  left: 0;
  top: -7px;
  height: 18px;
  width: 18px;

  background-color: transparent;
  border: 2px solid #D7DAE1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
}

.blueCheckmark.checkmark {
  border: 2px solid #26ABE1;
}

.checkboxContainer:hover input~.blueCheckmark.checkmark {
  background-color: transparent;
}

.checkboxContainer input:checked~.blueCheckmark.checkmark {
  background-color: #26ABE1;
  border: 2px solid #26ABE1;
}

.disabled {
  cursor: auto;
}