/* on tooltip hover, change the color of the inner svg */
.new-submission-pages-card-sample-id > svg.SampleID__ErrorIcon > path {
  fill: #6c7e8e;
}

svg.SampleID__ErrorIcon--red > path {
  fill: red;
}

.new-submission-pages-card-sample-id
  > svg.SampleID__ErrorIcon--red:hover
  > path {
  fill: rgb(166, 2, 2);
}

.new-submission-pages-card-sample-id label {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0px;
  color: #1f2d3b;
  width: 65px;
}

.new-submission-pages-card-sample-id input {
  padding: 5px 13px;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  color: #111a24a9;
  height: 29px;
  background: #fafbfc;
  border: 2px solid #e1e9f1;
  border-radius: 8px;
  flex-grow: 1;
  cursor: not-allowed;
}

.new-submission-pages-card-sample-id input.sample-submission-input-error {
  border-color: red;
}

.SampleID__InputDiv {
  position: relative;
  flex-grow: 1;
  /* width: 100%; */
}

.SampleID__InputDiv > input {
  width: 100%;
}
