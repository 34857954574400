.docCardFileAndName {
  background-color: none;
}

.docFileCard {
  max-width: 168px;
  min-width: 168px;
  height: 140px;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  padding: 8px;
  position: relative;
}

.docFileCard:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.docFileCardSelected {
  border: 2px solid #26A1D2;
}

.docCardHoverInputs {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 154px;
  align-items: center;
}

.docDotIconWrapper {
  cursor: pointer;
  width: 12px;
  display: flex;
  justify-content: center;
}

.docDotIcon {
  height: 18px;
}

.docCardFileIconName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
  font-family: Roboto Medium;
  font-size: 12px;
  line-height: 14px;
  color: #375393;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docCardFileIcon {
  height: 36.67px;
}

.docCardFileName {
  width: 177px;
  font-family: Roboto Medium;
  font-size: 12px;
  line-height: 14px;
  color: #375393;
  text-align: center;
  margin-top: 8px;
}

.docFileNameInputCard {
  border: none;
  border-radius: 3px;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  color: #375393;
  width: 100%;
  height: 40px;
  background-color: #FDFDFD;
  /* resize: none; */
  padding: 10px 32px 10px 10px;
}

.docFileNameInputCard:focus {
    box-shadow:none;
    border: 2px solid #26A1D2;
    outline: none;
}

.docFileNameInputCard::-webkit-scrollbar {
  display: none;
}

.DocFileNameInputCard__CloseIcon {
  position: absolute;
  right: 10px;
  height: 16px;
  width: 16px;
}

/* On mouse-over, add a grey background color */
.DocCardHoverInputs__CheckboxContainer:hover input ~ .miniCheckmark,
.DocCardHoverInputs__CheckboxContainer:hover input ~ .smallCheckmark,
.DocCardHoverInputs__CheckboxContainer:hover input ~ .checkmark {
  background-color: #D7DAE1;
}

/* When the checkbox is checked, add a blue background */
.DocCardHoverInputs__CheckboxContainer input:checked ~ .miniCheckmark,
.DocCardHoverInputs__CheckboxContainer input:checked ~ .smallCheckmark,
.DocCardHoverInputs__CheckboxContainer input:checked ~ .checkmark {
  background-color: #758AB9;
}

.DocCardFileName--relative-position {
  display: flex;
  align-items: center;
  position: relative;
}