.reset-password-btn {
  margin-top: 40px;
}

.reset-password-subtitle {
  font-size: 18px;
}

.reset-password-subtitle > .reset-password-email {
  font-family: "Roboto Bold";
}

.reset-password-require {
  font-family: "Roboto";
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #375393;
  margin-top: 5px;
  margin-bottom: 15px;
}