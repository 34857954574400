.StyledStepper {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.StyledStepper .StyledStepper__StepperButton {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.StyledStepper .StyledStepper__StepperButton.disabled,
.StyledStepper .StyledStepper__StepperButton:disabled {
  cursor: not-allowed;
}

.StyledStepper .StyledStepper__StepperButton svg {
  fill: #26ABE1;
}

.StyledStepper .StyledStepper__StepperButton.disabled svg {
  fill: #A5DDF3;
}

.StyledStepper__InputNumber {
  width: 60px;
}

.StyledStepper__InputNumber.hasError.ant-input-number-outlined,
.StyledStepper__InputNumber.hasError.ant-input-number-outlined:hover {
  border-color: #ee5a5a;
}

.StyledStepper__InputNumber .ant-input-number-input {
  text-align: center;
  user-select: none;
}

/* Hide spinner buttons in webkit-based browsers (Chrome, Edge, etc.) */
.StyledStepper__InputNumber input[type="number"]::-webkit-inner-spin-button,
.StyledStepper__InputNumber input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner buttons in Firefox */
.StyledStepper__InputNumber input[type="number"] {
  -moz-appearance: textfield;
}

.StyledStepper__InputNumber .ant-input-number-handler-wrap {
  display: none;
}