.productReportEditSpecNoResult {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.productReportEditSpecNoResultWatermelonImg {
  width: 220px;
  height: 160px;
}

.productReportEditSpecNoResultErrorMessage {
  font-size: 16px;
  color: #758ab9;
  font-family: Roboto;
}

.productReportEditSpecContainer {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #19305a;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.productReportEditSpecTitleDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-family: Roboto;
  font-weight: normal;
  padding: 8px 25px;
}

.productReportEditSpecTitleDiv__UpdateTestList {
  background-color: #F6F8FA;
}

.productReportEditSpecBackIcon {
  height: 14px;
  cursor: pointer;
}

.productReportEditSpecTableContainer {
  padding-left: 15px;
  padding-right: 5px;
  flex-grow: 1;
}

#productReportEditSpecButtons {
  padding: 24px 20px;
}

.LinkedFieldTitle,
.LinkedFieldValue {
  max-width: 708px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.productReportEditSpecTitle {
  overflow: hidden;
}

.productReportEditSpecButtonsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EditSpec__Button_Container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.EditSpec__Button__Outline {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border: 1px solid #26abe1;
  border-radius: 4px;
  cursor: pointer;
  color: #26abe1;
  background-color: rgba(255, 255, 255, 1);
}

.EditSpec__Button__Outline:hover {
  opacity: 0.5;
  color: #26abe1 !important;
  background-color: #ffffff;
}

.EditSpec__Button__Outline:focus-visible {
  border: 1px solid #025fcc;
}

.EditSpec__Button__Outline.disable, .EditSpec__Button__Outline:disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.EditSpec__Button__Outline > svg > path {
  fill: #26abe1;
}

/** Scrollbar styling and dynamic height **/
.EditSpec__LinkedFieldScrollBar.ScrollbarsCustom {
  max-height: calc(3 * 20px + 4px); /** Visible upto 3 lines **/
}

.EditSpec__LinkedFieldScrollBar .ScrollbarsCustom-Wrapper,
.EditSpec__LinkedFieldScrollBar .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.EditSpec__LinkedFieldScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.EditSpec__LinkedFieldScrollBar .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
	background-color: #133e8c !important;
}