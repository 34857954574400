.myAccountMainContainer {
  /* margin-right: 60px;
  margin-top: -52px; */
}

.myAccountRightMainContainer {
  width: 100%;
  overflow-y: auto;
  padding: 25px 50px;
  /* margin: 10px 10px; */
  /* border-radius: 15px; */
  background-color: white;
  /* box-shadow: 0px -2px 3px 0.75px rgb(0 0 0 / 2%), 2px 0px 3px 0.75px rgb(0 0 0 / 2%), -2px 0px 3px 0.75px rgb(0 0 0 / 2%), 0px 2px 3px 0.75px rgb(0 0 0 / 2%); */
}

.myAccountRightMainContainerNoShadow {
  height: calc(100vh - 80px);
  width: 100%;
  overflow-y: hidden;
  padding: 25px 50px 10px 50px;
  margin-top: 20px;
  background-color: white;
}

.myAccountSubmenuWrapper {
  width: 24px;
}

.myAccountSubmenuIcon {
  position: absolute;
  top: 14px;
  right: 20px;
  height: 16px;
  width: 16px;
  z-index: 1000;
}

.myAccountSubmenuLeftIcon {
  position: absolute;
  top: 12px;
  left: 20px;
  height: 16px;
  width: 16px;
  z-index: 1000;
}

.myAccountMainContainer .site-layout-background {
  height: 100vh;
  padding-top: 25px;
  background: #fdfdfd;
  border: 1px solid #eff2f3;
  box-sizing: border-box;
}

.myAccountMainContainer .ant-menu-item {
  background: #ffffff;
  padding-left: 50px !important;
  font-family: "Roboto";
}

.myAccountMainContainer
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #eff2f3;
  color: #26abe1;
}

.myAccountMainContainer
  .ant-layout-sider
  .ant-menu-inline
  .ant-menu-submenu-title {
  width: 100%;
}

.myAccountMainContainer .ant-layout-sider .ant-menu-inline {
  border-right: unset;
}

.myAccountContainerTitle {
  font-family: "Roboto Medium";
  font-size: 24px;
  color: #19305a;
  margin-bottom: 40px;
}

.myAccountContainerDesc {
  font-family: "Roboto Medium";
  font-size: 16px;
  color: #19305a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.myAccountFormTitle {
  font-family: "Roboto Medium";
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #375393;
}

.myAccountComponentContent {
  padding: 10px 10px;
  background: white;
}

/* Setting */
.myAccountSettingMainContainer {
  height: 100%;
  width: 100%;
}

.myAccountSettingMainContainer .settingRow {
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  font-family: "Roboto Medium";
  font-size: 18px;
  color: #19305a;
}

@media (max-width: 1150px) {
  .myAccountProfileMainContainer .myaccountEditInputPassWidth {
    width: 100px;
  }
}

@media (min-width: 1350px) {
  .myAccountProfileMainContainer .myaccountEditInputPassWidth {
    width: 300px;
  }
}

/* faq */

/* .myAccountFaqMainContainer {
  height: 100%;
  width: 100%;
}

.graybackground-shadow {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.faqCtgTitle {
  font-family: "Roboto Medium";
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #375393;
}

.marginTop-30 {
  margin-top: 30px;
}

.marginTop-50 {
  margin-top: 50px;
}

.marginBottom-20 {
  margin-bottom: 20px;
}

.myAccountFaqOpenCloseIcon {
  position: absolute;
  top: 5px;
  left: 20px;
  height: 16px;
  width: 16px;
  z-index: 1000;
} */

.faqShowResultContainer {
  background: #fdfdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  padding: 20px 20px 1px 20px;
}

.textarea-grayborder {
  background: #fdfdfd;
  width: 100%;
  height: 120px;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
  color: #758ab9;
  margin-bottom: 10px;
  padding: 5px 15px 5px 15px;
  font-family: "Roboto Medium";
  font-size: 14px;
  resize: none;
}

.textarea-grayborder:focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(39, 170, 225, 0.5);
}

.my-profile-layout {
  background-color: white !important;
}

.my-account-side-menu-sider {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important;
  flex: 0 0 290px !important;
}
