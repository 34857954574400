.COABuilderDroppableSection {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  /* max-width: 50%; */
  flex: 1 1 50%;
  padding: 10px 5px 10px 10px;
}

/* .COABuilderDroppableSection.COABuilderDroppableSection--left {
  padding: 10px 5px;
}

.COABuilderDroppableSection.COABuilderDroppableSection--right {
  padding: 10px 5px;
} */
