.Calendar__Container {
  background: #F2F6FA;
  height: 100%;
  display: grid;
  grid-template-columns: calc(310px + 32px) auto;
  gap: 16px;
  padding: 12px 16px;

}

.Calendar__CalendarFiltersContainer,
.Calendar__EventCalendar {
  background-color: #FFFFFF;
  border-radius: 12px;
}

.Calendar__Calendar {
  padding: 16px;
}

.Calendar__Calendar .Calendar_FlatpickrCalendar .Calendar__MarkDay {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #26ABE1;
  top: 8px;
  right: 8px;
}

.Calendar__Calendar .Calendar_FlatpickrCalendar .flatpickr-day.selected .Calendar__MarkDay {
  background-color: #FFFFFF;
}

.Calendar__CreateButtonPanel {
  padding: 24px 16px;
  border-top: 1px solid #EAF0F5;
  border-bottom: 1px solid #EAF0F5;
}

.Calendar__CreateButtonPanel .Calendar__CreateButton {
  gap: 8px;
  width: 100%;
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
}

.Calendar__EventFilters {
  padding: 16px;
}

.Calendar__EventCalendar {
  display: flex;
  flex-direction: column;
}

.Calendar__EventCalendar__Header {
  padding: 20px;
  padding-right: 10px;
  padding-left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EAF0F5;
}

.Calendar__EventCalendar__Header .Calendar__EventCalendar__HeaderTitle {
  font-family: "Roboto bold";
  font-size: 14px;
  font-weight: 500;
  color: #6C7E8E;
}

.Calendar__EventCalendar__Header .Calendar__EventCalendar__ButtonContainer {
  display: flex;
  align-items: stretch;
  gap: 16px;
}

.Calendar__EventCalendar__Header .Calendar__EventCalendar__ButtonContainer .Calendar__EventCalendar__Button {
  gap: 10px;
}

.EventCalendar__ExportButton {
  height: 32px !important;
}

.Calendar__Calendar .flatpickr-input {
  display: none;
}

.Calendar__Calendar .flatpickr-calendar {
  width: 100%;
}

#Calendar__CalendarFiltersContainer__Scrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

#Calendar__CalendarFiltersContainer__Scrollbar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}