.DetailsContentContainer {
  padding: 12px 25px 41px 27px;
  display: flex;
  gap: 46px;
}

.DetailsContent__ImageContainer {
  padding-top: 25px;
}

.DetailsContent__Inputs {
  display: flex;
  gap: 57px;
  width: 100%;
}

.DetailsContent__InputsLeft,
.DetailsContent__InputsRight {
  display: flex;
  flex-direction: column;
  gap: 9px;
  flex-grow: 1;
  flex-shrink: 1;
}

.DetailsContent__InputsLeft {
  flex-basis: 247px;
}

.DetailsContent__InputsRight {
  flex-basis: 205px;
}

.DetailsContent__InputsSectionLabel {
  color: #6c7e8e;
  font-size: 12px;
  font-family: "Roboto Medium";
  line-height: 16px;
  margin-bottom: 1px;
}

.DetailsContent__Inputs .TemplateBuilder__Textarea::placeholder,
.DetailsContent__Inputs .TemplateBuilder__Input::placeholder {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-family: "Roboto Medium";
  line-height: 20px;
}

.DetailsContent__Inputs .TemplateBuilder__Textarea,
.DetailsContent__Inputs .TemplateBuilder__Input,
.DetailsContent__Inputs .TemplateBuilder__Input--withLabel {
  color: #506375;
}

.DetailsContent__Inputs
  .TemplateBuilder__Input.DetailsContent__CompanyNameInput {
  font-size: 14px;
  line-height: 20px;
}

.DetailsContent__Inputs
  .TemplateBuilder__Textarea.DetailsContent__CompanyAddressInput {
  height: 59px;
}

.DetailsContent__InputsRight .TemplateBuilder__InputLabel {
  width: 56px;
}
