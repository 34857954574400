.pin-container {
  position: relative;
  cursor: pointer;
}

/* Pin container styling */
.pin-location-container {
  position: fixed;
  /* cursor: pointer; */
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
}

/* Circular (hide swab number) pin styling */

.pin-simple-tooltip {
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  padding: 2px 4px;
  border-radius: 11px;
  background-color: #FDFDFD;
  word-break: break-word;
  overflow: hidden;
  max-height: 100px;
  max-width: 200px;
  transform: translateX(-50%);
  z-index: 5;
}

.circular-pin-container {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  pointer-events: unset;
}

.circular-pin-halo {
  position: absolute;
  height: 22.23px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.circular-pin-solid-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  z-index: 2;
  padding: 0px 0px 0.5px 0px;
  flex-shrink: 0;
}

/* Show swab number pin styling */
.swab-number-pin-border {
  border-radius: 11px;
}

.swab-number-pin-background {
  display: flex;
  position: relative;
  padding: 2px 8px 2px 2px;
  border-radius: 11px;
  max-height: 96px;
  max-width: 200px;
}

.swabNumber {
  font-size: 16px;
  margin-left: 5px;
  line-height: 18.23px;
  word-break: break-word;
  overflow: hidden;
}

.swab-number-pin-arrow {
  width: 0px;
  height: 0px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, -0.5px);
}

.unpinned-pin-close-icon-background {
  position: absolute;
  right: -4px;
  top: -3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #FDFDFD;
}

.unpinned-pin-close-icon {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

/* Pin animation (old) */
.bounceanimation {
  animation: bounce-in-top 0.8s ease-out;
}

@keyframes bounce-in-top {
  0% {
    transform: translateY(-600px);
  }
  35% {
    transform: translateY(70px);
  }
  45% {
    transform: translateY(-35px);
  }
  70% {
    transform: translateY(20px);
  }
  88% {
    transform: translateY(-10px);
  }
  95% {
    transform: translateY(5px);
  }
  98% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

/* .pinNoAnimation {
  transform: translate(-50%, -76%);
} */

.highlightPinAnimation {
  animation: highlight-pin infinite 2s linear;
}

@keyframes highlight-pin {
  0% {
    transform: scale(1) translate(-50%, -76%);
  }
  50% {
    transform: scale(2) translate(-25%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -76%);
  }
}

.animationWhenMount {
  animation: pinTheLocation 1.5s linear;
}

@keyframes pinTheLocation {
  10% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animationWhenMount .pinBackground {
  animation: pinTheLocationBackground 1.5s linear;
}

@keyframes pinTheLocationBackground {
  0% {
    background-color: #26ABE1;
    box-shadow: none;
  }
  10% {
    background-color: #26ABE1;
    box-shadow: none;
  }
  100% {
    background-color: #FFFFFF;
    box-shadow: "0px 16px 8px rgba(0, 0, 0, 0.15), 0px 0px 1px #505050";
  }
}

.animationWhenMount .triangle {
  animation: triangleColor 1.5s linear;
}

@keyframes triangleColor {
  0% {
    border-top-color: #26ABE1;
    box-shadow: none;
  }
  10% {
    border-top-color: #26ABE1;
    box-shadow: none;
  }
  100% {
    border-top-color: #FFFFFF;
    box-shadow: "0px 16px 8px rgba(0, 0, 0, 0.15), 0px 0px 1px #505050";
  }
}

.animationWhenMount.outOfSpec .pinBackground {
  animation: pinTheLocationBackgroundOS 1.5s linear;
}

@keyframes pinTheLocationBackgroundOS {
  0% {
    background-color: #26ABE1;
    box-shadow: none;
  }
  10% {
    background-color: #26ABE1;
    box-shadow: none;
  }
  100% {
    background-color: #FFFFFF;
    box-shadow: "0px 16px 8px rgba(0, 0, 0, 0.15), 0px 0px 1px #505050";
  }
}

.animationWhenMount.outOfSpec .triangle {
  animation: triangleColorOS 1.5s linear;
}

@keyframes triangleColorOS {
  0% {
    border-top-color: #26ABE1;
    box-shadow: none;
  }
  10% {
    border-top-color: #26ABE1;
    box-shadow: none;
  }
  100% {
    border-top-color: #FFFFFF;
    box-shadow: "0px 16px 8px rgba(230, 53, 89, 0.3), 0px 0px 1px #E63559";
  }
}

.animationWhenMount.inSpec .pinBackground {
  animation: pinTheLocationBackgroundIS 1.5s linear;
}

@keyframes pinTheLocationBackgroundIS {
  0% {
    background-color: #26ABE1;
    box-shadow: none;
  }
  10% {
    background-color: #26ABE1;
    box-shadow: none;
  }
  100% {
    background-color: #FFFFFF;
    box-shadow: "0px 16px 8px rgba(30, 192, 74, 0.15), 0px 0px 1px #1EC04A";
  }
}

.animationWhenMount.inSpec .triangle {
  animation: triangleColorIS 1.5s linear;
}

@keyframes triangleColorIS {
  0% {
    border-top-color: #26ABE1;
    box-shadow: none;
  }
  10% {
    border-top-color: #26ABE1;
    box-shadow: none;
  }
  100% {
    border-top-color: #FFFFFF;
    box-shadow: "0px 16px 8px rgba(0, 0, 0, 0.15), 0px 0px 1px #505050";
  }
}

.animationWhenMount .pinIconBackground {
  animation: pinIconBackground 1.5s linear;
}

@keyframes pinIconBackground {
  0% {
    background-color: #FFFFFF;
  }
  10% {
    background-color: #FFFFFF;
  }
  100% {
    background-color: #505050;
  }
}

.animationWhenMount .swabNumber {
  animation: swabNumberColor 1.5s linear;
}

@keyframes swabNumberColor {
  0% {
    color: #FFFFFF;
  }
  10% {
    color: #FFFFFF;
  }
  100% {
    color: #505050;
  }
}

.animationWhenMount.outOfSpec .pinIconBackground {
  animation: pinIconBackgroundOS 1.5s linear;
}

@keyframes pinIconBackgroundOS {
  0% {
    background-color: #FFFFFF;
  }
  10% {
    background-color: #FFFFFF;
  }
  100% {
    background-color: #E63559;
  }
}

.animationWhenMount.outOfSpec .swabNumber {
  animation: swabNumberColorOS 1.5s linear;
}

@keyframes swabNumberColorOS {
  0% {
    color: #FFFFFF;
  }
  10% {
    color: #FFFFFF;
  }
  100% {
    color: #E63559;
  }
}

.animationWhenMount.inSpec .pinIconBackground {
  animation: pinIconBackgroundIS 1.5s linear;
}

@keyframes pinIconBackgroundIS {
  0% {
    background-color: #FFFFFF;
  }
  10% {
    background-color: #FFFFFF;
  }
  100% {
    background-color: #1EC04A;
  }
}

.animationWhenMount.inSpec .swabNumber {
  animation: swabNumberColorIS 1.5s linear;
}

@keyframes swabNumberColorIS {
  0% {
    color: #FFFFFF;
  }
  10% {
    color: #FFFFFF;
  }
  100% {
    color: #1EC04A;
  }
}

.animationWhenMount .pinIconOnMap {
  content: url("../../../../../assets/images/environment/pinIconWhite.png");
}

.animationWhenMount .themePin.pinIconOnMap {
  content: url("../../../../../assets/images/environment/miniPinIconBlue.png");
}

.animationWhenMount .outOfSpec .themePin.pinIconOnMap {
  content: url("../../../../../assets/images/environment/miniPinIconRed.png");
}

.animationWhenMount .inSpec .themePin.pinIconOnMap {
  content: url("../../../../../assets/images/environment/pinIconGreen.png");
}

.animationWhenMount .pinIconOnMap {
  animation: pinIconOnMapAnimate 1.5s linear;
}

@keyframes pinIconOnMapAnimate {
  0% {
    content: url("../../../../../assets/images/environment/miniPinIconBlue.png");
  }
  100% {
    content: url("../../../../../assets/images/environment/pinIconWhite.png");
  }
}