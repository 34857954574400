.env-report-history-container {
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 7px 20px 14px 20px;

  display: flex;
  flex-direction: column;
  gap: 6px;

  position: relative;
}

.env-report-history-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.env-report-history-header > svg {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.env-report-history-table {
  background: #F9F9F9;
  border-radius: 10px;
  padding: 16px 20px;
  height: 100%;
  width: 100%;
}
.env-report-history-table .ScrollbarsCustom-Content {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.env-report-history-table .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  right: -7px !important;
}
.env-report-history-table .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #B3BFDB !important;
}

.env-report-history-file-card {
  background-color: white;
  padding: 6px;
  width: 100%;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.env-report-history-file-card:hover {
  background-color: #daf1fa;
  max-height: fit-content;
}

.env-report-history-file-card > .pdf-icon-path {
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 60%;
}

.env-report-history-file-card:hover > .pdf-icon-path {
  max-width: 100%;
}

.env-report-history-file-card:hover > .pdf-icon-path {
  height: fit-content;
  color: #26ABE1;
}

.env-report-history-file-card > .pdf-icon-path > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.env-report-history-file-card:hover > .pdf-icon-path > span {
  white-space: normal;
  word-break: break-word;
}

.env-report-history-file-card > .pdf-icon-path > svg {
  flex-shrink: 0;
}

.env-report-history-file-card > .file-card-date {
  font-family: 'Roboto Medium';
  line-height: 9px;
  letter-spacing: 0.015em;
  color: #B3BFDB;
  white-space: nowrap;
  flex-shrink: 0;
}

.env-report-history-no-result {
  position: absolute;
  font-size: 16px;
  color: rgb(117, 138, 185);
  font-family: Roboto;
}

.env-report-history-file-card:hover > .file-card-date {
  display: none;
}

