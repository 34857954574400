.analysis-request li {
  word-break: break-word;
}

.analysis-request-search-box {
  position: relative;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.analysis-request-search-box input:focus {
  border-color: #97a3ae;
}

.analysis-request-search-box img {
  height: 13.54px;
  position: absolute;
  left: 9px;
  top: 13px;
}

.analysis-request-search-box input {
  background: #ffffff;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  padding-left: 31px;
  padding-right: 30px;
  font-family: "Roboto";
  font-size: 14px;
  color: black;
  line-height: 20px;
  height: 40px;
  border-radius: 8px;
  width: 57%;
}

.analysis-request-search-box input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #afbdca;
  opacity: 1;
  /* Firefox */
}

.analysis-request-search-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #afbdca;
}

.analysis-request-search-box input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #afbdca;
}

.analysis-request-tests-divider {
  border-top: 1px solid #d1dce8;
  margin-top: 10px;
  margin-bottom: 14px;
}

.analysis-request-tests-selected-and-checkbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sample-submission-checkbox-container.analysis-requested-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: unset;
  padding: 0px;
}

.sample-submission-checkbox-container.analysis-requested-checkbox-container
  > label {
  font-family: "Roboto medium";
  font-size: 12px;
  line-height: 16px;
  color: #6c7e8e;
  margin-bottom: 0;
}

.analysis-request-smart-suggestions-header,
.analysis-request-tests-selected-header,
.analysis-request-tests-selected-clear {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(108, 126, 142, 1);
  margin-bottom: 0;
}

.analysis-request-tests-selected-header-div {
  display: flex;
  flex-direction: row;
  gap: 21px;
}

.analysis-request-tests-selected-clear {
  cursor: pointer;
}

.analysis-request-tests-selected-clear:hover {
  text-decoration: underline;
}

.analysis-request-smart-suggestions-container {
  height: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis-request-smart-suggestions-header {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.analysis-request-smart-suggestions-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  width: 100%;
  margin-bottom: 0;
}

.analysis-request-smart-suggestions-list li,
.AnalysisRequest__UserAddedTest {
  box-sizing: border-box;
  width: 216px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.analysis-request-smart-suggestions-list li:hover,
.AnalysisRequest__UserAddedTest:hover {
  cursor: pointer;
}

.AnalysisRequest__UserAddedTest {
  justify-content: space-between;
}

.AnalysisRequest__UserAddedTest > span {
  word-break: break-all;
  max-width: 90%;
}

.AnalysisRequest__UserAddedTest > svg {
  flex-shrink: 0;
}

.AnalysisRequest__UserAddedTest.selected-test-suggestion > svg {
  fill: #26abe1;
}

.unselected-test-suggestion:hover {
  /* background: #edf2f7; */
  border: 1.5px solid rgba(248, 250, 252, 1);
  color: #26abe1;
}

.unselected-test-suggestion {
  color: #506375;
  border: 1.5px solid #e1e9f1;
  background-color: rgba(248, 250, 252, 1);
}

.disabled-test-suggestion {
  color: #d2d4d6;
  border: 1.5px solid #e1e9f1;
  cursor: default !important;
}

.analysis-request-tests-selected-container {
  height: 45%;
  width: 100%;
  padding: 14px 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analysis-request-tests-selected-container h4 {
  margin-bottom: 18px;
}

.analysis-request-tests-selected-list {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  width: 100%;
  padding-top: 2px;
}

.selected-test-suggestion,
.analysis-request-tests-selected-list
  .analysis-request-tests-selected-list-item {
  box-sizing: border-box;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(38, 171, 225, 1);
  border: 1px solid #26abe1;
  border-radius: 8px;
  background: rgba(242, 246, 250, 1);
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.analysis-request-tests-selected-list
  .analysis-request-tests-selected-list-item.AnalysisRequest__InvalidTest {
  color: #e63559;
  border: 1.5px solid #e63559;
}

.analysis-request-tests-selected-list
  .analysis-request-tests-selected-list-item
  .AnalysisRequest__CloseIcon {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis-request-tests-selected-list
  .analysis-request-tests-selected-list-item.AnalysisRequest__InvalidTest
  svg
  > path,
.analysis-request-tests-selected-list
  li.AnalysisRequest__InvalidTest
  svg
  > path {
  fill: #e63559;
}

.analysis-request-tests-selected-list
  .analysis-request-tests-selected-list-item
  .AnalysisRequest__CloseIcon:hover {
  cursor: pointer;
}

.AnalysisRequest__NoSuggestionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.analysis-request-no-tests-found,
.no-tests-added {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c7e8e;
  width: fit-content;
}

.AnalysisRequest__NoTestsFoundSubHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #6c7e8e;
  width: fit-content;
}

.searchHighlight {
  color: #26abe1;
}

.analysis-requested-checkbox-container label {
  pointer-events: none;
}

/* on tooltip hover, change the color of the inner svg */
.analysis-request-smart-suggestions-header
  > svg.AnalysisRequest__InfoIcon
  > path {
  fill: #6c7e8e;
}

.new_test_button {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  justify-self: end;
  align-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 32px;
  width: 32px;
  border: 2px solid #26abe1;
  border-radius: 4px;
  cursor: pointer;
  color: #26abe1;
  background-color: white;
}

.new_test_button > svg > path {
  fill: #26abe1;
  stroke: #26abe1;
}

.analysis-request-search-div {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.TooltipComponent_Analysis {
  width: 156px;
  height: 36px;
  border-radius: 2px;
  font-size: 12px;
  color: #ffffff;
  font-family: "Roboto";
}

.AnalysisRequest__MaximizeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #f2f6fa;
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: auto;
  margin-right: -22px;
}
