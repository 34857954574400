.productCoaSearch {
  position: relative;
  width: 330px;
}

.productCoaSearch input {
  width: 329px;
  height: 40px;
  /* background: #fdfdfd; */
  background: #F6F8FA;
  /* border: 1px solid #e1e1e1; */
  /* border-radius: 4px; */
  border: 1px solid #E1E9F1;
  border-radius: 8px;
  padding: 10px;
  padding-right: 64px;
  font-size: 14px;
}

.productCoaSearch input::placeholder {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  /* color: #758ab9; */
  color: #6C7E8E;
  font-style: normal;
}

.productCoaSearch input:focus.validSearchValue {
  border: 1px solid #77ccee;
}

.productCoaSearch input:focus.invalidSearchValue {
  border: 1px solid #b3e0f2;
}

.productCoaSearch input.validSearchValue + .searchIconBackground,
.productCoaSearch input.invalidSearchValue + .searchIconBackground {
  position: absolute;
  right: 2px;
  top: 1px;
  border-radius: 0px 7px 7px 0px;
  /* background-color: #eff2f3; */
  background-color: #E1E9F1;
  height: 38px;
  width: 52px;
  cursor: pointer;
}

.productCoaSearch input:focus.invalidSearchValue + .searchIconBackground {
  background-color: #b3e0f2;
}

.productCoaSearch input:focus.validSearchValue + .searchIconBackground {
  background-color: #26abe1;
}

.productCoaSearch input + .searchIconBackground {
  /* background: url("../../../../../../assets/images/product/searchIconBlue.png")
    no-repeat; */
  background: url("../../../../../../assets/images/product/template/SearchIconBlack.png")
    no-repeat;
  background-size: 16px 16px;
  background-position: left 18px top 11px;
}

.productCoaSearch input:focus + .searchIconBackground {
  background: url("../../../../../../assets/images/product/template/SearchIconWhite.png")
    no-repeat;
  background-size: 16px 16px;
  background-position: left 18px top 11px;
}
