.TestSuggestionList__TestList {
  flex: 1;
  margin-top: 20px;
}

.TestSuggestionList__Loading {
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TestSuggestionList__SmartSuggestionsList {
  list-style: none;
  padding-left: 0;
  /* display: flex;
  flex-flow: row wrap; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
  margin-bottom: 0;
  padding-right: 6px;
}

.TestSuggestionList__SmartSuggestionsList li {
  box-sizing: border-box;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.TestSuggestionList__SmartSuggestionsList li > span {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.TestSuggestionList__SmartSuggestionsList li:hover {
  cursor: pointer;
}

.TestSuggestionList__NoResult {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.TestSuggestionList__NoResultWatermelonImg {
  width: 220px;
  height: 160px;
}

.TestSuggestionList__NoResultErrorMessage {
  font-size: 16px;
  color: #758ab9;
  font-family: Roboto;
}

.TestSuggestionList__DisabledSuggestion {
  color: #d2d4d6;
  border: 1.5px solid #e1e9f1;
  cursor: default !important;
}

.TestSuggestionList__UnselectedSuggestion {
  color: #506375;
  border: 1.5px solid #e1e9f1;
  background-color: rgba(248, 250, 252, 1);
}

.TestSuggestionList__UnselectedSuggestion:hover {
  /* background: #edf2f7; */
  border: 1.5px solid #26abe1;
  color: #26abe1;
}

#TestSuggestionList__ScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  width: 6px !important;
  background: #d7dae1 !important;
}

#TestSuggestionList__ScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY:hover {
  width: 6px !important;
  background: #758ab9 !important;
}