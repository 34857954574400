.IndexTag{
  display: inline-block; 
  margin-right: 8px; 
  margin-bottom: 8px;
}

.IndexTag__Container {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0px;
  padding-left: 4px;
  background-color: #E7EDF8;
  border-radius: 3px;
  border: 1px solid #E7EDF8;
  color: #2C55A0;
}

.readOnlyMode .IndexTag__Container {
  padding-left: 8px;
  padding-right: 8px;
  color: #5F88D3;
}

.readOnlyMode .IndexTag__Container .tagRemoveIcon {
  display: none;
}

#IndexTag__RemoveIcon {
  height: 24px; 
  width: 24px; 
  padding: 5.33px;
}

.IndexTag__Container:hover,
.IndexTag__Container.IndexTag__Edit {
  border: 1px solid #19315C;
}

.IndexTag__InputContainer {
  display: flex;
  align-items: center;
}

.IndexTag__Container,
.IndexTag__Container .IndexTag__InputContainer.IndexTag__TagInputContainer .IndexTag__TagInputContainer-Input {
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}

.IndexTag__TagInputContainer{
  position: relative;
}

.IndexTag__TagInputContainer .IndexTag__TagInputContainer-dummyText {
  padding: 0px;
  display: inline-block;
  visibility: hidden;
  white-space: pre;
}

.IndexTag__Container .IndexTag__InputContainer.IndexTag__TagInputContainer .IndexTag__TagInputContainer-Input {
  border: none;
  padding: 0px;
  background-color: transparent;
  position: absolute;
  left: 0;
  width: 100%;
  color: #19315C;
}

.IndexTag__Container .IndexTag__InputContainer.IndexTag__TagInputContainer .IndexTag__TagInputContainer-Input:focus {
  border: none;
}