.draft-submission-card {
  word-break: break-word;
}

.draft-delete-icon path:last-child {
  fill: #AFBDCA;
}

.draft-delete-icon path {
  fill: #AFBDCA;
}

.draft-delete-icon:hover {
  cursor: pointer;
}

.draft-delete-icon:hover path {
  fill: #26ABE1;
}

.draft-submission-card-subtitle {
  font-size: 12px;
  line-height: 16px;
  color: #6C7E8E;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 18px;
  column-gap: 14px;
}

.draft-submission-card-time {
  font-family: "Roboto";
}

.draft-submission-card-submission-name {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #111A24;

  font-family: "Roboto Medium";
  font-weight: 500;
}

.draft-submission-card-edit,
.draft-delete-icon {
  margin-left: 6px;
}

.draft-submission-card-subtitle-num-samples {
  display: inline-block;
  min-width: fit-content;
  font-family: "Roboto Bold";
  font-size: 12px;
}

.draft-submission-card-submission-name:hover {
  cursor: pointer;
  color: #26ABE1;
}

.draft-submission-card-submission-name:hover path {
  fill: #26ABE1;
}

.draft-submission-card-header svg {
  vertical-align: initial;
}

.draft-submissions-status-icons {
  margin-right: 10px;
  margin-bottom: 2px;
  width: 12px;
  height: 13.3px;
}

.draft-submission__Loading {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  width: 16px;
  /* adjust the width to match the edit icon size */
  height: 16px;
  /* adjust the height to match the edit icon size */
  transform: scale(0.6);
}

.draft-submission__Checkbox__Spin {
  font-size: 12px;
  /* adjust the font size to match the edit icon size */
  color: #26abe1;
}