.createReportContainer, .createCoaContainer {
  /* width: 120px;
  height: 40px; */
  background: #26abe1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
  min-width: 110px;
}

.createReportContainer:hover {
  box-shadow: rgb(233, 232, 232) 1px 2px 7px 2px;
}

.coaContainer.coaNoResults {
  height: calc(100vh - 212px);
}

.createCoaCancelContainer {
  width: 120px;
  height: 40px;
  background: #F2F6FA;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #111A24;
  cursor: pointer;
}
