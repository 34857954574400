.env-upload-map {
  height: 100%;
  background: rgba(179, 191, 219, 0.12);
  border: 2.72027px dashed #EFF2F3;
  border-radius: 4px;
  overflow: hidden;
}

.env-drag-drop-map-dragging {
  border: 2.72027px dashed #26ABE1;
  background-color: #F5F7FA;
}

.UploadMap__Btns {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
}

.UploadMap__Btns button {
  height: 32px;
  border-radius: 3px;
  font-family: "Roboto medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  border: none;
}

.UploadMap__CancelBtn, .UploadMap__DoneBtn {
  padding: 8px 12px;
}

.UploadMap__Btns button:first-child {
  width: 70px;
  background: #EFF2F3;
  color: #19305A;
}

.UploadMap__Btns button:last-child {
  width: 70px;
  background: #26ABE1;
  color: #F9F9F9;
}

.UploadMap__Img {
  opacity: 0;
}