.DeleteIndexConfirmation.ant-modal .ant-modal-content{
    border-radius: 3px;
    padding: 16px 0px 24px;
}

.modalSubmitBtn{
    margin-top: 25px;
    padding: 10px 27px;
    width: 117px;
    border-radius: 5px;
    background-color: white;
    color: #203360;
    text-align: center;
    border: 1px solid #3EC6FF;
    font-size: 14px;
    margin-right: 20px;
    margin-left: 14px;
}

.modalSubmitBtn:hover{
    background: #3EC6FF;
    color: white;
}
 
.labelText{
    float: left;
    margin-left: 31px;
    color: #203360;
}

.DeleteIndexConfirmationAlert {
    min-height: 200px !important;
    box-shadow: 0px 1.78782px 6.25738px -1.34087px rgba(24, 39, 75, 0.12), 0px 4.0226px 19.6661px -0.893912px rgba(24, 39, 75, 0.14) !important;
    border-radius: 3px !important;
    background-color: #FDFDFD !important;
}

.DeleteIndexConfirmation--text-left {
    text-align: left !important;
}