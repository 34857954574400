.login-page {
  height: 100vh;
  position: relative;
}

.login-page .login-box {
  width: 420px;
  flex-shrink: 0;
  flex-basis: 420px;
}

.loginMainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 20%;
  padding-top: 200px;
}

.login-header {
  margin-bottom: 23px;
  font-family: "Roboto Medium";
  font-size: 32px;
  color: #19305a;
  line-height: 40px;
}

.login-header-subtitle {
  font-family: "Roboto";
  font-size: 16px;
  color: #19305a;
  letter-spacing: 0.5px;
  margin-bottom: 48px;
}

.login-input-row {
  position: relative;
}

.login-remember-forgot-row {
  height: 30px;
  margin-top: 10px;
}

.login-button-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

.login-btn {
  height: 48px;
  width: 223px;
  background: #26abe1;
  border: 1px solid #26abe1;
  border-radius: 5px;
  color: white;
  font-family: "Roboto Medium";
  font-size: 14px;
  letter-spacing: 0.1px;
}

.login-btn:hover {
  box-shadow: 0 3px 5px rgb(9 30 66 / 20%), 0 0 1px rgb(9 30 66 / 31%);
}

.Login__Button {
  height: 40px;
  width: 223px;
}

.login-dont-have-account-container {
  margin-top: 20px;
  text-align: center;
}

.login-dont-have-account-container span {
  font-family: "Roboto";
  font-size: 16px;
  color: #375393;
}

.loginImage {
  position: relative;
  flex-shrink: 2;
  flex-basis: 100%;
  overflow: visible;
  min-width: 600px;
  min-height: 550px;
  margin-top: -9px;

  display: flex;
}

.loginImageCharacters {
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  width: 66%;
  height: 66%;
  z-index: 2;
}

.loginImageBubbles {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  object-fit: fill;
}

.forgot-link {
  height: 100%;
  font-size: 14px;
  text-align: center;
  color: #182534;
  float: right;
  border: none;
  background: white;
}

.forgot-link:hover {
  color: #27aae1;
}

.signUp-link {
  border: none;
  font-family: "Roboto";
  font-size: 16px;
  height: 20px;
  background: white;
  color: #26abe1;
}

.signUp-link:hover {
  cursor: pointer;
  color: #26abe1;
}

.register-link {
  font-family: "Roboto";
  font-size: 14px !important;
  line-height: 30px;
  color: #182534 !important;
  float: left;
}

.ForgotPassword__Input {
  margin: -10px 0px 24px;
}

.forgotPasswordModal.ant-modal .ant-modal-content {
  border-radius: 3px;
  padding: 11px 0px 19px;
}

.linkSentModal.ant-modal .ant-modal-content {
  border-radius: 4px;
  padding: 11px 0px 19px;
}

.logoLoginPage {
  position: absolute;
  width: 86px;
  height: 86px;
  top: 15px;
  left: 40px;
}

/* checkbox */
.loginCheckboxContainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.loginCheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.loginSmallCheckmark {
  position: absolute;
  border-radius: 2px;
  left: 0;
  background-color: transparent;
  border: 2px solid #7ce;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
}

.login-remember-me-text {
  margin-left: 23px;
}

/* On mouse-over, add a grey background color */

.loginCheckboxContainer:hover input~.loginSmallCheckmark {
  background-color: #e7f0fe;
}

/* When the checkbox is checked, add a blue background */
.loginCheckboxContainer input:checked~.loginSmallCheckmark {
  background-color: #29ace1;
  border: 2px solid #29ace1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.loginSmallCheckmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.loginCheckboxContainer input:checked~.loginSmallCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.loginCheckboxContainer .loginSmallCheckmark:after {
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loginCheckboxContainer .loginSmallCheckmark:after {
  border-width: 0 2px 2px 0;
  left: 3px;
  top: 0px;
  width: 4px;
  height: 8px;
}

/* Forgot password modal styling */
.forgotPasswordDel {
  text-align: left;
  padding: 28px 40px 18px 40px;
}

.forgotPasswordDelTitle {
  font-family: "Roboto Medium";
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #19305a;
  margin-bottom: 16px;
}

.forgotPasswordDelTitleMessage {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #375393;
  margin-bottom: 24px;
}

.forgotPasswordDelBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.forgotPasswordDelBtnCancel {
  border: none;
  padding: 8px 12px;
  background: #eff2f3;
  border-radius: 3px;
  color: #19305a;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.forgotPasswordDelBtnDelete {
  border: none;
  padding: 8px 12px;
  background: #26abe1;
  border-radius: 3px;
  color: #f9f9f9;
  font-family: "Roboto Medium";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.forgotPasswordDelBtnCancel:hover,
.forgotPasswordDelBtnDelete:hover {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.Login__SignupLinkDiv {
  margin-top: 20px;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  color: #375393;
}