.SamplePageHeader__Container {
  display: flex;
  align-items: center;
}

.SamplePageHeader__Title {
  font-family: "Roboto Bold";
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}

.SamplePageHeader__Icon {
  margin-right: 10px;
}