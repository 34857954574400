/* Input Field Style */
.custom-field.one input {
  background: none;
  border: 2px solid #b3e0f2;
  transition: border-color 0.3s ease;
  width: 318px;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
  text-align: left;
  color: #b3bfdb;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #77ccee;
  transition-delay: 0.1s;
}

.custom-field.one input.dirty + .placeholder,
.custom-field.one input:not(:placeholder-shown) + .placeholder,
.custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #77ccee;
  background: #fff;
  width: auto;
}

/* Tabs Input field style */

.custom-field.tag input {
  background: none;
  border: 2px solid #b3e0f2;
  transition: border-color 0.3s ease;
  width: 201px;
}

.custom-field.tag input + .placeholder {
  left: 4px;
  padding: 0 5px;
  text-align: left;
  color: #b3bfdb;
}

.custom-field.tag input.dirty,
.custom-field.tag input:not(:placeholder-shown),
.custom-field.tag input:focus {
  border-color: #77ccee;
  transition-delay: 0.1s;
  padding-right: 50px;
}

.custom-field.tag input.dirty + .placeholder,
.custom-field.tag input:not(:placeholder-shown) + .placeholder,
.custom-field.tag input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #77ccee;
  background: #fff;
  width: auto;
}

.tagDesign {
  padding-left: 62px;
  text-align: left;
}

/* .ScrollbarsCustom-Content {
  padding-left: 64px !important;
  padding-right: 25px !important;
} */

/* Button CSS */
.cancelBtn {
  background-color: white;
  text-transform: none;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 35px;
  width: 113px;
  height: 36px;
  margin-bottom: 18px;
  margin-left: 20px;
  border: 1px solid rgb(119, 204, 238);
  color: rgb(119, 204, 238);
  box-shadow: none;
  transform: translate(11px, -8px);
  border-radius: 5px;
}

.cancelBtn:hover {
  background-color: #3ec6ff;
  color: white;
}

.productEditCard__Title {
  font-family: Roboto Medium;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.product-card .editCard .ProductEditCard__Body__ItemNameLabel {
  margin-top: 0px;
}

.product-card .editCard .charsCount.ProductEditCard__Body__CharsCount--is-last {
  margin-top: 0px;
  margin-bottom: 0px;
}

.product-card .productEditCard__Body--itemName {
  resize: none;
}

.product-card .productEditCard__tagName--Input {
  max-width: 205px;
}

.productEditCard__tagName--addTagIcon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.productEditCard__Scrollbar {
  text-align: left;
  border-radius: 3px;
  height: 95px;
  margin-top: 8px;
  background: #ffffff;
}

.productEditCard__Buttons {
  margin-top: 20px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

/** Inputs Scrollbar styling **/
.product-card .editCard .ProductEditCard__InputsScrollbar.ScrollbarsCustom {
  max-height: 205px !important;
  margin-top: 10px;
}

/* Allows for dynamic height up to max height*/
.product-card
  .editCard
  .ProductEditCard__InputsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Wrapper,
.product-card
  .editCard
  .ProductEditCard__InputsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

/* Make space for scrollbar only when it should show */
.product-card
  .editCard
  .ProductEditCard__InputsScrollbar.ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Scroller {
  margin-right: -15px !important;
}

.product-card
  .editCard
  .ProductEditCard__InputsScrollbar.ScrollbarsCustom
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  right: -12px !important;
}

.product-card
  .editCard
  .ProductEditCard__InputsScrollbar
  .ScrollbarsCustom-Content {
  padding: 0px !important;
}

.productEditCard__TabsLabelInput {
  display: flex;
  align-items: center;
}

.ProductEditCard__CardBody {
  padding: 0px 15px;
  background-color: #ffffff;
}
