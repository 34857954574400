.env-drag-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.env-drag-drop:hover,
.env-drag-drop-header:hover {
  cursor: pointer;
}

.env-drag-drop label {
  display: block;
}

.env-drag-drop-header {
  margin-top: 22px;
  margin-bottom: 22px;
  max-width: 238px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #375393;
  text-align: center;
}

.drag-drop-browse {
  width: 126px;
  height: 42px;
  background: #26abe1;
  border-radius: 4px;
  font-family: "Roboto medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* display: flex;
  align-items: center;
  text-align: center; */
  color: #f9f9f9;
  border: none;
}
