.SubmissionReceiptModal__Header__Title {
  display: flex;
  padding-left: 5px;
  margin-bottom: 24px;
  color: #000;
  font-family: "Roboto medium";
  font-size: 16px;
}

.SubmissionReceiptModal__Header__Title::before {
  content: "";
  width: 12px;
  height: 27px;
  background: #26abe1;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
}

.SubmissionReceiptModal__Header__Container {
  border-bottom: 1px solid #0000000a;
  padding: 0px 5px 24px 5px;
}

.SubmissionReceiptModal__Header {
  display: flex;
  gap: 36px;
}

.ReceiptModalHeader__Table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow-x: hidden;
}

.ReceiptModalHeader__Table__Row {
  display: flex;
  gap: 36px;
}

.ReceiptModalHeader__Table th,
.ReceiptModalHeader__Table td {
  display: flex;
  text-align: left;
  font-style: normal;
  flex: 1 1 33%;
  word-break: break-word;
}

.ReceiptModalHeader__Table th {
  font-size: 12px;
  line-height: 14px;
  color: #afbdca;
  font-family: "Roboto Medium";
  font-weight: 400;
}

.ReceiptModalHeader__Table td {
  color: #111a24;
  font-size: 12px;
  line-height: 14px;
  vertical-align: top;
  font-family: "Roboto Medium";
}

.ReceiptModalHeader__Comment__Title {
  font-size: 12px;
  line-height: 14px;
  color: #afbdca;
  font-family: "Roboto Medium";
  font-weight: 400;
  padding: 16px 0px 8px 0px;
}

.ReceiptModalHeader__Comment__Body {
  color: #111a24;
  font-size: 12px;
  line-height: 14px;
  vertical-align: top;
  font-family: "Roboto Medium";
}
