.Login__Input__Label {
  font-family: "Roboto medium";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #182534;
  display: block;
  text-align: left;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}

.Login__Input.ant-input-affix-wrapper,
.Login__Input.ant-input {
  gap: 4px;
  height: 40px;
  width: 100%;
  border-color: #c4d2df;
  border-radius: 4px;
  /* padding: 6px 12px; */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.Login__Input.ant-input-affix-wrapper:hover,
.Login__Input.ant-input-affix-wrapper:focus,
.Login__Input.ant-input:focus {
  border: 1px solid #26abe1 !important;
}

/* styles applied only to the component with only "Login__Input" class */
.Login__Input:not(.Login__PasswordInput) {
  margin-bottom: 24px;
}

.ant-input-password-icon {
  filter: brightness(0.5) saturate(0%);
}

.Login__Input.ant-input-affix-wrapper input,
.Login__Input.ant-input input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.Login__Input.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.Login__Input.ant-input-affix-wrapper:hover,
.Login__Input.ant-input-affix-wrapper.ant-input-focused,
.Login__Input.ant-input:hover {
  border: 1px solid #26abe1 !important;
}

/* Suffix styling */
.Login__Input .ant-input-suffix {
  padding-left: 4px;
}


.Login__Input .ant-input-suffix>img,
.Login__Input .ant-input-suffix>span {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.Login__Input .ant-input-suffix>.ant-input-clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: #182534 !important;
  cursor: pointer;
  transition: all 0.3s;
}

/* .Login__Input__ClearIcon {
  content: url("../../../assets/images/common/CloseIconOutlined.svg");
  transform: scale(0.75);
}

.Login__Input__ClearIcon:hover {
  content: url("../../../assets/images/common/CloseIconFilled.svg");
  transform: scale(0.75);
} */