.COABuilderAddSection__Container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}

.COABuilderAddSection__Line {
  border-bottom: 1px solid #eaf0f5;
  flex-grow: 1;
}

.COABuilder__AddSectionButton {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  color: #c4d2df;
  font-size: 12px;
  font-family: "Roboto";
  line-height: 20px;
}

.COABuilder__AddSectionButton:hover {
  color: #506375;
}

.COABuilder__AddIcon {
  width: 15px;
  height: 15px;
  fill: #c4d2df;
}

.COABuilder__AddSectionButton:hover .COABuilder__AddIcon {
  fill: #506375;
}
