.SampleSubmission__ActionsMenu .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0px;
}

.SampleSubmission__ActionsMenuItem {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Roboto";
  color: #19305a;

  padding: 5px 12px;
}

.SampleSubmission__ActionsMenuItem--disabled.SampleSubmission__ActionsMenuItem--add,
.SampleSubmission__ActionsMenuItem--disabled.SampleSubmission__ActionsMenuItem  {
  color: #AFBDCA;
}

.SampleSubmission__ActionsMenuItem--disabled.SampleSubmission__ActionsMenuItem--add path {
  stroke: #AFBDCA;
}

.SampleSubmission__ActionsMenuItem--add {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Roboto";
  color: #19305a;

  padding: 5px 12px 5px 9px;
}

.SampleSubmission__ActionsMenuEditIcon path {
  fill: #19305a;
}

.SampleSubmission__ActionsMenuDeleteIcon path:last-child {
  fill: transparent;
}

.SampleSubmission__ActionsMenuDeleteIcon path {
  fill: #19305a;
}

.SampleSubmission__ActionsMenuItem--disabled .SampleSubmission__ActionsMenuDeleteIcon path {
  fill: #AFBDCA;
}

.SampleSubmission__ActionsMenuItem--disabled .SampleSubmission__ActionsMenuDeleteIcon path:last-child {
  fill: transparent;
}

.SampleSubmission__ActionsMenuAddIcon path {
  stroke: #19305a;
}

.SampleSubmission__ActionsMenuMoreIcon {
  cursor: pointer;
}

.SampleSubmission__ActionsMenuMoreIcon--disabled {
  cursor: default;
}

.SampleSubmission__ActionsMenu__Tooltip {
  font-family: "Roboto";
  font-size: 12px;
}

.SampleSubmission__ActionsMenu__Tooltip .ant-tooltip-inner,
.SampleSubmission__ActionsMenu__Tooltip .ant-tooltip-arrow::before
 {
  color: #505050;
  background-color: #E0E4EB;
}