.signup-success {
  background-color: white;
  padding: 20px;
  height: 360px;
  width: 480px;
  overflow: hidden;
  border-radius: 5px;
  align-self: center;
}

button.ant-btn-primary.go-to-login-btn {
  background: #26aBE1;
  border: 1px solid #26aBE1;
  border-radius: 5px;
  color: white;
  font-family: 'Roboto Medium';
  box-shadow: unset;
}

button.ant-btn-primary.go-to-login-btn:hover {
  background-color: #9DD9F2 !important;
  border-color: #9DD9F2;
}

.StatusPage__Result {
  font-family: 'Roboto Medium';
}
