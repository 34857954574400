.envSpecTableRow {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #D7DAE1; */
  /* box-shadow: 0 1px rgba(0, 0, 0, 0.2); */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  filter: drop-shadow(0px 1px 1px rgba(9, 30, 66, 0.25))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  min-height: 44px;
  /* width: 800px; */
  border-radius: 4px;
  background: white;
  padding: 0 12px;
}

.envSpecTableRowLeftCol {
  /* margin-left: 13px; */
  margin-top: 12px;
  max-width: 80%;
}

.envSpecTableRowRightCol {
  /* margin-right: 7px; */
  margin-top: 6px;
}

.envSpecTableRow .ant-skeleton {
  display: flex;
  align-items: center;
}

.envSpecTableRow .ant-skeleton .ant-skeleton-button {
  width: 120px;
}

img.envSpecAdded {
  content: url("../../../../../../assets/images/product/checkBlue.png");
  display: inline-block;
  margin-right: 5px;
  width: 13.33px;
  height: 13.33px;
}

img.envSpecPending {
  margin-bottom: 2px;
  margin-right: 5px;
  width: 13.33px;
  height: 13.33px;
}

.envSpecStateBtn {
  /* margin-right: 5px; */
  padding: 10px;
  height: 32px;
  background-color: #eff2f3;
  text-transform: none;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 120px;
}

.envSpecEditBtn {
  color: #26abe1;
}

.envSpecEditBtn:hover,
.envSpecAddBtn:hover {
  background-color: #eff2f3;
  filter: drop-shadow(0px 1px 1px rgba(9, 30, 66, 0.25))
    drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  border-color: #eff2f3 !important;
}

.envSpecEditBtn:hover {
  color: #26abe1 !important;
}

.envSpecAddBtn:hover {
  color: #000000e0 !important;
}

.envSpecEditBtn,
.envSpecAddBtn {
  border: none;
}

.buttonDisableSection {
  opacity: 0.8;
  pointer-events: none;
}
