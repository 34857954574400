.ConfirmModal.ant-modal .ant-modal-content {
  border-radius: 4px;
  padding: 10px 0px 18px;
}

.ConfirmButton__Btn {
  width: auto !important;
}

.ConfirmButton__Btn.loading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ConfirmButton__Btn.loading>svg {
  fill: #f9f9f9;
}