.submitted-submissions-status-text-complete {
  color: #1ec04a;
}

.submitted-submissions-status-text-complete path {
  fill: #1ec04a;
}

.submitted-submissions-status-text-progress {
  color: #e1b226;
}

.submitted-submissions-status-text-progress path {
  fill: #e1b226;
}

.submitted-submissions-status-text-samples-received,
.submitted-submissions-status-text-submitted {
  color: #6c7e8e;
}

.submitted-submissions-status-text-samples-received,
.submitted-submissions-status-text-submitted path {
  fill: #6c7e8e;
}

.submitted-submissions-product-spec-text {
  color: #19305a;
}

.submitted-submissions-product-spec-text path {
  color: #19305a;
}

.SampleSubmissionRow__DeleteIcon path:last-child,
.SampleSubmissionRow__DeleteIcon--disabled path:last-child {
  fill: transparent;
}

.SampleSubmissionRow__DeleteIcon--disabled path {
  fill: #19305a;
}

.SampleSubmissionRow__DeleteIcon--disabled path {
  fill: #afbdca6d;
}

.SampleSubmissionRow__DeleteIcon:hover {
  cursor: pointer;
}

.SampleSubmissionRow__DeleteIcon:hover path {
  fill: #26abe1;
}

.submitted-submission-name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  -webkit-line-clamp: 4;
}
