.templateContainer {
  display: grid;
  grid-template-columns: 350px 1fr;
  column-gap: 24px;
  padding: 24px 24px 16px 24px;
  height: calc(100vh - 136px);
  background-color: #f4f6f8;
}

.TemplateTabsContainer {
  padding: 24px 24px 16px 24px;
  background-color: #f4f6f8;
}

.TemplateTabs.ant-tabs-top > .ant-tabs-nav {
  margin: 0px;
  z-index: 1;
}

.TemplateTabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: #f4f6f8;
  border: 0px;
  transition: none;
}

.TemplateTabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.TemplateTabs.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #1677ff;
  background: #ffffff;
  border-bottom: 0px;
}

.TemplateTabs.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}
