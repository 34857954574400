.SampleDetailsAutocompleteInput .SampleDetails__InputDiv {
  width: 100%;
}

.SampleDetailsAutocompleteInput {
  position: relative;
  display: inline-block;
}

.SampleDetailsAutocompleteInput > .ant-select {
  width: 100%;
}

.SampleDetailsAutocompleteInput .ant-select-clear {
  display: flex;
  align-items: center;
  inset-inline-end: 6px;
  margin-top: -7px;
}

.SampleDetailsAutocompleteInput .ant-select-auto-complete .ant-input:hover {
  border: 2px solid #97a3ae;
}

.SampleDetailsAutocompleteInput
  .ant-select-auto-complete
  .ant-input.sample-submission-input-error:hover {
  border: 2px solid red;
}

.SampleDetailsAutocompleteInput .ant-select-auto-complete .ant-input:focus {
  box-shadow: none;
}

.AntDAutocomplete__DropdownArrow {
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 7px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: -6px;
  cursor: pointer;
}

.AntDAutocomplete__DropdownArrow path {
  stroke: #97a3ae;
  stroke-width: 1.5;
}

/* Dropdown styling */
.SampleSubmissionDropdown.ant-select-dropdown .ant-select-item-option {
  font-family: "Roboto";
  color: #506375;
}

/* Dropdown scrollbar styling */
.SampleSubmissionDropdown.ant-select-dropdown .rc-virtual-list-scrollbar {
  right: 0px !important;
  width: 4px !important;
}

.SampleSubmissionDropdown.ant-select-dropdown .rc-virtual-list-scrollbar-thumb {
  background-color: #c4d2df !important;
}
