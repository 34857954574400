.COABuilderDraggable {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 6px;
  position: relative;
  margin-bottom: 8px;
  cursor: grab;
}

.COABuilderDraggable--disabled {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  position: relative;
  margin-bottom: 8px;
  cursor: default;
}

.COABuilder__CheckmarkIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  flex-shrink: 0;
}

.COABuilder__DeleteIconContainer {
  position: relative;
  height: 14px;
  width: 14px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  flex-shrink: 0;
}

.COABuilder__DeleteIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;
  position: absolute;
  top: -4.5px;
  left: -5px;
}

.COABuilder__EditIcon {
  width: 10px;
  height: 10px;
  cursor: pointer;
  flex-shrink: 0;
  /* margin-top: 4px; */
}

.COABuilder__EditIcon > path {
  fill: #26abe1;
}
