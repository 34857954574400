.deleteModal{
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 270px;
  z-index: 9;
  box-shadow: 0px 13px 37px 0px #00000036;
  background: white;
  border-radius: 4px;
  padding: 20px 24px;
  position: absolute;
  left: 90px;
  top:35%;
}

.dataFieldDeleteModal_fieldName{
  color: #26ABE1;
  word-break: break-all;
}

.dataFieldDeleteModal_text{
  font-weight: 500;
}

.dataFieldDeleteModal_buttonContainer{
  width: 100%;
}

.dataFieldDeleteModal_cancelButton{
  float: left;
  color: #26ABE1;
  border: none;
  font-weight:500;
}

.dataFieldDeleteModal_deleteButton{
  float: right;
  background-color: #E63559;
  color: white;
  padding: 6px 35px;
  border: none;
  border-radius: 4px;
}

