/** Font Registration **/
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black";
  src: url("./assets/fonts/roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: url("./assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin";
  src: url("./assets/fonts/roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Italic";
  src: url("./assets/fonts/roboto/Roboto-Italic.ttf") format("truetype");
}

/** Default tag styling **/
html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > iframe {
  /* FIX: Uncaught ReferenceError: process is not defined  */
  pointer-events: none;
}

[type="button"]:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

input {
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #fdfdfd;
  padding: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  outline: none;
}

label {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #375393;
}

form {
  width: 80%;
  padding: 10px 10px 10px 10px;
}

@media only screen and (min-width: 768px) {
  form {
    width: 60%;
    padding: 10px 10px 10px 10px;
  }
}

@media only screen and (min-width: 1024px) {
  form {
    width: 40%;
    padding: 10px 10px 10px 10px;
  }
}

/** Toast styling **/
.Toastify__toast-container--top-right {
  top: 4rem;
}

.Toastify__toast {
  min-height: 50px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.Toastify__progress-bar {
  transform-origin: right;
}

.toasts-container.toast-height {
  max-height: 50px;
  top: 60px !important;
}

.Toastify__toast-body {
  white-space: pre-line;
}

/** Ant Design styling **/
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: auto;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content,
.ant-layout {
  overflow: hidden !important;
}

/** Scrollbars-Custom styling **/
.ScrollbarsCustom {
  position: relative;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  height: 5px !important;
  background: inherit !important;
  bottom: 0 !important;
  height: 100% !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  background: #19305a !important;
  border-radius: 3px !important;
  height: 4px !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  width: 6.5px !important;
  background: inherit !important;
  top: 0 !important;
  height: 100% !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #19305a !important;
  border-radius: 3px !important;
  width: 4px !important;
}

/** Material UI **/
.MuiTooltip-popper {
  z-index: 6000 !important;
  color: #375393;
}

/** Bootstrap Tabs styling **/
.nav-tabs {
  border: none !important;
}

.nav-tabs .nav-item {
  margin-bottom: 0px !important;
}

.nav-tabs .nav-link {
  border: none !important;
}

/** Global classes **/
.Ant__SubmitBtn,
.Ant__DefaultBtn {
  border-radius: 4px;
  font-size: 14px;
  height: auto;
  width: 127px;
  font-family: "Roboto Medium";
  padding: 8px 14px 8px 14px;
  background-color: #f2f6fa;
  box-shadow: 0px 1px 2px 0px #1018280d;
  color: #26abe1;
  border-color: none;
}

.Ant__SubmitBtn.ant-btn-primary {
  color: #fff;
  background-color: #26abe1;
  box-shadow: none;
}

.Ant__SubmitBtn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background-color: #9dd9f2;
}

.Ant__SubmitBtn.ant-btn-primary:disabled,
.Ant__SubmitBtn.ant-btn-primary.ant-btn-disabled {
  color: #fff;
  background-color: #9dd9f2;
}

.Ant__DefaultBtn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #26abe1;
  border-color: #26abe1;
}

.Ant__RadioBtn .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #26abe1;
  background-color: #26abe1;
}

.ant-radio-wrapper:hover
  :where(.css-dev-only-do-not-override-1ck3jst).ant-radio-wrapper,
:where(.css-dev-only-do-not-override-1ck3jst).ant-radio-wrapper:hover
  .ant-radio-inner {
  border-color: #1677ff;
}

.Ant__RadioBtn .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #26abe1;
}

.Ant__RadioBtn .ant-radio-wrapper .ant-radio-checked::after {
  border: 1px solid #26abe1;
}

.OnboardInviteForm__MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.LoggedIn__Loading {
  height: 98vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LoggedIn__Loading__Text {
  font-family: "Roboto Medium";
  font-size: 24px;
  color: #182534;
}
