.PopoverMenu__Root .ant-popover-inner {
  padding: 0;
  border-radius: 4px;
  background: #FFFFFF;
}

.PopoverMenu__MenuList {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  min-width: 72px;
}

.PopoverMenu__MenuList .PopoverMenu__MenuListItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 2px;
}

.PopoverMenu__MenuList .PopoverMenu__MenuListItem:hover {
  background: rgba(0, 0, 0, 0.04);
}

.PopoverMenu__MenuList .PopoverMenu__MenuListItem span {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  color: #182534;
  flex: 1;
  user-select: none;
}

.PopoverMenu__MenuList .PopoverMenu__MenuListItem svg {
  fill: #182534;
  width: 16px;
}