.SampleSubmission__UploadImagePreviewContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.SampleSubmission__UploadImagePreviewContainer
  .SampleSubmission__CropPreviewContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.SampleSubmission__UploadImagePreviewContainer
  .SampleSubmission__CropPreviewContainer.hideCropPreviewContainer {
  opacity: 0;
  z-index: 0;
}

.SampleSubmission__CropPreviewContainer > canvas {
  object-fit: contain;
}

.SampleSubmission__UploadImagePreviewContainer .ReactCrop {
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
}

.SampleSubmission__UploadImagePreviewContainer .ReactCrop.hideReactCrop {
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
  pointer-events: none;
  opacity: 0;
}

.SampleSubmission__UploadImagePreviewContainer .ReactCrop__child-wrapper {
  background: white;
  width: fit-content;
}

.ReactCrop__child-wrapper > img.SampleSubmission__ReactCropImage {
  max-width: unset;
}

.SampleSubmission__UploadImagePreview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.env-upload-map-edit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eff2f3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 12px 12px;
}

.env-upload-map-edit-container.white-bg {
  background: #fdfdfd;
}

.SampleSubmission__UploadImageDelete {
  right: -12px;
  bottom: -12px;
  position: absolute;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 2.51685px 4.19475px rgba(9, 30, 66, 0.2))
    drop-shadow(0px 0px 0.838949px rgba(9, 30, 66, 0.31));
}

.SampleSubmission__UploadImageDelete:hover {
  cursor: pointer;
}

.SampleSubmission__UploadImageDelete svg {
  width: 16px;
  height: 16px;
}

.env-upload-map-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  color: #19305a;
  padding-left: 14px;
  height: 81px;
  justify-content: center;
}

.env-upload-map-img-size {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 2;
  text-align: left;
  margin-left: 2px;
}

.env-upload-map-title-name {
  display: flex;
  flex-direction: row;
}

.env-upload-map-title-edit-icon {
  margin-top: 5px;
  margin-left: 9px;
  cursor: pointer;
}

.env-upload-map-title-edit-icon path {
  fill: #19305a;
}

.env-upload-map-title-edit-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  height: 24px;
  background: rgb(239, 242, 243, 0.45);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #19305a;
  width: 42vw;
}

.env-upload-map-title-edit-input-display {
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: left;
  max-width: 42vw;
  white-space: pre;
  overflow: hidden;
}

.env-upload-map-error-card {
  margin-left: 1px;
  margin-top: 2px;
}

.env-upload-map-error-card-content {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #e63559;
}
