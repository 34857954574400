.signupSubtitle {
  /* width: 100px; */
  width: 140px;
  margin-bottom: 5px;
  /* margin-top: 15px; */
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #375393;
}

.SignUpInput {
  width: 90%;
  height: 40px;
  padding: 0px 10px;
  background-color: white;
  border: 2px solid #d7dae1;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;

  line-height: 40px;
  font-family: "Roboto";
  font-size: 14px;
  color: #19305a;
}

.SignUpInput:focus {
  outline: none;
  border: 2px solid #26abe1;
  box-sizing: border-box;
}

.SignUpInput.SignUpInput--disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #19305add;
}

.SignUpInput.SignUpInput--error,
.SignUpInput.SignUpInput--error:focus {
  border: 2px solid red;
}
