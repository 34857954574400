.MyAccountHelp__Layout {
  background-color: white;
}

.MyAccountHelp__Layout__Body {
  overflow: hidden;
  /* padding-right: 2px !important; */
}

.MyAccountHelp__Layout__Body__Title {
  margin-bottom: 10px;
}

.MyAccountHelp__Layout__Body__Row {
  height: 100%;
  padding-bottom: 60px;
  overflow: auto;
}

.MyAccountHelp__Layout__Body__ColContent {
  height: 100%;
  overflow: auto;
  margin-top: 5px;
}

.MyAccountHelp__Layout__Body__ColContent__Second {
  font-family: "Roboto Medium";
  font-size: 12px;
  color: #375393;
  margin-top: 30px;
  margin-bottom: 20px;
}

.MyAccountHelp__Layout__Body__ColContent__SendBtn {
  float: right;
  width: 100px;
}

.MyAccountHelp__Layout__Body__ColImg {
  height: 100%;
  overflow: auto;
  min-width: 170px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.MyAccountHelp__Layout__Body__ColImg__Image {
  float: right;
  width: 277px;
  height: 248px;
}
