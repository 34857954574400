.TemplateBuilder__StatementCard {
  display: flex;
  flex-direction: column;
  /* gap: 32px; */
  padding: 23px 32px 40px 12px;
}

.StatementCard__Droppable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  /* padding-right: 165px; */
  border-radius: 4px;
}

.StatementCard__Droppable > div {
  margin-bottom: 23px;
}
