.OnboardingFormLayout__SampleSubmission{
    margin: unset !important;
    padding: 50px 150px;
    height: calc(100vh - 50px);
    background-image: radial-gradient(circle, #26abe126 30%, transparent);
}
.Onboarding__SampleSubmission__Form{
    height: auto !important;
}

.OnboardingFormLayout__SampleSubmission>.FormLayout__Body{
    box-shadow: 0px 0px 5px 0px #091E4214;
    padding: 32px 71px;
    border: unset;
    border-radius: 16px;
    overflow: scroll;
}

.Onboarding__SampleSubmission__Form .ant-form-item{
    padding-bottom: unset !important;
}

.OnboardingContainer_Divider{
    border: 1px solid #EAF0F5;
    margin-bottom: 25px;
    margin-top: 25px;
}

.Onboarding__RadioBtn label.ant-radio-wrapper{
    color: #6C7E8E;
}

.Onboarding__LabelText_Fields{
    margin-bottom: 16px !important;
    font-family: "Roboto medium";
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #6C7E8E;
    align-items: center;
}

.Onboarding__SampleSubmission__Form .ant-select .ant-select-selector{
    background-color: #F2F6FA !important;
    border-color: unset;
    border: none !important;
}

.Onboarding__SampleSubmission__Form .ant-select-selection-placeholder{
    color: #182534;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.Ant__CancelBtn{
    width: 127px;
    height: auto;
}

.Onboarding__SampleSubmission__Form .ant-select-outlined.ant-select-multiple .ant-select-selection-item{
    background-color: #FFFFFF;
    border: 1px solid #C4D2DF;
    border-radius: 4px;
    /* Padding: 5px 4px 5px 8px; */
}

.Onboarding__SampleSubmission__Form .ant-select .ant-select-selector{
    color: #182534;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.Onboarding__SampleSubmission__Form .ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-content{
    margin-inline-end: 10px
}

.Onboarding__SampleSubmission__Form .anticon-close{
    fill: #182534
}

.Onboarding__Scrollbar  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background-color: #b3bfdb !important;
  }

.OnboardingFormBtn__Button{
    padding-bottom: 10px;
}