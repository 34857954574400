.SampleResultsModal.ant-modal .ant-modal-content {
  padding: 0px;
  border-radius: 0px;
  background-color: #fdfdfd;
  margin-bottom: -5px;
}

.SampleResultsModal.ant-modal .ant-modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border-radius: 4px;
  margin-top: -2px;
  border: none;
}

.SampleResultsModal__CloseIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-top: 2px;
}

.SampleResultsModal .SampleResultsModal__Header {
  height: 50px;
  padding: 11px 16px 15px 16px;
  background-color: #375393;
  color: #fdfdfd;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: space-between;
  display: flex;
}

.SampleResultsModal__Body {
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto Medium";
  font-weight: 500;
  color: rgb(25, 48, 90);
  padding: 18px 12px 0px;
  margin: 10px 10px 0px;
  background: rgb(255, 255, 255);
}

.SampleResultsModal__SubHeader__Top {
  display: flex;
  justify-content: space-between;
}

.SampleResultsModal__SubHeader__Entry {
  display: flex;
  text-align: start;
  font-family: "Roboto";
  font-weight: 400;
  height: fit-content;
}

.SampleResultsModal__SubHeader__Label {
  color: #758ab9;
  min-width: 100px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
}

.SampleResultsModal__SubHeader__Label--date {
  color: #758ab9;
  margin-right: 20px;
  font-family: "Roboto Medium";
  font-weight: 500;
  font-size: 14px;
}

.SampleResultsModal__SubHeader__Divider {
  padding-bottom: 5px;
}

.SampleResultsModal__SubHeader__Bottom {
  display: flex;
  margin-bottom: 26px;
  text-align: flex-start;
  height: fit-content;
}

.SampleResultsModal__Loading {
  margin-top: 50px;
  margin-bottom: 77px;
  height: 325px;
  background: #eff2f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SampleResultsModal__Loading--retest {
  margin-top: 50px;
  margin-bottom: 151px;
  height: 325px;
  background: #eff2f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SampleResultsModal__Footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 15px;
  align-items: center;
}

.SampleResultsModal__Footer > div {
  width: 100%;
}

.SampleResultsModal__CarouselControls {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
  padding-bottom: 20px;
}

.SampleResultsModal__Footer__SpecsFlagAndButtons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.SampleResultsModal__Footer__FinalResult {
  float: left;
}

.SampleResultsModal__Footer__Label {
  font-family: "Roboto Medium";
  font-size: 14px;
  margin-right: 20px;
  color: rgb(117, 138, 185);
  padding-left: 5px;
}

.SampleResultsModal__Footer__SpecsFlag {
  font-family: "Roboto Medium";
  display: inline-block;
  height: 32px;
  line-height: 28px;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
}

.SampleResultsModal__Footer__SpecsFlag > img {
  margin-right: 5px;
  width: 13.33px;
  padding-bottom: 2px;
}

.SampleResultsModal__Footer__Buttons {
  display: flex;
  gap: 8px;
}

.dateBlockContainer {
  align-items: center;
}

button.SampleResultsModal__AddSpecsButton {
  height: 32px;
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #f9f9f9;
  border: 2px solid transparent;
  text-transform: none;
  background-color: #26abe1;
  min-width: 107px;
  /* margin-right: 12px; */
}

button.SampleResultsModal__AddSpecsButton:hover:not(:active),
button.SampleResultsModal__AddSpecsButton:focus-visible {
  background-color: #9dd9f2;
}

button.SampleResultsModal__AddSpecsButton:hover:active {
  background-color: #7dcded;
}

.SampleResultsModal__PickingRetestsContainer {
  display: flex;
  gap: 10px;
}

.SampleResultsModal__CancelRetestRequestButton {
  background-color: #eff2f3 !important;
  color: #19305a !important;
  border-color: #eff2f3 !important;
  width: 122px;
}

.SampleResultsModal__CancelRetestRequestButton:hover {
  background-color: #26abe1;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.SampleResultsModal__RetestRequestButton {
  width: 155px;
}

.SampleResultsModal__RetestRequestButton.StyledButton.ant-btn-primary:disabled {
  background-color: #eaf0f5;
  color: #afbdca;
  font-family: "Roboto Medium";
}

/** Scrollbar styling and dynamic height **/
.SampleResultsModal__SampleIDScrollBar.ScrollbarsCustom {
  max-height: 100px;
}

.SampleResultsModal__SampleIDScrollBar .ScrollbarsCustom-Wrapper,
.SampleResultsModal__SampleIDScrollBar .ScrollbarsCustom-Scroller {
  max-height: inherit;
  position: unset !important;
}

.SampleResultsModal__SampleIDScrollBar .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background-color: #b3bfdb !important;
}

.SampleResultsModal__SampleIDScrollBar .ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
	background-color: #133e8c !important;
}

.SampleResultsModal__SampleID {
  padding-right: 5px;
  word-break: break-all;
}

.LinkedFieldTitle,
.LinkedFieldValue {
  max-width: 708px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: wrap;
}

.LinkedFieldsDelimiterDot {
  background-color: #19305a;
  margin: 0px 5px;
}

.SampleResultsModal__Product {
  height: fit-content;
  overflow: visible; 
  box-sizing: content-box;
  padding-right: 5px
}