.create-env-modal .ant-modal-content {
  width: 55vw;
  height: 76.4vh;
  padding: 20px 21px 29px 20px;
  min-width: 500px;
}

.create-env-modal .ant-modal-content .ant-modal-body {
  height: 100%;
}

.create-env-modal-header {
  height: 123px;
  text-align: center;
}

.create-env-modal-header-only-swab {
  height: 50px;
  text-align: center;
}

.create-env-modal-header h2 {
  font-family: "Roboto bold";
  font-size: 20px;
  line-height: 28px;
  color: #19305a;
  margin-bottom: 24px;
}

.create-env-modal-steps-process {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-env-modal-steps-process-blue-bar-step-one {
  width: 212px;
  height: 7px;
  background: #eff2f3;
}

.create-env-modal-steps-process-blue-bar-step-two {
  width: 212px;
  height: 7px;
  background: #26a1d2;
}

.create-env-modal-body {
  height: calc(100% - 211px);
  margin-top: 29px;
  margin-bottom: 34px;
}

.create-env-modal-body-only-swab {
  height: calc(100% - 138px);
}
