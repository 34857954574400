.CompositeModal__Container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-family: "Roboto";
}

.CompositeModal__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  gap: 8px;
  color: #19305a;
}

.CompositeModal__Body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.CompositeModal__InputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CompositeModal__InputQuestion__Container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.CompositeModal__InputQuestion {
  font-family: "Roboto Medium";
  font-size: 16px;
  color: #182534;
}

.CompositeModal__SelectionContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.CompositeModal__SelectionInputQuestion {
  font-family: "Roboto Medium";
  font-size: 14px;
  color: #6c7e8e;
}

.CompositeModal__SelectionInputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.CompositeModal--MarginLeft {
  margin-left: 12px;
}

.CompositeModal__SelectionInput {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-family: "Roboto Medium";
  font-size: 14px;
  color: #182534;
  width: fit-content;
}

.CompositeModal__Footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.CompositeModal__Footer__Button {
  width: 72px !important;
  height: 28px !important;
}

.CompositeModal__Checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26abe1 !important;
  border-color: #26abe1 !important;
}

.CompositeModal__Checkbox
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #26abe1b0 !important;
  border-color: #26abe185 !important;
}

.CompositeModal__Checkbox
  .ant-checkbox-wrapper-disabled
  span:not(.ant-checkbox) {
  color: #234b5bb0;
}
